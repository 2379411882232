@charset "UTF-8";
/* reset */
.matomeBody {
  --body-bg-color: #fbf2f2;
  --body-text-color: #000000;
  --color-white: #ffffff;
  --color-black: #000000;
  --color01: #fbf2f2;
  --color02: #ffc8cf;
  --color03: #b2b2b2;
  --color04: #00b6eb;
  --color05: #fff600;
  --color06: #808080;
  --color07: #404040;
  --color08: #666666;
  --color09: #b5b5b5;
  --color10: #a09a9a;
  --color11: #2a1c04;
  --color12: #00fec8;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-tab-size: 4;
  tab-size: 4;
  font-size: 16px;
}
@media screen and (max-width: 989.8px) {
  html {
    height: -webkit-fill-available;
  }
}

html:focus-within {
  scroll-behavior: auto;
}

.matomeBody {
  margin: 0;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  text-align: left;
  /* overflow-x: hidden; */
  /* set */
  background-color: var(--body-bg-color);
  color: var(--body-text-color);
  font-family: "Noto Sans JP", "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif ;
  font-weight: 300;
  letter-spacing: 0.01em !important;
  font-size: 1rem !important;
  line-height: 2.12 !important;
}
@media screen and (max-width: 989.8px) {
  .page_loaded {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}

.matomeBody main {
  display: block;
}

.matomeBody p,
.matomeBody table,
.matomeBody blockquote,
.matomeBody address,
.matomeBody pre,
.matomeBody iframe,
.matomeBody form,
.matomeBody figure,
.matomeBody dl {
  margin: 0;
  line-height: 2.12;
}

p:not([class]) {
  margin-bottom: 1rem;
}

.matomeBody h1,.matomeBody  .h1,
.matomeBody h2,.matomeBody  .h2,
.matomeBody h3,.matomeBody  .h3,
.matomeBody h4,.matomeBody  .h4,
.matomeBody h5,.matomeBody  .h5,
.matomeBody h6,.matomeBody  .h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

ol:not([class]), ul:not([class]), dl:not([class]) {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol:not([class]) ol, ul:not([class]) ul, ol:not([class]) ul, ul:not([class]) ol {
  margin-bottom: 0;
}

ul[role=list],
ol[role=list] {
  margin: 0;
  padding: 0;
  list-style: none;
}

.matomeBody dt {
  font-weight: bold;
}

.matomeBody dd {
  margin-left: 0;
}

.matomeBody dd:not([class]) {
  margin-bottom: 0.5rem;
}

.matomeBody hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
}

.matomeBody pre {
  font-family: monospace, monospace;
  font-size: inherit;
}

.matomeBody address {
  font-style: inherit;
}

.matomeBody a {
  color: var(--body-text-color);
  background-color: transparent;
  text-decoration: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.5em;
  color: var(--body-text-color);
  word-break: break-all;
  text-decoration: underline;
}

a:not([class]):not([href]),
a:not([class]):not([href]):hover,
a:not([class]):not([href]):focus {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

.matomeBody b,
.matomeBody strong {
  font-weight: bolder;
}

.matomeBody code,
.matomeBody kbd,
.matomeBody samp {
  font-family: monospace, monospace;
  font-size: inherit;
}

.matomeBody small {
  font-size: 80%;
}

.matomeBody sub,
.matomeBody sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.matomeBody sub {
  bottom: -0.25em;
}

.matomeBody sup {
  top: -0.5em;
}

.matomeBody img {
  vertical-align: middle;
  border-style: none;
  -webkit-backface-visibility: hidden;
  height: auto;
}

.matomeBody img,
.matomeBody embed,
.matomeBody object,
.matomeBody iframe {
  vertical-align: bottom;
}

.matomeBody img,
.matomeBody picture {
  max-width: 100%;
  display: block;
}

.matomeBody button,
.matomeBody input,
.matomeBody optgroup,
.matomeBody select,
.matomeBody textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit;
}

[type=checkbox] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

[type=radio] {
  -webkit-appearance: radio;
  appearance: radio;
}

.matomeBody button,
.matomeBody input {
  overflow: visible;
}

.matomeBody button,
.matomeBody select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

button[disabled],
[type=button][disabled],
[type=reset][disabled],
[type=submit][disabled] {
  cursor: default;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.matomeBody select::-ms-expand {
  display: none;
}

.matomeBody option {
  padding: 0;
}

.matomeBody fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}

.matomeBody legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

.matomeBody progress {
  vertical-align: baseline;
}

.matomeBody textarea {
  overflow: auto;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  /* 1 */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.matomeBody label[for] {
  cursor: pointer;
}

.matomeBody details {
  display: block;
}

.matomeBody summary {
  display: list-item;
}

summary[class]::-webkit-details-marker {
  display: none;
}

[contenteditable] {
  outline: none;
}

.matomeBody table {
  border-collapse: collapse;
  border-spacing: 0;
}

.matomeBody caption {
  text-align: left;
}

.matomeBody td,
.matomeBody th {
  vertical-align: top;
  padding: 0;
}

.matomeBody th {
  text-align: left;
  font-weight: bold;
}

.matomeBody template {
  display: none;
}

[hidden] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* style */
@media screen and (max-width: 989.8px) {
  .pc_only {
    display: none;
  }
}
@media screen and (min-width: 990px) {
  .pc_only {
    display: inherit;
  }
}

@media screen and (min-width: 990px) {
  .sp_only {
    display: none;
  }
}

/****************************
parts
****************************/
.matomeBody .page_list-wrap .page_list {
  display: grid;
  justify-content: flex-start;
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_list {
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_list {
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}
.matomeBody .page_list-wrap .page_card {
  display: grid;
  height: 100%;
  position: relative;
  background-color: var(--color-white);
  border: 1px solid var(--color03);
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_card {
    padding: 5px 5px 15px;
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_card {
    padding: 20px 10px 30px;
  }
}
.page_list-wrap .page_card:after {
  content: "";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -50%);
  left: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../public/assets/img/icon_arrow.svg");
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_card:after {
    bottom: 2px;
    background-size: 12px;
    width: 14px;
    height: 10px;
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_card:after {
    bottom: 4px;
    background-size: 18px;
    width: 20px;
    height: 16px;
  }
}
.matomeBody .page_list-wrap .page_card:hover {
  background-color: var(--color04);
  color: #000000 !important;
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_card .card_body {
    padding: 5px 0 10px;
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_card .card_body {
    padding: 20px 0 30px;
  }
}
.matomeBody .page_list-wrap .page_card .count {
  line-height: 1.55;
  text-align: right;
  font-family: "Maven Pro", sans-serif;
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_card .count {
    font-size: 0.8125rem;
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_card .count {
    font-size: 0.9375rem;
  }
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_card .count > span {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_card .count > span {
    font-size: 0.875rem;
  }
}
.page_list-wrap .page_card .title {
  line-height: 1.55;
  text-align: center;
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_card .title {
    font-size: 1rem;
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_card .title {
    font-size: 1.875rem;
  }
}
.matomeBody .page_list-wrap .page_card .sub_title {
  line-height: 1.55;
  text-align: center;
  font-weight: 300;
}
@media screen and (max-width: 989.8px) {
  .page_list-wrap .page_card .sub_title {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 990px) {
  .page_list-wrap .page_card .sub_title {
    font-size: 1.125rem;
  }
}

.matomeBody .list_toolbox {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
@media screen and (max-width: 989.8px) {
  .list_toolbox {
    max-width: 560px;
    margin: 0 auto;
  }
}
.matomeBody .list_toolbox .pagination_count-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.matomeBody .list_toolbox .pagination_count-wrap .pagination_count {
  color: var(--color08);
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  line-height: 1.55;
}
@media screen and (max-width: 989.8px) {
  .list_toolbox .order_link-wrap {
    display: none;
  }
}
@media screen and (min-width: 990px) {
  .list_toolbox .order_link-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px 15px;
  }
}
.matomeBody .list_toolbox .order_link-wrap .order_link {
  color: var(--color08);
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  line-height: 1.55;
}
.matomeBody .list_toolbox .order_link-wrap .order_link:hover {
  text-decoration: underline;
}
.matomeBody .list_toolbox .order_link-wrap .order_link.is_active {
  color: var(--color04) !important;
}
.matomeBody .list_toolbox .order_link_select-wrap {
  position: relative;
  text-align: center;
  border: 1px solid var(--color03);
  border-radius: 25px;
  width: 60%;
  max-width: 240px;
}
@media screen and (max-width: 989.8px) {
  .list_toolbox .order_link_select-wrap {
    display: block;
  }
}
@media screen and (min-width: 990px) {
  .list_toolbox .order_link_select-wrap {
    display: none;
  }
}
.matomeBody .list_toolbox .order_link_select-wrap:before {
  transform: translate(0, -50%) rotate(45deg);
  position: absolute;
  display: inline-block;
  border-right: 2px solid var(--color-black);
  border-bottom: 2px solid var(--color-black);
  content: "";
  right: 10px;
  top: calc(50% + 1px);
  width: 8px;
  height: 8px;
}
.matomeBody .list_toolbox .order_link_select-wrap .order_link_select {
  width: 100%;
  line-height: 1;
  padding: 15px 30px 15px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 25px;
}
.matomeBody .list_toolbox .order_link_select-wrap .order_link_select:focus {
  outline: revert;
}

.matomeBody .pagination-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px 16px;
}
.matomeBody .pagination-wrap .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px 8px;
}
.matomeBody .pagination-wrap .pagination_number {
  /* width: max-content; */
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  font-weight: 500;
}
@media screen and (max-width: 989.8px) {
  .pagination-wrap .pagination_number {
    font-size: 1rem;
    width: 34px;
    height: 34px;
  }
}
@media screen and (min-width: 990px) {
  .pagination-wrap .pagination_number {
    font-size: 1.125rem;
    width: 40px;
    height: 40px;
  }
}
.matomeBody .pagination-wrap .pagination_number.is_active {
  color: var(--color-white) !important;
  background-color: var(--color-black);
}
.matomeBody .pagination-wrap .pagination_number.is_disable {
  pointer-events: none;
  color: var(--color09);
  background-color: unset;
}
.matomeBody .pagination-wrap .pagination_prev,
.matomeBody .pagination-wrap .pagination_next {
  width: max-content;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 500;
}
.matomeBody .pagination-wrap .pagination_prev:before,
.matomeBody .pagination-wrap .pagination_next:before {
  position: absolute;
  display: inline-block;
  border-right: 2px solid var(--color-black);
  border-bottom: 2px solid var(--color-black);
  content: "";
  top: calc(50% + 1px);
  width: 8px;
  height: 8px;
}
.matomeBody .pagination-wrap .pagination_prev.is_disable,
.matomeBody .pagination-wrap .pagination_next.is_disable {
  pointer-events: none;
  color: var(--color09) !important;
}
.matomeBody .pagination-wrap .pagination_prev.is_disable:before,
.matomeBody .pagination-wrap .pagination_next.is_disable:before {
  border-right: 2px solid var(--color09);
  border-bottom: 2px solid var(--color09);
}
.matomeBody .pagination-wrap .pagination_prev {
  padding-left: 12px;
}
.matomeBody .pagination-wrap .pagination_prev:before {
  left: 2px;
  transform: translate(0, -50%) rotate(135deg);
}
.matomeBody .pagination-wrap .pagination_next {
  padding-right: 12px;
}
.matomeBody .pagination-wrap .pagination_next:before {
  right: 2px;
  transform: translate(0, -50%) rotate(-45deg);
}
.matomeBody .pagination-wrap .pagination_number:not(.is_active):not(.is_disable):hover, .pagination-wrap .pagination_number:not(.is_active):not(.is_disable):focus,
.matomeBody .pagination-wrap .pagination_prev:not(.is_disable):hover,
.matomeBody .pagination-wrap .pagination_prev:not(.is_disable):focus,
.matomeBody .pagination-wrap .pagination_next:not(.is_disable):hover,
.matomeBody .pagination-wrap .pagination_next:not(.is_disable):focus {
  text-decoration: underline;
  opacity: 0.9;
}

/****************************
contents_container
****************************/
.matomeBody .contents_container {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}



.matomeBody .contents_container-grey {
  position: relative;
  background-color: #d9d9d9;
  margin-top: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.matomeBody .contents_container-grey .grey-box{
  height: auto;
}



.matomeBody .contents_container-grey .grey-box h3 {
  font-size: 25px;
  text-align: left;
  font-weight: 400;
  margin-bottom: 20px;
}

.matomeBody .contents_container-grey .grey-box p {
  text-align: left;
  font-weight: 500;
  margin-bottom: 20px;
}

.matomeBody .contents_container-grey .footer_copy {
  font-weight: 400;
}

.matomeBody .contents_container-grey .btnMatome {
  position: relative;
  width: 300px;
  background-color: #595248;
  border: none;
  color: #fff;
  border-radius: 50px;
  border: 1px solid black;
  font-size: 20px;
  padding: 15px 10px;
  margin-bottom: 10px;
}


.matomeBody .contents_container-grey .btnMatome:hover {
  background: #736b60 !important;
}


.matomeBody .contents_container-grey .btnMatome  img{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 15px;
  width: 15px !important;
  height: auto;
}



@media(max-width: 768px){
  .matomeBody .contents_container-grey .grey-box{
    padding: 10px;
    margin-left: 0;
  }
  .matomeBody .contents_container-grey .btnMatome {
    margin-left: 0;
  }

  .matomeBody .contents_container-grey .grey-box h3 {
    font-size: 25px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .matomeBody .contents_container-grey .grey-box p {
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 989.8px) {
  .contents_container {
    max-width: 680px;
  }
}
@media screen and (min-width: 990px) {
  .contents_container {
    max-width: 1440px;
  }
}

/****************************
header
****************************/
.matomeBody #header {
  background-color: var(--color-white) !important;
  border-bottom: 1px solid var(--color-black)!important;
  inset-block-start: 0!important;
  position:fixed !important;
  z-index: 100 !important;
  padding: 0 !important;
  display: block !important;
  width: 100%;
}
.matomeBody #header .header_container {
  position: relative;
  max-width: 1600px;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
}
@media screen and (min-width: 1240px) {
  .header .header_container {
    align-items: center;
  }
}
.header .header_logo-wrap {
  display: grid;
}
@media screen and (max-width: 1239.8px) {
  .header .header_logo-wrap {
    padding: 8px 5px 8px 20px;
  }
}
@media screen and (min-width: 1240px) {
  .header .header_logo-wrap {
    padding: 15px 0 15px 20px;
  }
}
.matomeBody #header .header_logo {
  display: grid;
  width: max-content;
  white-space: nowrap;
  font-feature-settings: "palt";
}
.matomeBody #header .header_logo .top {
  width: 100%;
  line-height: 1;
  margin-bottom: 10px;
}
.matomeBody #header .header_logo .top .text {
  display: inline-block;
  font-weight: 300;
  transform-origin: left bottom;
  transform: scaleY(0.8);
  width: 100%;
  letter-spacing: calc(0.01em * 1.2);
}
@media screen and (max-width: 1239.8px) {
  .header .header_logo .top .text {
    font-size: clamp(1.0625rem, 2.72vw, 1.275rem);
  }
}
@media screen and (min-width: 1240px) {
  .header .header_logo .top .text {
    font-size: clamp(1.375rem, 1.65vw, 1.65rem);
  }
}
.matomeBody #header .header_logo .bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  line-height: 1;
}
@media screen and (max-width: 1239.8px) {
  .header .header_logo .bottom {
    gap: 6px;
  }
}
@media screen and (min-width: 1240px) {
  .header .header_logo .bottom {
    gap: 10px;
  }
}
.matomeBody #header .header_logo .bottom .text1 {
  display: inline-block;
  font-weight: 300;
  transform-origin: left bottom;
  letter-spacing: calc(0.01em * 1.2);
  transform: scaleY(0.8);
  width: 100%;
}
@media screen and (max-width: 1239.8px) {
  .header .header_logo .bottom .text1 {
    font-size: clamp(1.0625rem, 2.72vw, 1.275rem);
    margin-top: max(calc((2.2666666667vw * 0.2) * -1), calc(3.4 * -1px));
  }
}
@media screen and (min-width: 1240px) {
  .header .header_logo .bottom .text1 {
    font-size: clamp(1.375rem, 1.65vw, 1.65rem);
    margin-top: max(calc((1.4375vw * 0.2) * -1), calc(4.6 * -1px));
  }
}
.matomeBody #header .header_logo .bottom .text2 {
  display: inline-block;
  font-weight: 500;
}
@media screen and (max-width: 1239.8px) {
  .header .header_logo .bottom .text2 {
    font-size: 0.625rem;
  }
}
@media screen and (min-width: 1240px) {
  .header .header_logo .bottom .text2 {
    font-size: 0.75rem;
  }
}
.matomeBody #header .sp_menu_button-wrap {
  margin-left: auto;
  border-left: 1px solid var(--color-black);
}
@media screen and (min-width: 1240px) {
  .header .sp_menu_button-wrap {
    display: none;
  }
}
.matomeBody #header .sp_menu_button-wrap .sp_menu_button {
  cursor: pointer;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3px;
  height: 100%;
}
.matomeBody #header .sp_menu_button-wrap .sp_menu_button > span {
  background-color: var(--color-black);
  display: inline-block;
  width: 25%;
  height: 4px;
  font-size: 0;
}
.matomeBody #header .sp_menu_button-wrap .sp_menu_button:hover, .header .sp_menu_button-wrap .sp_menu_button:focus, .header .sp_menu_button-wrap .sp_menu_button.is_open {
  background-color: var(--color05);
}
.matomeBody #header .sp_menu_button-wrap .sp_menu_button:focus {
  outline: revert;
}
.matomeBody #header .sp_menu_button-wrap .sp_menu_button.is_open > span:nth-child(1) {
  transform: rotate(45deg) translate(2px, 3px);
  transform-origin: center;
}
.matomeBody #header .sp_menu_button-wrap .sp_menu_button.is_open > span:nth-child(2) {
  display: none;
}
.matomeBody #header .sp_menu_button-wrap .sp_menu_button.is_open > span:nth-child(3) {
  transform: rotate(-45deg) translate(2px, -3px);
  transform-origin: center;
}
.matomeBody #header .pc_menu-wrap {
  margin-left: auto !important;
  padding-left: 20px;
}
@media screen and (max-width: 1239.8px) {
  .header .pc_menu-wrap {
    display: none;
  }
}
@media screen and (min-width: 1240px) {
  .header .pc_menu-wrap {
    padding: 15px 20px 15px 0 !important;
  }
}
.matomeBody #header .pc_menu-wrap .pc_menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0 14px;
  margin-left: 0 !important;
}

.matomeBody #header .pc_menu-wrap .pc_menu > li {
  margin: 0 !important;
  cursor: pointer;
  position: relative;
}
.matomeBody #header .pc_menu-wrap .pc_menu_link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  line-height: 1;
  font-weight: 500;
  margin:  0 !important;
}
@media screen and (min-width: 1240px) {
  .header .pc_menu-wrap .pc_menu_link {
    font-size: 1.1875rem;
  }
}
.matomeBody #header .pc_menu-wrap .pc_menu_link span {
  padding: 6px 10px;
  display: inline-block;
}
.matomeBody #header .pc_menu-wrap .has_sub_menu {
  padding-bottom: 14px;
}
.matomeBody #header .pc_menu-wrap .has_sub_menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../public/assets/img/icon_arrow.svg");
  width: 16px;
  height: 8px;
}
.matomeBody #header .pc_menu-wrap .pc_menu > li .pc_menu_link.is_active span, .header .pc_menu-wrap .pc_menu > li:hover .pc_menu_link span, .header .pc_menu-wrap .pc_menu > li:focus .pc_menu_link span,
.matomeBody #header .pc_menu-wrap .pc_menu > li .pc_menu_link:focus span {
  background-color: var(--color05);
}
.matomeBody #header .pc_sub_menu-wrap {
  display: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 100%;
  padding-top: 10px;
  z-index: 110;
}
.matomeBody #header .pc_sub_menu-wrap.last_pc_sub_menu {
  right: 0;
  left: unset;
  transform: unset;
}
.matomeBody #header .pc_sub_menu-wrap.is_active {
  display: block;
}
.matomeBody #header .pc_sub_menu-wrap .pc_sub_menu {
  display: block !important;
  position: relative;
  width: max-content;
  white-space: nowrap;
  border: 1px solid var(--color-black);
  background-color: var(--color-white);
}
.matomeBody #header .pc_sub_menu-wrap .pc_sub_menu > li:not(:last-child) {
  margin-right: 0 !important;
  border-bottom: 1px solid var(--color-black);
}
.matomeBody #header .pc_sub_menu-wrap .pc_sub_menu_link {
  font-weight: 300;
  position: relative;
  display: inline-block;
  text-decoration: none;
  line-height: 1.55;
  padding: 10px 15px;
  width: 100%;
  cursor: pointer;
  margin-left: 0 !important;
}
.matomeBody #header .pc_sub_menu-wrap .pc_sub_menu_link:hover, .header .pc_sub_menu-wrap .pc_sub_menu_link:focus {
  background-color: var(--color04);
}
.matomeBody #header .has_sub_menu.is_action_click .pc_sub_menu-wrap {
  display: block;
}

.matomeBody .sp_menu-wrap {
  display: none;
  overflow: hidden;
  position: sticky;
  z-index: 90;
  inset-block-start: 58px;
  height: 100vh;
  background-color: #ffffff;
}
.matomeBody .sp_menu-wrap.is_open {
  display: block;
}
@media screen and (min-width: 1240px) {
  .sp_menu-wrap {
    display: none;
  }
}
.matomeBody .sp_menu-wrap .sp_menu_logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.matomeBody .sp_menu-wrap .sp_menu_logo-wrap > .sp_menu_logo {
  max-width: 160px;
}
.matomeBody .sp_menu-wrap .sp_menu-inner {
  background-color: var(--color02);
  overflow: hidden;
  overflow-y: auto;
  max-height: 100%;
  height: 100%;
  line-height: 1.55;
  padding-bottom: 60px;
}
.matomeBody .sp_menu-wrap .sp_menu > li {
  background-color: #fbf2f2;
  border-bottom: 1px solid var(--color11);
}
.matomeBody .sp_menu-wrap .sp_menu .sp_menu_link {
  padding: 15px 20px;
  width: 100%;
  display: block;
}
.matomeBody .sp_menu-wrap .sp_menu .has_sub_menu.sp_menu_link_arrow.is_open:after {
  transform: translate(0px, -50%) rotate(360deg);
}
.matomeBody .sp_menu-wrap .sp_menu .has_sub_menu.sp_menu_link_arrow.is_open + .sp_sub_menu-wrap {
  display: block;
}
.matomeBody .sp_menu-wrap .sp_sub_menu-wrap {
  display: none;
  padding-left: 20px;
  background-color: #ffffff;
  border-top: 1px solid var(--color11);
}
.matomeBody .sp_menu-wrap .sp_menu_link_arrow {
  position: relative;
}
.matomeBody .sp_menu-wrap .sp_menu_link_arrow:hover, .sp_menu-wrap .sp_menu_link_arrow:focus, .sp_menu-wrap .sp_menu_link_arrow.is_open {
  background-color: #00b6eb;

}
.matomeBody .sp_menu-wrap .sp_menu_link_arrow:after {
  content: "";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0px, -50%) rotate(-90deg);
  top: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../public/assets/img/icon_arrow.svg");
  right: 20px;
  background-size: 14px;
  width: 16px;
  height: 16px;
}
.matomeBody .sp_menu-wrap .sp_sub_menu {
  border-left: 1px solid var(--color11);
}
.matomeBody .sp_menu-wrap .sp_sub_menu > li:not(:first-child) {
  border-top: 1px solid var(--color11);
}
.matomeBody .sp_menu-wrap .sp_sub_menu .sp_sub_menu_link {
  padding: 15px 20px 15px 40px;
  width: 100%;
  display: block;
  position: relative;
  color: var(--color04);
  text-decoration: underline;
}
.matomeBody .sp_menu-wrap .sp_sub_menu .sp_sub_menu_link:hover, .sp_menu-wrap .sp_sub_menu .sp_sub_menu_link:focus {
  text-decoration: none;
}
.matomeBody .sp_menu-wrap .sp_sub_menu .sp_sub_menu_link:before {
  content: "";
  display: inline-block;
  color: var(--color-black);
  background-color: var(--color-black);
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

/****************************
footer
****************************/
.matomeBody .sub_footer .sub_footer_mv-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}
@media screen and (min-width: 990px) {
  .sub_footer .sub_footer_mv-wrap {
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.matomeBody .sub_footer .sub_footer_mv-wrap .sub_footer_mv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.matomeBody .sub_footer .title-wrap {
  line-height: 1;
}
@media screen and (max-width: 989.8px) {
  .sub_footer .title-wrap {
    padding-top: 50px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .title-wrap {
    padding-top: 80px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 989.8px) {
  .sub_footer .title-wrap .title {
    font-size: clamp(1.375rem, 8vw, 1.875rem);
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .title-wrap .title {
    font-size: 3.75rem;
  }
}
.matomeBody .sub_footer .title-wrap .title .title_bg {
  background: linear-gradient(transparent 10%, var(--color02) 0%, var(--color02) 90%, transparent 0%);
  display: inline;
  line-height: 1.2em;
  vertical-align: top;
}
.matomeBody .sub_footer .title-wrap .title > span {
  line-height: 1.2em;
  display: inline-block;
  vertical-align: top;
}
@media screen and (max-width: 989.8px) {
  .sub_footer .title-wrap .title > span:not(:last-child) {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .title-wrap .title > span:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 989.8px) {
  .sub_footer .message_list-wrap {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .message_list-wrap {
    margin-bottom: 60px;
  }
}
.matomeBody .sub_footer .message_list-wrap .message_list {
  line-height: 2.12 !important;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media screen and (max-width: 989.8px) {
  .sub_footer .message_list-wrap .message_list {
    gap: 5px 0;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .message_list-wrap .message_list {
    gap: 5px 0;
  }
}
.matomeBody .sub_footer .message_list-wrap .message_list li {
  width: 100%;
}
@media screen and (min-width: 990px) {
  .sub_footer .message_list-wrap .message_list li {
    font-size: 1.875rem;
  }
}
.matomeBody .sub_footer .message_list-wrap .message_list .emoji {
  padding-right: 5px;
  font-family: "Noto Color Emoji", sans-serif;
}
@media screen and (max-width: 989.8px) {
  .sub_footer .voice_group {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .voice_group {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 989.8px) {
  .sub_footer .voice_group .voice_title-wrap {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .voice_group .voice_title-wrap {
    margin-bottom: 30px;
  }
}
.matomeBody .sub_footer .voice_group .voice_title-wrap .voice_title {
  font-size: 1.25rem;
}
.matomeBody .sub_footer .voice_group .voice_title-wrap .emoji {
  padding-right: 5px;
  font-family: "Noto Color Emoji", sans-serif;
}
.matomeBody .sub_footer .voice_group .voice_list-wrap {
  display: grid;
  justify-content: center;
}
@media screen and (max-width: 989.8px) {
  .sub_footer .voice_group .voice_list-wrap {
    gap: 5px;
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .voice_group .voice_list-wrap {
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.matomeBody .sub_footer .voice_group .voice_list {
  border: 1px solid var(--color03);
  background-color: var(--color-white);
}
@media screen and (max-width: 989.8px) {
  .sub_footer .voice_group .voice_list {
    border-radius: 10px;
    padding: 20px 15px 15px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .voice_group .voice_list {
    border-radius: 20px;
    padding: 30px 25px 25px;
  }
}
.matomeBody .sub_footer .voice_group .voice_list .top {
  display: flex;
  /* justify-content: center; */
  align-items: flex-start;
}
@media screen and (max-width: 989.8px) {
  .sub_footer .voice_group .voice_list .top {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .voice_group .voice_list .top {
    margin-bottom: 25px;
  }
}
.matomeBody .sub_footer .voice_group .voice_list .bottom {
  display: block;
}
.matomeBody .sub_footer .voice_group .voice_list .img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 989.8px) {
  .sub_footer .voice_group .voice_list .img {
    flex-basis: 44px;
  }
}
@media screen and (min-width: 990px) {
  .sub_footer .voice_group .voice_list .img {
    flex-basis: 54px;
  }
}
.matomeBody.sub_footer .voice_group .voice_list .profile {
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .sub_footer .voice_group .voice_list .profile {
    padding-left: 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .sub_footer .voice_group .voice_list .profile {
    padding-left: 20px;
  }
}
.matomeBody .sub_footer .voice_group .voice_list .profile .name {
  display: block;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 5px;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .sub_footer .voice_group .voice_list .profile .name {
    font-size: 0.8125rem;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .sub_footer .voice_group .voice_list .profile .name {
    font-size: 1.125rem;
  }
}
.matomeBody .sub_footer .voice_group .voice_list .profile .text {
  line-height: 1.5;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .sub_footer .voice_group .voice_list .profile .text {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .sub_footer .voice_group .voice_list .profile .text {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .sub_footer .voice_group .voice_list .profile_text {
    font-size: 0.75rem;
  }
}

.matomeBody .footer {
  position: relative;
  padding: 0 !important;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .footer {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .footer {
    padding-bottom: 30px;
  }
}
.matomeBody .footer .contents_container {
  position: relative;
}
.footer:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: red !important; */
}
.matomeBody .footer:before {
  top: 0 !important;
}
.matomeBody .footer .footer_logo-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .footer .footer_logo-wrap {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .footer .footer_logo-wrap {
    margin-bottom: 15px;
  }
}
.matomeBody .footer .footer_logo-wrap .footer_logo_link:hover {
  opacity: 0.8;
}
.matomeBody .footer .footer_logo-wrap .footer_logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .footer .footer_logo-wrap .footer_logo {
    max-width: 220px;
  }
}
.matomeBody .footer .footer_register-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .footer .footer_register-wrap {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .footer .footer_register-wrap {
    margin-bottom: 45px;
  }
}
.matomeBody .footer .footer_register-wrap .footer_register_btn:hover {
  opacity: 0.8;
}
.matomeBody .footer .footer_register-wrap .footer_register {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.matomeBody .footer .footer_message-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (min-width: 990px) {
  /* .matomeBody .footer .footer_message-wrap {
    padding-left: 15em;
  } */
}
@media screen and (max-width: 989.8px) {
  .matomeBody .footer .footer_message-wrap .footer_message {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .footer .footer_message-wrap .footer_message {
    font-weight: 300;
    text-align: start;
    display: block;
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .footer .footer_message-wrap .message_block {
    display: block;
    padding-left: 50px;
  }
  /* .matomeBody .footer .footer_message-wrap .message_block:nth-of-type(2) {
    padding-left: 3em;
  }
  .matomeBody .footer .footer_message-wrap .message_block:nth-of-type(3) {
    padding-left: 6em;
  }
  .matomeBody .footer .footer_message-wrap .message_block:nth-of-type(4) {
    padding-left: 9em;
  }
  .matomeBody .footer .footer_message-wrap .message_block:nth-of-type(5) {
    padding-left: 12em;
  } */
}
.matomeBody .footer .footer_message-wrap .footer_copy {
  display: block;
  text-align: center;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .footer .footer_message-wrap .footer_copy {
    margin-top: 40px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .footer .footer_message-wrap .footer_copy {
    margin-top: 20px;
    padding-left: 15em;
    text-align: left;
  }
}

/****************************
top
****************************/
@media screen and (max-width: 989.8px) {
  .matomeBody .section_mvv {
    padding-top: 50px;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_mvv {
    margin-top: 85px;
    padding-top: 90px;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_mvv .mv_title-wrap {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 100px;
  }
}

@media screen and (max-width:700px) {
  .matomeBody .section_mvv .mv_title-wrap {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 50px;
  }
}

@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .mv_title-wrap {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 100px;
  }
}

@media screen and (max-width:700px) {
  .matomeBody .section_detail_mv .mv_title-wrap {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 50px;
  }
}

.matomeBody .section_mvv .mv_title-wrap .mv_title {
  font-feature-settings: "palt";
  line-height: 1.3;
  font-weight: 100;
  transform-origin: left bottom;
  transform: scaleY(0.8);
  width: 100%;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_mvv .mv_title-wrap .mv_title {
    font-size: clamp(2.5rem, 14.4vw, 3.15rem);
    letter-spacing: calc(0.01em * 1.2);
    margin-top: max(calc((60vw * 0.2) * -1), calc(90 * -1px));
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_mvv .mv_title-wrap .mv_title {
    font-size: clamp(2.875rem, 12.3vw, 12.3rem);
    letter-spacing: calc(0.01em * 1.2);
    margin-top: max(calc((48.125vw * 0.2) * -1), calc(154 * -1px));
  }
}

@media screen and (max-width: 989.8px) {
  .matomeBody .section01 {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 {
    margin-bottom: 50px;
  }
}
.matomeBody .section01 .title-wrap {
  display: grid;
  line-height: 1;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section01 .title-wrap {
    gap: 15px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 .title-wrap {
    gap: 25px;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section01 .title-wrap {
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 .title-wrap {
    margin-bottom: 60px;
  }
}
.matomeBody .section01 .title-wrap .title {
  font-feature-settings: "palt";
  font-weight: 200;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section01 .title-wrap .title {
    font-size: clamp(1.375rem, 8vw, 1.875rem);
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 .title-wrap .title {
    font-size: 3.75rem;
  }
}
.matomeBody .section01 .title-wrap .title .title_bg {
  background: linear-gradient(transparent 10%, var(--color02) 0%, var(--color02) 90%, transparent 0%);
  line-height: 1.5em;
  vertical-align: top;
  display: inline;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section01 .title-wrap .title .title_bg {
    padding-left: 6px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 .title-wrap .title .title_bg {
    padding-left: 10px;
  }
}
.matomeBody .section01 .title-wrap .title span {
  line-height: 1.2em;
  display: inline-block;
  vertical-align: top;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section01 .title-wrap .title span:not(:last-child) {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 .title-wrap .title span:not(:last-child) {
    margin-bottom: 25px;
  }
}
.matomeBody .section01 .text1 {
  line-height: 2.23;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section01 .text1 {
    font-size: 1rem;
    margin-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 .text1 {
    font-size: 1.875rem;
    margin-bottom: 60px;
    padding-right: 150px;
  }
}
.matomeBody .section01 .image1-wrap {
  /* display: flex; */
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}
@media screen and (min-width: 990px) {
  .matomeBody .section01 .image1-wrap {
    max-width: 1440px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.matomeBody .section01 .image1-wrap .image1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 989.8px) {
  .matomeBody .section02 {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section02 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section02 .text1 {
    font-size: 0.75rem;
    margin-bottom: 30px;
    padding: 0 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section02 .text1 {
    font-size: 1.25rem;
    margin-bottom: 40px;
    padding-right: 150px;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section02 .text2 {
    font-size: 0.75rem;
    margin-top: 30px;
    padding: 0 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section02 .text2 {
    font-size: 1.25rem;
    margin-top: 60px;
  }
}

/****************************
detail
****************************/
.matomeBody .section_detail_mv {
  position: relative;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv {
    padding-top: 70px;
    padding-bottom: 90px;
  }
}
.matomeBody .section_detail_mv .contents_container {
  max-width: 1200px;
}

.matomeBody .section_detail_mv .mv_title-wrap .mv_title {
  font-feature-settings: "palt";
  line-height: 1.4;
  font-weight: 100;
  transform-origin: left bottom;
  transform: scaleY(0.8);
  width: 100%;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .mv_title-wrap .mv_title {
    font-size: clamp(1.75rem, 13.0285714286vw, 2.85rem);
    letter-spacing: calc(0.01em * 1.2);
    margin-top: max(calc((21.3333333333vw * 0.2) * -1), calc(32 * -1px));
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .mv_title-wrap .mv_title {
    margin-top: 10px !important;
    font-size: clamp(2.875rem, 6.75vw, 6.75rem);
    letter-spacing: calc(0.01em * 1.2);
    margin-top: max(calc((23.75vw * 0.2) * -1), calc(76 * -1px));
  }
}
.matomeBody .section_detail_mv .lead_text-wrap {
  display: grid;
  line-height: 1;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .lead_text-wrap {
    gap: 15px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .lead_text-wrap {
    gap: 25px;
    margin-top: 60px;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .lead_text-wrap {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .lead_text-wrap {
    margin-bottom: 10px;
  }
}
.matomeBody .section_detail_mv .lead_text-wrap .lead_text {
  font-feature-settings: "palt";
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .lead_text-wrap .lead_text {
    font-size: clamp(1.125rem, 3.4666666667vw, 1.625rem);
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .lead_text-wrap .lead_text {
    font-size: clamp(1.125rem, 3.4666666667vw, 1.625rem);
  }
}
.matomeBody .section_detail_mv .lead_text-wrap .lead_text .title_bg {
  line-height: 2;
  background: linear-gradient(transparent 0%, var(--color02) 0%, var(--color02) 100%, transparent 0%);
  vertical-align: top;
  display: inline;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .lead_text-wrap .lead_text .title_bg {
    padding-left: 6px;
    padding-right: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .lead_text-wrap .lead_text .title_bg {
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.matomeBody .section_detail_mv .note_text-wrap {
  margin-top: 10px;
}
.matomeBody .section_detail_mv .note_text-wrap .note_text {
  color: var(--color10);
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .note_text-wrap .note_text {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .note_text-wrap .note_text {
    font-size: 0.875rem;
  }
}
.matomeBody .section_detail_mv .note_text-wrap .note_text + .note_text {
  margin-top: 10px;
}
.matomeBody .section_detail_mv .mv_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .mv_logo {
    margin-top: 10px;
    width: 50%;
    margin: auto;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .mv_logo {
    position: absolute;
    bottom: 30px;
    right: max(30px, calc((100% - 1600px) / 2));
  }
}
.matomeBody .section_detail_mv .update_balloon-wrap {
  display: inline-block;
  transform: scaleY(1.2);
  vertical-align: middle;
  margin-left: 10px;
}
.matomeBody .section_detail_mv .update_balloon-wrap .update_balloon {
  display: flex;
  flex-direction: column;
  gap: 2px;
  line-height: 1;
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid #8f8f8f;
  color: #8f8f8f;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section_detail_mv .update_balloon-wrap .update_balloon {
    font-size: 14px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section_detail_mv .update_balloon-wrap .update_balloon {
    font-size: 16px;
  }
}

.matomeBody .section03 {
  background-color: var(--color-white);
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 {
    padding: 25px 0 45px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 {
    padding: 45px 0 65px;
  }
}
.matomeBody .section03 .contents_container {
  max-width: 1200px;
}
.matomeBody .section03 .job_list-wrap {
  display: flex;
  justify-content: center;
}
.matomeBody .section03 .job_list-wrap .job_list {
  display: grid;
  justify-content: flex-start;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_list-wrap .job_list {
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 560px));
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_list-wrap .job_list {
    gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}
.matomeBody .section03 .job_card {
  border: 1px solid var(--color03);
  background-color: var(--color-white);
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  min-width: 500px;
}
@media  (max-width: 768px) {
  .matomeBody .section03 .job_card {
    border: 1px solid var(--color03);
    background-color: var(--color-white);
    display: flex !important;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    min-width: auto !important;
  }
}
.matomeBody .section03 .job_card:hover, .section03 .job_card:focus {
  opacity: 0.9;
}
.matomeBody .section03 .job_card .card_top {
  border-bottom: 1px solid var(--color03);
  background-color: var(--color01);
  width: 100%;
  display: grid;
}
.matomeBody .section03 .job_card .card_bottom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_card .card_bottom {
    padding: 20px 10px 10px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_card .card_bottom {
    padding: 30px 15px 20px;
  }
}
.matomeBody .section03 .job_card .job_status-wrap_layer {
  grid-area: 1/1;
  z-index: 20;
}
.matomeBody .section03 .job_card .job_status-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: column;
  gap: 4px;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_card .job_status-wrap {
    padding-top: 4px;
    padding-right: 4px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_card .job_status-wrap {
    padding-top: 8px;
    padding-right: 8px;
  }
}
.matomeBody .section03 .job_card .job_status-wrap .job_status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 0.75rem;
  color: var(--color06);
  border: 1px solid var(--color03);
  background-color: var(--color-white);
  text-align: center;
  padding: 2px 6px;
  line-height: 1.55;
}
.matomeBody .section03 .job_card .job_thumbnail-wrap {
  grid-area: 1/1;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.matomeBody .section03 .job_card .job_thumbnail-wrap .job_thumbnail {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_card .job_thumbnail-wrap .job_thumbnail {
    max-width: 63%;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_card .job_thumbnail-wrap .job_thumbnail {
    max-width: 320px;
  }
}
.matomeBody .section03 .job_card .job_thumbnail-wrap .job_thumbnail > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.matomeBody .section03 .job_card .job_tag-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_card .job_tag-wrap {
    margin-bottom: 8px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_card .job_tag-wrap {
    margin-bottom: 15px;
  }
}
.matomeBody .section03 .job_card .job_tag-wrap .job_tag {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: 0.75rem;
  color: var(--color-white);
  background-color: var(--color07);
  text-align: center;
  padding: 2px 20px;
  border-radius: 10px;
  line-height: 1.55;
}
.matomeBody .section03 .job_card .job_detail_item-wrap {
  line-height: 1.75;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_card .job_detail_item-wrap {
    font-size: 0.75rem;
    margin-bottom: 6px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_card .job_detail_item-wrap {
    margin-bottom: 10px;
  }
}
.matomeBody .section03 .job_card .job_detail_item-wrap > li:before {
  content: "●";
}
.matomeBody .section03 .job_card .job_title {
  line-height: 1.55;
  margin-bottom: 10px;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_card .job_title {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_card .job_title {
    font-size: 1.5rem;
  }
}
.matomeBody .section03 .job_card .job_company_name {
  line-height: 1.55;
  margin-top: 30px;
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .job_card .job_company_name {
    font-size: 1rem;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .job_card .job_company_name {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .list_toolbox {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .list_toolbox {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 989.8px) {
  .matomeBody .section03 .pagination-wrap {
    margin-top: 30px;
  }
}
@media screen and (min-width: 990px) {
  .matomeBody .section03 .pagination-wrap {
    margin-top: 50px;
  }
}

/****************************
is_page
****************************/
.matomeBody .is_page_detail .sub_footer_mv-wrap {
  display: none;
}

/*# sourceMappingURL=style.css.map */
