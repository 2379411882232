#parachannel {
  // ページネーション共通(丸型)
  .pagination_circle {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;

    li {
      margin: 0 5px;

      @include mq(sp) {
        margin: 3px 5px;
      }

      a {
        font-size: 14px;
        color: $blue;

        span {
          width: 30px;
          padding: 8px 0;
          background-color: #c8c8c8;
          border-radius: 50%;
          text-align: center;
          color: #fff;
          display: block;
        }
      }

      &.prev {
        margin: 0 10px 0 0;

        &.disabled {
          display: none;
        }
      }

      &.next {
        margin: 0 0 0 10px;

        &.disabled {
          display: none;
        }
      }

      &.active span {
        background-color: $blue;
      }
    }

    &.small {
      @include mq(pc) {
        justify-content: flex-end;
      }

      @include mq(sp) {
        margin-top: 20px;
      }

      li a {
        font-size: 12px;

        span {
          width: 26px;
          padding: 7px 0;
        }
      }
    }
  }

  .pagination_square {
    display: flex;
    align-items: center;
    align-content: center;

    li {
      width: 20px;
      margin: 0 5px 0 0;

      &:last-child {
        margin: 0;
      }

      a {
        width: 100%;
        padding: 4px 0;
        text-align: center;
        color: $blue;
      }

      &.arrow {
        &.disabled {
          display: none;
        }

        a {
          padding: 0;
        }
      }

      &.active {
        background-color: $blue;

        a {
          color: #fff;
        }
      }
    }
  }
}
