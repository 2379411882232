#parachannel header {
  padding: 15px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  @include mq(sp) {
    padding: 10px 0;
    flex-wrap: wrap;
  }
  .logo a {
    display: flex;
    align-items: center;
    align-content: center;
    img {
      width: auto;
      max-width: none;
      height: 95px;
      @include mq(sp) {
        height: 60px;
      }
    }
    p {
      padding: 25px 15px 23px 20px;
      background: url(../img/header_bg.png) no-repeat;
      background-size: 100% 100%;
      font-size: 14px;
      line-height: 16px;
      margin-left: 15px;
      @include mq(sp) {
        display: none;
      }
    }
  }
  ul {
    display: flex;
    @include mq(sp) {
      width: 220px;
      flex-wrap: wrap;
    }
    li {
      margin-right: 35px;
      @include mq(pc) {
        &:last-child {
          margin: 0;
        }
      }
      @include mq(sp) {
        width: 57%;
        margin: 3px 0;
        &:nth-child(2n) {
          width: 43%;
        }
      }
      a {
        color: $gray;
        @include mq(sp) {
          font-size: 11px !important;
          display: flex;
          align-items: center;
          align-content: center;
        }
        img {
          width: 25px;
          margin: 0 auto 5px;
          @include mq(sp) {
            width: 15px;
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }
  .btn_box {
    width: 130px;
    position: relative;
    @include mq(sp) {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      &:after{
        content: "";
        width: 100vw;
        height: 100%;
        background-color: $lightblue;
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: -1;
      }
    }
    p {
      padding: 15px;
      font-size: 12px;
      position: absolute;
      top: -15px;
      left: -130px;
      z-index: 9;
      @include mq(sp) {
        display: none;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../img/header_bg.png) no-repeat;
        background-size: 100% 100%;
        transform: scale(-1, 1);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
      }
      span {
        font-size: 14px;
      }
    }
    a {
      height: 40px;
      background-color: #fff;
      border: 2px $blue solid;
      border-radius: 5px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      @include mq(sp) {
        width: 48%;
        height: 30px;
        margin: 0 1%;
      }
      img {
        width: 15px;
        margin-right: 5px;
        @include mq(sp) {
          width: 10px;
        }
      }
      &:nth-of-type(2) {
        @include mq(pc) {
          height: 25px;
          font-size: 10px;
          margin-top: 5px;
        }
      }
    }
  }
}