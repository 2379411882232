/* detail - cont_box */

#parachannel #jobs .detail {

  > p {
    line-height: 20px;
  }

  .cont_box {
    width: 58%;

    @include mq(sp) {
      width: 100%;
      margin-bottom: 50px;
    }

    .ttl {
      position: relative;
      padding: 0 30px 10px 0;
      line-height: 1.4;
      font-size: 24px;
      border-bottom: 1px $lightgray solid;
      font-family: $shuei;
      //font-size: 34px;
      margin-bottom: 20px;

      .edit_icon {
        position: absolute;
        right: 0;
        top: 0;
      }

      @include mq(sp) {
        margin-bottom: 10px;
        padding-bottom: 5px;
        font-size: 20px;
      }

      button {
        display: block;
        margin-top: 10px;
        padding: 2px 30px;
        border: none;
        background-color: #00a0e9;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .slider_for {
      margin-bottom: 30px;

      @include mq(sp) {
        margin-bottom: 10px;
      }

      li {
        position: relative;

        input[type="text"] {
          width: 100%;
          padding: 10px 15px;
          background-color: rgba(102,102,102,0.6);
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .slick-arrow {
        position: absolute;
        top: 50%;
        width: 40px;
        height: 50%;
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center;
        z-index: 9;
        cursor: pointer;
        transform: translateY(-50%);

        &.prev {
          background-image: url(../../img/jobs/arrow04.png);
          left: 0;
        }

        &.next {
          background-image: url(../../img/jobs/arrow05.png);
          right: 0;
        }
      }

      .slick-slide {

        li {
          position: relative;
          //height: 390px;
          padding-bottom: 75%;
          overflow: hidden;
          background-color: #f8f8f8;

          img {
            position: absolute;
            object-fit: contain;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: auto;
            // max-width: none;
            height: 100%;
          }

          @include mq(sp) {
            height: 260px;
          }

          .advice_txt {
            position: absolute;
            left: 50%;
            top: 10%;
            width: 100%;
            padding: 20px 0;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            transform: translateX(-50%);

            @include mq(sp) {
              font-size: 100%;
            }
          }
          .add_icon {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100px;
            height: 100px;
            transform: translate(-50%, -50%);

            @include mq(sp) {
              top: 60%;
              width: 80px;
              height: 80px;
            }
          }

          label {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            cursor: pointer;

            input {
              display: none;
            }
          }
        }

        .caption {
          width: 100%;
          padding: 10px 15px;
          background-color: rgba(102, 102, 102, 0.6);
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .trash {
          position: absolute;
          left: auto;
          right: 10px;
          top: 10px;
          width: 50px;
          height: 50px;
          padding: 8px;
          border-radius: 50%;
          background-color: #fff;
          cursor: pointer;
          z-index: 1;
          transform: translate(0,0);
        }
      }
    }

    .slider_nav {
      width: calc(100% + 5px);
      margin-left: -5px;

      li {
        position: relative;
        padding: 0 0 75%;
        overflow: hidden;
        > div {
          cursor: pointer;

          img {
            position: absolute;
            object-fit: contain;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: auto;
            // max-width: none;
            height: 100%;
            //height: 90px;
            // object-fit: cover;
            @include mq(sp) {
              height: 14vw;
            }
          }
        }
      }

      .slick-slide {
        padding: 0 5px;
      }
    }

    //メイン


    //サムネイル
    .slider_edit {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: space-between;

      li {
        position: relative;
        width: calc(96%/5);
        margin: 2% 0 0;
        border: solid 1px #ccc;

        // & > p {
        //   //height: 180px;
        // }
        &.img {
          position: relative;
          :first-child {
            height: 100px;
            object-fit: cover;
          }
        }
        p {
          position: relative;
          width: 100%;
          height: 100px;
          border: 1px $lightgray solid;

          .add_icon {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 40px;
            height: 40px;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .note {
      font-size: 12px;
      color: #f00;
      margin-top: 10px;
    }

    select {
      position: relative;
      margin-top: 30px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAANCAYAAABGkiVgAAAACXBIWXMAABCbAAAQmwF0iZxLAAABEUlEQVQ4jZ2T8VXCQAyHP7qAbuAI4ASygQxwecgGjuAIOIFAOkCZwI4gG+gGMEF96Qt9eddKkfzTS5P7Lr9cbrLbaQU8A1uR9MKNplpugCWwL4DaMUvV8u0WpGq5dqBZNWmaJp5ithJJm38ATd2Hu63aFupBq/jJg48i6esK4Bz4dHcvkha2KEKO/Tj4ulYtZyNAi1fu2r7uPrpKPfEe+AbuPHEuko4DQMszJQ/ADzCLebFSPGCSTsA0XGIOrB1oeYv84CLf5L08S5n6JUZb+4E4sNf7HtTB1quVu92oZaNjU9JTQt7TAalx1LZh/S6SXv/adxE6AG7hYy9vUH5mVtF51A7XPOVRaJgIk2/fywb8Ajl9dI573d3wAAAAAElFTkSuQmCC) no-repeat;
      background-size: 15px;
      background-position: right 10px center;
      cursor: pointer;
    }

    h3 {
      padding-bottom: 10px;
      border-bottom: 1px $lightgray solid;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      margin: 50px 0 20px;
      @include mq(sp) {
        padding-bottom: 5px;
        margin: 30px 0 10px;
      }
      img {
        width: 15px;
      }

      & + p {
        white-space: pre-wrap;
        line-height: 1.4;
        word-wrap: break-word;
      }
    }

    .tag_ttl {
      padding: 3px 10px;
      border-left: 5px $blue solid;
      font-weight: bold;
      line-height: 100%;
      margin: 40px 0 20px;
      @include mq(sp) {
        margin: 20px 0 10px;
      }
    }

    .tag {
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0 5px 5px 0;
        padding: 5px 10px;
        background-color: $blue;
        border-radius: 5px;
        color: #fff;

        a {
          display: block;
          width: 100%;
          height: 100%;
          color: #fff;

        }
      }
    }

    .map {
      width: 100%;
      height: 300px;
      @include mq(sp) {
        height: 200px;
      }
    }

    .appeal_area p {
      width: 100%;
      padding: 12px 0;
      background-color: #df0056;
      border-radius: 10px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      margin-top: 30px;
      position: relative;
      @include mq(sp) {
        padding: 7px 0;
        font-size: 14px;
        line-height: 18px;
      }
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        border-top: 20px #df0056 solid;
        border-right: 10px transparent solid;
        border-left: 10px transparent solid;
        box-sizing: border-box;
        position: absolute;
        right: 0;
        bottom: -20px;
        left: 0;
        margin: 0 auto;
      }
    }

    //合理的配慮
    .consideration_area {
      h3 {
        display: block;
      }

      .edit_area {
        position: relative;
        padding-right: 50px;

        .edit_icon {
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          cursor: pointer;
        }
        .select_area_child {
          padding: 20px;
          background-color: #f2f2f2;
          margin-bottom: 20px;

          @include mq(sp) {
            padding: 10px;
          }
        }

        button {
          display: block;
          margin: 10px 0 0;
          padding: 2px 30px;
          border: none;
          background-color: #00a0e9;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }

    .movie {
      position: relative;
      width: 100%;
      padding-top: 56.25%;

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .edit {
      position: relative;
      display: block;
      padding-right: 50px;
      line-height: 1.5;

      .edit_icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
      }
    }

    .movie_box {
      width: 100%;
      margin: 0 auto;
      position: relative;

      input[type="text"] {
        margin: 5px 0;
      }

      button {
        padding: 5px 20px;
        background-color: $blue;
        border: none;
        color: #fff;
        border-radius: 3px;
        font-weight: bold;
      }

      > img {
        width: 30px;
        position: absolute;
        right: 15px;
        bottom: 15px;
        z-index: 9;
      }
      > div {
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        z-index: 8;
        overflow: hidden;
        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .sidebar {
    & + .cont_box {
      @include mq(sp) {
        margin-top: 50px;
      }
    }

    .back_btn {
      margin: 0 0 40px;
      font-size: 120%;

      a {
        display: inline-block;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .no_image {
    position: relative;
    width: 100%;
    padding-bottom: 75%;
    text-align: center;
    background-color: #f7f7f7;

    span {
      display: block;
      margin-bottom: 20px;
      font-size: 110%;
    }

    a {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: 50px;
        height: 50px;
        margin: 0 auto;
      }
    }
  }

}
