#parachannel #login {
  padding: 35px 0 85px;
  @include mq(sp) {
    padding: 20px 0 0;
  }
  .wrapper > a:not(.btn) {
    width: 400px;
    height: 60px;
    margin: 50px auto 0;
    line-height: 56px;
    font-weight: bold;
    text-align: center;
    color: #000;
    font-size: 130%;
    border: solid 2px #00a0e9;
    border-radius: 5px;;

    @include mq(sp) {
      width: 90%;
      margin-top: 30px;
    }
  }
  h3 {
    text-align: center;
    color: $blue;
    margin: 80px 0 35px;
    line-height: 1.5;

    @include mq(sp) {
      margin: 30px 0 20px;
    }
  }
  .input_box .form > p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #f00;
    margin: 0px 0 40px;
    @include mq(sp) {
      font-size: 12px;
      line-height: 18px;
      margin: -10px 0 20px;
    }
  }
  /* password */
  .password .input_box {
    border-color: #fff;
  }
  /* company */
  .company {
    .input_box {
      border-color: #fff;
      @include mq(pc) {
        padding-top: 0;
      }
      .form {
        @include mq(pc) {
          width: 435px;
        }
        dl {
          @include mq(pc) {
            margin-bottom: 50px;
          }
          dt {
            width: 70px;
            color: $blue;
            @include mq(sp) {
              width: 50px;
            }
          }
          dd {
            width: calc(100% - 70px);
            @include mq(sp) {
              width: calc(100% - 50px);
            }
            input {
              border-color: $blue;
              border-radius: 5px;
            }
          }
        }
      }
    }
    > p {
      font-weight: bold;
      text-align: center;
      color: #c1272d;
      margin-bottom: 50px;
      @include mq(pc) {
        font-size: 16px;
      }
      @include mq(sp) {
        line-height: 18px;
        margin-bottom: 30px;
      }
    }
    .ttl {
      @include mq(pc) {
        margin-top: 50px;
      }
    }
    > a {
      width: 260px;
      padding: 16px 0;
      border: 1px $lightgray solid;
      font-weight: bold;
      text-align: center;
      text-decoration: underline;
      //color: $lightgray;
      color: #000;
      margin: 0 auto;
      @include mq(pc) {
        font-size: 16px;
      }
      @include mq(sp) {
        width: 150px;
        padding: 13px 0;
      }
    }
  }
  /* manager */
  .input_box .manager dl dd:nth-of-type(-n+2) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    @include mq(sp) {
      display: block;
    }

    label {
      //width: 48%;
      width: 100%;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;

      @include mq(sp) {
        width: 100%;
        margin-bottom: 5px;
        &:last-child {
          margin: 0;
        }
      }
      input {
        //width: calc(100% - 35px);
      }
    }
  }

  .input_box {
    button[type="submit"] {
      & + a {
        display: block;
        margin-top: 30px;
        font-size: 120%;
        color: #333;
        text-decoration: underline;
  
        @include mq(sp) {
          margin-top: 20px;
        }
      }
    }
  }

  
}

.input_box {
  width: 70%;
  margin: 0 auto;

  @include mq(sp) {
    width: 100%;
  }

  .other_login {
    width: 100%;
    margin: 50px auto 0;
    padding-top: 50px;
    border-top: solid 1px #ccc;

    @include mq(sp) {
      margin-top: 30px;
      padding-top: 30px;
    }
  }
}