.name_box {
    // padding-bottom: 15px;
    // border-bottom: 1px #b3b3b3 solid;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    // margin-bottom: 25px;
}

.message .name_box img[data-v-e13aa758] {
    width: 55px !important;
    margin: 5px 15px 5px 0;
}

#parachannel .message .name_box h3[data-v-e13aa758] {
    font-size: 16px;
}

#parachannel .message .name_box h3 span.company_name[data-v-e13aa758] {
    line-height: 40px;
}

#parachannel .message .name_box h3 span[data-v-e13aa758] {
    font-size: 14px;
    margin-left: 15px;
}

#parachannel .message .name_box .spacer[data-v-e13aa758] {
    flex-grow: 1;
}

#parachannel .message .name_box > a[data-v-e13aa758] {
    height: 50px;
    padding: 18px 40px;
    min-width: 194px;
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background-color: #00a0e9;
}

#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont .btn_cont > a {
    margin-bottom: 20px;
    padding: 18px 40px;
    min-width: 180px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background-color: #00a0e9;
}

#parachannel a {
    color: #231815;
    display: block;
    text-decoration: none;
    transition: all .2s;
}

#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont .btn_cont > a.disabled {
    cursor: not-allowed;
    background-color: gray;
}

.change_schedules_page {
    display: inline-block !important; 
    color: rgb(35, 24, 21); 
    border-bottom: 1px solid; 
    margin-bottom: 20px;
}

.openModal {
    display: inline-block !important; 
    color: rgb(35, 24, 21); 
    border-bottom: 1px solid;
}

#parachannel a {
    color: #231815;
    display: block;
    text-decoration: none;
    transition: all .2s;
}

.center {
    text-align: center;
    margin: 50px;
}

.scrollHide {
    overflow: hidden !important;
}

.div_percent {
    margin: 10px;
    width: 50%;
}

.display_flex {
    display: flex;
    margin: 15px;
}

.white-space {
    white-space: unset;
}

.mar20 {
    margin-top: 20px;
}
 