//  mypage - スケジュール

#parachannel #schedule {
  font-weight: bold;
  .pagination {
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;
    @include mq(sp) {
      margin-bottom: 25px;
    }
    a {
      display: flex;
      align-items: center;
      width: auto;
      padding: 5px 10px;
      background-color: $lightblue;
      color: $blue;

      &:first-child {
        padding-left: 5px;
      }
      &:last-child {
        padding-right: 5px;
      }

      img {
        width: 10px;
        margin: 0 5px;
      }
      @include mq(sp) {
        //width: 8px;
      }
    }
    p {
      font-size: 16px;
      margin: 0 20px;
      @include mq(sp) {
        font-size: 14px;
        margin: 0 10px;
      }
    }
  }
  > ul > li {
    padding-left: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    @include mq(sp) {
      padding: 0;
      display: block;
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
    }
    .date {
      width: 120px;
      color: $lightgray;
      display: flex;
      align-items: center;
      align-content: center;
      @include mq(sp) {
        width: 100%;
        margin-bottom: 5px;
      }
      span {
        width: 30px;
        padding: 7px 0;
        background-color: #f2f2f2;
        font-size: 16px;
        text-align: center;
        color: $blue;
        margin-right: 10px;
      }
    }
  }

  > ul > li {
    ul {
      width: calc(100% - 120px);
      padding: 10px 0;
      border-bottom: 1px $lightgray solid;
      @include mq(sp) {
        width: 100%;
        padding: 15px 0;
      }

      li {
        margin-bottom: 15px;
        @include mq(sp) {
          margin-bottom: 25px;
        }
        &:last-child {
          margin: 0;
        }
        a {
          padding: 0 55px 0 15px;
          background: url(../img/company/icon20.svg) no-repeat;
          background-size: 20px;
          background-position: right 5px center;
          display: flex;
          align-items: center;
          align-content: center;
          @include mq(sp) {
            padding: 0 30px 0 5px;
            flex-wrap: wrap;
          }
        }
      }
    }
    .time {
      width: 80px;
      @include mq(sp) {
        width: 70px;
        order: 1;
      }
    }
    .img_box {
      width: 40px;
      height: 40px;
      padding: 0;
      background-size: cover;
      background-position: center;
      background-color: #f2f2f2;
      border-radius: 50%;
      margin-left: 75px;
      @include mq(sp) {
        margin: 10px 0 0;
        order: 4;
      }
    }
    .name {
      width: calc(100% - 320px);
      padding: 0 15px;
      font-size: 16px;
      @include mq(sp) {
        width: calc(100% - 40px);
        padding: 0 10px;
        margin-top: 10px;
        order: 5;
      }
      span {
        font-size: 14px;
        &:nth-of-type(1) {
          color: $lightgray;
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    .category,
    .tag {
      padding: 3px 0;
      border: 1px solid;
      border-radius: 5px;
      text-align: center;
      @include mq(sp) {
        padding: 3px 0;
      }
    }
    .category {
      width: 50px;
      border-color: #ff4800;
      color: #ff4800;
      @include mq(sp) {
        width: 40px;
        margin-right: 15px;
        order: 2;
      }
      &.traning {
        border-color: $green;
        color: $green;
      }
    }
    .tag {
      width: 75px;
      border-color: $lightgray;
      color: $lightgray;
      @include mq(sp) {
        width: 65px;
        margin-right: calc(100% - 190px);
        order: 3;
      }
    }
  }
  + div .btn.type3 {
    width: 215px;
    padding: 12px 0;
    background-color: #e6e6e6;
    color: #231815;
    margin-top: 45px;
    @include mq(sp) {
      width: 200px;
      padding: 13px 0;
      margin-top: 25px;
    }
  }
}