#parachannel #company .schedule > ul {
    &.no_plan {
      padding-top: 20px;
      padding-bottom: 100px;
      & > li {
        display: initial;
      }
    }
    & > li ul li a .img_box {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
}