#parachannel #jobs > section .sidebar > div p.note {
  margin-top: 7px;
  font-weight: normal;
  font-size: unset;
  color: unset;
}
.premium-button {
  background-color: #fff;
  border: 2px #00699F solid !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 250px;
  margin: auto;
  position: relative;
  padding: 8px;
  margin-bottom: 10px;
}
.w-100{
  width: 100%;
}
#parachannel #jobs > section .cont_box {
  .btn.type3.notify {
    margin: 50px auto;
    width: 100%;
    max-width: 500px;
    line-height: 140%;
    &:hover {
			opacity: 0.8;
		}
  }
  .pagination {
    .disabled {
      cursor: not-allowed;
      color: gray
    }
  }
  .none_bottom_line {
    border-bottom: none;
  }
}

.load-area{
  width: 100%;
  height: 100%;
  padding-top: 30px;
  background: #fff;
  display: none;
}
.load-area.load-area-open{
    display: block;
}
.load-area.load-area-close{
    display: none;
}
.jobs_list.load-area-open{
    display: block;
}
.jobs_list.load-area-close{
    display: none;
}
.load-text {
  width: 100%;
  margin: auto;
  text-align: center;
}
.load-spinner {
  height: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 80px;
  height: 80px;
  border: 4px #999 solid;
  border-top: 4px #ddd solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}
.ext-header{
  text-align: end;
}
.ext-header .premium-button-md{
  background-color: #fff;
  border: 2px #00699F solid !important;
  border-radius: 5px;
  vertical-align: middle;
  min-width: 250px;
  padding: 8px;
  margin-bottom: 10px;
}
#parachannel .premium-button-md img{
  height: 18px;
  display: inline-block;
  vertical-align: bottom;
}
#parachannel .mb-1{
  margin-bottom: 8px !important;
}
@keyframes sp-anime {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}