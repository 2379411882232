.header{
    display: flex;
    align-items: center;
    width: "100%";
    justify-content: space-around;
}
.m-0 {
    margin: 0 !important;
    padding: 0 !important;
}
.header .btn_box a {
    height: 40px !important;
    background-color: #fff !important;
    border: 2px #00699F solid !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}