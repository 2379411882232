.ckeditor-title {
    text-align: center;
    color: #00a0e9;
    margin: 0 auto 20px;
}

#parachannel #company .medias .list_box li.active {
    border: 4px solid #00a0e9;
}

#parachannel .medias {
    text-align: center;
}

#parachannel .medias button {
    text-align: center;
    margin: 20px auto;
    border-radius: 5px;
    background-color: #00a0e9;
    font-size: 14px!important;
    padding: 0.5em 2em;
    color: #fff!important;
    cursor: pointer;
    border: 0;
}