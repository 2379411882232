$p_gray : #9e9f9f;
$pink : #df0056;

#parachannel {
  .modal_box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2147483647;
    margin: 0 !important;

    .modal_inner {
      max-height: 90vh;
      position: relative;
      left: 50%;
      top: 50%;
      //padding: 0 85px 35px;
      padding: 0;
      background-color: #F7F8F8;
      border-radius: 10px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      // overflow: scroll;

      @include mq(pc) {
        width: 730px;
      }
      @include mq(sp) {
        //padding: 0 20px 20px;
        width: 90%;
        //padding: 0 0 20px;
      }
      .btn.example {
        width: 200px;
        padding: 12px 0;
        margin-bottom: 30px;
      }
    }

    h4 {
      //width: calc(100% + 170px);
      width: 100%;
      height: 70px;
      margin: 0 auto;
      padding: 0;
      line-height: 70px;
      background-color: $navyblue;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      //margin: 0 -85px 60px;

      @include mq(sp) {
        padding: 0 50px 0 20px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        align-content: center;
      }
    }

    h5 {
      margin-bottom: 30px;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }

    .read {
      padding: 5% 5% 0;
      font-size: 15px;
      line-height: 1.5;

      &.end {
        margin-bottom: 5%;
        text-align: center;
      }
    }

    .change_box {
      padding: 5%;

      input[type="email"] {
        & + div span {
          display: block;
          margin-top: 5px;
          color: #f00;
          font-size: 90%;
          font-weight: bold;
        }
      }
    }

    form {
      max-height: 580px;
      padding: 30px 85px 60px;

      @include mq(sp) {
        max-height: 70vh;
        padding: 20px;
      }
    }
    #btn_prev {
      position: absolute;
      left: 20px;
      top: 0;
      margin: 0!important;
      line-height: 70px!important;
      color: #fff!important;
      font-size: 16px!important;
      cursor: pointer;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      height: 70px;
      font-weight: normal;
      line-height: 100%;
      text-align: center;
      cursor: pointer;
      margin: 0!important;
      color: #fff!important;
      line-height: 70px!important;

      span,
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 30px;
      }

      @include mq(sp) {
        width: 50px;
        padding: 8px 0 12px;
      }
    }
    .overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      cursor: pointer;
    }

    .slider,
    .slider_item {
      //padding: 0 2%;
      background-color: #fff;
      border-radius: 5px;
    }

    .slider_item {
      overflow: scroll;
      max-height: 450px;
      padding: 2%;

      @include mq(sp) {
        max-height: 250px;
      }
    }

    .note {
      margin-bottom: 10px;
    }

    label {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: -1px;
      padding: 10px 20px;
      line-height: 30px;
      border: solid 1px #efefef;
      transition: opacity .15s linear;
			-webkit-transition: opacity .15s linear;
      cursor: pointer;
      font-weight: normal;

      &::before {
        content: '\e5cc';
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'Material Icons';
        font-size: 30px;
        color: #006496;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
      }

      @include mq(pc) {
        &:hover {
          opacity: .7;
        }
      }

      span {
        width: 100%;
        padding-left: 10px;
        font-size: 16px;
        @include mq(sp) {
          padding-right: 15px;
        }
      }

      input[type="radio"] {
        width: 20px;
        height: 20px;
        margin: 0;
        text-align: left;
      }

      input[type="text"] {
        //width: 90%;
        width: 100%;
      }

      //テキスト入力の場合
      &.input_text {
        display: block;
        &::before {
          display: none;
        }
      }

      &.large_category {
        &:before {
          height: 30px;
          top: 0;
          right: 10px;
          bottom: 0;
          margin: auto;
          transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
        }
        &.on:before {
          transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
        }
      }

      &.small_category {
        padding-left: 50px;
        background-color: $lightblue;
      }
    }

    &.selectRadio1 {
      label::before {
        display: none;
      }
    }

    .radio_box {
      margin-bottom: 20px;
      @include mq(pc) {
        display: flex;
        align-items: center;
        align-content: center;
        .ttl {
          margin: 0;
        }
      }
      .type {
        display: flex;
        label {
          padding: 0;
          border: none;
          margin: 0 20px 0 0;
          @include mq(sp) {
            margin-right: 10px;
          }
          &:before {
            display: none;
          }
        }
      }
    }

    #btn_next,
    .complete {
      width: 200px;
      height: 50px;
      line-height: 50px;
      margin: -20px auto 30px;
      text-align: center;
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      cursor: pointer;
      @include mq(sp) {
        margin-top: 0;
      }
    }

    #btn_next {
      margin-top: 50px;
      background-color: #CEE5F2;
      color: #006496;
      @include mq(sp) {
        margin-top: 10px;
      }
    }

    .complete {
      margin-top: 0;
      background-color: #006496;
      color: #fff;
    }

    .btn_area {
      display: flex;
      justify-content: center;
      margin: 30px auto;

      button {
        margin: 0 10px;
        @include mq(sp) {
          margin: 0 5px;
        }
      }
      .btn_reset {
        width: 200px;
        margin: 0 10px;
        padding: 20px 0;
        background-color: #ccc;
        border-radius: 5px;
        border: none;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        color: #333;
        display: block;
        cursor: pointer;
        @include mq(sp) {
          width: 250px;
          font-size: 12px;
          margin: 0 5px;
        }
      }
    }

    .btn_cont {
      text-align: center;
      margin-bottom: 5%;

      .set_btn {
        padding: 18px 60px;
        min-width: 180px;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        background-color: $blue;

        margin-left: 0;
        display: inline-block;

        @include mq(sp) {
          min-width: 72px;
          padding: 12px 16px;
          font-size: 14px;
        }

      }
    }
    .submitBtn {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      ul{
        width: 260px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-right: 20px;

        li{
          background-color: #39b54a;
          border-radius: 1000px;
          width: 48%;
          text-align: center;
          a{
            font-size: 14px !important;
            padding: 0.5em;
            color: #fff !important;
            font-weight: bold;
            cursor: pointer;
          }
        }
        .draft {
          background-color: #ea5514;
        }
      }
    }
  }

  /*
  .modal.interview_modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    padding: 60px 0;
    overflow-y: auto;
    width: 100%;
    height: 110vh;
    background-color: rgba(0, 0, 0, 0.6);

    .modal_box {
      margin: 0 auto 100px;
      padding: 40px 40px 50px;
      width: 94%;
      max-width: 724px;
      background-color: #fff;
      border-radius: 12px;
      height: auto;
      position: static;

      @include mq(sp) {
        padding: 30px 20px 30px;
      }

      .modal_ttl {
        text-align: center;
        line-height: 1.6;
        font-weight: bold;

        dt {
          margin-bottom: 50px;
          color: $blue;
          font-size: 20px;

          @include mq(sp) {
            margin-bottom: 30px;
            font-size: 18px;
          }
        }

        dd {
          margin-bottom: 40px;
          font-size: 16px;

          @include mq(sp) {
            margin-bottom: 20px;
            font-size: 12px;
          }
        }
      }

      .change_box {
        input {
          margin-bottom: 70px;
          padding: 27px 20px;
          font-size: 18px;
          background-color: #f7f7f7;
          border: none;

          @include mq(sp) {
            margin-bottom: 30px;
            font-size: 16px;
          }

        }

        // 日時用
        .candidate_list {
          li {
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 60px;
            }
          }

          .date_ttl {
            font-size: 16px;
            margin-bottom: 14px;
          }

          .date_input_cont {
            display: flex;
            align-items: center;

            @include mq(sp) {
              flex-wrap: wrap;
            }

            .date,
            .time {
              margin-bottom: 0;
              min-width: 100px;
              margin-right: 5px;
              padding: 12px;
              font-size: 13px;
              font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif;

              @include mq(sp) {
                margin-bottom: 10px;
                min-width: auto;
              }
            }

            .date {
              @include mq(sp) {
                width: 55%;
              }
            }

            .time {
              @include mq(sp) {
                width: 35%;
              }
            }

            >span {
              margin-right: 5px;
            }

            .edit_cont {
              display: flex;
              min-width: 90px;

              span {
                img {
                  margin-left: 10px;
                  width: 32px;
                  min-width: 32px;
                  height: 32px;
                }
              }
            }
          }
        }
      }

      .btn_cont {
        text-align: center;

        .set_btn {
          padding: 18px 60px;
          min-width: 180px;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          background-color: $blue;

          margin-left: 0;
          display: inline-block;

          @include mq(sp) {
            min-width: 72px;
            padding: 12px 16px;
            font-size: 14px;
          }

        }
      }
    }

    &.select {
      .modal_box {
        padding: 0;
        overflow: hidden;
        .ttl {
          @include mq(sp) {
            font-size: 14px;
          }
        }

        .category_ttl {
          position: relative;
          padding: 20px 82px;
          text-align: center;
          background-color: #325a78;
          color: #fff;
          font-size: 17px;

          @include mq(sp) {
            font-size: 12px;
          }


          a {
            color: #fff;
            position: absolute;

            span {
              display: inline-block;
              margin: 0 10px;
              width: 10px;
              height: 10px;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;

              @include mq(sp) {
                width: 7px;
                height: 7px;

              }

            }

            &.prev {
              top: 50%;
              left: 10px;
              transform: translate(0, -50%);

              span {
                transform: rotate(135deg);
              }
            }

            &.next {
              top: 50%;
              right: 10px;
              transform: translate(0, -50%);

              span {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .modal_inner {
          padding: 60px;

          @include mq(sp) {
            padding: 30px 20px 30px;
          }

          .btn_cont {
            margin-top: 20px;
          }

          .modal_ttl {
            margin-bottom: 40px;
            text-align: center;
            font-size: 15px;
            font-weight: bold;
            @include mq(sp) {
              font-size: 13px;
              margin-bottom: 20px;
            }

          }

          ul {
            border: 1px solid $p_gray;

            li {
              position: relative;
              cursor: pointer;
              padding: 20px 50px 20px 50px;
              line-height: 1.6;
              font-size: 14px;
              border-bottom: 1px solid $p_gray;

              @include mq(sp) {
                font-size: 12px;
              }

              &:last-child {
                border-bottom: none;
              }

              &.active {
                background-color: #dbffff;
              }
            }

            &.radio {
              li {

                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 20px;
                  width: 18px;
                  height: 18px;
                  background-image: url(../img/mypage/modal_radio_off.svg);
                  transform: translate(0, -50%);
                }

                &.active {
                  &::before {
                    background-image: url(../img/mypage/modal_radio_on.svg);
                  }
                }

                &::after {
                  content: '';
                  position: absolute;
                  top: 48%;
                  right: 20px;
                  width: 10px;
                  height: 10px;
                  border-bottom: 2px solid $blue;
                  border-right: 2px solid $blue;
                  transform: rotate(-45deg) translate(0, -50%);

                  @include mq(sp) {
                    width: 7px;
                    height: 7px;

                  }

                }
              }
            }

            &.check {
              li {
                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 20px;
                  width: 20px;
                  height: 18px;
                  background-image: url(../img/mypage/modal_check_off.svg);
                  transform: translate(0, -50%);
                }

                &.active {
                  &::before {
                    background-image: url(../img/mypage/modal_check_on.svg);
                  }
                }

              }
            }
          }

        }
      }
    }
  }
  */

}
