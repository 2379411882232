/* SP-menu */
#SP-menu {
	
	@include mq(pc) {
		display: none;
	}
	@include mq(sp) {
		position: absolute;
		right: 0;
		top: 0;
		width: 6rem;
		height: 6rem;
		cursor: pointer;
	
		span {
			display: block;
			position: absolute;
			left: 50%;
			width: 3rem;
			height: .4rem;
			margin-left: -1.5rem;
			transition: all .2s;
			-weblit-transition: all .2s;
			background-color: #000;
			
			&:first-child {top: 2rem;}
			&:nth-child(2) {top: 3rem;}
			&:last-child {top: 4rem;}
		}

		
		/* .opened */
		&.opened {
			span {
				//background-color: #fff;
				
				&:nth-child(2) {
					opacity: 0;
				}
				&:first-child {
					-webkit-transform: translateY(1rem) rotate(45deg);
					transform: translateY(1rem) rotate(45deg);
				}
				
				&:last-child {
					-webkit-transform: translateY(-1rem) rotate(-45deg);
					transform: translateY(-1rem) rotate(-45deg);
				}
			}
		}
	}
	
	/*横向きの際に適用*/
	@media only screen and (orientation : landscape) {
		
	}
	
}