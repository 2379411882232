#parachannel #about {
  margin: 0;
  section {
    padding-bottom: 140px;
    background-color: #d7f5ff;
    @include mq(sp) {
      padding-bottom: 50px;
    }
    .mv {
      padding: 10px 0 25px;
      background-color: #fff;
      margin-bottom: 50px;
      @include mq(sp) {
        padding: 20px 0;
        margin-bottom: 30px;
      }
    }
    > .wrapper {
      > div {
        margin-bottom: 60px;
        @include mq(sp) {
          margin-bottom: 30px;
        }
        &:last-child {
          margin: 0;
        }
      }
      h3,h4,h5 {
        font-weight: bold;
        text-align: center;
        color: $blue;
      }
      h3 {
        font-size: 28px;
        margin-bottom: 45px;
        @include mq(sp) {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
      h4 {
        padding-bottom: 45px;
        border-bottom: 1px $blue solid;
        font-size: 26px;
        line-height: 40px;
        margin-bottom: 40px;
        @include mq(sp) {
          padding-bottom: 10px;
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 20px;
        }
      }
      h5 {
        font-size: 20px;
        text-align: left;
        margin: 55px 0 15px;
        @include mq(sp) {
          font-size: 14px;
          margin: 30px 0 10px;
        }
        &:nth-of-type(1) {
          margin-top: 0;
        }
      }
      .white_box {
        padding: 45px 80px;
        background-color: #fff;
        border-radius: 30px;
        overflow: hidden;
        @include mq(sp) {
          padding: 20px;
          border-radius: 15px;
        }
        p {
          font-size: 20px;
          line-height: 42px;
          @include mq(sp) {
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
      .cont1 .white_box {
        padding: 60px 25px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include mq(sp) {
          padding: 30px 10px 0;
        }
        h3 {
          width: 100%;
          font-size: 38px;
          margin-bottom: 60px;
          @include mq(sp) {
            font-size: 22px;
            margin-bottom: 20px;
          }
        }
        > p {
          width: 100%;
          padding: 0 25px;
          margin-bottom: 60px;
          @include mq(pc) {
            line-height: 36px;
          }
          @include mq(sp) {
            padding: 0 10px;
            margin-bottom: 20px;
          }
        }
        .item_box {
          width: 49%;
          padding: 30px;
          border: 8px #d7f5ff solid;
          position: relative;
          @include mq(sp) {
            padding: 10px;
            border-width: 4px;
          }
          h4 {
            padding: 0;
            border: none;
            margin-bottom: 20px;
            @include mq(sp) {
              margin-bottom: 10px;
              overflow: hidden;
              img {
                width: 130%;
                max-width: none;
              }
            }
          }
          h5 {
            padding: 10px 0;
            border-top: 1px $blue solid;
            border-bottom: 1px $blue solid;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            margin-bottom: 20px;
            @include mq(sp) {
              margin-bottom: 10px;
            }
            img {
              width: 25px;
              margin-right: 15px;
              @include mq(sp) {
                width: 20px;
                margin-right: 5px;
              }
            }
          }
          p {
            font-size: 18px;
            line-height: 38px;
            @include mq(sp) {
              font-size: 12px;
              line-height: 24px;
            }
          }
          > img {
            width: 75px;
            position: absolute;
            right: 90px;
            bottom: -80px;
            @include mq(sp) {
              width: 30px;
              right: 30px;
              bottom: -30px;
            }
          }
          &:nth-of-type(2) {
            border-color: rgba(5,106,135,0.1);
            h5 {
              border-color: $navyblue;
              color: $navyblue;
              img {
                margin: 0 0 0 15px;
                @include mq(sp) {
                  margin-left: 5px;
                }
              }
            }
            > img {
              right: auto;
              left: 90px;
              @include mq(sp) {
                left: 30px;
              }
            }
          }
        }
        > div:not(.item_box) {
          width: 100%;
          margin: 100px 0 50px;
          @include mq(sp) {
            margin: 50px 0 20px;
          }
          h4 {
            width: 440px;
            padding: 0;
            border: none;
            margin: 0 auto 40px;
            @include mq(sp) {
              width: 200px;
              margin-bottom: 20px;
            }
          }
          p {
            text-align: center;
            @include mq(pc) {
              font-size: 18px;
              line-height: 38px;
            }
            span {
              font-weight: bold;
              color: $blue;
            }
          }
        }
        > img {
          width: calc(100% + 50px);
          max-width: none;
          margin: 0 -25px;
          @include mq(sp) {
            width: calc(100% + 40px);
            margin: 0 -20px;
          }
        }
      }
      .cont4 {
        @include mq(pc) {
          padding: 50px 0 55px;
        }
        h3 {
          font-size: 36px;
          color: $navyblue;
          @include mq(pc) {
            margin-bottom: 30px;
          }
          @include mq(sp) {
            font-size: 22px;
          }
        }
        p {
          text-align: center;
          margin-bottom: 30px;
          @include mq(sp) {
            margin-bottom: 20px;
          }
        }
        .btn {
          @include mq(pc) {
            width: 560px;
            padding: 22px 0;
            border-radius: 10px;
            font-size: 26px;
          }
        }
      }
    }
  }
}