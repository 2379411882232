::-webkit-input-placeholder {color: #ccc; font-weight: normal;}
::-moz-placeholder {color: #ccc; font-weight: normal;}
:-ms-input-placeholder {color: #ccc; font-weight: normal;}

@include mq(sp) {
	*:focus {
		outline: none;
  	}
}
.swiper-button-next, .swiper-button-prev {
	color: grey !important;
}

#parachannel {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif;
	font-size: 14px;
	color: $black;

	main {
		display: block;
		margin-bottom: 85px;
	}
	@include mq(pc) {
		min-width: 1000px;
	}
	@include mq(sp) {
		font-size: 12px;
	}
	* {
		box-sizing: border-box;
	}
	img {
		width: 100%;
		max-width: 100%;
		height: auto;
		display: block;
	}
	a {
		color: $black;
		display: block;
		text-decoration: none;
		transition: all .2s;
		&:hover {
			color: $navyblue;
			opacity: 0.8;
		}
	}
	.head_contact_link {
		padding: 10px 0;
		background-color: $blue;
		text-align: center;
		font-size: 120%;
		font-weight: bold;

		a {
			color: #fff;
			text-decoration: underline;

			@include mq(pc) {

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.pc_only {
		@include mq(sp) {
			display: none !important;
		}
	}
	.sp_only {
		@include mq(pc) {
			display: none !important;
		}
	}
	.wrapper {
		width: 94%;
		margin: 0 auto;
		@include mq(pc) {
			max-width: 1200px;
			min-width: 960px;
		}
	}
	main {
		display: block;
		// margin-bottom: 85px;
		//overflow: hidden;
		@include mq(sp) {
			margin-bottom: 50px;
		}
	}
	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		-webkit-appearance: none;
		width: 100%;
		height: 40px;
		padding: 0 15px;
		border: 1px $lightgray solid;
		font-size: 14px;
		display: block;
		margin: 0;
		@include mq(sp) {
			height: 35px;
			padding: 0 10px;
			font-size: 12px;
		}
	}
	textarea {
		height: 140px;
		padding: 15px;
		resize: none;
	}
	.ttl {
		font-size: 20px;
		font-weight: bold;
		@include mq(sp) {
			font-size: 16px;
		}
		&.type1 {
			font-size: 18px;
			display: flex;
			align-items: center;
			align-content: center;
			@include mq(sp) {
				font-size: 14px;
			}
			img {
				width: 40px;
				margin-right: 10px;
				@include mq(sp) {
					width: 30px;
					margin-right: 5px;
				}
			}
			span {
				font-size: 24px;
				@include mq(sp) {
					font-size: 18px;
				}
			}
		}
		&.type2 {
			width: 430px;
			background-color: #fff;
			box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
			border-radius: 5px;
			display: flex;
			@include mq(sp) {
				width: 100%;
			}
			p {
				width: 55px;
				padding: 0 10px;
				background: url(../img/top/ttl_bg.png) no-repeat;
				background-size: 100% 100%;
				border-radius: 5px 0 0 5px;
				display: flex;
				align-items: center;
				align-content: center;
				img {
					width: auto;
					max-width: none;
					height: 30px;
				}
			}
			h2 {
				width: calc(100% - 55px);
				padding: 5px 10px;
				@include mq(pc) {
					font-size: 24px;
				}
				@include mq(sp) {
					line-height: 20px;
				}
				span {
					font-size: 12px;
					display: block;
					margin-top: 6px;
					line-height: 1.4;
					@include mq(sp) {
						//line-height: 12px;
						margin-top: 2px;
					}
				}
			}
		}
		&.type3 {
			width: 100%;
			padding: 10px 20px;
			background-color: $lightblue;
			color: $blue;
			margin-bottom: 35px;
			@include mq(sp) {
				padding: 12px 15px;
				margin-bottom: 15px;
			}
		}
	}
	.btn {
		font-size: 26px;
		font-weight: bold;
		@include mq(sp) {
			font-size: 16px;
		}
		&.type1 {
			width: 310px;
			padding: 32px 0;
			background-color: $orange;
			text-align: center;
			color: #fff;
			margin: 0 auto;
			position: relative;
			@include mq(sp) {
				width: 200px;
				padding: 22px 0;
			}
			&:after {
				content: "";
				width: 26px;
				height: 26px;
				border: 13px transparent solid;
				border-top-color: #fff;
				border-right-color: #fff;
				box-sizing: border-box;
				position: absolute;
				top: 2px;
				right: 2px;
				pointer-events: none;
				@include mq(sp) {
					width: 20px;
					height: 20px;
					border-width: 10px;
				}
			}
		}
		&.type2 {
			width: 360px;
			padding: 12px 0;
			background-color: $black;
			text-align: center;
			color: #fff;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			margin: 0 auto;
			@include mq(sp) {
				width: 100%;
			}
			img {
				width: 25px;
				margin-left: 10px;
				@include mq(sp) {
					width: 20px;
				}
			}
		}
		&.type3 {
			width: 320px;
			padding: 20px 0;
			background-color: $blue;
			border-radius: 5px;
			border: none;
			font-size: 16px;
			line-height: 100%;
			text-align: center;
			color: #fff;
			display: block;
			margin: 0 auto;
			cursor: pointer;
			@include mq(sp) {
				width: 200px;
				padding: 18px 0;
				font-size: 14px;
			}
			&.yellow {
				color: $blue;
				background-color: $yellow;
			}
			&.green {
				background-color: $green;
			}
			&.orange {
				background-color: #ff7d00;
			}
			&.gray {
				background-color: #e6e6e6;
				color: $black;
			}
		}
    &.type4 {
      font-size: unset;
      font-weight: normal;
      display: block;
      margin: 10px 0 0;
      padding: 2px 30px;
      border: none;
      background-color: #00a0e9;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
	}
	.bread_crumb {
		display: flex;
		li {
			display: flex;
			flex-wrap: wrap;
			&:after {
				content: "＞";
				display: block;
				margin: 0 15px;
				@include mq(sp) {
					margin: 0 5px;
				}
			}
			a {
				font-weight: bold;
			}
			&:nth-last-child(n+2) a {
				text-decoration: underline;
				color: $blue;
			}
			&:last-child {
				&:after {
					display: none;
				}
				a {
					pointer-events: none;
				}
			}
		}
	}
	.input_box {
		padding: 50px 0;
		border: 1px $lightgray solid;
		font-weight: bold;
    	margin-top: 35px;
		@include mq(sp) {
			padding: 20px;
			margin-top: 15px;
		}
    h3 {
			text-align: center;
			color: $blue;
			margin: 0 0 50px !important;
      @include mq(sp) {
        margin-bottom: 20px !important;
      }
    }
    > p {
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      margin-bottom: 35px;
      @include mq(sp) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 30px;
      }
    }
    .form {
      width: 635px;
      margin: 0 auto;
      @include mq(sp) {
        width: 100%;
      }
      dl {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin-bottom: 80px;
        @include mq(sp) {
          margin-bottom: 30px;
        }
        dt,dd {
          margin-bottom: 30px;
          @include mq(sp) {
            margin-bottom: 15px;
          }
          &:last-of-type {
            margin: 0;
          }
        }
        dt {
          width: 140px;
          @include mq(pc) {
            font-size: 16px;
          }
          @include mq(sp) {
            width: 110px;
          }
          span {
            display: block;
            margin-top: 8px;
            @include mq(sp) {
              margin-top: 5px;
            }
          }
        }
        dd {
          width: calc(100% - 140px);
          @include mq(sp) {
            width: calc(100% - 110px);
          }
					span {
						font-size: 12px;
						color: #f00;
						display: block;
						margin-top: 5px;
						@include mq(sp) {
							font-size: 10px;
						}
					}
        }
      }
			.error_text {
				font-size: 12px;
				color: #f00;
				text-align: center;
				margin: -30px 0 30px;
				@include mq(sp) {
					font-size: 10px;
					margin: 20px 0;
				}
			}
			> a {
        text-align: center;
        color: $lightgray;
        margin-top: 15px;
        @include mq(pc) {
          font-size: 14px;
        }
        @include mq(sp) {
          margin-top: 10px;
        }
      }
    }
  }

	// 注釈用
	p.note {
		font-size: 80%;
		color: #888;
		line-height: 1.4;
		&.wide {
			width: 100%;
			margin-top: 7px;
		}
	}

	.img_box {
		position: relative;
		width: 100%;
		padding-bottom: 75%;
		overflow: hidden;
		background-color: #f7f8f8;

		// @include mq(pc) {
		// 	height: 173px;
		// }

		// @include mq(sp) {
		// 	height: 98px;
		// }

		img {
			position: absolute;
			left: 50%;
			top: 0;
			width: auto;
			max-width: none;
			height: 100%;
			transform: translateX(-50%);
		}

	}

	.mt5 {
		margin-top: 5px;
	}

	.mt10 {
		margin-top: 10px;
	}

	.mb10 {
		margin-bottom: 10px;
	}

}
