#parachannel #register {
  font-weight: bold;
  padding: 35px 0 85px;
  @include mq(sp) {
    padding: 20px 0 0;
  }
  .ttl_box {
    margin-bottom: 50px;
    position: relative;
    @include mq(sp) {
      margin-bottom: 30px;
    }
    h2 {
      text-align: center;
      @include mq(pc) {
        font-size: 24px;
      }
    }
    p {
      width: 125px;
      padding: 15px 0;
      text-align: center;
      position: absolute;
      top: -15px;
      right: 0;
      left: -300px;
      z-index: 9;
      margin: 0 auto;
      @include mq(sp) {
        width: 110px;
        padding: 14px 0;
        font-size: 10px;
        left: -220px;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../img/header_bg.png) no-repeat;
        background-size: 100% 100%;
        transform: scale(-1, 1);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
      }
      span {
        font-size: 14px;
        @include mq(sp) {
          font-size: 12px;
        }
      }
    }
  }
  .tab_box {
    width: 780px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @include mq(sp) {
      width: 100%;
    }
    li {
      width: 32%;
      padding: 27px 15px 27px 0;
      background: url(../img/text_bg.png) no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      text-align: center;
      color: $blue;
      position: relative;
      @include mq(sp) {
        padding: 15px 5px 15px 0;
        font-size: 10px;
      }
      &:last-child {
        border: none;
      }
      span {
        position: absolute;
        top: -20px;
        left: 0;
        @include mq(sp) {
          top: -15px;
        }
      }
      &.active {
        background-image: url(../img/text_bg_on.png);
        color: #fff;
        span {
          color: $blue;
        }
      }
    }
  }
  .wrapper > p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-top: 35px;
    margin-top: 100px;

    @include mq(sp) {
      font-size: 14px;
      line-height: 20px;
      //margin-top: 15px;
      margin-top: 50px;
      text-align: justify;
    }

    small {
      display: block;
      margin-top: 20px;
      font-size: 90%;
      line-height: 1.5;
      color: #888;
    }
  }
}