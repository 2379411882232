#parachannel #company .jobs {

  > .link {
    width: 250px;
    padding: 17px 0;
    background-color: #f15a24;
    position: absolute;
    top: 40px;
    right: 30px;
    @include mq(sp) {
      width: 150px;
      padding: 9px 0;
      font-size: 12px;
      top: 105px;
      right: 3%;
    }
  }

  &.create1 {
    font-weight: bold;
    text-align: center;

    > div {
      padding-bottom: 40px;
      border: 1px $lightgray solid;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 20px;
      @include mq(sp) {
        padding-bottom: 25px;
        margin-bottom: 20px;
      }
      form {
        > p:nth-of-type(n+2),
        > div p {
          color: #f00;
        }
      }
    }

    h3 {
      padding: 17px 0;
      background-color: #f7931e;
      font-size: 16px;
      color: #fff;
      margin-bottom: 35px;
      @include mq(sp) {
        padding: 13px 0;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    p {
      margin-bottom: 45px;
      @include mq(pc) {
        font-size: 14px;
      }
      @include mq(sp) {
        margin-bottom: 20px;
      }
      &.note {
        font-size: 12px;
        line-height: 20px;
        margin: 0 0 30px;
        @include mq(sp) {
          font-size: 10px;
        }
        a {
          text-decoration: underline;
          color: $blue;
          display: inline-block;
        }
      }
    }
    label {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-bottom: 50px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      input {
        width: 210px;
        border-radius: 5px;
        @include mq(sp) {
          width: 150px;
        }
        &:nth-child(1) {
          width: 100px;
          @include mq(sp) {
            width: 80px;
          }
        }
      }
      span {
        margin: 0 10px;
        @include mq(sp) {
          margin: 0 5px;
        }
      }
    }
    button.btn {
      width: 200px;
      background-color: #f15a24;

      @include mq(sp) {
        width: 150px;
        padding: 6px 0;
      }
    }
    .btn {
      width: 450px;
      padding: 12px 0;
      border-radius: 30px;

      @include mq(pc) {
        font-size: 16px;
      }
      @include mq(sp) {
        width: 250px;
        padding: 7px 0;
        line-height: 18px;
      }
    }
  }

  &.create2,
  &.create3 {
    font-weight: bold;
    h3 {
      padding-bottom: 20px;
      border-bottom: 1px $blue solid;
      text-align: center;
      color: $blue;
      margin-bottom: 40px;
      @include mq(sp) {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
    > p {
      line-height: 24px;
      text-align: center;
      margin-bottom: 40px;
      @include mq(pc) {
        font-size: 14px;
      }
      @include mq(sp) {
        line-height: 20px;
        margin-bottom: 20px;
      }
    }

    form {
      @include mq(pc) {
        > dl:nth-of-type(1) > dd:nth-of-type(1) {
          width: 100%;
          margin-top: -10px;
        }
      }
      &.type2 > div dl dd input,
      &.type2 > div dl dd select.long {
        @include mq(pc) {
          width: 44%;
          max-width: 300px;
        }
        @include mq(sp) {
          margin-top: 10px;
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }
    }

    dl {
      padding-bottom: 40px;
      border-bottom: 1px $lightgray solid;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
      @include mq(pc) {
        dt,dd {
          margin-bottom: 20px;
          &:last-of-type {
            margin: 0;
          }
        }
      }
      @include mq(sp) {
        padding-bottom: 30px;
        display: block;
        margin-bottom: 30px;
      }
      &:last-of-type {
        padding-bottom: 0;
        border: none;
        margin-bottom: 0;
      }
    }

    dt {
      //width: 180px;
      width: 240px;
      height: 40px;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      align-content: center;

      @include mq(sp) {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        br {
          display: none;
        }
      }
      span {
        padding: 4px 8px;
        background-color: #f00;
        border-radius: 20px;
        font-size: 12px;
        line-height: 100%;
        color: #fff;
        margin-left: 10px;
      }
    }

    dd {
      //width: calc(100% - 180px);
      width: calc(100% - 240px);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;

      @include mq(sp) {
        width: 100%;
        margin-bottom: 25px;
        &:last-child {
          margin: 0;
        }
      }
      input {
        width: 300px;
        @include mq(sp) {
          width: calc(100% - 90px);
        }
        &.small {
          width: 80px;
          margin-right: 10px;
        }
      }
      select {
        width: 150px;
        background: url(../../img/company/icon04.png) no-repeat;
        background-size: 15px;
        background-position: right 10px center;
        cursor: pointer;
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }
      }
      dl {
        //width: calc(100% + 180px);
        width: calc(100% + 240px);
        //margin: 20px 0 0 -180px;
        margin: 20px 0 0 -240px;

        @include mq(sp) {
          width: 100%;
          margin: 25px 0 0;
          padding-left: 5%;
        }
      }

      dt {
        padding-left: 30px;
        font-size: 14px;
        line-height: 16px;
        color: #606e84;
        @include mq(sp) {
          padding: 0;
        }
      }
    }
    dd.time_box {
      & > label {
        font-size: 14px;
        line-height: 16px;
        color: #606e84;
        display: block;
        margin: unset;
        cursor: auto;
        select {
          margin: unset;
          width: 75px!important;
        }
      }
      & > p {
        line-height: 10px;
        margin: 21px 10px 0;
      }
    }

    .btn_box {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      @include mq(sp) {
        justify-content: space-between;
        margin-top: 30px;
      }
      .btn {
        width: 220px;
        padding: 11px 0;
        border-radius: 30px;
        margin: 0 25px;
        position: relative;
        @include mq(pc) {
          font-size: 18px;
        }
        @include mq(sp) {
          width: 150px;
          padding: 11px 0;
          margin: 0 auto;
        }
        img {
          width: 10px;
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          margin: auto;
          @include mq(sp) {
            width: 8px;
            right: 10px;
          }
        }
        &:not(.orange) img {
          transform: scale(-1, 1);
          right: auto;
          left: 15px;
          @include mq(sp) {
            left: 10px;
          }
        }
      }
    }
  }

  &.create3 form > div {
    margin-top: 80px;
    @include mq(sp) {
      margin-top: 30px;
    }
    h4 {
      padding: 0 10px 15px;
      border-bottom: 1px #606e84 solid;
      font-size: 16px;
      color: #606e84;
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 30px;
      span {
        font-size: 12px;
        color: $lightgray;
        margin-left: 15px;
      }
      img {
        width: 15px;
        margin-left: 15px;
      }
    }
    dl dd {
      p {
        display: flex;
        align-items: center;
        align-content: center;
        &.dummy {
          width: 40px;
          height: 40px;
          @include mq(sp) {
            display: none;
          }
        }

        &.note {
          display: block;
          margin-top: 5px;
          font-size: 100%;
          font-weight: normal;
        }
      }
      input {
        &[type="text"],
        &[type="number"] {
          @include mq(pc) {
            margin: 0 10px;
            &:nth-child(1) {
              margin-left: 0;
            }
          }
          &.small {
            width: 100px;
          }
          &.long {
            width: 100%;
            max-width: 500px;
            margin: 0;
          }
        }
        &[type="number"] {
          @include mq(sp) {
            width: 150px;
            margin: 0 10px;
            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }
        &[type="checkbox"],
        &[type="radio"] {
          width: 20px;
          height: 20px;
          padding: 0;
          margin: 0 5px 0 0;
        }
      }
      select {
        @include mq(pc) {
          width: 150px;
        }
        &.long {
          width: 300px;
          @include mq(sp) {
            margin-bottom: 10px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
      textarea {
        @include mq(pc) {
          height: 140px;
        }
      }
      label {
        display: flex;
        align-items: center;
        align-content: center;
        margin: 5px 30px 5px 0;
        cursor: pointer;
        @include mq(sp) {
          margin-right: 20px;
        }
      }
      &.no_flex {
        display: block;
        label {
          margin: 0 0 15px;
        }
      }
      &.max {
        @include mq(pc) {
          width: 100%;
          margin-top: -10px;
        }
      }
      .check_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        li {
          input {
            display: none;
            &:checked + label {
              background-color: $blue;
              border-color: $blue;
              color: #fff;
            }
          }
          label {
            min-width: 110px;
            padding: 8px 10px;
            border: 1px #9e9f9f solid;
            border-radius: 20px;
            text-align: center;
            color: #ccc;
            display: block;
            margin: 5px 10px 5px 0;
            @include mq(sp) {
              min-width: 100px;
            }
          }
        }
      }
    }
  }

}
