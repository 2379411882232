.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#parachannel #company .outline {
  .error {
    color: #8a0421;
    border-color: #dd0f3b;
    background-color: #ffd9d9;
  }
  button.disabled {
      cursor: not-allowed;
      opacity: 0.3;
  }
  & > div.cont6 dl dd.city {
    display: flex;
    input[type="checkbox"] {
      width: unset;
      margin-left: 10px;
    }
    @include mq(sp) {
      flex-wrap: wrap;
      input[name="prefecture"] {
        margin-bottom: 10px;
      }
    }
    @include mq(pc) {
      input[name="prefecture"] {
        width: 100px;
        margin-right: 10px;
      }
      input[name="city"] {
        max-width: 290px;
      }
    }
  }
}
#parachannel #company {
  /* 共通 */
  display: flex;
  margin: 0;

  @include mq(sp) {
    display: block;
  }

  .btn_preview {
    width: 360px;
    height: 50px;
    padding: 0;
    line-height: 50px;
    //border-radius: 15px;
    margin: 0 0 30px auto;

    @include mq(sp) {
      width: 90%;
      margin: 0 auto 30px;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
    }
  }

  .tooltip_box {
    display: flex;
    align-items: center;
    align-content: center;

    @include mq(pc) {
      flex-direction: row-reverse;
      position: absolute;
      top: 0;
      right: 0;
    }

    @include mq(sp) {
      margin-bottom: 10px;
    }

    .countdown {
      width: 135px;
      padding: 6px 0;
      border: 1px #f00 solid;
      border-radius: 5px;
      line-height: 16px;
      text-align: center;
      color: #f00;
      margin: 0 !important;
    }

    .tooltip {
      margin-right: 10px;
      position: relative;

      @include mq(sp) {
        margin: 0 0 0 10px;
      }

      .icon {
        width: 24px;
        cursor: pointer;
        margin: 0 !important;

        @include mq(sp) {
          width: 20px;
        }
      }

      .text {
        width: 600px;
        padding: 10px 0;
        background-color: #00a0e9;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 50%;
        right: 40px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        pointer-events: none;
        opacity: 0;
        transition: all .2s;
        margin: 0;

        @include mq(sp) {
          width: 250px;
          padding: 10px;
          font-size: 10px;
          line-height: 16px;
          top: 35px;
          right: auto;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
        }

        &:after {
          content: "";
          width: 10px;
          height: 20px;
          border-top: 10px transparent solid;
          border-bottom: 10px transparent solid;
          border-left: 10px $blue solid;
          box-sizing: border-box;
          position: absolute;
          top: 0;
          right: -10px;
          bottom: 0;
          margin: auto;

          @include mq(sp) {
            width: 20px;
            height: 10px;
            border-top: none;
            border-right: 10px transparent solid;
            border-bottom: 10px $blue solid;
            border-left: 10px transparent solid;
            top: -10px;
            right: 0;
            bottom: auto;
            left: 0;
          }
        }
      }

      &:hover .text {
        opacity: 1;
      }
    }
  }

  >section:not(#sidebar) {
    width: calc(100% - 330px);
    padding: 40px 30px 140px;
    position: relative;

    @include mq(pc) {
      max-width: 1000px;
      margin: 0 auto;
    }

    @include mq(sp) {
      width: 100%;
      padding: 30px 3% 50px;
    }

    .ttl_box {
      display: flex;
      align-items: flex-end;
      align-content: flex-end;
      justify-content: space-between;
      margin: 50px 0 40px;
      //margin: 20px 0 70px;

      @include mq(sp) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin: 30px 0;

        .ttl {
          width: 100%;
          margin-top: 20px;
        }
      }

      h2 {
        position: relative;
        padding: 5px 0 5px 10px;
        border-left: solid 10px #006496;
      }

      ul {
        height: 30px;
        display: flex;
        align-items: flex-end;
        align-content: flex-end;

        @include mq(sp) {
          width: 100%;
          height: 15px;
        }

        li {
          margin-right: 25px;

          @include mq(sp) {
            margin-right: 15px;
          }

          a {
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            align-content: flex-end;

            @include mq(sp) {
              font-size: 10px;
            }

            img {
              width: 15px;
              margin-right: 5px;

              @include mq(sp) {
                width: 10px;
              }

              &.guide {
                width: 30px;

                @include mq(sp) {
                  width: 15px;
                }
              }
            }
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    .edit_box {
      font-weight: bold;

      input {
        width: 15px;
        height: 15px;
        margin: 0;
        cursor: pointer;
      }

      >div {
        padding-bottom: 5px;
        border-bottom: 1px $lightgray solid;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 15px;

        &.bottom {
          padding: 5px 0 0;
          border: none;
          border-top: 1px $lightgray solid;
          margin: 15px 0 0;
          justify-content: flex-end;
        }

        >div:not(.icon) {
          display: flex;
          align-items: center;
          align-content: center;

          >a {
            color: $lightgray;
            display: flex;
            align-items: center;
            align-content: center;
            margin-right: 15px;

            img {
              width: 20px;
              margin-left: 10px;
            }
          }
        }
      }

      .csv_download {
        display: inline-block;
        margin: 20px 0;
        width: 130px;

        label input {
          margin-right: 20px;

          @include mq(sp) {
            margin-right: 10px;
          }
        }

        a {
          width: 15px;
        }

        .btn {
          width: 100%;
          padding: 5px 0;
          background-color: #325a78;
          border-radius: 0;
          font-size: 12px;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;

          //margin-left: 20px;
          @include mq(sp) {
            width: 110px;
            font-size: 10px;
            //margin-left: 10px;
          }

          img {
            width: 10px;
            margin-left: 5px;
          }
        }

        &+.note {
          display: inline-block;
          width: calc(98% - 130px);
          text-align: right;

          @include mq(sp) {
            vertical-align: middle;
          }
        }
      }


      .pagination {
        display: flex;
        align-items: center;
        align-content: center;

        li {
          width: 20px;
          margin: 0 5px 0 0;

          &:last-child {
            margin: 0;
          }

          a {
            width: 100%;
            padding: 4px 0;
            text-align: center;
            color: $blue;
          }

          &.arrow a {
            padding: 0;
          }

          &.active {
            background-color: $blue;

            a {
              color: #fff;
            }
          }
        }
      }

      .category {
        display: flex;

        li {
          padding-bottom: 25px;
          margin-right: 20px;

          &:last-child {
            margin: 0;
          }

          a {
            display: flex;
            align-items: center;
            align-content: center;

            img {
              width: 20px;
              margin-right: 10px;
            }
          }

          &.active {
            border-bottom: 3px $blue solid;

            a {
              color: $blue;
            }
          }
        }

        &.new {
          width: 50px;
          height: 50px;
          padding: 0;
          border: none;
          margin: 0;
          position: absolute;
          top: -15px;
          left: -105px;
          z-index: 9;
          pointer-events: none;

          @include mq(sp) {
            top: -96px;
            left: -10px;
          }

          &:after {
            content: "";
            width: 100%;
            height: 100%;
            border-top: 25px #f00 solid;
            border-right: 25px transparent solid;
            border-bottom: 25px transparent solid;
            border-left: 25px #f00 solid;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
          }

          span {
            width: 100%;
            line-height: 30px;
            text-align: center;
            color: #fff;
            transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }
        }
      }

      .list_box {
        border: 1px $lightgray solid;
        border-right: 3px $blue solid;

        li {
          border-bottom: 1px $lightgray solid;
          display: flex;
          align-items: center;
          align-content: center;
          position: relative;

          &:last-child {
            border: none;
          }

          label {
            width: 40px;

            @include mq(sp) {
              width: 30px;
            }

            input {
              display: block;
              margin: 0 auto;
            }
          }

          >div {
            width: 100%;

            /*width: calc(100% - 40px);
            border-left: 1px $lightgray solid;
            @include mq(sp) {
              width: calc(100% - 30px);
            }*/
            .cont1 {
              padding: 15px 10px 10px;
              border-bottom: 1px $lightgray solid;
              display: flex;
              align-items: center;
              align-content: center;
              position: relative;

              @include mq(sp) {
                padding: 15px 10px;
                display: block;
              }

              .booked {
                position: absolute;
                top: .25em;
                left: .25em;
                padding: .25em;
                background-color: rgba(255, 255, 255, 0.7);
                border: 1px solid $gray;
              }

              .img_box {
                width: 95px;
                padding-bottom: calc(95px/4*3);

                @include mq(sp) {
                  width: 150px;
                  margin: 0 auto 10px;
                  float: left;
                }
              }

              .text_box {
                width: calc(100% - 95px);
                padding-left: 15px;

                @include mq(sp) {
                  width: 100%;
                  padding: 0;
                }

                h3 {
                  font-size: 16px;
                  line-height: 20px;
                  color: $blue;
                  margin-bottom: 5px;

                  @include mq(sp) {
                    width: calc(100% - 160px);
                    padding: 20px 0;
                    margin: 0;
                    float: right;
                  }

                  .id_text {
                    font-size: 14px;
                    color: $black;
                    display: block;
                    margin-bottom: 5px;
                  }
                }

                h4 {
                  font-size: 14px;
                  line-height: 18px;
                  margin-bottom: 5px;

                  @include mq(sp) {
                    clear: both;
                  }
                }

                p {
                  line-height: 16px;

                  @include mq(sp) {
                    clear: both;
                  }
                }

                .countdown {
                  width: 135px;
                  padding: 6px 0;
                  border: 1px #f00 solid;
                  border-radius: 5px;
                  text-align: center;
                  color: #f00;

                  @include mq(sp) {
                    margin-left: auto;
                  }
                }
              }
            }

            .cont2 {
              height: 35px;
              padding: 0 10px;
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: flex-end;

              @include mq(sp) {
                height: auto;
                padding: 10px;
                flex-wrap: wrap;
              }

              p {
                color: $lightgray;
                margin-right: 25px;

                @include mq(sp) {
                  font-size: 10px;
                  margin-right: 10px;
                  order: 3;
                }
              }

              .btn {
                width: 80px;
                padding: 6px 0;
                font-size: 13px;
                margin: 0 10px 0 0;

                @include mq(sp) {
                  margin: 0 10px 5px 0;
                  order: 2;
                }

                &:not(.orange):not(.gray):not(.blue) {
                  width: 50px;
                  padding: 5px 0;
                  background: none;
                  border: 1px $blue solid;
                  color: $black;

                  @include mq(sp) {
                    margin-right: 0;
                  }
                }

                &.blue,
                &.orange {
                  background-color: $lightblue;
                  color: $blue;
                  display: flex;
                  align-items: center;
                  align-content: center;
                  justify-content: center;

                  @include mq(pc) {
                    margin-right: 15px;
                  }

                  @include mq(sp) {
                    order: 1;
                  }

                  img {
                    width: 10px;
                    margin-right: 5px;
                  }

                  &:nth-of-type(2) {
                    width: auto;
                    padding: 6px;

                    @include mq(pc) {
                      position: absolute;
                      top: 45px;
                      right: 10px;
                      margin: 0;
                    }
                  }

                  &:nth-of-type(3) {
                    margin-right: 0;

                    @include mq(pc) {
                      position: absolute;
                      top: 10px;
                      right: 10px;
                      margin: 0;
                    }
                  }
                }

                &.orange {
                  background-color: #ff7d00;
                  color: #fff;

                  @include mq(pc) {
                    width: 100px;
                    font-size: 15px;

                    img {
                      width: 15px;
                    }
                  }
                }
              }

              // a:not(.btn) {
              //   width: 15px;
              //   margin: 0 10px 0 5px;
              //   @include mq(sp) {
              //     margin-right: 0;
              //     order: 4;
              //   }
              // }
              a.del {
                width: 15px;
                margin: 0 10px 0 5px;

                @include mq(sp) {
                  margin-right: 0;
                  order: 4;
                }
              }

              p {
                font-size: 100%;

                a {
                  width: 100%;
                }
              }

              select[name="status"] {
                width: 135px;
                height: 25px;
                background: url(../img/company/icon04.png) no-repeat;
                background-size: 10px;
                background-position: right 10px center;
                border-radius: 5px;
                margin-right: 10px;
                cursor: pointer;

                @include mq(sp) {
                  margin: 0 10px 5px calc(100% - 220px);
                  order: 2;
                }
              }
            }
          }

          &.end_cont {
            opacity: .3;
          }

          &.new_icon {
            position: relative;

            &:after {
              content: "";
              width: 15px;
              height: 15px;
              background-color: #f00;
              border-radius: 50%;
              position: absolute;
              top: 15px;
              left: 5px;

              @include mq(sp) {
                top: 5px;
              }
            }
          }
        }
      }
    }

    .select_box {
      width: 200px;
      background-color: #f7f7f7;
      margin: 0 0 25px auto;

      @include mq(sp) {
        width: 180px;
        margin: -45px 0 15px auto;
      }

      select {
        width: 100%;
        height: 35px;
        padding: 0 30px 0 15px;
        background: url(../img/company/icon11.png) no-repeat;
        background-size: 10px;
        background-position: right 15px center;
        border: none;
        cursor: pointer;
      }

      p {
        padding: 0 15px 10px;
        font-size: 10px;
      }
    }
  }

  /* top */
  .top {
    font-weight: bold;

    >div {
      margin-bottom: 90px;

      @include mq(sp) {
        margin-bottom: 50px;
      }

      &:last-child {
        margin: 0;
      }

      h2 {
        font-size: 22px;
        color: $blue;
        margin-bottom: 25px;

        @include mq(sp) {
          font-size: 18px;
          margin-bottom: 15px;
        }
      }

      >ul {
        margin-bottom: 20px;

        @include mq(sp) {
          margin-bottom: 10px;
        }

        li {
          border-bottom: 1px $lightgray solid;

          a,
          >div {
            padding: 21px 60px 21px 25px;
            background: url(../img/company/icon18.png) no-repeat;
            background-size: 8px;
            background-position: right 10px center;
            display: flex;
            position: relative;

            @include mq(sp) {
              flex-wrap: wrap;
              padding: 14px 20px 14px 5px;
              background-size: 6px;
              background-position: right 5px center;
            }

            .date {
              width: 210px;

              @include mq(sp) {
                width: 140px;
              }
            }

            .name {
              width: 140px;
            }

            .new,
            .category {
              border-radius: 5px;
              text-align: center;
              position: absolute;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              pointer-events: none;

              @include mq(sp) {
                font-size: 10px;
              }
            }

            .new {
              width: 40px;
              padding: 4px 0;
              background-color: #df0056;
              color: #fff;
              left: 120px;
              font-size: 80%;

              @include mq(sp) {
                left: 95px;
                padding: 5px 0;
              }
            }

            .category {
              width: 50px;
              padding: 3px 0;
              border: 1px #ff4800 solid;
              color: #ff4800;
              left: 165px;

              @include mq(sp) {
                width: 40px;
                padding: 4px 0;
                left: 125px;
              }

              &.type2 {
                border-color: $blue;
                color: $blue;
              }
            }

            p {
              width: calc(100% - 210px);
              line-height: 20px;

              @include mq(sp) {
                width: calc(100% - 140px);
                line-height: 18px;
              }
            }
          }

          >div {
            background: none;
            align-items: center;
          }

          &.read p {
            color: $lightgray;
          }

          &.scout p:not(.date):not(.new) {
            color: #df0056;
          }
        }

        &.cont1.recommended p {
          width: 100%;
        }

        &.cont1.news li {
          >a {
            background-image: url(../img/lp/faq_icon01.png);
            background-size: 13px;

            @include mq(sp) {
              background-size: 9px;
            }

            .new {
              line-height: 100%;
              top: 20px;
              -webkit-transform: translateY(0);
              transform: translateY(0);

              @include mq(sp) {
                top: 15px;
              }
            }
          }

          >div {
            width: 100%;
            padding: 0;
            display: block;

            >a {
              width: 100%;
              padding-top: 10px;
              background: none;
              line-height: 24px;
              text-decoration: underline;
              white-space: pre-wrap;

              @include mq(sp) {
                padding-top: 5px;
                line-height: 18px;
              }
            }

            >p {
              width: 100%;
              padding: 10px 60px 21px 25px;
              line-height: 24px;
              white-space: pre;

              @include mq(sp) {
                padding: 5px 20px 14px 5px;
                line-height: 18px;
              }
            }
          }

          &.active>a {
            background-image: url(../img/lp/faq_icon02.png);
          }
        }

        &.cont2 li a p:not(.name),
        &.cont2 li>div p:not(.name) {
          width: calc(100% - 140px);
        }

        &.cont3 li a,
        &.cont3 li>div {

          // .name {
          //   width: 290px;
          //   @include mq(sp) {
          //     width: 170px;
          //   }
          // }
          .name,
          .date {
            position: relative;
            left: 0;
            width: auto;
            margin-right: 20px;
          }

          p:not(.name):not(.date):not(.category) {
            width: calc(100% - 290px);

            @include mq(sp) {
              width: calc(100% - 170px);
            }
          }
        }

        // &.cont3.schedule .category {
        //   left: 200px;
        //   @include mq(sp) {
        //     left: 130px;
        //   }
        // }
        &.cont4 li a,
        &.cont4 li>div {
          padding-left: 5px;

          .category {
            width: 160px;
            position: static;
            -webkit-transform: translateY(0);
            transform: translateY(0);
            margin: -4px 0;

            @include mq(sp) {
              width: 130px;
            }

            &.type1 {
              border-color: #c9bc16;
              color: #c9bc16;
            }

            &.type2 {
              border-color: #489ed9;
              color: #489ed9;
            }
          }

          .new {
            left: 190px;

            @include mq(sp) {
              left: 145px;
            }
          }

          p:not(.category):not(.new) {
            width: calc(100% - 160px);
            padding-left: 90px;

            @include mq(sp) {
              width: calc(100% - 130px);
              padding-left: 60px;
            }
          }
        }
      }

      .btn_box {
        display: flex;
        justify-content: space-between;

        a {
          width: 25px;

          @include mq(sp) {
            width: 20px;
          }
        }
      }

      .select_box {
        margin: -45px 0 20px auto !important;

        @include mq(sp) {
          margin: -30px 0 10px auto !important;
        }
      }

      >div {
        margin-bottom: 75px;

        @include mq(sp) {
          margin-bottom: 50px;
        }

        &:last-child {
          margin: 0;
        }

        h3 {
          color: #606e84;
          display: flex;
          align-items: center;
          align-content: center;
          margin-bottom: 35px;

          @include mq(sp) {
            line-height: 24px;
            flex-wrap: wrap;
            margin-bottom: 15px;
          }

          span {
            width: 100px;
            padding: 3px 0;
            border: 1px #ff4800 solid;
            border-radius: 5px;
            font-size: 12px;
            text-align: center;
            color: #ff4800;
            margin-left: 15px;

            @include mq(sp) {
              width: 80px;
              padding: 4px 0;
              font-size: 10px;
              line-height: 100%;
              margin-left: 10px;
            }
          }
        }

        >ul {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 20%;
            border-right: 1px $lightgray solid;
            text-align: center;
            margin-top: 35px;

            @include mq(pc) {
              &:nth-child(-n+5) {
                margin-top: 0;
              }

              &:nth-child(5n+1) {
                border-left: 1px $lightgray solid;
              }
            }

            @include mq(sp) {
              width: 33.33%;
              margin-top: 20px;

              &:nth-child(-n+3) {
                margin-top: 0;
              }

              &:nth-child(3n+1) {
                border-left: 1px $lightgray solid;
              }
            }

            p {
              @include mq(pc) {
                font-size: 16px;
              }

              &:nth-child(2) {
                color: #606e84;
                margin-top: 10px;

                @include mq(sp) {
                  margin-top: 5px;
                }

                span {
                  font-size: 50px;
                  color: $blue;
                  margin-right: 5px;

                  @include mq(sp) {
                    font-size: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.mypage {
      .category {
        position: relative;
        left: 0;
        top: 0;
        width: auto;
        margin-right: 10px;
        padding: 3px 10px;
        transform: translateY(0);
        //left: 120px;
        // @include mq(sp) {
        //   left: 95px;
        // }

        &+p {
          width: auto !important;

          @include mq(sp) {
            width: 100% !important;
            margin-top: 10px;
          }
        }

        &.traning {
          border-color: $green;
          color: $green;
        }
      }

      >div .btn {
        padding: 12px 0;
        border-radius: 30px;
        margin-top: 45px;

        @include mq(pc) {
          width: 215px;
          font-size: 16px;
        }

        @include mq(sp) {
          padding: 13px 0;
          margin-top: 25px
        }
      }
    }
  }

  /* outline */
  .outline>div {
    margin-bottom: 50px;

    @include mq(sp) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin: 0;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      align-content: center;
      margin-bottom: 20px;
      position: relative;

      @include mq(sp) {
        font-size: 14px;
        margin-bottom: 15px;
      }

      img {
        width: 20px;
        margin-right: 10px;

        @include mq(sp) {
          margin-right: 5px;
        }
      }

      a {
        text-decoration: underline;
        position: absolute;
        top: 0;
        right: 0;
      }

      span {
        padding: 4px 8px;
        background-color: #f00;
        border-radius: 20px;
        font-size: 12px;
        color: #fff;
        margin-left: 10px;
      }

      small {
        display: inline-block;
        margin-left: 20px;
        vertical-align: middle;
        font-weight: normal;
        font-size: 85%;
      }

      .note {
        font-size: 12px;
        font-weight: normal;
        margin-left: 10px;

        @include mq(sp) {
          font-size: 10px;
        }
      }
    }

    .error_text {
      font-size: 12px;
      font-weight: bold;
      color: #f00;
      margin: 10px 0;
    }

    .member_cont {
      align-items: center;
      justify-content: flex-start !important;

      input {
        max-width: 100px;
        margin-right: 5px;
      }
    }

    .disabled {
      dt {
        @include mq(pc) {
          width: 160px !important;
        }
      }

      dd {
        @include mq(pc) {
          width: calc(100% - 160px) !important;
        }

        select {
          margin-right: 0 !important;
          margin-left: 0 !important;
          width: 100% !important;
        }
      }

      &.results {
        padding-top: 20px;
        border: none;
      }
    }

    .checkbox_cont {
      display: flex;
      flex-wrap: wrap;

      label {
        font-size: 12px;
        font-weight: normal;
        width: 48%;
        display: flex;
        align-items: center;
        align-content: center;
        cursor: pointer;
        margin-top: 20px;

        @include mq(pc) {
          &:nth-child(-n+4) {
            margin: 0;
          }
        }

        @include mq(sp) {
          width: 100%;
          margin-top: 15px;

          &:nth-child(-n+3) {
            margin: 0;
          }
        }

        input {
          min-width: 15px !important;
          width: 15px !important;
          height: 15px !important;
          margin: 0 20px 0 0;

          @include mq(sp) {
            margin-right: 5px;
          }
        }
      }
    }

    .select_cont {
      width: 100%;

      select {
        width: 100%;
        max-width: 400px;
        height: 35px;
        background: url(../img/company/icon04.png) no-repeat;
        background-size: 15px;
        background-position: right 10px center;
        cursor: pointer;
      }

      label {
        width: 100%;
        max-width: 400px;
        display: flex;
        align-items: center;
        align-content: center;
      }

      &.establishment_cont {
        input {
          width: 100px;
          margin-right: 5px;
        }

        span {
          margin-right: 5px;
        }

        select {
          margin-right: 5px;
        }

        select {
          width: 100px;
        }
      }
    }

    &.cont2 {
      >div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        button {
          width: 22.5%;
          padding: 30px 0 20px;
          background: none;
          border: 1px $lightgray solid;
          font-weight: bold;
          line-height: 100%;
          cursor: pointer;

          @include mq(sp) {
            width: 48.5%;
            margin-top: 10px;

            &:nth-child(-n+2) {
              margin: 0;
            }
          }

          img {
            width: 20px;
            margin: 0 auto 20px;
          }
        }

        >p {
          margin: 10px 0;

          a {
            text-decoration: underline;
            display: inline-block;
          }
        }
      }

      .logo_edit button {
        padding: 0;
        border: none;
        cursor: auto;

        img {
          width: 100%;
        }

        label {
          width: 80px;
          height: 80px;
          background: url(../img/company/icon03.svg) no-repeat;
          background-size: 30px;
          background-position: center;
          border: 1px $lightgray solid;
          display: block;
          margin-top: 20px;
          cursor: pointer;

          @include mq(sp) {
            width: 60px;
            height: 60px;
            background-size: 20px;
          }

          input {
            display: none;
          }
        }
      }

      .slider_edit {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;

        li {
          width: 32%;
          margin: 2% 2% 0 0;
          position: relative;

          @include mq(pc) {
            &:nth-child(-n+3) {
              margin-top: 0;
            }

            &:nth-child(3n) {
              margin-right: 0;
            }
          }

          @include mq(sp) {
            width: 49%;

            &:nth-child(-n+2) {
              margin-top: 0;
            }

            &:nth-child(2n) {
              margin-right: 0;
            }
          }

          p {
            width: 100%;
            height: 180px;
            border: 1px $lightgray solid;

            label {
              display: block;
              width: 100%;
              height: 100%;
              padding: 18% 5% 5%;
              cursor: pointer;

              input[type="file"] {
                display: none;
              }

              .add_icon {
                width: 50px;
                height: 50px;
                margin: 0 auto 30px;
              }
            }

            span {
              display: block;
              font-size: 15px;
              text-align: center;
              line-height: 1.2;
            }
          }

          .trash {
            position: absolute;
            right: 3%;
            top: 3%;
            width: 25px;
            height: 25px;
            padding: 5px;
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
          }

          img:not(.trash) {
            height: 200px;
            object-fit: cover;

            @include mq(sp) {
              height: 150px;
            }
          }

          &.add_img {
            background-color: #f7f7f7;
            cursor: pointer;

            p {
              padding: 18% 5% 5%;
              border: none;
            }

            .add_icon {
              width: 50px;
              height: 50px;
              margin: 0 auto 30px;
            }
          }
        }
      }

      .small {
        max-width: 400px;
      }
    }

    &.cont3 {
      .select {
        margin-bottom: 30px;
        padding: 10px;
        background-color: $lightblue;

        li {
          padding: 5px;
          font-size: 110%;

          &::before {
            content: '・';
            display: inline-block;
            font-size: 100%;
          }
        }
      }

      >div {
        display: flex;
        flex-wrap: wrap;

        label {
          width: 21%;
          line-height: 16px;
          display: flex;
          //align-items: center;
          align-content: center;
          padding-right: 10px;
          cursor: pointer;
          margin-top: 20px;

          @include mq(pc) {
            &:nth-child(-n+4) {
              margin: 0;
            }
          }

          @include mq(sp) {
            width: 33.33%;
            margin-top: 15px;

            &:nth-child(-n+3) {
              margin: 0;
            }
          }

          input {
            min-width: 15px;
            width: 15px;
            height: 15px;
            margin: 0 10px 0 0;

            @include mq(sp) {
              margin-right: 5px;
            }
          }
        }
      }

      button {
        width: 120px;
        height: 35px;
        background-color: $blue;
        border-radius: 20px;
        border: none;
        font-weight: bold;
        color: #fff;
        margin-top: 20px;

        @include mq(sp) {
          width: 100px;
          height: 30px;
          font-size: 12px;
          display: block;
          margin: 10px 0 0 auto;
        }
      }
    }

    &.cont6 {
      .message_ttl {
        display: inline-flex;
        align-self: flex-start;
        flex-wrap: wrap;

        span {
          font-size: 14px;
          color: #f00;
          margin: 5px;

          @include mq(sp) {
            font-size: 12px;
          }
        }
      }

      .message_cont {
        textarea {
          margin-bottom: 10px;
        }

        button {
          width: 100%;
          padding: 30px 0 20px;
          background: none;
          border: 1px $lightgray solid;
          font-weight: bold;
          line-height: 100%;
          cursor: pointer;

          @include mq(sp) {
            margin-top: 10px;

            &:nth-child(-n+2) {
              margin: 0;
            }
          }

          img {
            width: 20px;
            margin: 0 auto 20px;
          }
        }
      }
    }

    &.cont6 .name_cont {

      @include mq(pc) {
        display: flex;
      }

      label {

        //margin-bottom: 10px;
        @include mq(sp) {
          flex-wrap: wrap;
        }

        &:last-child {
          margin: 0 0 0 30px;
        }

        span {
          //width: 45px;
          width: auto;
          font-size: 12px;
          font-weight: normal;
          //margin-right: 5px;

          @include mq(pc) {
            margin-right: 10px;
          }

          @include mq(sp) {
            width: 100px;
            margin-bottom: 5px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        input {
          margin-right: 15px;

          @include mq(sp) {
            //width: calc(100% - 50px) !important;
            width: calc(100% - 100px) !important;
            margin: 0 0 5px;

            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }

    &.cont6 dl {
      padding: 40px 0;
      border-top: 1px $lightgray solid;
      border-bottom: 1px #b3b3b3 solid;
      font-weight: bold;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;

      @include mq(pc) {
        font-size: 16px;
      }

      @include mq(sp) {
        padding: 20px 0;
      }

      dt,
      dd {
        margin-bottom: 20px;

        @include mq(sp) {
          margin-bottom: 10px;
        }

        &:last-of-type {
          margin: 0;
        }
      }

      dt {
        width: 100px;
        line-height: 20px;

        @include mq(sp) {
          width: 70px;
          line-height: 16px;
        }
      }

      dd {
        width: calc(100% - 100px);

        @include mq(sp) {
          width: calc(100% - 70px);
        }

        input {
          width: 100%;
          max-width: 400px;
          height: 35px;
        }

        select {
          width: 70px;
          height: 35px;
          background: url(../img/company/icon04.png) no-repeat;
          background-size: 15px;
          background-position: right 10px center;
          cursor: pointer;
        }

        label {
          display: flex;
          align-items: center;
          align-content: center;
        }

        &:nth-of-type(1) {
          input {
            width: 165px;

            @include mq(sp) {
              width: 125px;
            }
          }

          .btn {
            width: 155px;
            padding: 10px 0;
            font-size: 16px;
            margin: 0 0 0 15px;

            @include mq(sp) {
              width: 125px;
              padding: 12px 0;
              font-size: 11px;
              margin-left: 10px;
            }
          }
        }

        &:nth-of-type(4) {
          input {
            //width: 125px;
            margin-right: 15px;

            @include mq(sp) {
              margin-right: 10px;
            }
          }

          select {
            margin: 0 15px;

            @include mq(sp) {
              margin: 0 10px;
            }
          }
        }
      }

      .checkbox_cont label {
        line-height: 16px;
        margin: 5px 0;
      }
    }
  }

  #add_item {
    display: block;
    margin-top: 30px;

    .add_item_box {
      margin-bottom: 30px;
      border: solid 1px #f2f2f2;
      border-top: none;

      h2 {
        padding: 15px;
        //font-size: 12px;
        font-weight: bold;
        background-color: #f2f2f2;
        border-radius: 5px 5px 0 0;
      }

      ul {
        padding: 15px;
      }

      li {
        margin: 0 auto 10px;

        input {
          border-color: #efefef;
        }
      }

      p {
        text-align: center;
        margin: 5px auto;
        padding: 0 15px 15px;
        font-weight: bold;

        button {
          display: inline-block;
          margin-left: 10px;
          padding: 10px 30px;
          background-color: #fff;
          border: solid 1px #00a0e9;
          color: #00a0e9;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }
      }

      dl {

        width: 95%;
        margin: 0 auto;
        padding: 2%;
        border-top: solid 1px #efefef;

        @include mq(pc) {
          display: flex;
          justify-content: space-between;
        }

        @include mq(sp) {
          padding: 2% 2% 5%;
        }
      }

      dt {
        width: 20%;
        line-height: 40px;
        color: #888;
        font-weight: bold;
        //font-size: 13px;

        @include mq(sp) {
          width: 100%;
        }
      }

      dd {
        width: 80%;

        @include mq(sp) {
          width: 100%;
        }

        label {
          position: relative;
          margin: 1% 0;
          padding-left: 25px;
          line-height: 1.5;

          input[type="checkbox"] {
            position: absolute;
            left: 0;
            display: inline-block;
            vertical-align: middle;
          }
        }

        &._flex {
          display: flex;
          flex-wrap: wrap;

          label {
            margin: 1% 0 1% 1%;

            @include mq(pc) {
              width: calc(97%/4);

              &:nth-child(4n + 1) {
                margin-left: 0;
              }
            }

            @include mq(sp) {
              width: calc(98%/3);

              &:nth-child(3n + 1) {
                margin-left: 0;
              }
            }
          }
        }
      }

      dl.no_flex {
        display: block;

        dd {
          width: 100%;

          @include mq(pc) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          label {
            display: inline-block;
            width: 47%;

            @include mq(sp) {
              width: 100%;
            }
          }
        }
      }
    }

  }

  /* apply */
  .apply .edit_box {
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 60px;

      @include mq(sp) {
        margin-bottom: 30px;
      }

      select {
        width: 155px;
        height: 35px;
        background: url(../img/company/icon04.png) no-repeat;
        background-size: 15px;
        background-position: right 10px center;
        border: none;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        cursor: pointer;

        @include mq(sp) {
          width: 48%;
        }

        &:nth-child(1) {
          width: calc(100% - 340px);
          background-image: url(../img/company/icon11.png);
          background-color: #d9ecfa;
          margin: 0;

          @include mq(sp) {
            width: 100%;
            margin-bottom: 15px;
          }
        }
      }

      .btn {
        width: 155px;
        padding: 10px 0;
        margin: 25px 0 0;

        @include mq(sp) {
          width: 48%;
          margin-top: 15px;
        }

        &.gray {
          padding: 11px 0;
          font-size: 14px;

          @include mq(pc) {
            width: 200px;
            margin: 25px auto 0 15px;
          }

          @include mq(sp) {
            font-size: 12px;
          }
        }
      }
    }

    .note {
      text-align: right;
      color: #f00;
      margin-bottom: 10px;
    }

    .list_box li>div {
      .cont1 {
        .img_box {
          @include mq(pc) {
            align-items: center;
          }

          @include mq(sp) {
            float: none !important;
          }
        }

        .text_box {
          //display: flex;
          position: relative;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          flex-wrap: wrap;

          @include mq(sp) {
            display: block;
          }

          h3 {
            color: $black !important;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            margin: 0 0 20px !important;

            @include mq(sp) {
              width: 100% !important;
              float: none !important;
              margin-bottom: 0 !important;
            }

            ._flex {
              display: flex;
              align-items: center;

              @include mq(pc) {
                &:first-child {
                  margin-right: 20px;
                }
              }

              @include mq(sp) {
                width: 100%;
                flex-wrap: wrap;

                &:nth-of-type(2) {
                  margin-top: 10px;
                }
              }
            }

            span {
              line-height: 1.4;
            }

            .date {
              display: inline-block;
              font-size: 12px;
              margin-left: 10px;
            }

            .category {
              margin-left: 10px;

              @include mq(sp) {
                margin-left: 0;
                margin: 0 10px 5px 0;
              }

              padding: 3px 10px;
              border: 1px solid;
              border-radius: 5px;
              font-size: 12px;

              &:first-child {
                margin-left: 0;
              }

              &.type1 {
                border-color: #ff4800;
                color: #ff4800;
              }

              &.type2 {
                border-color: #8d8d8d;
                color: #8d8d8d;
              }

              &.type3 {
                border-color: #e44e66;
                color: #e44e66;
              }

              &.type4 {
                border-color: #f39800;
                color: #f39800;
              }
            }
          }

          h4 {
            color: $blue;
            display: none;
          }

          select {
            display: block;
            width: 135px;
            height: 30px;
            margin: 10px 0 0 auto;
            background: url(../img/company/icon04.png) no-repeat;
            background-size: 10px;
            background-position: right 10px center;
            border-radius: 5px;
            cursor: pointer;

            @include mq(pc) {
              position: absolute;
              right: 0;
              top: 50%;
              width: 155px;
              transform: translateY(-50%);
            }

            @include mq(sp) {
              margin-left: auto;
            }
          }

          p {
            margin-top: 5px;
          }

        }
      }

      .cont2 {
        padding: 0 !important;
        align-items: stretch !important;
        align-content: stretch !important;

        @include mq(sp) {
          height: auto !important;
          display: block !important;
        }

        p {
          width: 50%;
          padding: 0 10px;
          display: flex;
          align-items: center;
          align-content: center;
          margin: 0 !important;

          @include mq(sp) {
            width: 100%;
            height: 45px;
          }

          &:nth-child(1) {
            border-right: 1px $lightgray solid;
            justify-content: center;
            color: $blue !important;

            @include mq(sp) {
              border: none;
              border-bottom: 1px $lightgray solid;
            }
          }

          &:nth-child(2) {
            justify-content: space-between;

            img {
              width: 20px;
            }
          }
        }
      }
    }
  }

  /* message */
  .message {
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq(sp) {
      display: block;
    }

    .ttl_box {
      width: 100%;
      border-bottom: 1px $lightgray solid;
      justify-content: flex-start !important;

      @include mq(sp) {
        flex-direction: row !important;
      }

      .ttl {
        padding: 15px;
        margin-bottom: -1px;

        @include mq(sp) {
          width: auto !important;
          padding: 10px;
          margin: 0 0 -1px !important;
        }

        &.active {
          background-color: #fff;
          border-radius: 5px 5px 0 0;
          box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.3);
          color: $blue;
          position: relative;

          &:after {
            content: "";
            width: calc(100% - 30px);
            height: 2px;
            background-color: $blue;
            position: absolute;
            bottom: 10px;
            left: 15px;
            pointer-events: none;

            @include mq(sp) {
              width: calc(100% - 20px);
              bottom: 5px;
              left: 10px;
            }
          }
        }

        &.new_icon {
          position: relative;

          &:before {
            content: "";
            width: 15px;
            height: 15px;
            background-color: #f00;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            right: -5px;
          }
        }
      }
    }

    .cont_box {
      width: calc(100% - 185px);

      @include mq(sp) {
        width: 100%;
        margin-bottom: 50px;
      }

      .message_box {
        .view_box {
          max-height: 350px;
          padding-right: 10px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background-color: #f2f2f2;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $blue;
          }

          >div {
            width: 66%;
            margin-bottom: 30px;

            @include mq(sp) {
              width: 90%;
            }

            &:last-child {
              margin-bottom: 0;
            }

            >p {
              margin-bottom: 5px;
            }

            >div {
              padding: 20px;
              line-height: 20px;
              margin-bottom: 15px;

              @include mq(sp) {
                padding: 15px;
              }

              &:last-child {
                margin: 0;
              }

              form {
                margin-top: 15px;

                label {
                  display: flex;
                  align-items: center;
                  align-content: center;

                  input {
                    width: 15px;
                    height: 15px;
                    margin: 0 10px 0 0;
                  }
                }

                .btn_box {
                  display: flex;
                  justify-content: space-between;
                  margin-top: 15px;

                  .btn {
                    width: 49%;
                    height: 50px;
                    padding: 0;
                    background-color: #fff;
                    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
                    font-size: 12px;
                    line-height: 16px;
                    color: $lightgray;
                    margin: 0;

                    &:hover {
                      color: $blue;
                    }
                  }
                }
              }
            }

            &.left>div {
              background-color: #f2f2f2;

              &.auto_message {
                background-color: #fceded;
              }
            }

            &.right {
              margin-left: auto;

              >div {
                background-color: #dbffff;
              }
            }
          }
        }

        .input_box {
          padding: 0;
          border: none;
          margin: 100px 0 0;

          @include mq(sp) {
            margin-top: 50px;
          }

          ul {
            border-bottom: 1px $lightgray solid;
            display: flex;
            margin-bottom: 25px;

            @include mq(sp) {
              margin-bottom: 20px;
            }

            li {
              width: 110px;
              padding: 11px 0;
              border: 1px #fff solid;
              border-bottom: none;
              border-radius: 5px 5px 0 0;
              text-align: center;
              color: $gray;
              margin-bottom: -1px;

              @include mq(sp) {
                width: 80px;
                padding: 8px 0;
              }

              &.active {
                background-color: #fff;
                border-color: $lightgray;
              }
            }
          }

          form {
            width: 100%;

            textarea {
              background-color: #f2f2f2;
              border: none;
            }

            .btn {
              width: 155px;
              padding: 15px 0;
              margin: 20px 0 0;

              @include mq(sp) {
                width: 120px;
                padding: 13px 0;
                margin-top: 15px;
              }
            }
          }

          >div {
            p {
              line-height: 20px;
              color: $lightgray;
              margin-bottom: 20px;

              @include mq(sp) {
                margin-bottom: 15px;
              }
            }

            .btn {
              width: 400px;
              margin: 0 0 15px;
              position: relative;

              @include mq(sp) {
                width: 100%;
                margin-bottom: 10px;
              }

              &:last-child {
                margin: 0;
              }

              img {
                width: 20px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 30px;
                margin: auto;
              }
            }
          }
        }
      }
    }

    .sidebar {
      width: 160px;

      @include mq(sp) {
        width: 100%;
      }

      h4 {
        padding-bottom: 15px;
        border-bottom: 1px $lightgray solid;
        font-size: 14px;
        text-align: center;
        color: $gray;
        margin-bottom: 20px;

        &.memo {
          padding: 0 5px 10px;
          display: flex;
          align-items: center;
          align-content: center;

          img {
            width: 25px;
            margin-left: 10px;
          }
        }
      }

      .btn_box1 {
        border: 1px $lightgray solid;
        margin-bottom: 30px;

        a {
          width: 100%;
          padding: 14px 0;
          border-bottom: 1px $lightgray solid;
          text-align: center;
          color: $lightgray;

          &:last-child {
            border: none;
          }

          &.active {
            background-color: $lightgray;
            color: #fff;
          }
        }
      }

      .btn_box2 a {
        width: 100%;
        padding: 8px 0;
        border: 1px $lightgray solid;
        text-align: center;
        color: $blue;
        margin-bottom: 10px;

        &:last-child {
          margin: 0;
        }
      }

      ul {
        margin-bottom: 30px;

        li {
          padding-bottom: 15px;
          border-bottom: 1px $lightgray solid;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 15px;

          &:last-child {
            margin: 0;
          }

          p {
            &:nth-of-type(2) {
              margin-left: 20px;
            }

            &:nth-of-type(3) {
              width: 100%;
              font-weight: normal;
              margin-top: 10px;
            }
          }
        }
      }
    }

    &.cont1 .cont_box {
      position: relative;

      .tooltip_box {
        top: -30px;

        @include mq(sp) {
          margin: 10px 50% 5px 0;
        }
      }

      .detail_box>div {
        margin-bottom: 50px;

        &:last-child {
          margin: 0;
        }

        h4 {
          padding: 12px 15px;
          background-color: #f2f2f2;
          border-left: 4px #606e84 solid;
          font-size: 16px;
          color: #606e84;
          margin-bottom: 20px;
        }

        dl {
          font-size: 14px;
          line-height: 24px;
          display: flex;
          flex-wrap: wrap;

          @include mq(sp) {
            display: block;

            dt,
            dd {
              width: 100% !important;
            }

            dt {
              border: none !important;
            }
          }

          dd span {
            font-size: 12px;
          }

          &.type1 {

            dt,
            dd {
              padding: 13px 0;
              border-bottom: 1px #e6e6e6 solid;

              @include mq(sp) {
                padding: 0 15px;
              }

              &:last-of-type {
                border: none;
              }
            }

            dt {
              width: 250px;

              @include mq(pc) {
                padding-left: 40px;
              }

              @include mq(sp) {
                margin-bottom: 10px;
              }
            }

            dd {
              width: calc(100% - 250px);

              @include mq(sp) {
                padding-bottom: 15px;
                margin-bottom: 15px;

                &:last-of-type {
                  padding-bottom: 0;
                  margin-bottom: 0;
                }
              }
            }
          }

          &.type2 {
            border: 1px #adadad solid;
            border-radius: 5px;
            overflow: hidden;

            dt,
            dd {
              padding: 18px 20px;
              border-bottom: 1px #adadad solid;

              @include mq(sp) {
                padding: 13px 15px;
              }

              &:last-of-type {
                border: none;
              }
            }

            dt {
              width: 200px;
              background-color: #f2f2f2;
            }

            dd {
              width: calc(100% - 200px);

              span {
                color: #ccc;
              }
            }
          }
        }
      }
    }

    .name_box .note {
      color: #f00;
    }
  }

  /* setting */
  .setting {
    font-weight: bold;

    >div {
      margin-bottom: 75px;

      @include mq(sp) {
        margin-bottom: 50px;
      }

      h3 {
        padding-bottom: 20px;
        border-bottom: 1px $lightgray solid;
        font-size: 16px;
        margin-bottom: 30px;

        @include mq(sp) {
          padding-bottom: 10px;
          font-size: 14px;
          margin-bottom: 15px;
        }
      }

      &.cont1>div {
        >div {
          border: 1px $lightgray solid;

          dl {
            display: flex;
            border-bottom: 1px $lightgray solid;

            &:last-of-type {
              border: none;
            }

            dt,
            dd {
              display: flex;
              align-items: center;
              padding: 12px 15px;
              font-size: 16px;

              @include mq(sp) {
                padding: 10px;
                font-size: 12px;
                line-height: 20px;
                word-wrap: break-word;
              }
            }

            dt {
              width: 40%;
              border-right: 1px $lightgray solid;
            }

            dd {
              justify-content: space-between;
              width: 60%;

              &>.btn {
                padding: 0 10px;
                font-size: 14px;
                margin: unset;
              }
            }

            &:nth-child(1) {
              text-align: center;

              dt,
              dd {
                background-color: $lightgray;
                border-color: #fff;
                color: #fff;

                @include mq(sp) {
                  font-size: 14px;
                }
              }
            }
          }
        }

        a {
          width: 80px;
          padding: 24px 0;
          border: 1px $lightgray solid;
          margin-top: 25px;

          @include mq(sp) {
            width: 60px;
            padding: 19px 0;
            margin-top: 20px;
          }

          img {
            width: 30px;
            margin: 0 auto;

            @include mq(sp) {
              width: 20px;
            }
          }
        }

        .note {
          margin-top: 5px;
        }
      }

      &.cont2 img {
        width: 100%;
        max-width: 600px;
      }

      &.cont3 {
        p {
          color: $lightgray;
          margin-bottom: 35px;

          @include mq(pc) {
            font-size: 14px;
          }

          @include mq(sp) {
            margin-bottom: 15px;
          }
        }

        >div {
          display: flex;

          .btn {
            width: 140px;
            background-color: #fff;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
            border-radius: 0;
            color: $lightgray;
            margin: 0;

            @include mq(sp) {
              width: 100px;
              padding: 13px 0;
            }

            &.active {
              background-color: #00b900;
              color: #fff;
            }
          }
        }
      }
    }

    >a {
      text-decoration: underline;
      text-align: right;
      color: $lightgray;
    }
  }

  .step_box {
    position: relative;
    margin-bottom: 55px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 50%;
      height: 2px;
      background-color: $blue;
      transform: translate(-50%, -50%);

      @include mq(sp) {
        width: 90%;
      }
    }

    @include mq(sp) {
      margin-bottom: 30px;
    }

    ul {
      position: relative;
      display: flex;
      justify-content: center;
      z-index: 1;

      @include mq(sp) {
        justify-content: space-between;
      }
    }

    li {
      height: 60px;
      padding: 0 40px;
      line-height: 60px;
      background-color: #c9f3ff;
      text-align: center;
      margin: 0 20px;
      font-size: 130%;
      border-radius: 30px;
      font-weight: bold;
      position: relative;

      &:after {
        font-size: 14px;
        line-height: 100%;
        position: absolute;
        top: -20px;
        left: 10px;

        @include mq(sp) {
          font-size: 12px;
          top: -15px;
          left: 0;
        }
      }

      &.active {
        color: #fff;
        background-color: $blue;

        &:after {
          color: $blue;
        }
      }

      @include mq(sp) {
        height: 36px;
        line-height: 36px;
        margin: 0;
        padding: 0 15px;
        font-size: 100%;
      }

      &:nth-child(1):after {
        content: "ステップ1";
      }

      &:nth-child(2):after {
        content: "ステップ2";
      }

      &:nth-child(3):after {
        content: "ステップ3";
      }
    }
  }

  .modal_box .modal_inner .recruitment_inner {
    margin: 30px;

    .img_box {
      background-color: #efefef
    }

    ;
  }

  // エラーメッセージ用
  p.error_text {
    font-size: 12px;
    color: #f00;
    margin-top: 10px;
  }

  p.error_text.nomargin {
    margin: unset;
  }

  // コラム
  &.column {
    .columns {
      .list_box {
        .cont1 {
          .img_box {
            width: 140px !important;
            padding-bottom: calc(140px/4*3) !important;
          }
        }

        .cont2 {
          .date {
            display: flex;

            @include mq(sp) {
              margin-bottom: 10px;

              p {
                &:last-child {
                  margin-right: 0 !important;
                }
              }
            }
          }

          .view {
            margin-right: auto !important;

            img {
              margin-right: 6px;
              width: 20px;
              height: 13px;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  // メディア
  .medias {
    .list_box {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      margin-bottom: 40px;

      li {
        position: relative;
        margin-right: 16px;
        width: 140px;
        height: 140px;
        border: 1px solid $lightgray;
        margin-bottom: 20px;

        @include mq(sp) {
          width: 28vw;
          height: 28vw;
        }

        >.thumb {
          object-fit: cover;
          width: 100%;
          height: 100%
        }

        span {
          position: absolute;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 5px;
          right: 5px;
          width: 22px;
          height: 22px;
          background-color: #fff;
          border-radius: 1000px;

          img {
            width: 12px;
          }
        }

        &.using {
          &::before {
            content: "使用中";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            text-align: center;
            color: $blue;
            font-size: 12px;
            font-weight: bold;
            padding: 4px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .icatch_modal {
    .medias {
      margin-bottom: 0;
      padding: 20px 20px 0;
      text-align: center;

      .list_box {
        margin-bottom: 0;

        li {
          &.active {
            border: 4px solid $blue;
          }

          @include mq(sp) {
            width: 23vw;
            height: 23vw;
          }
        }
      }

      button {
        margin: 20px auto;
        border-radius: 5px;
        background-color: #00a0e9;
        font-size: 14px !important;
        padding: 0.5em 2em;
        color: #fff !important;
        cursor: pointer;
        border: 0;
      }
    }
  }

  // ページネーション共通
  .pagination_common {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;

    li {
      margin: 0 5px;

      @include mq(sp) {
        margin: 3px 5px;
      }

      a {
        font-size: 14px;
        color: $blue;

        span {
          width: 30px;
          padding: 8px 0;
          background-color: #c8c8c8;
          border-radius: 50%;
          text-align: center;
          color: #fff;
          display: block;
        }
      }

      &.prev {
        margin: 0 10px 0 0;
      }

      &.next {
        margin: 0 0 0 10px;
      }

      &.active span {
        background-color: $blue;
      }
    }

    &.small {
      @include mq(pc) {
        justify-content: flex-end;
      }

      @include mq(sp) {
        margin-top: 20px;
      }

      li a {
        font-size: 12px;

        span {
          width: 26px;
          padding: 7px 0;
        }
      }
    }
  }
}

// コラムプレビュー用 #jobs
#parachannel .column_preview {
  .btn.type3.preview {
    background-color: $orange !important;
    margin: 0 0 20px auto !important;

    @include mq(sp) {
      width: 100% !important;
    }
  }

  .column_article {
    padding: 20px;
  }
}

#parachannel .column_article {
  h2 {
    font-family: $shuei;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    line-height: 1.6;
    margin-bottom: 30px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .view {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        width: 36px;
        height: 36px;
      }
    }
  }

  .sns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;

    li {
      width: 30px;
      height: 30px;
      margin-left: 12px;
    }
  }

  .article {
    line-height: 1.8;
    font-size: 16px;

    h3 {
      margin-bottom: 30px;
      font-family: $shuei;
      font-weight: bold;
      color: $blue;
      font-size: 18px !important;
      border-bottom: 0 !important;
    }

    p {
      margin-bottom: 30px;
    }

    img {
      width: 100% !important;
      margin-bottom: 30px;
      height: auto !important;
    }

    // 以下、エディタ反映用CSS2022/12/02
    h1 {
      display: block;
      font-size: 2em;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }

    h2 {
      display: block;
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: left;
      font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif
    }

    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-align: left;
      font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif;
      color: $black;
    }

    strong {
      font-weight: bold;
    }

    blockquote {
      display: block;
      margin-left: 40px;
    }
    a {
      display: inline;
      color: $blue;
      text-decoration: underline;
    }
  }
}

#parachannel .column_list {

  h3 {
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: center !important;
    border: none !important;
    justify-content: center !important;
  }

  .btn.type3.back {
    background-color: $blue !important;
  }

  ul {
    border-top: 2px solid $lightgray;
    margin-bottom: 30px;

    li {
      border-bottom: 2px solid $lightgray;

      a {
        display: flex;
        justify-content: space-between;
        padding: 20px;

        .img_box {
          margin-right: 20px;
          width: 140px !important;
          min-width: 140px;
          padding-bottom: calc(140px/4*3) !important;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .txt_box {
          padding: 10px 0;
          width: 100% !important;

          .date {
            margin-bottom: 14px;
            color: $gray;
          }

          .column_ttl {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.6;
            overflow-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            @include mq(sp) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

#parachannel .column_editor {
  .editor {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .editor_box {
      width: calc(100% - 300px);
      margin-bottom: 30px;

      @include mq(sp) {
        width: 100%;
      }


      >form {
        margin-bottom: 30px;

        input {
          font-size: 18px;
          border: 1px solid #d1d1d1;
        }
      }
    }

    .setting_box {
      width: 260px;

      @include mq(sp) {
        width: 100%;
      }

      a {
        cursor: pointer;
      }

      >div {
        border: 1px solid #d1d1d1;
        margin-bottom: 30px;

        p {
          padding: .75em 1em;
          font-size: 16px;
          font-weight: bold;
          border-bottom: 1px solid #d1d1d1;
        }

        &.icatch {
          a {
            display: inline-block;
            text-decoration: underline;
            color: $blue;
          }
        }

        .cont {
          padding: 1em;

          >img {
            margin-bottom: 8px;
          }

          * {
            font-size: 14px !important;
          }

          .btn {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            flex-wrap: wrap;

            li {
              border-radius: 1000px;
              width: 48%;
              text-align: center;
              margin-bottom: 10px;
              background-color: $green;

              &:last-child {
                width: 100%;
              }

              a {
                padding: .5em;
                color: #fff !important;
              }

              &.draft {
                background-color: $orange;
              }

              &.preview {
                background-color: $blue;
              }

            }
          }

          .status {
            li {
              margin-bottom: .75em;

              &:last-child {
                margin-bottom: 0;
              }

              .vdp-datepicker__calendar {
                right: 0;
              }

              span {
                font-weight: bold;
              }

              dl {
                display: flex;
                align-items: center;

                dd {
                  a {
                    margin-left: 7px;
                    display: inline-block;
                    text-decoration: underline;
                    color: $blue;
                  }

                  >div {
                    display: flex;
                    flex-direction: column;

                    input {
                      font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif;
                      font-size: 14px;
                      width: 100%;
                      height: 40px;
                      padding: 0 15px;
                      border: 1px #b3b3b3 solid;
                      font-size: 14px;
                      display: block;
                      margin: 0;
                      margin-bottom: 5px;
                    }
                  }
                }

                dt {
                  display: flex;
                  align-items: center;
                  min-width: 20px;
                  margin-right: 4px;

                  img {
                    display: inline-block;
                    width: 20px;
                    margin-right: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


#parachannel {

  #cke_editor1,
  .cke_dialog {
    * {
      box-sizing: content-box !important;
    }

    input {
      box-sizing: border-box !important;
    }
  }

  a.cke_dialog_tab {
    height: 25px !important;
    padding: 4px 8px !important;
    display: inline-block !important;
    cursor: pointer !important;
    line-height: 26px !important;
    outline: 0 !important;
    color: #484848 !important;
    border: 1px solid #d1d1d1 !important;
    border-radius: 3px 3px 0 0 !important;
    background: #f8f8f8 !important;
    min-width: 90px !important;
    text-align: center !important;
    margin-left: -1px !important;
    letter-spacing: .3px !important;
  }

  a.cke_dialog_tab_selected {
    background: #fff !important;
    border-bottom-color: #fff !important;
    cursor: default !important;
    filter: none !important;
  }

  a.cke_button {
    display: inline-block !important;
    height: 18px !important;
    padding: 4px 6px !important;
    outline: 0 !important;
    cursor: default !important;
    float: left !important;
    border: 0 !important;
    position: relative !important;
    transition: none;
  }

  a.cke_button_on {
    background: #fff !important;
    border: 1px #bcbcbc solid !important;
    padding: 3px 5px !important;
  }

  a.cke_button_off:hover,
  a.cke_button_off:focus,
  a.cke_button_off:active {
    background: #e5e5e5 !important;
    border: 1px #bcbcbc solid !important;
    padding: 3px 5px !important;
  }

  a.cke_combo_button {
    transition: none;
  }
}

#parachannel {
  .head_contact_link {
    margin-bottom: 20px;
  }

  .icatch_modal {
    text-align: center;
    margin-bottom: 20px;

    button {
      text-align: center;
      margin: 20px auto;
      border-radius: 5px;
      background-color: #00a0e9;
      font-size: 14px !important;
      padding: 0.5em 2em;
      color: #fff !important;
      cursor: pointer;
      border: 0;
    }

    .media_att {
      a {
        display: inline;
        text-decoration: underline;
        color: $blue;
      }
    }

    h4 {
      text-align: center;
      color: $blue;
      margin: 0 auto 20px;
    }

    .medias {
      max-width: 1200px;
      margin: 0 auto;

      .list_box {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin-bottom: 40px;

        li {
          position: relative;
          margin-right: 16px;
          width: 140px;
          height: 140px;
          border: 1px solid $lightgray;
          margin-bottom: 20px;

          &.active {
            border: 4px solid $blue;
          }

          @include mq(sp) {
            width: 28vw;
            height: 28vw;
          }

          >.thumb {
            object-fit: cover;
            width: 100%;
            height: 100%
          }

          span {
            position: absolute;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 5px;
            right: 5px;
            width: 22px;
            height: 22px;
            background-color: #fff;
            border-radius: 1000px;

            img {
              width: 12px;
            }
          }

          &.using {
            &::before {
              content: "使用中";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              background-color: rgba(255, 255, 255, 0.9);
              text-align: center;
              color: $blue;
              font-size: 12px;
              font-weight: bold;
              padding: 4px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

}
