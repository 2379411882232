//	項目追加ボタン

.acc_ttl {
	position: relative;
	cursor: pointer;
	// margin-top: 20px;
	// margin-bottom: 10px;
	background-color: #dbffff;
	width: 100%;
	//padding: 10px 10px 10px 45px;
	border-radius: 3px 3px 3px 3px;

	a {
		padding: 10px 10px 10px 45px;
	}

	// &::before {
	//   content: '';
	//   position: absolute;
	//   top: 50%;
	//   left: 12px;
	//   width: 20px;
	//   height: 20px;
	//   background-image: url(../img/mypage/add_icon.svg);
	//   background-position: center;
	//   background-repeat: no-repeat;
	//   background-size: contain;
	//   transform: translate(0, -50%);
	// }

	&::before {
	  content: '\e145';
	  position: absolute;
	  top: 50%;
	  left: 12px;
	  font-family: 'Material Icons';
	  width: 20px;
	  height: 20px;
	  line-height: 20px;
	  background: #fff;
	  border-radius: 50%;
	  color: #00a0e9;
	  text-align: center;
	  transform: translate(0, -50%);
	}

	&.open {
	  &::before {
		background-image: url(../img/mypage/minus_icon.svg);
	  }
	}

	//削除ボタン
	&.del {
	  background-color: #ccc;

	  &::before {
		content: '\e15b';
		color: #333;
	  }
	}

	& + .acc_ttl.del {
		margin-top: 10px;
	}
  }