#parachannel #company {
  
  /* jobs */
  .jobs.edit {
    
    .sidebar {
      width: 38%;
      position: relative;
      @include mq(sp) {
        width: 100%;
      }

      .tab_box {
        position: relative;
        
        .btn_box {
          display: flex;
          position: absolute;
          top: 10px;
          right: 10px;
          a {
            width: 25px;
            margin-right: 10px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
      > a {
        font-size: 16px;
        font-weight: bold;
        color: $blue !important;
      }
      .sample_text {
        width: 250px;
        padding: 10px 0;
        background-color: #df0056;
        border-radius: 10px;
        position: absolute;
        top: 10px;
        left: 0;
        z-index: 99;
        margin: 0;
        @include mq(sp) {
          padding: 8px 0;
        }
        &:after {
          content: "";
          width: 20px;
          height: 20px;
          border-right: 10px transparent solid;
          border-bottom: 20px #df0056 solid;
          border-left: 10px transparent solid;
          box-sizing: border-box;
          position: absolute;
          top: -20px;
          right: 0;
          left: 0;
          margin: 0 auto;
        }
        p {
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #fff;
          @include mq(sp) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        span {
          width: 30px;
          padding: 1px 0 5px;
          background-color: #fff;
          border: 2px #df0056 solid;
          border-radius: 50%;
          font-size: 20px;
          line-height: 100%;
          text-align: center;
          color: #df0056;
          position: absolute;
          top: -10px;
          right: -10px;
          cursor: pointer;
        }
      }
    }
    
    
  }

}
