body {
    // background-color: rgb(170, 59, 59);
    opacity: 1;
}

#semiTransparenDiv {
	width:100%;
	
	/*-Lets Center the Spinner-*/
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    
    /*Centering my shade */
    margin-bottom: 40px;
    margin-top: 60px;
    
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display: none; 
}

.template {
    display: contents;
}

#parachannel .message {
    .name_box {
      padding-bottom: 15px;
      border-bottom: 1px lightgray solid;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      margin-bottom: 25px;
    //   @include mq(sp) {
    //     flex-wrap: wrap;
    //   }
      img {
        width: 55px;
        margin: 5px 15px 5px 0;
      }
      h3 {
        font-size: 16px;
        span {
          font-size: 14px;
          margin-left: 15px;
        }
        span.company_name {
          line-height: 40px;
        }
        span.tag {
          padding: 4px;
          border: 1px #8d8d8d solid;
          border-radius: 5px;
          color: #8d8d8d;
          display: inline-block;
          vertical-align: middle;
        }
      }
      p {
        color: lightgray;
        margin-top: 5px;
      }
      .spacer {
        flex-grow: 1;
      }
      & > a {
        height: 50px;
        padding: 18px 40px;
        min-width: 194px;
        margin-left: 20px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        background-color: blue;
        // @include mq(sp) {
        //   height: initial;
        //   min-width: 102px;
        //   padding: 12px 16px;
        //   font-size: 10px;
        // }
      }
      .note {
        width: 100%;
        display: block;
        // @include mq(pc) {
        text-align: right;
        // }
      }
    }
  }

  #parachannel .message .name_box {
    select {
      width: 135px;
      height: 30px;
      background: url(../../../../../public/assets/img/company/icon04.png) no-repeat;
      background-size: 10px;
      background-position: right 10px center;
      border-radius: 5px;
      cursor: pointer;
      @media screen and (max-width: 769px) {
        width: 160px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 15px;
      }
    }
  }

  .displayNone {
    display: "none";
  };