#parachannel #guide {
  padding: 35px 0 25px;
  font-weight: bold;
  @include mq(sp) {
    padding: 20px 0 0;
  }
  .tab_btn {
    width: 100%;
    max-width: 700px;
    border: 1px $blue solid;
    display: flex;
    margin: 0 auto 50px;
    @include mq(sp) {
      margin-bottom: 10px;
    }
    li {
      width: 33.33%;
      padding: 21px 0;
      border-right: 1px $blue solid;
      text-align: center;
      color: $lightgray;
      cursor: pointer;
      @include mq(pc) {
        font-size: 16px;
      }
      @include mq(sp) {
        padding: 13px 0;
      }
      &.active {
        background-color: $blue;
        color: #fff;
      }
      &:last-child {
        border: none;
      }
    }
  }
  .tab_box > li {
    display: none;
    &.active {
      display: block;
    }
    ul li {
      border-bottom: 1px $lightgray solid;
      a {
        padding: 27px 45px 27px 5px;
        background: url(../img/guide/arrow.png) no-repeat;
        background-size: 10px;
        background-position: right 30px center;
        line-height: 20px;
        @include mq(pc) {
          font-size: 14px;
        }
        @include mq(sp) {
          padding: 14px 25px 14px 5px;
          background-size: 8px;
          background-position: right 10px center;
          line-height: 18px;
        }
      }
    }
  }
  /* detail */
  .detail {
    h3 {
      padding-bottom: 35px;
      border-bottom: 1px $lightgray solid;
      line-height: 30px;
      text-align: center;
      margin-bottom: 45px;
      @include mq(sp) {
        padding-bottom: 15px;
        line-height: 24px;
        margin-bottom: 20px;
      }
    }
    p {
      line-height: 26px;
      margin-bottom: 35px;
      @include mq(pc) {
        font-size: 14px;
      }
      @include mq(sp) {
        line-height: 20px;
        margin-bottom: 20px;
      }
    }
    img {
      width: auto;
      margin-bottom: 35px;
      @include mq(sp) {
        margin-bottom: 20px;
      }
    }
    .btn {
      background-color: #606e84;
      margin-top: 95px;
      @include mq(sp) {
        margin-top: 50px;
      }
    }
    .bg {
      padding: 20px;
      border: 2px $blue solid;
      background: repeating-linear-gradient(45deg,$lightblue,$lightblue 3px,#fff 0,#fff 10px);
      @include mq(sp) {
        padding: 10px;
      }
    }
  }
  .bg {
    padding: 20px;
    border: 2px $blue solid;
    background: repeating-linear-gradient(45deg,$lightblue,$lightblue 3px,#fff 0,#fff 10px);
    @include mq(sp) {
      padding: 10px;
    }
  }
}