.caption {
    width: 100%;
    padding: 10px 15px;
    background-color: rgba(102, 102, 102, 0.6);
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
}
.slider-header .swiperSlide {
    position: relative;
    padding-bottom: 75%;
    overflow: hidden;
    background-color: #f8f8f8;
    width: 100%;
    display: inline-block;
}
.slider-header .swiperSlide img {
    position: absolute;
    -o-object-fit: contain;
    object-fit: contain;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: auto !important;
    height: 100% !important;
}
// .swiperSlideNav{
//     position: relative;
//     padding: 0 0 75%;
//     overflow: hidden;
//     width: 100%;
//     display: inline-block;
// }
// .swiperSlideNav img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain; /* 画像の縦横比を保ちながらコンテナに収める */
//   }
.swiper-slide-visible .swiperSlide {
    position: relative;
    width: 100%;
    height: 120px; /* 固定の高さを設定 */
    overflow: hidden;
    background-color: #f8f8f8;
    display: inline-block;
}
.swiper-slide-visible .swiperSlide img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.swiperSlideNav {
    position: relative;
    width: 92px;
    height: 92px; /* .swiperSlide と同じ高さに設定 */
    overflow: hidden;
    display: inline-block;
    background-color: #f8f9fa;
}
.swiperSlideNav img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* スライド間の余白を追加 */
.swiper-slide.swiper-slide-visible {
    padding-right: 10px; /* 右側に余白を追加 */
    height: 108px; /* スライドの高さを固定 */
    width: 108px !important;
}
.swiper-slide.swiper-slide-visible:last-child {
    padding-right: 0; /* 最後のスライドの右余白を削除 */
}
.marg {
    margin-top: 40px !important;
    font-size: 15px;
}
.cont_box p {
    font-size: 13px !important;
}
// .swiper-wrapper {
//     display: flex !important;
//     align-items: center !important;
//     justify-content: center !important;
// }
