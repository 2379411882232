/* detail */

#parachannel #jobs .detail {

  .link_box {
    width: 100%;
    margin-top: 100px;

    @include mq(sp) {
      margin-top: 50px;
    }

    .etc_box {
      margin-bottom: 30px;

      .ttl {
        margin-bottom: 15px;
        @include mq(sp) {
          line-height: 24px;
          margin-bottom: 10px;
        }
      }

      ul {
        width: 100%;
        border: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      li {
        width: 16%;
        padding: 0;
        background-color: #f0f0f0;
        border-radius: 0;
        box-shadow: none;
        text-align: left;
        margin: 10px 0.8% 0 0;
        @include mq(pc) {
          &:nth-child(-n+6) {
            margin-top: 0;
          }
          &:nth-child(6n) {
            margin-right: 0;
          }
        }
        @include mq(sp) {
          width: 49%;
          margin-right: 2%;
          &:nth-child(-n+2) {
            margin-top: 0;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
        a {
          padding: 0;
          color: $black;
          .img_box {
            width: 100%;
            img {
              height: 150px;
              object-fit: cover;
              @include mq(sp) {
                height: 30vw;
              }
            }
          }
          .text_box {
            padding: 10px;
            p {
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 10px;
              &:last-of-type {
                margin: 0;
              }
              &.company {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }

    &.link_box_cont_box {
      margin: 0;

      .etc_box {
        ul {
          @include mq(sp) {
            justify-content: space-between;
          }
        }
      }

      li {
        width: 30%;
        margin: 0 3% 3% 0;

        @include mq(sp) {
          width: 49%;
          margin: 0 0 2%;
        }
      }

    }

    .category_box {
      margin-bottom: 85px;

      @include mq(sp) {
        margin-bottom: 50px;
      }

      .ttl {
        font-size: 14px;
        margin-bottom: 15px;
        @include mq(sp) {
          line-height: 20px;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        margin: 0 5px 5px 0;
        a {
          padding: 9px 10px;
          background-color: #dcdcdc;
          border-radius: 10px;
          font-weight: bold;
          color: $blue;
          @include mq(sp) {
            padding: 4px 10px;
            border-radius: 5px;
          }
        }
      }
    }

    .btn_box {
      width: 830px;
      height: 165px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      margin: 0 auto;
      position: relative;
      z-index: 9;

      @include mq(sp) {
        width: 100%;
        height: auto;
        padding: 30px 0;
        display: block;
      }

      &:after {
        content: "";
        width: 100vw;
        height: 100%;
        background-color: $yellow;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        pointer-events: none;
        @include mq(pc) {
          min-width: 1000px;
        }
      }

      .btn {
        padding: 0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin: 0;

        &:not(.orange) {
          background-color: #fff;
          border: 2px $blue solid;
          color: $black;
        }
      }

      > .btn {
        width: 170px;
        height: 45px;

        @include mq(sp) {
          margin: 0 auto 20px;
        }

        img {
          width: 25px;
          margin-right: 15px;
        }
      }

      > div {
        display: flex;

        @include mq(sp) {
          width: 100%;
        }

        .btn {
          width: 230px;
          height: 50px;
          font-size: 24px;
          margin-right: 50px;

          @include mq(sp) {
            width: 48.5%;
            font-size: 18px;
            margin-right: 3%;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  &.type2 {

    .sidebar .tab_box dl {
      dt {
        width: 90px;
      }
      dd {
        width: calc(100% - 90px);

        &.feature {
          width: 100%;
          margin-top: -15px;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          list-style-type: none;
        }

        li {
          width: 70px;
          margin: 0 5px 10px 0;

          p {
            font-size: 12px;
            line-height: 100%;
            color: $black;
            margin-top: 5px;
          }
        }

        a {
          text-decoration: underline;
          color: $blue;
          word-break: break-all;

          &:hover {
            text-decoration: none;
          }

          .material-icons {
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
            font-size: 130%;
          }
        }
      }
    }

    .cont_box .ttl {
      padding: 10px 0 36px;
      background: url(../../img/jobs/ttl_bg.png) no-repeat;
      background-size: 100% 100%;
      border: none;
      text-align: center;
      color: #fff;

      @include mq(sp) {
        padding: 6px 0 24px;
      }
    }
  }

  //エントリー前テキスト
  .entry_txt {
    width: 100%;
    margin: 30px auto 10px;
    text-align: center;
    font-size: 120%;
    line-height: 1.5;

    @include mq(sp) {
      font-size: 100%;
    }
  }
  //エントリーボタンエリア
  .btn_area {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mq(sp) {
      &.login_comp {
        flex-direction: column-reverse;
      }
    }

    width: 100%;
    margin: 50px auto;

    @include mq(sp) {
      margin: 0 auto;
    }

    .btn {
      position: relative;
      width: 400px;
      background-color: $orange;
      border: none;
      color: #fff;
      margin: 0 20px;

      @include mq(pc) {
        font-size: 22px;
      }

      @include mq(sp) {
        width: 90%;
        margin: 0 auto 20px;
        padding: 20px 0;
        font-size: 18px;
      }

      img {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 0 0 15px;
        width: 15px;
        height: auto;

        @include mq(sp) {
          width: 15px;
          margin-left: 10px;
        }
      }

      //お気に入り
      &.favarite {
        width: 300px;
        border: solid 2px $blue;
        background-color: #fff;
        color: $blue;

        img {
          width: 22px;

          @include mq(sp) {
            width: 20px;
          }
        }
      }
      //お気に入り登録済み
      &.favarited {
        width: 300px;
        background-color: $blue;
        color: #fff;
        font-size: 120%;
      }
      &.favarite,
      &.favarited {
        display: block;
        height: 100%;
        padding: 0;
        line-height: 60px;
        font-size: 120%;

        @include mq(sp) {
          width: 70%;
        }
      }
    }

    .btn.gray {
      background-color: #e6e6e6;
      color: #231815;
    }
  }
}
