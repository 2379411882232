@charset "utf-8";

$default_font_size: 16;
$default_font_size_t: $default_font_size + px;

$default_zindex: 10;

//rem記法
@function rem($px) {
  @return ($px / $default_font_size) * 1rem;
}

//vw文字サイズ(表示させたい文字サイズ÷幅×100)
@function vw_size($px,$width) {
  @return (($px / $width) * 100) * 1vw;
}


//ブレイクポイント
$breakpoints: (
  tab: 640px,
  lg: 990px,
  xl: 1200px,
  xxl: 1400px,
  pc_headar: 1240px,
) !default;
@mixin mq_up($breakpoint: lg) {
  @media screen and (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}
@mixin mq_up_tab() {
  @include mq_up(tab) {
    @content;
  }
}
@mixin mq_up_lg() {
  @include mq_up(lg) {
    @content;
  }
}
@mixin mq_up_xl() {
  @include mq_up(xl) {
    @content;
  }
}
@mixin mq_up_xxl() {
  @include mq_up(xxl) {
    @content;
  }
}
@mixin mq_down($breakpoint: lg) {
  $size: map-get($breakpoints, $breakpoint) - 0.2;
  @media screen and (max-width: #{$size}) {
    @content;
  }
}
@mixin mq_down_tab() {
  @include mq_down(tab) {
    @content;
  }
}
@mixin mq_down_lg() {
  @include mq_down(lg) {
    @content;
  }
}
@mixin mq_down_xl() {
  @include mq_down(xl) {
    @content;
  }
}
@mixin mq_down_xxl() {
  @include mq_down(xxl) {
    @content;
  }
}


//root_color
@mixin root_setting {
  --body-bg-color: #fbf2f2;
  --body-text-color: #000000;
  --color-white: #ffffff;
  --color-black: #000000;

  --color01: #fbf2f2;
  --color02: #ffc8cf;
  --color03: #b2b2b2;
  --color04: #00b6eb;
  --color05: #fff600;
  --color06: #808080;
  --color07: #404040;
  --color08: #666666;
  --color09: #b5b5b5;
  --color10: #a09a9a;
  --color11: #2a1c04;
  --color12: #00fec8;

}


//setting
@mixin all_setting {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

@mixin html_setting {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-tab-size: 4;
  tab-size: 4;
  font-size: $default_font_size_t;
}

@mixin body_setting {
  margin: 0;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  text-align: left;
  // overflow-x: hidden;
  //word-break: break-all;
  //word-break: break-word;

  /* set */
  background-color: var(--body-bg-color);
  color: var(--body-text-color);
  font-family: 'Noto Sans JP', "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: 300;
  letter-spacing: 0.01em;
  font-size: rem(16);
  line-height: 2.12;
}

