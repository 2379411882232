#parachannel #privacy,
#parachannel #terms {
  padding: 35px 0 85px;
  @include mq(sp) {
    padding: 20px 0 0;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 30px 0 10px;
    @include mq(sp) {
      font-size: 14px;
    }
  }
  p {
    line-height: 24px;
    @include mq(pc) {
      font-size: 14px;
    }
    @include mq(sp) {
      line-height: 20px;
    }
    &.wrap {
      white-space: pre-wrap;
    }
    &.left {
      text-align: right;
      margin-top: 40px;
    }
  }
  strong {
    font-weight: bold;
  }
}
