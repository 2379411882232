/* detail - sidebar */

#parachannel #jobs .detail .sidebar {

  font-weight: bold;

  .tab_btn {
    display: flex;

    li {
      width: 135px;
      box-shadow: 2px 0 2px rgba(0,0,0,0.3);
      font-size: 16px;
      text-align: center;
      margin-right: 5px;
      position: relative;
      cursor: pointer;

      @include mq(sp) {
        width: 120px;
        font-size: 14px;
      }

      a {
        width: 100%;
        padding: 22px 0;
        @include mq(sp) {
          padding: 18px 0;
        }
      }
      &.active:after {
        content: "";
        width: 100%;
        height: 8px;
        background-color: $blue;
        position: absolute;
        left: 0;
        pointer-events: none;
        @include mq(sp) {
          height: 5px;
        }
      }
    }
    &.top li {
      border-radius: 5px 5px 0 0;
      &:after {
        border-radius: 5px 5px 0 0;
        top: 0;
      }
    }
    &.bottom li {
      border-radius: 0 0 5px 5px;
      &:after {
        border-radius: 0 0 5px 5px;
        bottom: 0;
      }
    }
  }

  .tab_box {
    width: 100%;
    padding: 30px 5px;
    background-color: #fff;
    box-shadow: 2px 0 2px rgba(0,0,0,0.3);
    border-radius: 5px;
    position: relative;
    z-index: 9;
    margin: 0;

    h3 {
      font-size: 16px;
      margin-bottom: 40px;
    }

    .ex {
      color: #000;
      font-size: 90%;

      span {
        color: #f00;
      }
    }

    .logo_box {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      // margin-bottom: 40px;
      margin-bottom: 20px;

      img {
        width: 80px;
      }

      h2 {
        width: calc(100% - 100px);
        margin: 0;
      }
    }
    .follow_box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .follow_btn {
        cursor: pointer;
        margin-right: 10px;
        border: 1px solid $lightgray;
        padding: 10px;
        color: $gray;
        &.disabled {
          background-color: $lightgray;
        }
      }
      p {
        color: $gray;
      }
    }

    .edit_icon {
      position: absolute;
      right: 5px;
      top: 10px;
      display: block;
      width: 25px;
      height: 25px;
      margin: 0;
      //margin: 5px 0 8px 10px;
      padding: 0;
      background-image: url("../../img/company/icon13.svg");
      background-repeat: no-repeat;
      vertical-align: middle;
    }

    span.required_icon {
      position: relative;
      top: -5px;
      font-size: 80%;
      color: #f00;
      font-weight: bold;
    }

    dl {
      display: flex;
      flex-wrap: wrap;
      width: 98%;
      border-top: solid 1px #ccc;
    }

    dt,dd {
      padding: 10px 0;
      line-height: 24px;
    }

    dt {
      width: 100px;

      small {
        display: block;
        margin-top: 5px;
        padding-right: 15%;
        font-size: 70%;
        line-height: 1.4;
      }
    }

    dd {
      position: relative;
      width: calc(100% - 100px);
      font-weight: normal;
      word-break: break-all;

      // span {
      //   display: block;

      //   &.edit {
      //     padding-right: 30px;
      //   }

      //   &.error {
      //     line-height: 2;
      //   }
      // }

      strong {
        //color: #333;
        font-weight: bold;
      }

      button {
        display: block;
        margin: 0;
        padding: 2px 30px;
        border: none;
        background-color: #00a0e9;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
      }

      button + label {
        display: block;
        margin-top: 10px;
      }

      label p {
        margin-bottom: 10px;
        font-size: 14px;
      }

      select,
      input[type="text"] {
        margin-bottom: 5px;
      }

      input[type="number"] {
        display: inline-block;
        width: 80%;
        margin: 0 5px 5px 0;
      }

      textarea {
        margin-bottom: 0;
      }

      div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      dl {
        display: block;
        width: 100%;
        border: none;

        &.half {
          width: 46%;
          border: none;
          border-bottom: dotted 1px #ccc;
          margin-top: 10px;

          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-top: 0;
          }
        }

        &.single {
          margin-top: 10px;
          border: none;
        }
      }

      .edit + div {
        margin-top: 10px;
      }
      .edit + span {
        margin-top: 10px;
      }

      dt {
        width: 100%;
        margin: 5px 0;
        font-size: 12px;
        color: #888;
        border: none;
        padding: 0;

        &:last-of-type {
          margin: 5px 0;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      dd {
        width: 100%;
        padding: 0 0 5px;
        border: none;

        label {
          display: inline-block;
        }
        //border-bottom: solid 1px #efefef;

        // .edit_icon {
        //   top: 0;
        // }
      }

      .single {
        dd {
          padding: 0;
          border: none;
        }
      }

      .half {
        .edit_icon {
          top: -5px;
        }
      }

      &.txt_box {
        position: relative;
        //padding-right: 40px;

        .edit {
          white-space: pre-wrap;
        }
      }

      .edit {
        position: relative;
        display: block;
        padding-right: 30px;
      }

      .edit_icon {
        top: 0;

        &.edit_icon_t10 {
          top: 10px;
        }
      }

      .white_space {
        white-space: pre-wrap;
        word-break: break-all;
      }
    }
  }

}
