/* initial_registration */

#parachannel #company {

  .initial_registration {
    font-weight: bold;

    .cont_box {
      width: 100%;
      //max-width: 525px;
      margin: 0 auto;

      @include mq(pc) {
        max-width: 850px;
      }

      > p:not(.number) {
        font-size: 18px;
        font-family: $shuei;
        margin-bottom: 15px;
        @include mq(sp) {
          font-size: 14px;
          margin-bottom: 10px
        }
        &.read {
          margin: 0 0 40px;
          font-size: 16px;
          line-height: 1.4;
          text-align: center;

          @include mq(sp) {
            font-size: 14px;
          }
        }
      }
    }
    .number {
      font-size: 18px;
      font-family: $shuei;
      color: $blue;
      margin-bottom: 35px;
      @include mq(sp) {
        font-size: 14px;
        margin-bottom: 20px;
      }
      span {
        font-size: 45px;
        @include mq(sp) {
          font-size: 30px;
        }
      }
    }

    form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      > p {
        line-height: 20px;
        margin: 10px 0;
        a {
          text-decoration: underline;
          color: #00a0e9;
          display: inline-block;
        }
      }
      .error_box {
        width: 100%;
        .error_msg {
          font-size: 12px;
          color: #f00;
          margin-top: 10px;
          @include mq(sp) {
            font-size: 10px;
          }
        }
      }
      > .note_text {
        width: 100%;
        margin: 50px 0 0;
        @include mq(sp) {
          margin-top: 30px;
        }
      }
    }

    h3 {
      position: relative;
      width: 100%;
      padding-left: 30px;
      font-size: 20px;
      font-family: $shuei;
      margin: 60px auto 35px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 6px;
        width: 20px;
        height: 6px;
        background-color: $blue;
      }

      @include mq(sp) {
        font-size: 16px;
        margin-top: 40px;
        margin-bottom: 20px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
    // > p {
    //   font-size: 16px;
    //   margin-bottom: 20px;
    //   @include mq(sp) {
    //     margin-bottom: 10px;
    //   }
    //   &:last-of-type {
    //     margin: 0;
    //   }
    // }
    input {
      border-radius: 5px;
    }

    select {
      //width: calc(100% - 30px);
      width: 100%;
      margin-bottom: 20px;
      @include mq(sp) {
        //width: calc(100% - 25px);
        margin-bottom: 10px;
      }
      &:last-of-type {
        margin: 0;
      }
    }

    label {
      width: 100%;
      font-size: 16px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      margin-bottom: 20px;
      line-height: 1.5;
      @include mq(sp) {
        font-size: 14px;
        margin-bottom: 10px;
      }
      &:last-of-type {
        margin: 0;
      }
      input {
        width: calc(100% - 80px);
        @include mq(sp) {
          width: calc(100% - 70px);
        }
      }
      &.step3 {
        margin: 20px 0 0;
        > p {
          width: 180px;
          @include mq(sp) {
            width: 140px;
          }
        }
        > div {
          width: calc(100% - 180px);
          @include mq(sp) {
            width: calc(100% - 140px);
          }
        }
        &:nth-of-type(2) > div {
          display: flex;
          align-items: center;
          align-content: center;
          select {
            width: 150px;
            margin: 0;
            @include mq(sp) {
              width: 70px;
            }
          }
          p {
            margin: 0 20px 0 10px;
            @include mq(sp) {
              margin: 0 10px 0 5px;
            }
          }
        }
      }
    }
    .gray_box {
      padding: 20px;
      background-color: #f2f2f2;
      color: $gray;
      margin-top: 20px;
      @include mq(sp) {
        padding: 15px;
      }
      h4 {
        margin-bottom: 15px;
      }
      ul {
        padding: 0 45px;
        list-style-type: disc;
        @include mq(sp) {
          padding: 0;
        }
        li {
          margin: 0 0 10px 1.5em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .radio,
    .checkbox_list {
      width: 100%;
      label {
        justify-content: flex-start;
        input {
          width: 15px;
          height: 15px;
          padding: 0;
          margin: 0 10px 0 0;
        }
      }
    }

    .radio {
      label {
        display: inline-block;
        width: auto;
        margin-right: 50px;
      }
    }

    //地域・合理的配慮
    .checkbox_list.area,
    .checkbox_list.double,
    .checkbox_list.short {
      display: flex;
      flex-wrap: wrap;

      label {
        margin-bottom: 20px;

        @include mq(sp) {
          margin-bottom: 10px;
        }
      }
    }

    //合理的配慮
    .checkbox_list.double {
      label {
        width: calc(100%/2);
        line-height: 1.5;

        @include mq(sp) {
          width: 100%;
        }
      }
    }

    //合理的配慮
    .checkbox_list.short {
      label {
        width: auto;
        margin-right: 50px;
        line-height: 1.5;

        @include mq(sp) {
          width: calc(100%/3);
          margin-right: 0;
        }
      }
    }

    .checkbox_list {
      button {
        width: 120px;
        height: 35px;
        background-color: $blue;
        border-radius: 20px;
        border: none;
        font-weight: bold;
        color: #fff;
        margin-top: 0;
        cursor: pointer;

        @include mq(sp) {
          width: 100px;
          height: 30px;
          font-size: 12px;
          display: block;
          margin: 10px 0 0 auto;
        }
      }
    }

    .name,
    .birthday {
      width: 100%;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      label {
        width: 48%;
        margin: 0;
        input {
          width: calc(100% - 40px);
          @include mq(sp) {
            width: calc(100% - 35px);
          }
        }
      }
    }
    .name.type2 {
      margin-bottom: 20px;

      @include mq(pc) {
        justify-content: space-between;
      }

      @include mq(sp) {
        display: block;
      }

      &:last-child {
        margin: 0;
      }

      label {
        @include mq(sp) {
          width: 100%;

          &:last-child {
            margin-top: 10px;
          }
        }
      }
      input {
        width: calc(100% - 120px);

        @include mq(sp) {
          width: calc(100% - 120px);
        }
      }
    }
    .birthday {
      justify-content: flex-start;
      select {
        width: 100px;
        margin: 0;
        @include mq(sp) {
          width: 80px;
        }
      }
      p {
        font-size: 16px;
        margin: 0 20px 0 10px;
        @include mq(sp) {
          font-size: 14px;
          margin: 0 10px 0 5px;
        }
      }
    }
    dl.address {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      align-items: center;
      align-content: center;
      line-height: 1.5;
      dt {
        width: 130px;
        font-size: 16px;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
        @include mq(sp) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      dd {
        width: calc(100% - 130px);
        margin-bottom: 20px;
        label input {
          width: 100%;
        }
        &:nth-of-type(-n+2) {
          label {
            justify-content: unset;
          }
        }
        &:nth-of-type(1) {
          input[type="text"] {
            width: calc(100% - 160px);
            @include mq(sp) {
              width: calc(100% - 120px);
            }
          }
          button {
            width: 150px;
            padding: 13px 0;
            font-size: 14px;
            margin: 0 0 0 10px;
            @include mq(sp) {
              width: 120px;
              padding: 12px 0;
              font-size: 11px;
              margin-left: 5px;
            }
          }
        }
        &:nth-of-type(2) {
          display: flex;
          @include mq(sp) {
            flex-wrap: wrap;
          }
          justify-content: space-between;
          label {
            margin-right: 10px;
            margin-bottom: 0;
          }
          label.city {
            width: 800px;
            @include mq(sp) {
              width: 100%;
            }
          }
          label.prefecture {
            width: 500px;
            @include mq(sp) {
              margin-bottom: 10px;
            }
          }
          label.check {
            margin-right: 0;
            display: flex;
            align-items: center;
            align-content: center;
            cursor: pointer;
            @include mq(sp) {
              margin: 10px 0 0 0;
            }
          }
          input[type="checkbox"]  {
            width: 20px;
            height: 20px;
            padding: 0;
            font-size: 14px;
            margin: 0 5px 0 15px;
            @include mq(sp) {
              margin: 0 5px 0 0;
            }
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .btn {
      width: 220px;
      padding: 11px 0;
      border-radius: 30px;
      margin-top: 65px;
      position: relative;
      @include mq(pc) {
        font-size: 18px;
      }
      @include mq(sp) {
        width: 150px;
        padding: 11px 0;
        margin-top: 30px;
      }
      img {
        width: 10px;
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 0;
        margin: auto;
        @include mq(sp) {
          width: 8px;
          right: 10px;
        }
      }
      &:not(.orange) img {
        transform: scale(-1, 1);
        right: auto;
        left: 15px;
        @include mq(sp) {
          left: 10px;
        }
      }
    }
    .btn_box {
      display: flex;
      justify-content: space-between;
      margin-top: 65px;
      @include mq(sp) {
        margin-top: 30px;
      }
      .btn {
        margin: 0;
      }
    }

    .privacy_policy {
      width: 100%;
      margin-top: 50px;
      @include mq(sp) {
        margin-top: 30px;
      }
      p {
        text-align: center;
        @include mq(sp) {
          line-height: 16px;
        }

        a {
          display: inline-block;
          text-decoration: underline;
        }
      }
      label {
        justify-content: center;
        margin-top: 30px;
        @include mq(sp) {
          margin-top: 20px;
        }
        input {
          width: 15px;
          height: 15px;
          margin: 0 15px 0 0;
          @include mq(sp) {
            margin-right: 15px;
          }
        }
        span {
          @include mq(sp) {
            line-height: 18px;
          }
        }
      }
    }

  }
}
