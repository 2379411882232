.unset {
  color: unset;
}
.style1 {
  color: gray; width: 100%;
}
.style2 {
  width: 160px;
}
.style3 {
  background-color:white !important;
  color: red;
}
.style4 {
  display: none;
}
.style5 {
  outline: none; 
  width: 100%;
}

#parachannel .entry .cont2 dl.type2 dd {
  word-wrap: break-word;
}
#parachannel .entry .entry_cont dd {
  word-wrap: break-word;
}