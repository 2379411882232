#parachannel #top {
  .btn_box {
    width: 600px;
    font-weight: bold;
    margin: 0 auto 80px;
    display: flex;
    justify-content: space-between;
    position: relative;
    @include mq(sp) {
      width: 100%;
      padding: 20px 3%;
      margin: -20px 0 30px;
    }
    &:after {
      content: "";
      width: 100vw;
      height: calc(100% + 60px);
      background-color: $lightblue;
      position: absolute;
      top: -30px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: -1;
      pointer-events: none;
      @include mq(pc) {
        min-width: 1000px;
      }
      @include mq(sp) {
        height: 100%;
        top: 0;
      }
    }
    p {
      padding: 15px;
      font-size: 12px;
      position: absolute;
      top: -10px;
      left: -140px;
      z-index: 9;
      @include mq(sp) {
        display: none;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../img/header_bg.png) no-repeat;
        background-size: 100% 100%;
        transform: scale(-1, 1);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
      }
      span {
        font-size: 14px;
      }
    }
    a {
      width: 48%;
      height: 50px;
      background-color: #fff;
      border: 2px $blue solid;
      border-radius: 10px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      @include mq(pc) {
        font-size: 16px;
      }
      @include mq(sp) {
        height: 30px;
        border-radius: 5px;
        margin: 0 1%;
      }
      img {
        width: 15px;
        margin-right: 5px;
        @include mq(sp) {
          width: 10px;
        }
      }
    }
  }
  /* mv */
  .mv {
    margin-bottom: 50px;
    @include mq(sp) {
      margin-bottom: 30px;
    }
    .list_box {
      height: 80px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      @include mq(sp) {
        height: 65px;
        padding: 0 3%;
        flex-wrap: wrap;
      }
      p {
        font-size: 17px;
        font-weight: bold;
        margin-right: 30px;
        @include mq(sp) {
          font-size: 15px;
          margin: 0 0 10px;
        }
      }
      > div {
        @include mq(sp) {
          overflow: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        ul {
          display: flex;
          @include mq(sp) {
            width: 620px;
          }
          li {
            margin-right: 10px;
            &:last-child {
              margin: 0;
            }
            a {
              width: 60px;
              padding: 5px 0;
              border-radius: 5px;
              font-weight: bold;
              text-align: center;
              @include mq(pc) {
                font-size: 16px;
              }
              @include mq(sp) {
                padding: 4px 0;
              }
              &:hover {
                background-color: $blue;
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .cont_box {
      padding: 140px 0;
      background: linear-gradient(90deg, $lightblue, #fff 15%, #fff 85%, $lightblue);
      position: relative;
      z-index: 9;
      @include mq(sp) {
        padding: 52vw 0 0;
        background: none;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        // background: url(../img/top/mv_img.png) no-repeat;
        background-size: auto 100%;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        @include mq(sp) {
          background-size: 100%;
          background-position: top;
        }
      }
      .wrapper form {
        width: 420px;
        padding-bottom: 35px;
        background-color: rgba(255,255,255,0.9);
        border-radius: 15px;
        overflow: hidden;
        @include mq(sp) {
          width: 100%;
          padding: 0;
        }
        h2 {
          padding: 19px 0;
          background: url(../img/top/mv_bg.jpg) no-repeat;
          background-size: cover;
          background-position: center;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          color: $blue;
          margin-bottom: 30px;
          @include mq(sp) {
            padding: 13px 0;
            font-size: 15px !important;
            margin-bottom: 15px;
          }
        }
        dl {
          width: 320px;
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto 30px;
          @include mq(sp) {
            width: 80%;
            margin-bottom: 15px;
          }
          dt,dd {
            margin-bottom: 20px;
            @include mq(sp) {
              margin-bottom: 10px;
            }
            &:last-of-type {
              margin: 0;
            }
          }
          dt {
            width: 120px;
            font-weight: bold;
            display: flex;
            align-items: center;
            align-content: center;
            @include mq(pc) {
              font-size: 16px;
            }
            @include mq(sp) {
              width: 90px;
            }
            img {
              width: 20px;
              margin-right: 10px;
              @include mq(sp) {
                width: 15px;
                margin-right: 5px;
              }
            }
          }
          dd {
            width: calc(100% - 120px);
            @include mq(sp) {
              width: calc(100% - 90px);
            }
            select {
              height: 30px;
              background: url(../img/top/arrow04.png) no-repeat;
              background-size: 10px;
              background-position: right 15px center;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
        button {
          width: 320px;
          background-color: $green;
          @include mq(sp) {
            width: 80%;
            padding: 13px 0;
          }
        }
      }
    }
  }
  /* article */
  .article {
    margin-bottom: 85px;
    @include mq(sp) {
      margin-bottom: 50px;
    }
    > div {
      margin-bottom: 80px;
      position: relative;
      @include mq(sp) {
        margin-bottom: 30px;
      }
      .slider {
        width: 100%;
        margin-top: 25px;
        li {
          padding: 0 20px;
          @include mq(sp) {
            padding: 0 15px;
          }
          a {
            position: relative;
            h3 {
              font-family: $shuei;
              font-size: 16px;
              line-height: 24px;
              color: $blue;
              margin: 10px 0;
              @include mq(sp) {
                font-size: 14px;
                line-height: 20px;
              }
            }
            p {
              line-height: 18px;
              color: $gray;
              margin-bottom: 5px;
              &:last-child {
                margin: 0;
              }
              &.category {
                padding: 0 10px;
                border: 1px $blue solid;
                border-radius: 20px;
                color: $blue;
                display: inline-block;
                margin: 5px 0;
              }
              &.new_graduate {
                padding: 1px 10px;
                background-color: $blue;
                border-radius: 5px;
                color: #fff;
                display: inline-block;
                margin: 5px 0 5px 5px;
              }
              &.done {
                padding: 6px 0;
                background-color: #e6e6e6;
                border-radius: 5px;
                font-weight: bold;
                text-align: center;
                color: $black;
                margin-top: 10px;
              }
            }
            .heart {
              width: 20px;
              position: absolute;
              top: 5px;
              right: 5px;
            }
            ul {
              display: flex;
              flex-wrap: wrap;
              margin-top: 5px;
              li {
                width: auto;
                padding: 0 5px;
                border: 1px $lightgray solid;
                border-radius: 5px;
                margin: 3px;
              }
            }
          }
          &.new a:after {
            content: "NEW";
            width: 45px;
            padding: 5px 0;
            background-color: $black;
            font-weight: bold;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .slick-arrow {
          width: 60px;
          height: 100%;
          background-repeat: no-repeat;
          background-size: 18px;
          background-position: center;
          position: absolute;
          top: 0;
          cursor: pointer;
          @include mq(sp) {
            width: calc(3vw + 15px);
            background-size: 10px;
          }
          &.prev {
            background-image: url(../img/top/arrow01.png);
            left: -40px;
            @include mq(sp) {
              left: -3vw;
            }
          }
          &.next {
            background-image: url(../img/top/arrow02.png);
            right: -40px;
            @include mq(sp) {
              right: -3vw;
            }
          }
        }
      }
      > a {
        width: 120px;
        padding: 12px 0;
        background-color: $blue;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        @include mq(sp) {
          width: 200px;
          margin: 25px auto 0;
          position: static;
        }
      }
    }
  }
  /* manual */
  .manual {
    padding: 45px 30px 70px;
    border: 2px $black solid;
    border-radius: 15px;
    margin-bottom: 60px;
    position: relative;
    @include mq(sp) {
      padding: 50px 20px;
      margin-bottom: 50px;
    }
    .ttl.type2 {
      position: absolute;
      top: -25px;
      left: 0;
      @include mq(sp) {
        top: -20px;
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      @include mq(sp) {
        display: block;
      }
      li {
        width: 30%;
        @include mq(sp) {
          width: 100%;
          margin-bottom: 25px;
          &:last-child {
            margin: 0;
          }
        }
        .img_box {
          height: 0;
          padding-bottom: 56.25%;
          margin-bottom: 20px;
          position: relative;
          overflow: hidden;
          @include mq(sp) {
            margin-bottom: 15px;
          }
          iframe,
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        h3 {
          text-align: center;
          span {
            margin: 0 5px;
            position: relative;
            z-index: 9;
            &:after {
              content: "";
              width: 40px;
              height: 40px;
              background-color: $blue;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              z-index: -1;
              pointer-events: none;
              @include mq(sp) {
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }
    .btn.type2 {
      position: absolute;
      right: 0;
      bottom: -25px;
      left: 0;
      @include mq(pc) {
        width: 440px;
      }
      @include mq(sp) {
        bottom: -20px;
      }
    }
  }
  /* column */
  .column {
    padding: 25px 0;
    background: url(../img/top/column_bg.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 55px;
    @include mq(sp) {
      margin-bottom: 30px;
    }
    h3 {
      margin: 45px 0 15px;
      @include mq(sp) {
        margin: 25px 0 10px;
      }
    }
    .wrapper > ul {
      display: flex;
      justify-content: space-between;
      @include mq(sp) {
        flex-wrap: wrap;
      }
      > li {
        width: 22.5%;
        background-color: #fff;
        @include mq(sp) {
          width: 48.5%;
          margin-top: 10px;
          &:nth-child(-n+2) {
            margin-top: 0;
          }
        }
        .img_box img {
          //height: 150px;
          object-fit: cover;
          // @include mq(sp) {
          //   height: 100px;
          // }
        }
        .text_box {
          padding: 10px 10px 5px;
          display: flex;
          flex-wrap: wrap;
          h4 {
            width: 100%;
            font-weight: bold;
            line-height: 16px;
            margin-bottom: 10px;
          }
          p {
            width: 100%;
            margin-bottom: 10px;
          }
          ul {
            display: flex;
            li {
              padding: 2px;
              border: 1px $gray solid;
              border-radius: 3px;
              font-size: 12px;
              text-align: center;
              margin: 2px;
            }
          }
        }
      }
    }
    .btn.type2 {
      margin-top: 50px;
      @include mq(sp) {
        margin-top: 25px;
      }
    }
  }
  /* resume */
  .resume > a:not(.btn.type1) {
    width: 770px;
    padding-left: 115px;
    margin: 15px auto 55px;
    @include mq(sp) {
      width: 100%;
      padding-left: 10%;
      margin: 15px 0 25px;
    }
  }

  .cont_box form button.type3.disabled {
    background-color: gray !important;
  }
}