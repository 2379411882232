.pageer_cont {
    .prev {
      &.disabled {
        display: none;
      }
    }
    .next {
      &.disabled {
        display: none;
      }
    }
}

#parachannel #mypage .recruit_management_cont .tab_anchor a {
  position: relative;
}

#parachannel #mypage .recruit_management_cont .tab_anchor .new_icon:after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #f00;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
}