@charset "UTF-8";
/* @import url("https://fonts.googleapis.com/icon?family=Material+Icons"); */
::-webkit-input-placeholder {
  color: #ccc;
  font-weight: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');


::-moz-placeholder {
  color: #ccc;
  font-weight: normal;
}

:-ms-input-placeholder {
  color: #ccc;
  font-weight: normal;
}

a{
  cursor: pointer;
}

@media print, screen and (max-width: 768px) {
  *:focus {
    outline: none;
  }
}
body{
  color: #231815;
}
/*横向きの際に適用*/
#parachannel {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif; */
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-size: 14px;
  color: #231815;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel {
    min-width: 1000px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel {
    font-size: 12px;
  }
}
#parachannel * {
  box-sizing: border-box;
}
#parachannel img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}
#parachannel a {
  color: #231815;
  display: block;
  text-decoration: none;
  transition: all 0.2s;
}
#parachannel a:hover {

  /* color: #ffffff ; */
  opacity: 0.8;
}
#parachannel .head_contact_link {
  padding: 10px 0;
  background-color: #00a0e9;
  text-align: center;
  font-size: 120%;
  font-weight: bold;
}
#parachannel .head_contact_link a {
  color: #fff;
  text-decoration: underline;
}
@media print, screen and (min-width: 769px) {
  #parachannel .head_contact_link a:hover {
    text-decoration: none;
  }
}
#parachannel .pc_only {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .pc_only {
    display: none !important;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel .sp_only {
    display: none !important;
  }
}


#parachannel main {
  display: block;
  margin-bottom: 85px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel main {
    margin-bottom: 50px;
  }
}
#parachannel input[type=text],
#parachannel input[type=number],
#parachannel input[type=password],
#parachannel input[type=email],
#parachannel input[type=tel],
#parachannel select,
#parachannel textarea {
  -webkit-appearance: none;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: 1px #b3b3b3 solid;
  font-size: 14px;
  display: block;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel input[type=text],
  #parachannel input[type=number],
  #parachannel input[type=password],
  #parachannel input[type=email],
  #parachannel input[type=tel],
  #parachannel select,
  #parachannel textarea {
    height: 35px;
    padding: 0 10px;
    font-size: 12px;
  }
}
#parachannel textarea {
  height: 140px;
  padding: 15px;
  resize: none;
}
#parachannel .ttl {
  font-size: 20px;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl {
    font-size: 16px;
  }
}
#parachannel .ttl.type1 {
  font-size: 18px;
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl.type1 {
    font-size: 14px;
  }
}
#parachannel .ttl.type1 img {
  width: 40px;
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl.type1 img {
    width: 30px;
    margin-right: 5px;
  }
}
#parachannel .ttl.type1 span {
  font-size: 24px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl.type1 span {
    font-size: 18px;
  }
}
#parachannel .ttl.type2 {
  width: 430px;
  background-color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl.type2 {
    width: 100%;
  }
}
#parachannel .ttl.type2 p {
  width: 55px;
  padding: 0 10px;
  background: url(./img/top/ttl_bg.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel .ttl.type2 p img {
  width: auto;
  max-width: none;
  height: 30px;
}
#parachannel .ttl.type2 h2 {
  width: calc(100% - 55px);
  padding: 5px 10px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .ttl.type2 h2 {
    font-size: 24px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl.type2 h2 {
    line-height: 20px;
  }
}
#parachannel .ttl.type2 h2 span {
  font-size: 12px;
  display: block;
  margin-top: 6px;
  line-height: 1.4;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl.type2 h2 span {
    margin-top: 2px;
  }
}
#parachannel .ttl.type3 {
  width: 100%;
  padding: 10px 20px;
  background-color: #ecfbff;
  color: #00a0e9;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .ttl.type3 {
    padding: 12px 15px;
    margin-bottom: 15px;
  }
}
#parachannel .btn {
  font-size: 26px;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .btn {
    font-size: 16px;
  }
}
#parachannel .btn.type1 {
  width: 310px;
  padding: 32px 0;
  background-color: #ea5514;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .btn.type1 {
    width: 200px;
    padding: 22px 0;
  }
}
#parachannel .btn.type1:after {
  content: "";
  width: 26px;
  height: 26px;
  border: 13px transparent solid;
  border-top-color: #fff;
  border-right-color: #fff;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
  right: 2px;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .btn.type1:after {
    width: 20px;
    height: 20px;
    border-width: 10px;
  }
}
#parachannel .btn.type2 {
  width: 360px;
  padding: 12px 0;
  background-color: #231815;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .btn.type2 {
    width: 100%;
  }
}
#parachannel .btn.type2 img {
  width: 25px;
  margin-left: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .btn.type2 img {
    width: 20px;
  }
}
#parachannel .btn.type3 {
  width: 320px;
  padding: 20px 0;
  background-color: #00a0e9;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #fff;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .btn.type3 {
    width: 100%;
    padding: 18px 0;
    font-size: 14px;
  }
}
#parachannel .btn.type3.yellow {
  color: #00a0e9;
  background-color: #fff100;
}
#parachannel .btn.type3.green {
  background-color: #39b54a;
}
#parachannel .btn.type3.orange {
  background-color: #ff7d00;
}
#parachannel .btn.type3.gray {
  background-color: #e6e6e6;
  color: #231815;
}
#parachannel .btn.type4 {
  font-size: unset;
  font-weight: normal;
  display: block;
  margin: 10px 0 0;
  padding: 2px 30px;
  border: none;
  background-color: #00a0e9;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
#parachannel .bread_crumb {
  display: flex;
}
#parachannel .bread_crumb li {
  display: flex;
  flex-wrap: wrap;
}
#parachannel .bread_crumb li:after {
  content: "＞";
  display: block;
  margin: 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .bread_crumb li:after {
    margin: 0 5px;
  }
}
#parachannel .bread_crumb li a {
  font-weight: bold;
}
#parachannel .bread_crumb li:nth-last-child(n+2) a {
  text-decoration: underline;
  color: #00a0e9;
}
#parachannel .bread_crumb li:last-child:after {
  display: none;
}
#parachannel .bread_crumb li:last-child a {
  pointer-events: none;
}
#parachannel .input_box {
  padding: 50px 0;
  border: 1px #b3b3b3 solid;
  font-weight: bold;
  margin-top: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box {
    padding: 20px;
    margin-top: 15px;
  }
}
#parachannel .input_box h3 {
  text-align: center;
  color: #00a0e9;
  margin: 0 0 50px !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box h3 {
    margin-bottom: 20px !important;
  }
}
#parachannel .input_box > p {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box > p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}
#parachannel .input_box form {
  width: 635px;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form {
    width: 100%;
  }
}
#parachannel .input_box form dl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin-bottom: 80px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form dl {
    margin-bottom: 30px;
  }
}
#parachannel .input_box form dl dt, #parachannel .input_box form dl dd {
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form dl dt, #parachannel .input_box form dl dd {
    margin-bottom: 15px;
  }
}
#parachannel .input_box form dl dt:last-of-type, #parachannel .input_box form dl dd:last-of-type {
  margin: 0;
}
#parachannel .input_box form dl dt {
  width: 140px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .input_box form dl dt {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form dl dt {
    width: 110px;
  }
}
#parachannel .input_box form dl dt span {
  display: block;
  margin-top: 8px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form dl dt span {
    margin-top: 5px;
  }
}
#parachannel .input_box form dl dd {
  width: calc(100% - 140px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form dl dd {
    width: calc(100% - 110px);
  }
}
#parachannel .input_box form dl dd span {
  font-size: 12px;
  color: #f00;
  display: block;
  margin-top: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form dl dd span {
    font-size: 10px;
  }
}
#parachannel .input_box form .error_text {
  font-size: 12px;
  color: #f00;
  text-align: center;
  margin: -30px 0 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form .error_text {
    font-size: 10px;
    margin: 20px 0;
  }
}
#parachannel .input_box form > a {
  text-align: center;
  color: #b3b3b3;
  margin-top: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .input_box form > a {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .input_box form > a {
    margin-top: 10px;
  }
}
#parachannel p.note {
  font-size: 80%;
  color: #888;
  line-height: 1.4;
}
#parachannel p.note.wide {
  width: 100%;
  margin-top: 7px;
}
#parachannel .img_box {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  overflow: hidden;
  background-color: #f7f8f8;
}
#parachannel .img_box img {
  position: absolute;
  left: 50%;
  top: 0;
  width: auto;
  max-width: none;
  height: 100%;
  transform: translateX(-50%);
}
#parachannel .mt5 {
  margin-top: 5px;
}
#parachannel .mt10 {
  margin-top: 10px;
}
#parachannel .mb10 {
  margin-bottom: 10px;
}

#parachannel header {
  padding: 15px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header {
    padding: 10px 0;
    flex-wrap: wrap;
  }
}
#parachannel header .logo a {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel header .logo a img {
  width: auto;
  max-width: none;
  height: 95px; /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header .logo a img {
    height: 60px;
  }
}
#parachannel header .logo a p {
  padding: 25px 15px 23px 20px;
  background: url(./img/header_bg.png) no-repeat;
  background-size: 100% 100%;
  font-size: 14px;
  line-height: 16px;
  margin-left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header .logo a p {
    display: none;
  }
}
#parachannel header ul {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header ul {
    width: 220px;
    flex-wrap: wrap;
  }
}
#parachannel header ul li {
  margin-right: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel header ul li:last-child {
    margin: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel header ul li {
    width: 57%;
    margin: 3px 0;
  }
  #parachannel header ul li:nth-child(2n) {
    width: 43%;
  }
}
#parachannel header ul li a {
  color: #595757;
  /*横向きの際に適用*/
}
#parachannel .topHeaderLink:hover{
  color: #00699F !important;
}
@media print, screen and (max-width: 768px) {
  #parachannel header ul li a {
    font-size: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    color: #000;
  }
}

@media print, screen and (min-width: 769px) {
 
  #parachannel header ul li a {
    font-size: inherit;
    align-items: center;
    align-content: center;
    color: #000;
    margin-left: 30px;
  }
  
  .headerButton {
    height: 40px !important;
    white-space: nowrap;
    margin: 10px !important;
    width: 50% !important;
    color: #000;
    font-size: small !important;
  }
}

  
@media screen and (min-width: 1300px) {
  #parachannel header ul {
    /* flex-wrap: wrap;
    width: 220px; */
    margin-left: 20%;
  }
  .headerButton {
    height: 40px !important;
    white-space: nowrap;
    margin: 10px !important;
    width: 50% !important;
    color: #000;
    font-size: small !important;
  }
}
@media screen and (min-width: 1500px) {
  #parachannel header ul {
    /* flex-wrap: wrap;
    width: 220px; */
    margin-left: 15% !important;
  }
  .headerButton {
    height: 40px !important;
    white-space: nowrap;
    margin: 10px !important;
    width: 50% !important;
    color: #000;
    font-size: larger !important;
  }
}
#parachannel header ul li a {
  font-size: large;
}

@media (max-width: 1520px) {
  #parachannel header ul li a {
    font-size: small !important;
  }
}

#parachannel header ul li a img {
  width: 25px;
  margin: 0 auto 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header ul li a img {
    width: 15px;
    margin: 0 5px 0 0;
  }
  
  #parachannel header ul li a {
    font-size: 10px !important;
  }
}
#parachannel header .btn_box {
  width: 130px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header .btn_box {
    width: 100% !important;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  #parachannel header .btn_box:after {
    content: "";
    width: 100vw;
    height: 100%;
    background-color: #ecfbff;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }
}

@media print, screen and (min-width: 769px) {
  #parachannel header .btn_box {
    width: 25% !important;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    margin-top: -20px;
  }
}
#parachannel header .btn_box p {
  padding: 15px;
  font-size: 12px;
  position: absolute;
  top: -15px;
  left: -130px;
  z-index: 9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header .btn_box p {
    display: none;
  }
}
#parachannel header .btn_box p:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url(./img/header_bg.png) no-repeat;
  background-size: 100% 100%;
  transform: scale(-1, 1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}
#parachannel header .btn_box p span {
  font-size: 14px;
}
#parachannel header .btn_box a {
  height: 40px;
  background-color: #fff;
  border: 2px #00699F solid !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
#parachannel header .btn_box a:hover {
  color: #00699F !important;
}
@media print, screen and (max-width: 768px) {
  #parachannel header .btn_box a {
    width: 48%;
    height: 30px;
    margin: 0 1%;
  }
}
#parachannel header .btn_box a img {
  width: 15px;
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel header .btn_box a img {
    width: 10px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel header .btn_box a:nth-of-type(2) {
    height: 25px;
    font-size: 10px;
    margin-top: 5px;
  }
}

#parachannel footer {
  font-weight: bold;
}
#parachannel footer .cont1 {
  background-color: #fff100;
}
#parachannel footer .cont1 .wrapper {
  padding: 50px 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper {
    padding: 30px 0;
    display: block;
  }
}
#parachannel footer .cont1 .wrapper > p {
  width: 800px;
  position: absolute;
  top: -30px;
  left: 0;
  z-index: -1;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper > p {
    width: 100%;
    top: -3.5vw;
  }
}
#parachannel footer .cont1 .wrapper .category_box {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .category_box {
    margin-bottom: 20px;
  }
}
#parachannel footer .cont1 .wrapper .category_box h2 {
  font-size: 18px;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .category_box h2 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
#parachannel footer .cont1 .wrapper .category_box ul {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .category_box ul {
    flex-wrap: wrap;
  }
}
#parachannel footer .cont1 .wrapper .category_box ul li {
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel footer .cont1 .wrapper .category_box ul li:last-child {
    margin: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .category_box ul li {
    margin: 5px;
  }
}
#parachannel footer .cont1 .wrapper .category_box ul li a {
  min-width: 130px;
  padding: 8px 20px;
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  color: #595757;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .category_box ul li a {
    min-width: 100px;
    padding: 9px 10px;
  }
}
#parachannel footer .cont1 .wrapper .link_box {
  width: auto;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .link_box {
    justify-content: center;
  }
}
#parachannel footer .cont1 .wrapper .link_box li {
  width: 220px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .link_box li {
    width: 180px;
  }
}
#parachannel footer .cont1 .wrapper .link_box li.sns {
  width: 50px;
  margin-left: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont1 .wrapper .link_box li.sns {
    width: 40px;
  }
}
#parachannel footer .cont2 {
  background-color: #00a0e9;
}
#parachannel footer .cont2 .wrapper {
  padding: 50px 0 20px;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont2 .wrapper {
    padding: 30px 0 15px;
    text-align: center;
  }
}
#parachannel footer .cont2 .wrapper a {
  color: #fff;
}
#parachannel footer .cont2 .wrapper nav {
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont2 .wrapper nav {
    margin-bottom: 30px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel footer .cont2 .wrapper nav ul {
    display: flex;
  }
}
#parachannel footer .cont2 .wrapper nav ul li {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont2 .wrapper nav ul li {
    margin-bottom: 5px;
  }
}
#parachannel footer .cont2 .wrapper nav ul li:after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #fff;
  display: block;
  margin: 0 5px;
}
#parachannel footer .cont2 .wrapper nav ul li:last-child:after {
  display: none;
}
#parachannel footer .cont2 .wrapper p {
  font-size: 12px;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel footer .cont2 .wrapper p {
    font-size: 10px;
  }
}

#parachannel #sidebar {
  width: 330px;
  padding-bottom: 50px;
  border-right: 1px #b3b3b3 solid;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar {
    width: 100%;
    padding: 0;
    border-right: none;
    border-bottom: 1px #b3b3b3 solid;
  }
}
#parachannel #sidebar > div {
  padding: 45px 30px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #sidebar > div {
    height: 100vh;
    overflow: scroll;
    position: sticky;
    top: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div {
    width: 90%;
    padding: 10px 3%;
    display: flex;
    align-items: center;
    align-content: center;
  }
}
#parachannel #sidebar > div h1 {
  width: 150px;
  margin: 0 auto 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div h1 {
    width: auto;
    margin: 0;
  }
  #parachannel #sidebar > div h1 img {
    width: auto;
    max-width: none;
    height: 60px;
  }
}
#parachannel #sidebar > div .page_ttl_area {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div .page_ttl_area {
    padding-left: 20px;
  }
}
#parachannel #sidebar > div .page_ttl {
  height: 40px;
  line-height: 40px;
  margin: 10px auto 50px;
  text-align: center;
  font-size: 120%;
  background-color: #00a0e9;
  border-radius: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div .page_ttl {
    display: inline-block;
    height: auto;
    line-height: 1.5;
    margin: 0 0 5px;
    padding: 2px 15px;
    font-size: 100%;
  }
}
#parachannel #sidebar > div .page_ttl a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}
#parachannel #sidebar > div .name_box {
  font-size: 16px;
  /* margin-bottom: 30px; */
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div .name_box {
    font-size: 12px;
    margin: 0;
  }
}
#parachannel #sidebar > div .name_box h2 {
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #sidebar > div .name_box h2 {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div .name_box h2 {
    margin-bottom: 0;
  }
}
#parachannel #sidebar > div .name_box h2 span {
  display: inline-block;
  font-size: 22px;
  margin: 5px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div .name_box h2 span {
    font-size: 14px;
  }
}
#parachannel #sidebar > div nav {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #sidebar > div nav {
    height: auto !important;
    padding: 0 !important;
    display: block !important;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div nav {
    width: 100vw;
    height: calc(100vh - 115px);
    padding: 30px;
    background-color: #fff;
    position: absolute;
    top: 115px;
    left: 0;
    z-index: 9999;
    overflow: scroll;
  }
}
#parachannel #sidebar > div nav ul {
  margin-bottom: 30px;
}
#parachannel #sidebar > div nav > ul > li {
  width: 100%;
  margin-bottom: 30px;
}
#parachannel #sidebar > div nav > ul > li:last-child {
  margin: 0;
}
#parachannel #sidebar > div nav > ul > li a {
  width: 100%;
  font-size: 18px;
  color: #606e84;
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #sidebar > div nav > ul > li a img {
  width: 45px;
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div nav > ul > li a img {
    width: 30px;
  }
}
#parachannel #sidebar > div nav > ul > li a span:not(.icon_box) {
  width: 60px;
  padding: 4px 0;
  border: 1px #00a0e9 solid;
  font-size: 16px;
  text-align: center;
  color: #00a0e9;
  margin-left: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div nav > ul > li a span:not(.icon_box) {
    width: 50px;
    padding: 3px 0;
    font-size: 12px;
    margin-left: 10px;
  }
}
#parachannel #sidebar > div nav > ul > li ul {
  margin: 30px 0 70px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div nav > ul > li ul {
    margin: 25px 0 50px;
  }
}
#parachannel #sidebar > div nav > ul > li ul li {
  padding-left: 55px;
  margin-bottom: 15px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div nav > ul > li ul li {
    padding-left: 40px;
  }
  #parachannel #sidebar > div nav > ul > li ul li a {
    font-size: 16px;
  }
}
#parachannel #sidebar > div nav > ul > li ul li:after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #606e84;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto;
}
#parachannel #sidebar > div nav > ul > li ul li:last-child {
  margin: 0;
}
#parachannel #sidebar > div nav .active > a {
  color: #00a0e9;
}
#parachannel #sidebar > div nav .passive > a {
  color: #b3b3b3;
}
#parachannel #sidebar > div nav .new_icon .icon_box {
  display: inline-block;
  position: relative;
}
#parachannel #sidebar > div nav .new_icon .icon_box:after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #f00;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
}
#parachannel #sidebar > div nav .banner {
  margin-top: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #sidebar > div nav .banner {
    width: 150px;
    margin-top: 25px;
  }
}
#parachannel #sidebar > div .menu_btn {
  width: 25px;
  height: 20px;
  position: absolute;
  right: 3%;
  z-index: 999;
}
@media print, screen and (min-width: 769px) {
  #parachannel #sidebar > div .menu_btn {
    display: none;
  }
}
#parachannel #sidebar > div .menu_btn span {
  width: 100%;
  height: 2px;
  background-color: #00a0e9;
  position: absolute;
  left: 0;
  transition: all 0.4s;
}
#parachannel #sidebar > div .menu_btn span:nth-of-type(1) {
  top: 0;
}
#parachannel #sidebar > div .menu_btn span:nth-of-type(2) {
  top: 9px;
}
#parachannel #sidebar > div .menu_btn span:nth-of-type(3) {
  bottom: 0;
}
#parachannel #sidebar > div .menu_btn.active span:nth-of-type(1) {
  transform: translateY(9px) rotate(-45deg);
}
#parachannel #sidebar > div .menu_btn.active span:nth-of-type(2) {
  opacity: 0;
}
#parachannel #sidebar > div .menu_btn.active span:nth-of-type(3) {
  transform: translateY(-9px) rotate(45deg);
}
#parachannel.overflow {
  overflow: hidden;
}

/* http://meyerweb.com/eric/tools/css/reset/ */
/* v2.0 | 20110126 */
/* License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

#loader {
  content: "";
  display: block;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  transition: opacity 1s linear;
  -webkit-transition: opacity 1s linear;
}

#loader.loaded {
  opacity: 0;
}

body.loaded::after {
  content: none;
}

/* SP-menu */
#SP-menu {
  /*横向きの際に適用*/
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #SP-menu {
    display: none;
  }
}
@media print, screen and (max-width: 768px) {
  #SP-menu {
    position: absolute;
    right: 0;
    top: 0;
    width: 6rem;
    height: 6rem;
    cursor: pointer;
    /* .opened */
  }
  #SP-menu span {
    display: block;
    position: absolute;
    left: 50%;
    width: 3rem;
    height: 0.4rem;
    margin-left: -1.5rem;
    transition: all 0.2s;
    -weblit-transition: all 0.2s;
    background-color: #000;
  }
  #SP-menu span:first-child {
    top: 2rem;
  }
  #SP-menu span:nth-child(2) {
    top: 3rem;
  }
  #SP-menu span:last-child {
    top: 4rem;
  }
  #SP-menu.opened span:nth-child(2) {
    opacity: 0;
  }
  #SP-menu.opened span:first-child {
    transform: translateY(1rem) rotate(45deg);
  }
  #SP-menu.opened span:last-child {
    transform: translateY(-1rem) rotate(-45deg);
  }
}
#parachannel {
  /*
  .modal.interview_modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    padding: 60px 0;
    overflow-y: auto;
    width: 100%;
    height: 110vh;
    background-color: rgba(0, 0, 0, 0.6);

    .modal_box {
      margin: 0 auto 100px;
      padding: 40px 40px 50px;
      width: 94%;
      max-width: 724px;
      background-color: #fff;
      border-radius: 12px;
      height: auto;
      position: static;

      @include mq(sp) {
        padding: 30px 20px 30px;
      }

      .modal_ttl {
        text-align: center;
        line-height: 1.6;
        font-weight: bold;

        dt {
          margin-bottom: 50px;
          color: $blue;
          font-size: 20px;

          @include mq(sp) {
            margin-bottom: 30px;
            font-size: 18px;
          }
        }

        dd {
          margin-bottom: 40px;
          font-size: 16px;

          @include mq(sp) {
            margin-bottom: 20px;
            font-size: 12px;
          }
        }
      }

      .change_box {
        input {
          margin-bottom: 70px;
          padding: 27px 20px;
          font-size: 18px;
          background-color: #f7f7f7;
          border: none;

          @include mq(sp) {
            margin-bottom: 30px;
            font-size: 16px;
          }

        }

        // 日時用
        .candidate_list {
          li {
            margin-bottom: 30px;

            &:last-child {
              margin-bottom: 60px;
            }
          }

          .date_ttl {
            font-size: 16px;
            margin-bottom: 14px;
          }

          .date_input_cont {
            display: flex;
            align-items: center;

            @include mq(sp) {
              flex-wrap: wrap;
            }

            .date,
            .time {
              margin-bottom: 0;
              min-width: 100px;
              margin-right: 5px;
              padding: 12px;
              font-size: 13px;
              font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif;

              @include mq(sp) {
                margin-bottom: 10px;
                min-width: auto;
              }
            }

            .date {
              @include mq(sp) {
                width: 55%;
              }
            }

            .time {
              @include mq(sp) {
                width: 35%;
              }
            }

            >span {
              margin-right: 5px;
            }

            .edit_cont {
              display: flex;
              min-width: 90px;

              span {
                img {
                  margin-left: 10px;
                  width: 32px;
                  min-width: 32px;
                  height: 32px;
                }
              }
            }
          }
        }
      }

      .btn_cont {
        text-align: center;

        .set_btn {
          padding: 18px 60px;
          min-width: 180px;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          background-color: $blue;

          margin-left: 0;
          display: inline-block;

          @include mq(sp) {
            min-width: 72px;
            padding: 12px 16px;
            font-size: 14px;
          }

        }
      }
    }

    &.select {
      .modal_box {
        padding: 0;
        overflow: hidden;
        .ttl {
          @include mq(sp) {
            font-size: 14px;
          }
        }

        .category_ttl {
          position: relative;
          padding: 20px 82px;
          text-align: center;
          background-color: #325a78;
          color: #fff;
          font-size: 17px;

          @include mq(sp) {
            font-size: 12px;
          }


          a {
            color: #fff;
            position: absolute;

            span {
              display: inline-block;
              margin: 0 10px;
              width: 10px;
              height: 10px;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;

              @include mq(sp) {
                width: 7px;
                height: 7px;

              }

            }

            &.prev {
              top: 50%;
              left: 10px;
              transform: translate(0, -50%);

              span {
                transform: rotate(135deg);
              }
            }

            &.next {
              top: 50%;
              right: 10px;
              transform: translate(0, -50%);

              span {
                transform: rotate(-45deg);
              }
            }
          }
        }

        .modal_inner {
          padding: 60px;

          @include mq(sp) {
            padding: 30px 20px 30px;
          }

          .btn_cont {
            margin-top: 20px;
          }

          .modal_ttl {
            margin-bottom: 40px;
            text-align: center;
            font-size: 15px;
            font-weight: bold;
            @include mq(sp) {
              font-size: 13px;
              margin-bottom: 20px;
            }

          }

          ul {
            border: 1px solid $p_gray;

            li {
              position: relative;
              cursor: pointer;
              padding: 20px 50px 20px 50px;
              line-height: 1.6;
              font-size: 14px;
              border-bottom: 1px solid $p_gray;

              @include mq(sp) {
                font-size: 12px;
              }

              &:last-child {
                border-bottom: none;
              }

              &.active {
                background-color: #dbffff;
              }
            }

            &.radio {
              li {

                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 20px;
                  width: 18px;
                  height: 18px;
                  background-image: url(./img/mypage/modal_radio_off.svg);
                  transform: translate(0, -50%);
                }

                &.active {
                  &::before {
                    background-image: url(./img/mypage/modal_radio_on.svg);
                  }
                }

                &::after {
                  content: '';
                  position: absolute;
                  top: 48%;
                  right: 20px;
                  width: 10px;
                  height: 10px;
                  border-bottom: 2px solid $blue;
                  border-right: 2px solid $blue;
                  transform: rotate(-45deg) translate(0, -50%);

                  @include mq(sp) {
                    width: 7px;
                    height: 7px;

                  }

                }
              }
            }

            &.check {
              li {
                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 20px;
                  width: 20px;
                  height: 18px;
                  background-image: url(./img/mypage/modal_check_off.svg);
                  transform: translate(0, -50%);
                }

                &.active {
                  &::before {
                    background-image: url(./img/mypage/modal_check_on.svg);
                  }
                }

              }
            }
          }

        }
      }
    }
  }
  */
}
#parachannel .modal_box {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  margin: 0 !important;
}
#parachannel .modal_box .modal_inner {
  max-height: 90vh;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 0;
  background-color: #F7F8F8;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  overflow: scroll;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .modal_box .modal_inner {
    width: 730px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box .modal_inner {
    width: 90%;
  }
}
#parachannel .modal_box .modal_inner .btn.example {
  width: 200px;
  padding: 12px 0;
  margin-bottom: 30px;
}
#parachannel .modal_box h4 {
  width: 100%;
  height: 70px;
  margin: 0 auto;
  padding: 0;
  line-height: 70px;
  background-color: #006496 !important;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #fff !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box h4 {
    padding: 0 50px 0 20px;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    align-content: center;
  }
}
#parachannel .modal_box h5 {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}
#parachannel .modal_box .read {
  padding: 5% 5% 0;
  font-size: 15px;
  line-height: 1.5;
}
#parachannel .modal_box .read.end {
  margin-bottom: 5%;
  text-align: center;
}
#parachannel .modal_box .change_box {
  padding: 5%;
}
#parachannel .modal_box .change_box input[type=email] + div span {
  display: block;
  margin-top: 5px;
  color: #f00;
  font-size: 90%;
  font-weight: bold;
}
#parachannel .modal_box form {
  max-height: fit-content !important;
  padding: 30px 85px 60px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box form {
    /* max-height: 70vh; */
    padding: 20px;
  }
}
#parachannel .modal_box #btn_prev {
  position: absolute;
  left: 20px;
  top: 0;
  margin: 0 !important;
  line-height: 70px !important;
  color: #fff !important;
  font-size: 16px !important;
  cursor: pointer;
}
#parachannel .modal_box .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  font-weight: normal;
  line-height: 100%;
  text-align: center;
  cursor: pointer;
  margin: 0 !important;
  color: #fff !important;
  line-height: 70px !important;
  /*横向きの際に適用*/
}
#parachannel .modal_box .close span,
#parachannel .modal_box .close i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 30px;
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box .close {
    width: 50px;
    padding: 8px 0 12px;
    line-height: 45px !important;
  }
}
#parachannel .modal_box .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: pointer;
}
#parachannel .modal_box .slider,
#parachannel .modal_box .slider_item {
  background-color: #fff;
  border-radius: 5px;
}
#parachannel .modal_box .slider_item {
  /* overflow: scroll; */
  /* max-height: 450px; */
  padding: 2%;
  max-height: fit-content !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box .slider_item {
    /* max-height: 250px; */
  }
}
#parachannel .modal_box .note {
  margin-bottom: 10px;
}
.allStatusChangeBtn {
  height: 40px;
  line-height: 40px;
  margin: 20px auto;
  text-align: center;
  font-size: 14px;
  background-color: #00a0e9;
  border-radius: 5px;
  color: #fff;
  float: right;
}
#parachannel .modal_box label {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -1px;
  padding: 10px 20px;
  line-height: 30px;
  border: solid 1px #efefef;
  transition: opacity 0.15s linear;
  -webkit-transition: opacity 0.15s linear;
  cursor: pointer;
  font-weight: normal;
}
#parachannel .modal_box label::before {
  content: "\e5cc";
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Material Icons";
  font-size: 30px;
  color: #006496;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
@media print, screen and (min-width: 769px) {
  #parachannel .modal_box label:hover {
    opacity: 0.7;
  }
}
#parachannel .modal_box label span {
  width: 100%;
  padding-left: 10px;
  font-size: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box label span {
    padding-right: 15px;
  }
}
#parachannel .modal_box label input[type=radio] {
  width: 20px;
  height: 20px;
  margin: 0;
  text-align: left;
}
#parachannel .modal_box label input[type=text] {
  width: 100%;
}
#parachannel .modal_box label.input_text {
  display: block;
}
#parachannel .modal_box label.input_text::before {
  display: none;
}
#parachannel .modal_box label.large_category:before {
  height: 30px;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
#parachannel .modal_box label.large_category.on:before {
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
#parachannel .modal_box label.small_category {
  padding-left: 50px;
  background-color: #ecfbff;
}
#parachannel .modal_box.selectRadio1 label::before {
  display: none;
}
#parachannel .modal_box .radio_box {
  margin-bottom: 20px;
}
@media print, screen and (min-width: 769px) {
  #parachannel .modal_box .radio_box {
    display: flex;
    align-items: center;
    align-content: center;
  }
  #parachannel .modal_box .radio_box .ttl {
    margin: 0;
  }
}
#parachannel .modal_box .radio_box .type {
  display: flex;
}
#parachannel .modal_box .radio_box .type label {
  padding: 0;
  border: none;
  margin: 0 20px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box .radio_box .type label {
    margin-right: 10px;
  }
}
#parachannel .modal_box .radio_box .type label:before {
  display: none;
}
#parachannel .modal_box #btn_next,
#parachannel .modal_box .complete {
  width: 200px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto 30px;
  text-align: center;
  border-radius: 5px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box #btn_next,
  #parachannel .modal_box .complete {
    margin-top: 0;
  }
}
#parachannel .modal_box #btn_next {
  margin-top: 50px;
  background-color: #CEE5F2;
  color: #006496;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box #btn_next {
    margin-top: 10px;
  }
}
#parachannel .modal_box .complete {
  margin-top: 0;
  background-color: #006496;
  color: #fff;
}
#parachannel .modal_box .btn_area {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}
#parachannel .modal_box .btn_area button {
  margin: 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box .btn_area button {
    margin: 0 5px;
  }
}
#parachannel .modal_box .btn_area .btn_reset {
  width: 200px;
  margin: 0 10px;
  padding: 20px 0;
  background-color: #ccc;
  border-radius: 5px;
  border: none;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  color: #333;
  display: block;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box .btn_area .btn_reset {
    width: 250px;
    font-size: 12px;
    margin: 0 5px;
  }
}
#parachannel .modal_box .btn_cont {
  text-align: center;
  margin-bottom: 5%;
}
#parachannel .modal_box .btn_cont .set_btn {
  padding: 18px 60px;
  min-width: 180px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  background-color: #00a0e9;
  margin-left: 0;
  display: inline-block;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .modal_box .btn_cont .set_btn {
    min-width: 72px;
    padding: 12px 16px;
    font-size: 14px;
  }
}
#parachannel .modal_box .submitBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
#parachannel .modal_box .submitBtn ul {
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 20px;
}
#parachannel .modal_box .submitBtn ul li {
  background-color: #39b54a;
  border-radius: 1000px;
  width: 48%;
  text-align: center;
}
#parachannel .modal_box .submitBtn ul li a {
  font-size: 14px !important;
  padding: 0.5em;
  color: #fff !important;
  font-weight: bold;
  cursor: pointer;
}
#parachannel .modal_box .submitBtn ul .draft {
  background-color: #ea5514;
}

#parachannel .pagination_circle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}
#parachannel .pagination_circle li {
  margin: 0 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .pagination_circle li {
    margin: 3px 5px;
  }
}
#parachannel .pagination_circle li a {
  font-size: 14px;
  color: #00a0e9;
}
#parachannel .pagination_circle li a span {
  width: 30px;
  padding: 8px 0;
  background-color: #c8c8c8;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  display: block;
}
#parachannel .pagination_circle li.prev {
  margin: 0 10px 0 0;
}
#parachannel .pagination_circle li.prev.disabled {
  display: none;
}
#parachannel .pagination_circle li.next {
  margin: 0 0 0 10px;
}
#parachannel .pagination_circle li.next.disabled {
  display: none;
}
#parachannel .pagination_circle li.active span {
  background-color: #00a0e9;
}
#parachannel .pagination_circle.small {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .pagination_circle.small {
    justify-content: flex-end;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .pagination_circle.small {
    margin-top: 20px;
  }
}
#parachannel .pagination_circle.small li a {
  font-size: 12px;
}
#parachannel .pagination_circle.small li a span {
  width: 26px;
  padding: 7px 0;
}
#parachannel .pagination_square {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel .pagination_square li {
  width: 20px;
  margin: 0 5px 0 0;
}
#parachannel .pagination_square li:last-child {
  margin: 0;
}
#parachannel .pagination_square li a {
  width: 100%;
  padding: 4px 0;
  text-align: center;
  color: #00a0e9;
}
#parachannel .pagination_square li.arrow.disabled {
  display: none;
}
#parachannel .pagination_square li.arrow a {
  padding: 0;
}
#parachannel .pagination_square li.active {
  background-color: #00a0e9;
}
#parachannel .pagination_square li.active a {
  color: #fff;
}

.acc_ttl {
  position: relative;
  cursor: pointer;
  background-color: #dbffff;
  width: 100%;
  border-radius: 3px 3px 3px 3px;
}
.acc_ttl a {
  padding: 10px 10px 10px 45px;
}
.acc_ttl::before {
  content: "\e145";
  position: absolute;
  top: 50%;
  left: 12px;
  font-family: "Material Icons";
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #fff;
  border-radius: 50%;
  color: #00a0e9;
  text-align: center;
  transform: translate(0, -50%);
}
.acc_ttl.open::before {
  background-image: url(./img/mypage/minus_icon.svg);
}
.acc_ttl.del {
  background-color: #ccc;
}
.acc_ttl.del::before {
  content: "\e15b";
  color: #333;
}
.acc_ttl + .acc_ttl.del {
  margin-top: 10px;
}

#parachannel .edit_box {
  /*cursor: pointer;
  position: relative;
  margin-top: 0!important;
  margin-bottom: 20px;
  width: 100%;
  padding: 24px;
  border-radius: 2px;
  background-color: #dbffff;
  @include mypage_shadow();*/
  position: relative;
}
#parachannel .edit_box .txt_cont h4 {
  position: relative;
  color: #888; /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .edit_box .txt_cont h4 {
    font-size: 110%;
  }
}
#parachannel .edit_box .txt_cont p {
  color: #000;
  font-weight: normal;
  line-height: 1.4;
  padding-bottom: 10px;
}
#parachannel .edit_box.fixed h4 {
  padding-right: 60px;
}
#parachannel .edit_box.not_enter {
  background-color: rgba(255, 215, 200, 0.5);
}
#parachannel .edit_box.not_enter h4 {
  padding-right: 200px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .edit_box.not_enter h4 {
    margin-top: 36px;
    padding-right: 0;
  }
}
#parachannel .edit_box .icon_cont {
  position: absolute;
  right: -50px;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .edit_box .icon_cont {
    right: -40px;
  }
}
#parachannel .edit_box .icon_cont.uninput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  overflow: hidden;
  border-radius: 4px;
}
#parachannel .edit_box .icon_cont.uninput p {
  padding: 0 10px;
  padding-left: 15px;
  color: #ea5514;
}
#parachannel .edit_box .icon_cont.uninput .error {
  display: block;
  padding: 10px;
  background: #ea5514;
}
#parachannel .edit_box .icon_cont.uninput .error img {
  width: 15px;
  height: 13px;
}
#parachannel .edit_box .icon_cont .check img {
  width: 21px;
  height: 21px;
}
#parachannel .edit_box .icon_cont .edit {
  width: 46px;
  height: 46px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .edit_box .icon_cont .edit {
    width: 36px;
    height: 36px;
  }
}
#parachannel .edit_box .icon_cont .edit img {
  width: 100%;
  height: 100%;
}

#parachannel .textarea_cont {
  width: 100%;
  margin-top: 10px;
}
#parachannel .textarea_cont h4 {
  color: #888;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .textarea_cont h4 {
    font-size: 110%;
  }
}
#parachannel .textarea_cont label {
  display: block;
  margin-bottom: 5px;
}
#parachannel .upload_cont {
  width: 100%;
  padding: 0;
}
#parachannel .upload_cont label {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
#parachannel .upload_cont input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100% !important;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 0;
  text-indent: -9999px;
  background: none;
}
#parachannel .upload_cont .support_txt {
  display: block;
  margin-top: 10px;
  font-weight: normal;
  font-size: 75%;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .upload_cont .support_txt {
    padding: 0 5%;
    text-align: justify;
  }
}
#parachannel .upload_cont .file_upload {
  position: relative;
  width: 60%;
  padding: 0;
  background-color: #00a0e9;
  border-radius: 6px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .upload_cont .file_upload {
    width: 100%;
  }
}
#parachannel .upload_cont .file_upload p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#parachannel .upload_cont .file_upload input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100% !important;
  font-size: 0;
  text-indent: -9999px;
}
#parachannel .upload_cont .file_upload .text {
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  display: block;
  color: #fff;
}
#parachannel .upload_cont .file_upload .icon {
  padding: 18px 12px;
  background-color: #fff;
}
#parachannel .upload_cont .file_upload .icon img {
  width: 14px;
  height: 14px;
}
#parachannel .upload_cont > span.support_txt {
  color: #231815;
}
#parachannel .upload_cont .file_name {
  margin-top: 5px;
  color: #333;
  font-size: 90%;
}
#parachannel .pr_movie {
  flex-direction: column;
}
#parachannel .pr_movie .upload_cont.btn.type3 {
  position: relative;
  width: 100%;
}
#parachannel .pr_movie .upload_cont.btn.type3 + div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
#parachannel .pr_movie .upload_cont.btn.type3 + div a {
  display: inline-block;
  margin-left: 10px;
  padding: 3px 20px;
  background-color: #006496;
  color: #fff;
  border-radius: 5px;
}
#parachannel .pr_movie .note {
  margin-top: 5px;
}
#parachannel textarea {
  width: 100%;
  border-radius: 6px;
  font-size: 100%;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif;
  margin-bottom: 10px;
}
#parachannel textarea + .text_length_alert {
  margin-top: -5px;
  color: #df0056;
  text-align: right;
}
#parachannel .nest {
  margin: 0;
  line-height: 1.8;
  /*横向きの際に適用*/
}
#parachannel .nest_child {
  width: 100%;
  border-bottom: 1px solid #9e9f9f;
}
#parachannel .nest_child:last-of-type, #parachannel .nest_child:last-child {
  border-bottom: 0;
}
#parachannel .nest_child .nest_child {
  margin-left: 0;
}
#parachannel .nest_child .checkbox_cont .checkbox_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#parachannel .nest_child .checkbox_cont .checkbox_list li {
  width: 49%;
  margin: 10px 0;
}
#parachannel .nest_child .checkbox_cont .checkbox_list li label {
  display: flex;
  cursor: pointer;
}
#parachannel .nest_child .checkbox_cont .checkbox_list li label input {
  overflow: hidden;
  border-radius: 2px;
  width: 20px;
  height: 18px;
}
#parachannel .nest_child h4 {
  position: relative;
  margin-bottom: 5px;
  color: #888;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .nest_child h4 {
    font-size: 110%;
  }
}
#parachannel .nest_child h4.acc_h4 {
  cursor: pointer;
  padding: 10px 0;
}
#parachannel .nest_child h4.acc_h4:last-child {
  margin-bottom: 0;
}
#parachannel .nest_child h4.acc_h4::before {
  content: "";
  position: absolute;
  right: 5px;
  top: 18px;
  display: inline-block;
  margin-left: 1em;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid #00a0e9;
  border-right: 2px solid #00a0e9;
  transform: rotate(45deg);
  transition: transform 0.15s linear;
  -webkit-transition: transform 0.15s linear;
}
#parachannel .nest_child h4.acc_h4.open::before {
  transform: translate(0, -2px) rotate(225deg);
}
#parachannel .nest_child h4.acc_h4.fixed::before {
  content: "";
  position: absolute;
  right: 0;
  top: 10px;
  background-image: url(./img/mypage/check_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
}
#parachannel .nest_child h4.acc_h4 .any {
  margin-left: 1em;
  padding: 0.2em 0.5em;
  font-size: 9px;
  color: #fff;
  background-color: #9e9f9f;
}
#parachannel .nest_child .acc_list_cont {
  width: 100%;
  margin: 5px 0;
}
#parachannel .nest_child .acc_list_cont .skill_list li {
  opacity: 0.3;
  background-color: rgba(220, 220, 220, 0.75);
}
#parachannel .nest_child .acc_list_cont .skill_list li.choice {
  opacity: 1;
  background-color: rgba(219, 255, 255, 0.5);
}
#parachannel .nest_child.acc_list_cont {
  border: none;
}
#parachannel .nest_child.select_cont {
  width: 100%;
  line-height: 1.8;
}
#parachannel .nest_child.select_cont:last-child {
  margin-top: 20px;
}
#parachannel .nest_child.select_cont:last-child .edit_box {
  margin-bottom: 0 !important;
}
#parachannel .nest_child.select_cont .select {
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  color: #adadad;
  border: 1px solid #9e9f9f;
  border-radius: 6px;
  background-color: #dbffff;
  font-weight: normal;
  /*横向きの際に適用*/
}
#parachannel .nest_child.select_cont .select a {
  padding: 2px 20px 2px 10px !important;
}
#parachannel .nest_child.select_cont .select::after {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid #00a0e9;
  border-right: 2px solid #00a0e9;
  transform: rotate(45deg);
}
#parachannel .nest_child.select_cont .select.comp {
  background-color: #fff;
  color: #000;
  font-weight: normal;
}
#parachannel .nest_child.select_cont .select.disable {
  background-color: #fff;
  font-weight: normal;
  cursor: default !important;
}
#parachannel .nest_child.select_cont .select.disable > span {
  display: block;
  padding: 2px 20px 2px 10px;
}
@media print, screen and (max-width: 768px) {
  #parachannel .nest_child.select_cont .select {
    width: 100%;
  }
}
#parachannel .nest_child.long textarea {
  height: 240px;
}
#parachannel .text_length_alert {
  font-size: 90%;
  color: #f00;
}

.media_uploader {
  margin-bottom: 40px;
  text-align: center;
  line-height: 2;
}
.media_uploader p {
  font-size: 18px;
}
.media_uploader p span {
  font-size: 14px;
}
.media_uploader #drop-zone {
  background-color: #F1F1F1;
  border: 4px dashed #b3b3b3 !important;
}

#parachannel .modal_box .media_uploader form {
  padding: 20px 20px 0;
}

#parachannel #top {
  /* mv */
  /* article */
  /* manual */
  /* column */
  /* resume */
}
#parachannel #top .btn_box {
  width: 600px;
  font-weight: bold;
  margin: 0 auto 80px;
  display: flex;
  justify-content: space-between;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .btn_box {
    width: 100%;
    padding: 20px 3%;
    margin: -20px 0 30px;
  }
}
#parachannel #top .btn_box:after {
  content: "";
  width: 100vw;
  height: calc(100% + 60px);
  background-color: #ecfbff;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #top .btn_box:after {
    min-width: 1000px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .btn_box:after {
    height: 100%;
    top: 0;
  }
}
#parachannel #top .btn_box p {
  padding: 15px;
  font-size: 12px;
  position: absolute;
  top: -10px;
  left: -140px;
  z-index: 9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .btn_box p {
    display: none;
  }
}
#parachannel #top .btn_box p:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url(./img/header_bg.png) no-repeat;
  background-size: 100% 100%;
  transform: scale(-1, 1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}
#parachannel #top .btn_box p span {
  font-size: 14px;
}
#parachannel #top .btn_box a {
  width: 48%;
  height: 50px;
  background-color: #fff;
  border: 2px #00a0e9 solid;
  border-radius: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #top .btn_box a {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .btn_box a {
    height: 30px;
    border-radius: 5px;
    margin: 0 1%;
  }
}
#parachannel #top .btn_box a img {
  width: 15px;
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .btn_box a img {
    width: 10px;
  }
}
#parachannel #top .mv {
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv {
    margin-bottom: 30px;
  }
}
#parachannel #top .mv .list_box {
  height: 80px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .list_box {
    height: 65px;
    padding: 0 3%;
    flex-wrap: wrap;
  }
}
#parachannel #top .mv .list_box p {
  font-size: 17px;
  font-weight: bold;
  margin-right: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .list_box p {
    font-size: 15px;
    margin: 0 0 10px;
  }
}
#parachannel #top .mv .list_box > div {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .list_box > div {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  #parachannel #top .mv .list_box > div::-webkit-scrollbar {
    display: none;
  }
}
#parachannel #top .mv .list_box > div ul {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .list_box > div ul {
    width: 620px;
  }
}
#parachannel #top .mv .list_box > div ul li {
  margin-right: 10px;
}
#parachannel #top .mv .list_box > div ul li:last-child {
  margin: 0;
}
#parachannel #top .mv .list_box > div ul li a {
  width: 60px;
  padding: 5px 0;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #top .mv .list_box > div ul li a {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .list_box > div ul li a {
    padding: 4px 0;
  }
}
#parachannel #top .mv .list_box > div ul li a:hover {
  background-color: #00a0e9;
  color: #fff;
  opacity: 1;
}
#parachannel #top .mv .cont_box {
  padding: 0 !important;
  background: none !important;
  position: relative;
  z-index: 9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box {
    background: none;
  }
}
/* #parachannel #top .mv .cont_box:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url(./img/top/mv_img.png) no-repeat;
  background-size: auto 100%;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
} */
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box:after {
    background-size: 100%;
    background-position: top;
  }
}
#parachannel #top .mv .cont_box .wrapper form {
  width: 420px;
  padding-bottom: 35px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  overflow: hidden;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form {
    width: 100%;
    padding: 0;
  }
}
#parachannel #top .mv .cont_box .wrapper form h2 {
  padding: 19px 0;
  background: url(./img/top/mv_bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #00699F;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form h2 {
    padding: 13px 0;
    font-size: 14px;
    margin-bottom: 15px;
  }
}
#parachannel #top .mv .cont_box .wrapper form dl {
  width: 320px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form dl {
    width: 80%;
    margin-bottom: 15px;
  }
}
#parachannel #top .mv .cont_box .wrapper form dl dt, #parachannel #top .mv .cont_box .wrapper form dl dd {
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form dl dt, #parachannel #top .mv .cont_box .wrapper form dl dd {
    margin-bottom: 10px;
  }
}
#parachannel #top .mv .cont_box .wrapper form dl dt:last-of-type, #parachannel #top .mv .cont_box .wrapper form dl dd:last-of-type {
  margin: 0;
}
#parachannel #top .mv .cont_box .wrapper form dl dt {
  width: 120px;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #top .mv .cont_box .wrapper form dl dt {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form dl dt {
    width: 90px;
    font-size: 13px;
  }
}
#parachannel #top .mv .cont_box .wrapper form dl dt img {
  width: 20px;
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form dl dt img {
    width: 15px;
    margin-right: 5px;
  }
}
#parachannel #top .mv .cont_box .wrapper form dl dd {
  width: calc(100% - 120px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form dl dd {
    width: calc(100% - 90px);
  }
}
#parachannel #top .mv .cont_box .wrapper form dl dd select {
  height: 30px;
  background: url(./img/top/arrow04.png) no-repeat;
  background-size: 10px;
  background-position: right 15px center;
  border-radius: 5px;
  cursor: pointer;
}
#parachannel #top .mv .cont_box .wrapper form button {
  width: 320px;
  background-color: #39b54a;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .mv .cont_box .wrapper form button {
    width: 80%;
    padding: 13px 0;
  }
}
#parachannel #top .article {
  margin-bottom: 85px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article {
    margin-bottom: 50px;
  }
}
#parachannel #top .article > div {
  margin-bottom: 80px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article > div {
    margin-bottom: 60px;
    margin-top: 60px;
  }
}
#parachannel #top .article > div .slider {
  width: 100%;
  margin-top: 25px;
}
#parachannel #top .article > div .slider li {
  padding: 0 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article > div .slider li {
    padding: 0 15px;
    list-style: none;
  }
}
#parachannel #top .article > div .slider li a {
  position: relative;
}
#parachannel #top .article > div .slider li a h3 {
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #00699F;
  margin: 10px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article > div .slider li a h3 {
    font-size: 18px;
    line-height: 20px;
  }
}
#parachannel #top .article > div .slider li a p {
  line-height: 18px;
  font-size: 16px;
  color: #595757;
  margin-bottom: 4px;
}
#parachannel #top .article > div .slider li a div{
  margin-top: 4px;
}
#parachannel #top .article > div .slider li a p:last-child {
  margin: 0;
}
#parachannel #top .article > div .slider li a p.category {
  padding: 0 10px;
  border: 1px #00699F solid;
  border-radius: 20px;
  color: #00699F;
  display: inline-block;
  margin: 5px 0;
}
#parachannel #top .article > div .slider li a p.new_graduate {
  padding: 1px 10px;
  background-color: #00699F;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin: 5px 0 5px 5px;
}
#parachannel #top .article > div .slider li a p.done {
  padding: 6px 0;
  background-color: #e6e6e6;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  color: #231815;
  margin-top: 10px;
}
#parachannel #top .article > div .slider li a .heart {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
#parachannel #top .article > div .slider li a ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
#parachannel #top .article > div .slider li a ul li {
  width: auto;
  padding: 0 5px;
  border: 1px #b3b3b3 solid;
  border-radius: 5px;
  margin: 3px;
}
#parachannel #top .article > div .slider li.new a:after {
  content: "NEW";
  width: 45px;
  padding: 5px 0;
  background-color: #231815;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}
#parachannel #top .article > div .slider .slick-arrow {
  width: 60px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center;
  position: absolute;
  top: 0;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article > div .slider .slick-arrow {
    width: calc(3vw + 15px);
    background-size: 10px;
  }
}
#parachannel #top .article > div .slider .slick-arrow.prev {
  background-image: url(./img/top/arrow01.png);
  left: -40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article > div .slider .slick-arrow.prev {
    left: -3vw;
  }
}
#parachannel #top .article > div .slider .slick-arrow.next {
  background-image: url(./img/top/arrow02.png);
  right: -40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article > div .slider .slick-arrow.next {
    right: -3vw;
  }
}
#parachannel #top .article > div > a {
  width: 120px;
  padding: 12px 0;
  background-color: #00699F;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .article > div > a {
    width: 200px;
    margin: 25px auto 0;
    position: static;
  }
}
#parachannel #top .manual {
  padding: 45px 30px 70px;
  border: 2px #231815 solid;
  border-radius: 15px;
  margin-bottom: 60px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .manual {
    padding: 50px 20px;
    margin-bottom: 50px;
  }
}
#parachannel #top .manual .ttl.type2 {
  position: absolute;
  top: -25px;
  left: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .manual .ttl.type2 {
    top: -20px;
  }
}
#parachannel #top .manual ul {
  display: flex;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .manual ul {
    display: block;
  }
}
#parachannel #top .manual ul li {
  width: 30%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .manual ul li {
    width: 100%;
    margin-bottom: 25px;
  }
  #parachannel #top .manual ul li:last-child {
    margin: 0;
  }
}
#parachannel #top .manual ul li .img_box {
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .manual ul li .img_box {
    margin-bottom: 15px;
  }
}
#parachannel #top .manual ul li .img_box iframe,
#parachannel #top .manual ul li .img_box img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#parachannel #top .manual ul li h3 {
  text-align: center;
}
#parachannel #top .manual ul li h3 span {
  margin: 0 5px;
  position: relative;
  z-index: 9;
}
#parachannel #top .manual ul li h3 span:after {
  content: "";
  width: 40px;
  height: 40px;
  background-color: #00a0e9;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .manual ul li h3 span:after {
    width: 30px;
    height: 30px;
  }
}
#parachannel #top .manual .btn.type2 {
  position: absolute;
  right: 0;
  bottom: -25px;
  left: 0;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #top .manual .btn.type2 {
    width: 440px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .manual .btn.type2 {
    bottom: -20px;
  }
}
#parachannel #top .column {
  padding: 25px 0;
  background: url(./img/top/column_bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 55px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column {
    margin-bottom: 30px;
  }
}
#parachannel #top .column h3 {
  margin: 45px 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column h3 {
    margin: 25px 0 10px;
  }
}
#parachannel #top .column .wrapper > ul {
  display: flex;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column .wrapper > ul {
    flex-wrap: wrap;
  }
}
#parachannel #top .column .wrapper > ul > li {
  width: 22.5%;
  background-color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column .wrapper > ul > li {
    width: 48.5%;
    margin-top: 10px;
  }
  #parachannel #top .column .wrapper > ul > li:nth-child(-n+2) {
    margin-top: 0;
  }
}
#parachannel #top .column .wrapper > ul > li .img_box img {
  -o-object-fit: cover;
     object-fit: cover;
}
#parachannel #top .column .wrapper > ul > li .text_box {
  padding: 10px 10px 5px;
  display: flex;
  flex-wrap: wrap;
}
#parachannel #top .column .wrapper > ul > li .text_box h4 {
  width: 100%;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 10px;
}
#parachannel #top .column .wrapper > ul > li .text_box p {
  width: 100%;
  margin-bottom: 10px;
}
#parachannel #top .column .wrapper > ul > li .text_box ul {
  display: flex;
}
#parachannel #top .column .wrapper > ul > li .text_box ul li {
  padding: 2px;
  border: 1px #595757 solid;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  margin: 2px;
}
#parachannel #top .column .btn.type2 {
  margin-top: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column .btn.type2 {
    margin-top: 25px;
  }
}
#parachannel #top .resume > a:not(.btn.type1) {
  width: 770px;
  padding-left: 115px;
  margin: 15px auto 55px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .resume > a:not(.btn.type1) {
    width: 100%;
    padding-left: 10%;
    margin: 15px 0 25px;
  }
}

#parachannel #login {
  padding: 35px 0 85px;
  /*横向きの際に適用*/
  /* password */
  /* company */
  /* manager */
}
@media print, screen and (max-width: 768px) {
  #parachannel #login {
    padding: 20px 0 0;
  }
}
#parachannel #login .wrapper > a:not(.btn) {
  width: 400px;
  height: 60px;
  margin: 50px auto 0;
  line-height: 56px;
  font-weight: bold;
  text-align: center;
  color: #000;
  font-size: 130%;
  border: solid 2px #00a0e9;
  border-radius: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .wrapper > a:not(.btn) {
    width: 90%;
    margin-top: 30px;
  }
}
#parachannel #login h3 {
  text-align: center;
  color: #00a0e9;
  margin: 80px 0 35px;
  line-height: 1.5;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login h3 {
    margin: 30px 0 20px;
  }
}
#parachannel #login .input_box form > p {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #f00;
  margin: -40px 0 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .input_box form > p {
    font-size: 12px;
    line-height: 18px;
    margin: -10px 0 20px;
  }
}
#parachannel #login .password .input_box {
  border-color: #fff;
}
#parachannel #login .company .input_box {
  border-color: #fff;
}
@media print, screen and (min-width: 769px) {
  #parachannel #login .company .input_box {
    padding-top: 0;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #login .company .input_box form {
    width: 435px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #login .company .input_box form dl {
    margin-bottom: 50px;
  }
}
#parachannel #login .company .input_box form dl dt {
  width: 70px;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .company .input_box form dl dt {
    width: 50px;
  }
}
#parachannel #login .company .input_box form dl dd {
  width: calc(100% - 70px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .company .input_box form dl dd {
    width: calc(100% - 50px);
  }
}
#parachannel #login .company .input_box form dl dd input {
  border-color: #00a0e9;
  border-radius: 5px;
}
#parachannel #login .company > p {
  font-weight: bold;
  text-align: center;
  color: #c1272d;
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #login .company > p {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .company > p {
    line-height: 18px;
    margin-bottom: 30px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #login .company .ttl {
    margin-top: 50px;
  }
}
#parachannel #login .company > a {
  width: 260px;
  padding: 16px 0;
  border: 1px #b3b3b3 solid;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  color: #000;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #login .company > a {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .company > a {
    width: 150px;
    padding: 13px 0;
  }
}
#parachannel #login .input_box .manager dl dd:nth-of-type(-n+2) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .input_box .manager dl dd:nth-of-type(-n+2) {
    display: block;
  }
}
#parachannel #login .input_box .manager dl dd:nth-of-type(-n+2) label {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .input_box .manager dl dd:nth-of-type(-n+2) label {
    width: 100%;
    margin-bottom: 5px;
  }
  #parachannel #login .input_box .manager dl dd:nth-of-type(-n+2) label:last-child {
    margin: 0;
  }
}
#parachannel #login .input_box button[type=submit] + a {
  display: block;
  margin-top: 30px;
  font-size: 120%;
  color: #333;
  text-decoration: underline;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #login .input_box button[type=submit] + a {
    margin-top: 20px;
  }
}

.input_box {
  width: 70%;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  .input_box {
    width: 100%;
  }
}
.input_box .other_login {
  width: 100%;
  margin: 50px auto 0;
  padding-top: 50px;
  border-top: solid 1px #ccc;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  .input_box .other_login {
    margin-top: 30px;
    padding-top: 30px;
  }
}

#parachannel #register {
  font-weight: bold;
  padding: 35px 0 85px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register {
    padding: 20px 0 0;
  }
}
#parachannel #register .ttl_box {
  margin-bottom: 50px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register .ttl_box {
    margin-bottom: 30px;
  }
}
#parachannel #register .ttl_box h2 {
  text-align: center;
}
@media print, screen and (min-width: 769px) {
  #parachannel #register .ttl_box h2 {
    font-size: 24px;
  }
}
#parachannel #register .ttl_box p {
  width: 125px;
  padding: 15px 0;
  text-align: center;
  position: absolute;
  top: -15px;
  right: 0;
  left: -300px;
  z-index: 9;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register .ttl_box p {
    width: 110px;
    padding: 14px 0;
    font-size: 10px;
    left: -220px;
  }
}
#parachannel #register .ttl_box p:after {
  content: "";
  width: 100%;
  height: 100%;
  background: url(./img/header_bg.png) no-repeat;
  background-size: 100% 100%;
  transform: scale(-1, 1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}
#parachannel #register .ttl_box p span {
  font-size: 14px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register .ttl_box p span {
    font-size: 12px;
  }
}
#parachannel #register .tab_box {
  width: 780px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register .tab_box {
    width: 100%;
  }
}
#parachannel #register .tab_box li {
  width: 32%;
  padding: 27px 15px 27px 0;
  background: url(./img/text_bg.png) no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
  text-align: center;
  color: #00a0e9;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register .tab_box li {
    padding: 15px 5px 15px 0;
    font-size: 10px;
  }
}
#parachannel #register .tab_box li:last-child {
  border: none;
}
#parachannel #register .tab_box li span {
  position: absolute;
  top: -20px;
  left: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register .tab_box li span {
    top: -15px;
  }
}
#parachannel #register .tab_box li.active {
  background-image: url(./img/text_bg_on.png);
  color: #fff;
}
#parachannel #register .tab_box li.active span {
  color: #00a0e9;
}
#parachannel #register .wrapper > p {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-top: 35px;
  margin-top: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #register .wrapper > p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 50px;
    text-align: justify;
  }
}
#parachannel #register .wrapper > p small {
  display: block;
  margin-top: 20px;
  font-size: 90%;
  line-height: 1.5;
  color: #888;
}

#parachannel #about {
  margin: 0;
}
#parachannel #about section {
  padding-bottom: 140px;
  background-color: #d7f5ff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section {
    padding-bottom: 50px;
  }
}
#parachannel #about section .mv {
  padding: 10px 0 25px;
  background-color: #fff;
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section .mv {
    padding: 20px 0;
    margin-bottom: 30px;
  }
}
#parachannel #about section > .wrapper > div {
  margin-bottom: 60px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper > div {
    margin-bottom: 30px;
  }
}
#parachannel #about section > .wrapper > div:last-child {
  margin: 0;
}
#parachannel #about section > .wrapper h3, #parachannel #about section > .wrapper h4, #parachannel #about section > .wrapper h5 {
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
}
#parachannel #about section > .wrapper h3 {
  font-size: 28px;
  margin-bottom: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
#parachannel #about section > .wrapper h4 {
  padding-bottom: 45px;
  border-bottom: 1px #00a0e9 solid;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper h4 {
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}
#parachannel #about section > .wrapper h5 {
  font-size: 20px;
  text-align: left;
  margin: 55px 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper h5 {
    font-size: 14px;
    margin: 30px 0 10px;
  }
}
#parachannel #about section > .wrapper h5:nth-of-type(1) {
  margin-top: 0;
}
#parachannel #about section > .wrapper .white_box {
  padding: 45px 80px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .white_box {
    padding: 20px;
    border-radius: 15px;
  }
}
#parachannel #about section > .wrapper .white_box p {
  font-size: 20px;
  line-height: 42px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .white_box p {
    font-size: 14px;
    line-height: 28px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box {
  padding: 60px 25px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box {
    padding: 30px 10px 0;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box h3 {
  width: 100%;
  font-size: 38px;
  margin-bottom: 60px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box > p {
  width: 100%;
  padding: 0 25px;
  margin-bottom: 60px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #about section > .wrapper .cont1 .white_box > p {
    line-height: 36px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box > p {
    padding: 0 10px;
    margin-bottom: 20px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box {
  width: 49%;
  padding: 30px;
  border: 8px #d7f5ff solid;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box {
    padding: 10px;
    border-width: 4px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box h4 {
  padding: 0;
  border: none;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box h4 {
    margin-bottom: 10px;
    overflow: hidden;
  }
  #parachannel #about section > .wrapper .cont1 .white_box .item_box h4 img {
    width: 130%;
    max-width: none;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box h5 {
  padding: 10px 0;
  border-top: 1px #00a0e9 solid;
  border-bottom: 1px #00a0e9 solid;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box h5 {
    margin-bottom: 10px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box h5 img {
  width: 25px;
  margin-right: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box h5 img {
    width: 20px;
    margin-right: 5px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box p {
  font-size: 18px;
  line-height: 38px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box p {
    font-size: 12px;
    line-height: 24px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box > img {
  width: 75px;
  position: absolute;
  right: 90px;
  bottom: -80px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box > img {
    width: 30px;
    right: 30px;
    bottom: -30px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box:nth-of-type(2) {
  border-color: rgba(5, 106, 135, 0.1);
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box:nth-of-type(2) h5 {
  border-color: #006496;
  color: #006496;
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box:nth-of-type(2) h5 img {
  margin: 0 0 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box:nth-of-type(2) h5 img {
    margin-left: 5px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box .item_box:nth-of-type(2) > img {
  right: auto;
  left: 90px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box .item_box:nth-of-type(2) > img {
    left: 30px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box > div:not(.item_box) {
  width: 100%;
  margin: 100px 0 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box > div:not(.item_box) {
    margin: 50px 0 20px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box > div:not(.item_box) h4 {
  width: 440px;
  padding: 0;
  border: none;
  margin: 0 auto 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box > div:not(.item_box) h4 {
    width: 200px;
    margin-bottom: 20px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box > div:not(.item_box) p {
  text-align: center;
}
@media print, screen and (min-width: 769px) {
  #parachannel #about section > .wrapper .cont1 .white_box > div:not(.item_box) p {
    font-size: 18px;
    line-height: 38px;
  }
}
#parachannel #about section > .wrapper .cont1 .white_box > div:not(.item_box) p span {
  font-weight: bold;
  color: #00a0e9;
}
#parachannel #about section > .wrapper .cont1 .white_box > img {
  width: calc(100% + 50px);
  max-width: none;
  margin: 0 -25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont1 .white_box > img {
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #about section > .wrapper .cont4 {
    padding: 50px 0 55px;
  }
}
#parachannel #about section > .wrapper .cont4 h3 {
  font-size: 36px;
  color: #006496;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #about section > .wrapper .cont4 h3 {
    margin-bottom: 30px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont4 h3 {
    font-size: 22px;
  }
}
#parachannel #about section > .wrapper .cont4 p {
  text-align: center;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #about section > .wrapper .cont4 p {
    margin-bottom: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #about section > .wrapper .cont4 .btn {
    width: 560px;
    padding: 22px 0;
    border-radius: 10px;
    font-size: 26px;
  }
}

#parachannel #guide {
  padding: 35px 0 25px;
  font-weight: bold;
  /*横向きの際に適用*/
  /* detail */
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide {
    padding: 20px 0 0;
  }
}
#parachannel #guide .tab_btn {
  width: 100%;
  max-width: 700px;
  border: 1px #00a0e9 solid;
  display: flex;
  margin: 0 auto 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .tab_btn {
    margin-bottom: 10px;
  }
}
#parachannel #guide .tab_btn li {
  width: 33.33%;
  padding: 21px 0;
  border-right: 1px #00a0e9 solid;
  text-align: center;
  color: #b3b3b3;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #guide .tab_btn li {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .tab_btn li {
    padding: 13px 0;
  }
}
#parachannel #guide .tab_btn li.active {
  background-color: #00a0e9;
  color: #fff;
}
#parachannel #guide .tab_btn li:last-child {
  border: none;
}
#parachannel #guide .tab_box > li {
  display: none;
}
#parachannel #guide .tab_box > li.active {
  display: block;
}
#parachannel #guide .tab_box > li ul li {
  border-bottom: 1px #b3b3b3 solid;
}
#parachannel #guide .tab_box > li ul li a {
  padding: 27px 45px 27px 5px;
  background: url(./img/guide/arrow.png) no-repeat;
  background-size: 10px;
  background-position: right 30px center;
  line-height: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #guide .tab_box > li ul li a {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .tab_box > li ul li a {
    padding: 14px 25px 14px 5px;
    background-size: 8px;
    background-position: right 10px center;
    line-height: 18px;
  }
}
#parachannel #guide .detail h3 {
  padding-bottom: 35px;
  border-bottom: 1px #b3b3b3 solid;
  line-height: 30px;
  text-align: center;
  margin-bottom: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .detail h3 {
    padding-bottom: 15px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
#parachannel #guide .detail p {
  line-height: 26px;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #guide .detail p {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .detail p {
    line-height: 20px;
    margin-bottom: 20px;
  }
}
#parachannel #guide .detail img {
  width: auto;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .detail img {
    margin-bottom: 20px;
  }
}
#parachannel #guide .detail .btn {
  background-color: #606e84;
  margin-top: 95px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .detail .btn {
    margin-top: 50px;
  }
}
#parachannel #guide .detail .bg {
  padding: 20px;
  border: 2px #00a0e9 solid;
  background: repeating-linear-gradient(45deg, #ecfbff, #ecfbff 3px, #fff 0, #fff 10px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .detail .bg {
    padding: 10px;
  }
}
#parachannel #guide .bg {
  padding: 20px;
  border: 2px #00a0e9 solid;
  background: repeating-linear-gradient(45deg, #ecfbff, #ecfbff 3px, #fff 0, #fff 10px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #guide .bg {
    padding: 10px;
  }
}

#parachannel #jobs {
  padding-top: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs {
    padding-top: 20px;
  }
}
#parachannel #jobs > section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section {
    display: block;
  }
}
#parachannel #jobs > section .sidebar {
  width: 35%;
  font-size: 14px;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .sidebar {
    width: 100%;
  }
}
#parachannel #jobs > section .sidebar > .btn {
  width: 100%;
  padding: 14px 0;
  background: none;
  border: 1px #006496 solid;
  color: #006496;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .sidebar > .btn {
    margin-bottom: 30px;
  }
}
#parachannel #jobs > section .sidebar > .btn img {
  width: 20px;
  margin-right: 35px;
}
#parachannel #jobs > section .sidebar > div {
  margin-bottom: 30px;
}
#parachannel #jobs > section .sidebar > div .ttl_box {
  padding-bottom: 15px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 15px;
}
#parachannel #jobs > section .sidebar > div .ttl_box h3 {
  font-size: 16px;
}
#parachannel #jobs > section .sidebar > div .ttl_box a {
  color: #b3b3b3;
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #jobs > section .sidebar > div .ttl_box a img {
  width: 6px;
  margin-left: 15px;
}
#parachannel #jobs > section .sidebar > div p, #parachannel #jobs > section .sidebar > div ul {
  line-height: 20px;
  color: #00a0e9;
}
#parachannel #jobs > section .sidebar > div p.largecategory:before, #parachannel #jobs > section .sidebar > div p.smallcategory:before, #parachannel #jobs > section .sidebar > div ul.largecategory:before, #parachannel #jobs > section .sidebar > div ul.smallcategory:before {
  content: "・";
  display: inline-block;
}
#parachannel #jobs > section .sidebar > div p.smallcategory, #parachannel #jobs > section .sidebar > div ul.smallcategory {
  color: #006496;
  margin-left: 1em;
}
#parachannel #jobs > section .sidebar > div ul {
  list-style-type: disc;
}
#parachannel #jobs > section .sidebar > div ul li {
  margin: 0 0 5px 1.5em;
}
#parachannel #jobs > section .sidebar > div ul li:last-child {
  margin-bottom: 0;
}
#parachannel #jobs > section .sidebar > div > form input {
  height: 35px;
}
#parachannel #jobs > section .sidebar > div.category .ttl_box {
  padding: 0;
  border: none;
}
#parachannel #jobs > section .sidebar > div.category ul {
  list-style-type: none;
  display: flex;
}
#parachannel #jobs > section .sidebar > div.category ul li {
  margin: 0 10px 0 0;
}
#parachannel #jobs > section .sidebar > div.category ul li:last-child {
  margin: 0;
}
#parachannel #jobs > section .sidebar > div.category ul li .btn {
  width: auto;
  min-width: 120px;
  padding: 10px;
  background-color: #b3b3b3;
  border-radius: 10px;
  margin: 0;
}

#parachannel #jobs > section .sidebar > div.category ul li .workBtn {
  font-size: 12px !important;
}


@media print, screen and (max-width: 1200px) {
  #parachannel #jobs > section .sidebar > div.category ul li .workBtn {
    font-size: 10px !important;
    min-width: 100px !important;
  }
}

#parachannel #jobs > section .sidebar > div.category ul li .btn.active {
  background-color: #00a0e9;
}
#parachannel #jobs > section .sidebar > div.btn_box {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 40px;
}
#parachannel #jobs > section .sidebar > div.btn_box .btn {
  width: 49%;
  margin: 0;
}
#parachannel #jobs > section .sidebar > div.btn_box .btn.gray {
  padding: 21px 0;
  font-size: 14px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .sidebar > div.btn_box .btn.gray {
    padding: 19px 0;
    font-size: 12px;
  }
}
#parachannel #jobs > section .sidebar > p {
  font-weight: normal;
  line-height: 20px;
  margin: -10px 0 30px;
}
#parachannel #jobs > section .sidebar > a {
  color: #00a0e9;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
}
#parachannel #jobs > section .sidebar > a:last-of-type {
  margin: 0;
}
#parachannel #jobs > section .sidebar > a img {
  width: 6px;
  margin-left: 15px;
}
#parachannel #jobs > section .cont_box {
  width: 62%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box {
    width: 100%;
    margin-bottom: 30px;
  }
}
#parachannel #jobs > section .cont_box .pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}
#parachannel #jobs > section .cont_box .pagination li {
  margin: 0 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box .pagination li {
    margin: 3px 5px;
  }
}
#parachannel #jobs > section .cont_box .pagination li a {
  font-size: 14px;
  color: #00a0e9;
}
#parachannel #jobs > section .cont_box .pagination li a span {
  width: 30px;
  padding: 8px 0;
  background-color: #c8c8c8;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  display: block;
}
#parachannel #jobs > section .cont_box .pagination li.prev {
  margin: 0 10px 0 0;
}
#parachannel #jobs > section .cont_box .pagination li.next {
  margin: 0 0 0 10px;
}
#parachannel #jobs > section .cont_box .pagination li.active span {
  background-color: #00a0e9;
}
#parachannel #jobs > section .cont_box .pagination.small {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs > section .cont_box .pagination.small {
    justify-content: flex-end;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box .pagination.small {
    margin-top: 20px;
  }
}
#parachannel #jobs > section .cont_box .pagination.small li a {
  font-size: 12px;
}
#parachannel #jobs > section .cont_box .pagination.small li a span {
  width: 26px;
  padding: 7px 0;
}
#parachannel #jobs > section .cont_box .tab_btn {
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  justify-content: center;
  margin: 40px 0 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box .tab_btn {
    padding: 0;
    margin: 30px 0 0;
  }
}
#parachannel #jobs > section .cont_box .tab_btn li {
  width: 25%;
  background-color: #f8f8f8;
  border-left: solid 1px #ddd;
  /*横向きの際に適用*/
}
#parachannel #jobs > section .cont_box .tab_btn li:first-child {
  border-left: none;
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box .tab_btn li {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}
#parachannel #jobs > section .cont_box .tab_btn li a {
  display: block;
  padding: 15px 0;
  font-weight: bold;
  text-align: center;
  color: #b3b3b3;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs > section .cont_box .tab_btn li a {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box .tab_btn li a {
    line-height: 16px;
  }
}
#parachannel #jobs > section .cont_box .tab_btn li.active {
  background-color: #00a0e9;
}
#parachannel #jobs > section .cont_box .tab_btn li.active a {
  color: #fff;
}
#parachannel #jobs > section .cont_box .tab_box {
  padding-bottom: 40px;
  border-bottom: 1px #b3b3b3 solid;
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box .tab_box {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
#parachannel #jobs > section .cont_box .tab_box > li {
  display: none;
}
#parachannel #jobs > section .cont_box .tab_box > li.active {
  display: block;
}
#parachannel #jobs > section .cont_box .tab_box > li .jobs_list li {
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs > section .cont_box .tab_box > li .jobs_list li:last-child {
    margin: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs > section .cont_box .tab_box > li .jobs_list li {
    margin: 0 auto;
    padding: 20px 0;
    border-top: solid 1px #efefef;
  }
}
#parachannel #jobs p.error_text {
  font-size: 12px;
  color: #f00;
  margin-top: 10px;
}
#parachannel #jobs .ttl .error_text {
  text-align: right;
  font-family: initial;
}
#parachannel #jobs .detail .sidebar .tab_box div.error_box {
  display: block;
  text-align: right;
}
#parachannel #jobs .detail .sidebar .tab_box div.error_box p.error_text {
  color: #f00;
}
#parachannel #jobs .detail_other .add_item_box .preview_display p.error_text {
  width: 100%;
  text-align: right;
  margin: 10px 0 0;
  padding: 0;
}
#parachannel #jobs .detail .cont_box p.error_text {
  text-align: right;
}
#parachannel #jobs button:disabled {
  background-color: lightgray !important;
}

#parachannel .entry {
  font-weight: bold;
  display: block;
}
#parachannel .entry > p {
  width: 100%;
  line-height: 30px;
  text-align: center;
  margin-bottom: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .entry > p {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry > p {
    line-height: 24px;
    margin-bottom: 20px;
  }
}
#parachannel .entry > p span {
  color: #b3b3b3;
}
#parachannel .entry .note {
  margin-top: 5px;
}
#parachannel .entry .entry_cont .ttl_box {
  padding-bottom: 20px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 45px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .entry_cont .ttl_box {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}
#parachannel .entry .entry_cont .ttl_box h3 {
  line-height: 35px;
  color: #606e84;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .entry_cont .ttl_box h3 {
    width: 100%;
    line-height: 25px;
  }
}
#parachannel .entry .entry_cont .ttl_box a {
  width: 260px;
  padding: 9px 0;
  border: 1px #00a0e9 solid;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .entry_cont .ttl_box a {
    width: 160px;
    padding: 4px 0;
    font-size: 10px;
  }
}
#parachannel .entry .entry_cont .ttl_box a span {
  width: 14px;
  padding: 2px 0;
  background-color: #00a0e9;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
}
#parachannel .entry .entry_cont dl {
  padding: 0 45px;
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .entry .entry_cont dl dt, #parachannel .entry .entry_cont dl dd {
    margin-top: 20px;
    padding-top: 20px;
  }
  #parachannel .entry .entry_cont dl dt:first-of-type, #parachannel .entry .entry_cont dl dd:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .entry_cont dl {
    padding: 0 30px 0 0;
    line-height: 24px;
    display: block;
  }
}
#parachannel .entry .entry_cont dt, #parachannel .entry .entry_cont dd {
  border-top: dotted 1px #ccc;
}
#parachannel .entry .entry_cont dt:first-of-type, #parachannel .entry .entry_cont dd:first-of-type {
  border: none;
}
#parachannel .entry .entry_cont dt {
  position: relative;
  width: 290px;
  font-size: 18px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .entry_cont dt {
    width: 100%;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
#parachannel .entry .entry_cont dt a {
  display: block;
  width: 150px;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  font-size: 80%;
  color: #00a0e9;
  text-align: center;
  background-color: #fff100;
  border-radius: 20px;
}
#parachannel .entry .entry_cont dt a span {
  display: inline-block;
  width: 100%;
  height: 100%;
}
#parachannel .entry .entry_cont dt:nth-child(n+2) a {
  top: 20px;
}
#parachannel .entry .entry_cont dd {
  width: calc(100% - 290px);
  font-size: 16px;
  position: relative;
  /*横向きの際に適用*/
  /*span {
    font-size: 14px;
    @include mq(sp) {
      font-size: 10px;
    }
  }*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .entry_cont dd {
    width: 100%;
    font-size: 12px;
    margin-bottom: 25px;
  }
  #parachannel .entry .entry_cont dd:last-child {
    margin: 0;
  }
}
#parachannel .entry .entry_cont dd .edit_box {
  margin: 0 0 10px;
  padding: 10px;
  background-color: #ecfbff;
  border: none;
}
#parachannel .entry .entry_cont dd .nest_child {
  margin-top: 10px;
}
#parachannel .entry .entry_cont dd .nest_child:first-child {
  margin-top: 0;
}
#parachannel .entry .cont1:not(.btn_box):after {
  content: "";
  width: 100%;
  height: 100%;
  border: 10px rgba(255, 215, 200, 0.5) solid;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1:not(.btn_box):after {
    border-width: 5px;
  }
}
#parachannel .entry .cont1 {
  padding: 35px 80px 90px;
  margin-bottom: 75px;
  position: relative;
  z-index: 9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1 {
    padding: 20px;
    margin-bottom: 30px;
  }
}
#parachannel .entry .cont1 > div {
  margin-top: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1 > div {
    margin-top: 50px;
  }
}
#parachannel .entry .cont1 > div:nth-of-type(1) {
  margin: 0;
}
#parachannel .entry .cont1 > div .not_set .red {
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #ed1c24;
  margin-bottom: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1 > div .not_set .red {
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
#parachannel .entry .cont1 > div .not_set .btn {
  width: 150px;
  padding: 10px 0;
  border-radius: 30px;
}
#parachannel .entry .cont1 > div dl dd.not_set {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1 > div dl dd.not_set {
    display: block;
  }
}
#parachannel .entry .cont1 > div dl dd.not_set div,
#parachannel .entry .cont1 > div dl dd.not_set p {
  width: calc(100% - 150px);
  color: #ccc;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1 > div dl dd.not_set div,
  #parachannel .entry .cont1 > div dl dd.not_set p {
    width: 100%;
  }
}
#parachannel .entry .cont1 > div dl dd.not_set .btn {
  margin: 0;
  position: static;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1 > div dl dd.not_set .btn {
    margin-top: 10px;
  }
}
#parachannel .entry .cont1 dd .textarea_cont {
  margin-top: 20px;
}
#parachannel .entry .cont1 dd .upload_cont.btn.type3 {
  width: 100%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont1 dd .upload_cont.btn.type3 {
    width: 250px;
    padding: 0;
    margin: 15px 0 0;
  }
  #parachannel .entry .cont1 dd .upload_cont.btn.type3 label {
    padding: 16px 0;
  }
}
#parachannel .entry .cont2 dl {
  margin-bottom: 80px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont2 dl {
    padding: 0;
    margin-bottom: 50px;
  }
}
#parachannel .entry .cont2 dl .radio_cont .radio_list {
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  padding: 4px 10px;
}
#parachannel .entry .cont2 dl .radio_cont .radio_list label {
  display: flex;
  cursor: pointer;
  margin-right: 10px;
}
#parachannel .entry .cont2 dl .radio_cont .radio_list label input {
  overflow: hidden;
  border-radius: 2px;
  width: 18px;
  height: 18px;
}
#parachannel .entry .cont2 dl .radio_cont .radio_list label span {
  color: unset;
  margin-top: 1px;
}
#parachannel .entry .cont2 dl dd span {
  color: #8d8d8d;
}
#parachannel .entry .cont2 dl dd span.any {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  padding: 5px 10px 4px;
  background-color: #8d8d8d;
  color: #fff;
  font-size: 70%;
  line-height: 1;
  border-radius: 2px;
}
#parachannel .entry .cont2 dl dd span.ex {
  display: block;
  margin-top: 10px;
  line-height: 1.5;
  font-size: 90%;
}
#parachannel .entry .cont2 dl .preview span {
  color: unset;
  font-size: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont2 dl .preview span {
    font-size: 100%;
  }
}
#parachannel .entry .cont2 dl.type2 {
  padding: 0;
  border: 1px #adadad solid;
  border-radius: 5px;
  margin-bottom: 50px;
  overflow: hidden;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont2 dl.type2 {
    margin-bottom: 30px;
  }
}
#parachannel .entry .cont2 dl.type2:last-child {
  margin: 0;
}
#parachannel .entry .cont2 dl.type2 dt, #parachannel .entry .cont2 dl.type2 dd {
  padding: 20px;
  border-bottom: 1px #adadad solid;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont2 dl.type2 dt, #parachannel .entry .cont2 dl.type2 dd {
    width: 100% !important;
    padding: 13px 15px !important;
  }
}
#parachannel .entry .cont2 dl.type2 dt:last-of-type, #parachannel .entry .cont2 dl.type2 dd:last-of-type {
  border: none;
}
#parachannel .entry .cont2 dl.type2 dt {
  width: 270px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: rgba(127, 228, 255, 0.7);
  color: #658e99;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont2 dl.type2 dt {
    border: none;
  }
}
#parachannel .entry .cont2 dl.type2 dt small {
  font-size: 80%;
}
#parachannel .entry .cont2 dl.type2 dt span {
  padding: 4px 8px;
  background-color: #f00;
  border-radius: 20px;
  font-size: 12px;
  line-height: 100%;
  white-space: nowrap;
  color: #fff;
  margin-left: 10px;
}
#parachannel .entry .cont2 dl.type2 dt span.any {
  background-color: #9e9f9f;
}
#parachannel .entry .cont2 dl.type2 dd {
  width: calc(100% - 270px);
  padding-right: 100px;
  font-weight: normal;
}
#parachannel .entry .cont2 dl.type2 dd h4 {
  margin-bottom: 5px;
  font-size: 18px;
  color: #231815;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .cont2 dl.type2 dd h4 {
    font-size: 13px;
  }
}
#parachannel .entry .cont2 dl.type2 dd p {
  font-weight: normal;
}
#parachannel .entry .cont2 dl.type2 dd p.acc_ttl {
  font-weight: bold;
}
#parachannel .entry .cont2 dl.type2 dd p.acc_ttl + div {
  margin-top: 10px;
}
#parachannel .entry .cont2 dl.type2 dd select {
  width: 280px;
  background: #dbffff url(./img/company/icon11.png) no-repeat;
  background-size: 15px;
  background-position: right 15px center;
  border-radius: 5px;
}
#parachannel .entry .cont2 dl.type2 dd > div {
  padding-bottom: 15px;
  border-bottom: 1px #adadad solid;
  margin-bottom: 15px;
}
#parachannel .entry .cont2 dl.type2 dd > div:last-of-type {
  padding: 0;
  border: none;
  margin: 0;
}
#parachannel .entry .cont2 dl.type2 dd.error_box {
  background-color: rgba(255, 215, 200, 0.5);
  position: relative;
}
#parachannel .entry .cont2 dl.type2 dd.error_box .error_item {
  display: flex;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
}
#parachannel .entry .cont2 dl.type2 dd.error_box::after {
  content: "未入力です。";
  display: block;
  font-size: 90%;
  font-weight: bold;
  color: #f00;
}
#parachannel .entry .cont2 dl.type2 dd .select {
  width: 100%;
  background: #ecfbff url(./img/mypage/arrow01.png) no-repeat;
  background-size: 12px;
  background-position: right 15px center;
  border: 1px #b3b3b3 solid;
  border-radius: 5px;
  cursor: pointer;
}
#parachannel .entry .cont2 dl.type2 dd .select a {
  padding: 4px 15px;
}
#parachannel .entry .cont2 dl.type2 dd .select.comp {
  background-color: #fff;
}
#parachannel .entry .cont2 dl.type2 dd.photo .img_box {
  padding: 0;
  background: none;
  border: none;
}
#parachannel .entry .cont2 dl.type2 dd.photo .img_box img {
  position: static;
  transform: translateX(0);
}
#parachannel .entry .cont2 dl.type2 dd.photo .btn {
  position: relative;
  display: block;
  width: 160px;
  margin: 10px 0 0;
  padding: 15px 0;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
}
#parachannel .entry .cont2 dl.type2 dd.photo .btn input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 0;
  text-indent: -9999px;
  background: none;
}
#parachannel .entry .cont2 dl.type2 dd.zip label {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel .entry .cont2 dl.type2 dd.zip label input {
  width: 150px;
}
#parachannel .entry .cont2 dl.type2 dd.zip label .btn {
  width: 130px;
  padding: 10px 0;
  font-size: 12px;
  margin: 0 0 0 10px;
}
#parachannel .entry .cont2 dl.type2 dd.tel > div:not(.error_item) {
  padding: 0;
  border: none;
  margin: 0;
}
#parachannel .entry .btn_box {
  display: flex;
  justify-content: center;
  margin-top: 95px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .btn_box {
    display: block;
    margin-top: 50px;
  }
}
#parachannel .entry .btn_box .btn {
  margin: 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .btn_box .btn {
    margin: 0 auto 15px;
  }
  #parachannel .entry .btn_box .btn:last-child {
    margin-bottom: 0;
  }
}
#parachannel .entry .btn_box.cont1 .btn {
  background-color: #f2f2f2;
  color: #00a0e9;
}
#parachannel .entry .btn_box.cont1 .btn:hover {
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}
#parachannel .entry .btn_box.cont2 {
  margin-top: 115px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .btn_box.cont2 {
    margin-top: 50px;
  }
}
#parachannel .entry .btn_box.cont2 .btn {
  width: 215px;
  padding: 11px 0;
  background-color: #fff;
  border: 1px #ff9d3f solid;
  border-radius: 30px;
  font-size: 16px;
  color: #ff9d3f;
  position: relative;
}
#parachannel .entry .btn_box.cont2 .btn span {
  line-height: 38px;
  position: absolute;
  top: 0;
  right: 15px;
}
#parachannel .entry .btn_box.cont2 .btn.back {
  border-color: #00a0e9;
  color: #00a0e9;
}
#parachannel .entry .btn_box.cont2 .btn.submit {
  background-color: #ff9d3f;
  color: #fff;
}
#parachannel .entry .complete_cont > div {
  padding: 50px 150px;
  border: 1px #b3b3b3 solid;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .complete_cont > div {
    padding: 20px;
    margin-bottom: 30px;
  }
}
#parachannel .entry .complete_cont > div label {
  width: 33.33%;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 10px 0;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .entry .complete_cont > div label {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .complete_cont > div label {
    width: 50%;
  }
}
#parachannel .entry .complete_cont > div label input {
  width: 20px;
  height: 20px;
  margin: 0 20px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry .complete_cont > div label input {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}
#parachannel .entry.complete {
  display: block !important;
}
#parachannel .entry.complete p {
  font-size: 105%;
}
#parachannel .entry.complete h3 {
  margin-bottom: 60px;
  font-size: 150%;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry.complete h3 {
    margin: 40px 0 20px;
    font-size: 130%;
  }
}
#parachannel .entry.complete .btn {
  width: 400px;
  height: 60px;
  margin: 0 auto 20px;
  line-height: 60px;
  text-align: center;
  background-color: #006496;
  border-radius: 5px;
  font-size: 130%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .entry.complete .btn {
    width: 80%;
    height: 50px;
    margin: 0 auto 10px;
    line-height: 50px;
    font-size: 110%;
  }
}
#parachannel .entry.complete .btn a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}

/* detail */
#parachannel #jobs .detail .link_box {
  width: 100%;
  margin-top: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box {
    margin-top: 50px;
  }
}
#parachannel #jobs .detail .link_box .etc_box {
  margin-bottom: 30px;
}
#parachannel #jobs .detail .link_box .etc_box .ttl {
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .etc_box .ttl {
    line-height: 24px;
    margin-bottom: 10px;
  }
}
#parachannel #jobs .detail .link_box .etc_box ul {
  width: 100%;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#parachannel #jobs .detail .link_box .etc_box li {
  width: 16%;
  padding: 0;
  background-color: #f0f0f0;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
  margin: 10px 0.8% 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail .link_box .etc_box li:nth-child(-n+6) {
    margin-top: 0;
  }
  #parachannel #jobs .detail .link_box .etc_box li:nth-child(6n) {
    margin-right: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .etc_box li {
    width: 49%;
    margin-right: 2%;
  }
  #parachannel #jobs .detail .link_box .etc_box li:nth-child(-n+2) {
    margin-top: 0;
  }
  #parachannel #jobs .detail .link_box .etc_box li:nth-child(2n) {
    margin-right: 0;
  }
}
#parachannel #jobs .detail .link_box .etc_box li a {
  padding: 0;
  color: #231815;
}
#parachannel #jobs .detail .link_box .etc_box li a .img_box {
  width: 100%;
}
#parachannel #jobs .detail .link_box .etc_box li a .img_box img {
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .etc_box li a .img_box img {
    height: 30vw;
  }
}
#parachannel #jobs .detail .link_box .etc_box li a .text_box {
  padding: 10px;
}
#parachannel #jobs .detail .link_box .etc_box li a .text_box p {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}
#parachannel #jobs .detail .link_box .etc_box li a .text_box p:last-of-type {
  margin: 0;
}
#parachannel #jobs .detail .link_box .etc_box li a .text_box p.company {
  font-size: 12px;
  line-height: 16px;
}
#parachannel #jobs .detail .link_box.link_box_cont_box {
  margin: 0;
}
#parachannel #jobs .detail .link_box.link_box_cont_box .etc_box ul {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box.link_box_cont_box .etc_box ul {
    justify-content: space-between;
  }
}
#parachannel #jobs .detail .link_box.link_box_cont_box li {
  width: 30%;
  margin: 0 3% 3% 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box.link_box_cont_box li {
    width: 49%;
    margin: 0 0 2%;
  }
}
#parachannel #jobs .detail .link_box .category_box {
  margin-bottom: 85px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .category_box {
    margin-bottom: 50px;
  }
}
#parachannel #jobs .detail .link_box .category_box .ttl {
  font-size: 14px;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .category_box .ttl {
    line-height: 20px;
  }
}
#parachannel #jobs .detail .link_box .category_box ul {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #jobs .detail .link_box .category_box li {
  margin: 0 5px 5px 0;
}
#parachannel #jobs .detail .link_box .category_box li a {
  padding: 9px 10px;
  background-color: #dcdcdc;
  border-radius: 10px;
  font-weight: bold;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .category_box li a {
    padding: 4px 10px;
    border-radius: 5px;
  }
}
#parachannel #jobs .detail .link_box .btn_box {
  width: 830px;
  height: 165px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  z-index: 9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .btn_box {
    width: 100%;
    height: auto;
    padding: 30px 0;
    display: block;
  }
}
#parachannel #jobs .detail .link_box .btn_box:after {
  content: "";
  width: 100vw;
  height: 100%;
  background-color: #fff100;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  pointer-events: none;
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail .link_box .btn_box:after {
    min-width: 1000px;
  }
}
#parachannel #jobs .detail .link_box .btn_box .btn {
  padding: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0;
}
#parachannel #jobs .detail .link_box .btn_box .btn:not(.orange) {
  background-color: #fff;
  border: 2px #00a0e9 solid;
  color: #231815;
}
#parachannel #jobs .detail .link_box .btn_box > .btn {
  width: 170px;
  height: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .btn_box > .btn {
    margin: 0 auto 20px;
  }
}
#parachannel #jobs .detail .link_box .btn_box > .btn img {
  width: 25px;
  margin-right: 15px;
}
#parachannel #jobs .detail .link_box .btn_box > div {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .btn_box > div {
    width: 100%;
  }
}
#parachannel #jobs .detail .link_box .btn_box > div .btn {
  width: 230px;
  height: 50px;
  font-size: 24px;
  margin-right: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .link_box .btn_box > div .btn {
    width: 48.5%;
    font-size: 18px;
    margin-right: 3%;
  }
}
#parachannel #jobs .detail .link_box .btn_box > div .btn:last-child {
  margin: 0;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dt {
  width: 90px;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd {
  width: calc(100% - 90px);
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd.feature {
  width: 100%;
  margin-top: -15px;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd li {
  width: 70px;
  margin: 0 5px 10px 0;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd li p {
  font-size: 12px;
  line-height: 100%;
  color: #231815;
  margin-top: 5px;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd a {
  text-decoration: underline;
  color: #00a0e9;
  word-break: break-all;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd a:hover {
  text-decoration: none;
}
#parachannel #jobs .detail.type2 .sidebar .tab_box dl dd a .material-icons {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  font-size: 130%;
}
#parachannel #jobs .detail.type2 .cont_box .ttl {
  padding: 10px 0 36px;
  background: url(./img/jobs/ttl_bg.png) no-repeat;
  background-size: 100% 100%;
  border: none;
  text-align: center;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail.type2 .cont_box .ttl {
    padding: 6px 0 24px;
  }
}
#parachannel #jobs .detail .entry_txt {
  width: 100%;
  margin: 30px auto 10px;
  text-align: center;
  font-size: 120%;
  line-height: 1.5;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .entry_txt {
    font-size: 100%;
  }
}
#parachannel #jobs .detail .btn_area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*横向きの際に適用*/
  width: 100%;
  margin: 50px auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .btn_area.login_comp {
    flex-direction: column-reverse;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .btn_area {
    margin: 0 auto;
  }
}
#parachannel #jobs .detail .btn_area .btn {
  position: relative;
  width: 400px;
  background-color: #ea5514;
  border: none;
  color: #fff;
  margin: 0 20px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail .btn_area .btn {
    font-size: 22px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .btn_area .btn {
    width: 90%;
    margin: 0 auto 20px;
    padding: 20px 0;
    font-size: 18px;
  }
}
#parachannel #jobs .detail .btn_area .btn img {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 15px;
  width: 15px;
  height: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .btn_area .btn img {
    width: 15px;
    margin-left: 10px;
  }
}
#parachannel #jobs .detail .btn_area .btn.favarite {
  width: 300px;
  border: solid 2px #00a0e9;
  background-color: #fff;
  color: #00a0e9;
}
#parachannel #jobs .detail .btn_area .btn.favarite img {
  width: 22px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .btn_area .btn.favarite img {
    width: 20px;
  }
}
#parachannel #jobs .detail .btn_area .btn.favarited {
  width: 300px;
  background-color: #00a0e9;
  color: #fff;
  font-size: 120%;
}
#parachannel #jobs .detail .btn_area .btn.favarite, #parachannel #jobs .detail .btn_area .btn.favarited {
  display: block;
  height: 100%;
  padding: 0;
  line-height: 60px;
  font-size: 120%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .btn_area .btn.favarite, #parachannel #jobs .detail .btn_area .btn.favarited {
    width: 70%;
  }
}
#parachannel #jobs .detail .btn_area .btn.gray {
  background-color: #e6e6e6;
  color: #231815;
}

/* detail - sidebar */
#parachannel #jobs .detail .sidebar {
  font-weight: bold;
}
#parachannel #jobs .detail .sidebar .tab_btn {
  display: flex;
}
#parachannel #jobs .detail .sidebar .tab_btn li {
  width: 135px;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  text-align: center;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .sidebar .tab_btn li {
    width: 120px;
    font-size: 14px;
  }
}
#parachannel #jobs .detail .sidebar .tab_btn li a {
  width: 100%;
  padding: 22px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .sidebar .tab_btn li a {
    padding: 18px 0;
  }
}
#parachannel #jobs .detail .sidebar .tab_btn li.active:after {
  content: "";
  width: 100%;
  height: 8px;
  background-color: #00a0e9;
  position: absolute;
  left: 0;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .sidebar .tab_btn li.active:after {
    height: 5px;
  }
}
#parachannel #jobs .detail .sidebar .tab_btn.top li {
  border-radius: 5px 5px 0 0;
}
#parachannel #jobs .detail .sidebar .tab_btn.top li:after {
  border-radius: 5px 5px 0 0;
  top: 0;
}
#parachannel #jobs .detail .sidebar .tab_btn.bottom li {
  border-radius: 0 0 5px 5px;
}
#parachannel #jobs .detail .sidebar .tab_btn.bottom li:after {
  border-radius: 0 0 5px 5px;
  bottom: 0;
}
#parachannel #jobs .detail .sidebar .tab_box {
  width: 100%;
  padding: 30px 5px;
  background-color: #fff;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: relative;
  z-index: 9;
  margin: 0;
}
#parachannel #jobs .detail .sidebar .tab_box h3 {
  font-size: 16px;
  margin-bottom: 40px;
}
#parachannel #jobs .detail .sidebar .tab_box .ex {
  color: #000;
  font-size: 90%;
}
#parachannel #jobs .detail .sidebar .tab_box .ex span {
  color: #f00;
}
#parachannel #jobs .detail .sidebar .tab_box .logo_box {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
#parachannel #jobs .detail .sidebar .tab_box .logo_box img {
  width: 80px;
}
#parachannel #jobs .detail .sidebar .tab_box .logo_box h2 {
  width: calc(100% - 100px);
  margin: 0;
}
#parachannel #jobs .detail .sidebar .tab_box .follow_box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
#parachannel #jobs .detail .sidebar .tab_box .follow_box .follow_btn {
  cursor: pointer;
  margin-right: 10px;
  border: 1px solid #b3b3b3;
  padding: 10px;
  color: #595757;
}
#parachannel #jobs .detail .sidebar .tab_box .follow_box .follow_btn.disabled {
  background-color: #b3b3b3;
}
#parachannel #jobs .detail .sidebar .tab_box .follow_box p {
  color: #595757;
}
#parachannel #jobs .detail .sidebar .tab_box .edit_icon {
  position: absolute;
  right: 5px;
  top: 10px;
  display: block;
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  background-image: url("./img/company/icon13.svg");
  background-repeat: no-repeat;
  vertical-align: middle;
}
#parachannel #jobs .detail .sidebar .tab_box span.required_icon {
  position: relative;
  top: -5px;
  font-size: 80%;
  color: #f00;
  font-weight: bold;
}
#parachannel #jobs .detail .sidebar .tab_box dl {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  border-top: solid 1px #ccc;
}
#parachannel #jobs .detail .sidebar .tab_box dt, #parachannel #jobs .detail .sidebar .tab_box dd {
  padding: 10px 0;
  line-height: 24px;
}
#parachannel #jobs .detail .sidebar .tab_box dt {
  width: 100px;
}
#parachannel #jobs .detail .sidebar .tab_box dt small {
  display: block;
  margin-top: 5px;
  padding-right: 15%;
  font-size: 70%;
  line-height: 1.4;
}
#parachannel #jobs .detail .sidebar .tab_box dd {
  position: relative;
  width: calc(100% - 100px);
  font-weight: normal;
  word-break: break-all;
}
#parachannel #jobs .detail .sidebar .tab_box dd strong {
  font-weight: bold;
}
#parachannel #jobs .detail .sidebar .tab_box dd button {
  display: block;
  margin: 0;
  padding: 2px 30px;
  border: none;
  background-color: #00a0e9;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
#parachannel #jobs .detail .sidebar .tab_box dd button + label {
  display: block;
  margin-top: 10px;
}
#parachannel #jobs .detail .sidebar .tab_box dd label p {
  margin-bottom: 10px;
  font-size: 14px;
}
#parachannel #jobs .detail .sidebar .tab_box dd select,
#parachannel #jobs .detail .sidebar .tab_box dd input[type=text] {
  margin-bottom: 5px;
}
#parachannel #jobs .detail .sidebar .tab_box dd input[type=number] {
  display: inline-block;
  width: 80%;
  margin: 0 5px 5px 0;
}
#parachannel #jobs .detail .sidebar .tab_box dd textarea {
  margin-bottom: 0;
}
#parachannel #jobs .detail .sidebar .tab_box dd div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#parachannel #jobs .detail .sidebar .tab_box dd dl {
  display: block;
  width: 100%;
  border: none;
}
#parachannel #jobs .detail .sidebar .tab_box dd dl.half {
  width: 46%;
  border: none;
  border-bottom: dotted 1px #ccc;
  margin-top: 10px;
}
#parachannel #jobs .detail .sidebar .tab_box dd dl.half:nth-of-type(1), #parachannel #jobs .detail .sidebar .tab_box dd dl.half:nth-of-type(2) {
  margin-top: 0;
}
#parachannel #jobs .detail .sidebar .tab_box dd dl.single {
  margin-top: 10px;
  border: none;
}
#parachannel #jobs .detail .sidebar .tab_box dd .edit + div {
  margin-top: 10px;
}
#parachannel #jobs .detail .sidebar .tab_box dd .edit + span {
  margin-top: 10px;
}
#parachannel #jobs .detail .sidebar .tab_box dd dt {
  width: 100%;
  margin: 5px 0;
  font-size: 12px;
  color: #888;
  border: none;
  padding: 0;
}
#parachannel #jobs .detail .sidebar .tab_box dd dt:last-of-type {
  margin: 5px 0;
}
#parachannel #jobs .detail .sidebar .tab_box dd dt:first-child {
  margin-top: 0;
}
#parachannel #jobs .detail .sidebar .tab_box dd dd {
  width: 100%;
  padding: 0 0 5px;
  border: none;
}
#parachannel #jobs .detail .sidebar .tab_box dd dd label {
  display: inline-block;
}
#parachannel #jobs .detail .sidebar .tab_box dd .single dd {
  padding: 0;
  border: none;
}
#parachannel #jobs .detail .sidebar .tab_box dd .half .edit_icon {
  top: -5px;
}
#parachannel #jobs .detail .sidebar .tab_box dd.txt_box {
  position: relative;
}
#parachannel #jobs .detail .sidebar .tab_box dd.txt_box .edit {
  white-space: pre-wrap;
}
#parachannel #jobs .detail .sidebar .tab_box dd .edit {
  position: relative;
  display: block;
  padding-right: 30px;
}
#parachannel #jobs .detail .sidebar .tab_box dd .edit_icon {
  top: 0;
}
#parachannel #jobs .detail .sidebar .tab_box dd .edit_icon.edit_icon_t10 {
  top: 10px;
}
#parachannel #jobs .detail .sidebar .tab_box dd .white_space {
  white-space: pre-wrap;
  word-break: break-all;
}

/* detail - cont_box */
#parachannel #jobs .detail > p {
  line-height: 20px;
}
#parachannel #jobs .detail .cont_box {
  width: 58%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box {
    width: 100%;
    margin-bottom: 50px;
  }
}
#parachannel #jobs .detail .cont_box .ttl {
  position: relative;
  padding: 0 30px 10px 0;
  line-height: 1.4;
  font-size: 24px;
  border-bottom: 1px #b3b3b3 solid;
  font-family: dnp-shuei-mgothic-std, sans-serif;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
#parachannel #jobs .detail .cont_box .ttl .edit_icon {
  position: absolute;
  right: 0;
  top: 0;
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .ttl {
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-size: 20px;
  }
}
#parachannel #jobs .detail .cont_box .ttl button {
  display: block;
  margin-top: 10px;
  padding: 2px 30px;
  border: none;
  background-color: #00a0e9;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
#parachannel #jobs .detail .cont_box .slider_for {
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .slider_for {
    margin-bottom: 10px;
  }
}
#parachannel #jobs .detail .cont_box .slider_for li {
  position: relative;
}
#parachannel #jobs .detail .cont_box .slider_for li input[type=text] {
  width: 100%;
  padding: 10px 15px;
  background-color: rgba(102, 102, 102, 0.6);
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}
#parachannel #jobs .detail .cont_box .slider_for .slick-arrow {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: center;
  z-index: 9;
  cursor: pointer;
  transform: translateY(-50%);
}
#parachannel #jobs .detail .cont_box .slider_for .slick-arrow.prev {
  background-image: url(./img/jobs/arrow04.png);
  left: 0;
}
#parachannel #jobs .detail .cont_box .slider_for .slick-arrow.next {
  background-image: url(./img/jobs/arrow05.png);
  right: 0;
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide li {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
  background-color: #f8f8f8;
  /*横向きの際に適用*/
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide li img {
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .slider_for .slick-slide li {
    height: 260px;
  }
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide li .advice_txt {
  position: absolute;
  left: 50%;
  top: 10%;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  transform: translateX(-50%);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .slider_for .slick-slide li .advice_txt {
    font-size: 100%;
  }
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide li .add_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .slider_for .slick-slide li .add_icon {
    top: 60%;
    width: 80px;
    height: 80px;
  }
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide li label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide li label input {
  display: none;
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide .caption {
  width: 100%;
  padding: 10px 15px;
  background-color: rgba(102, 102, 102, 0.6);
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}
#parachannel #jobs .detail .cont_box .slider_for .slick-slide .trash {
  position: absolute;
  left: auto;
  right: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  padding: 8px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  z-index: 1;
  transform: translate(0, 0);
}
#parachannel #jobs .detail .cont_box .slider_nav {
  width: calc(100% + 5px);
  margin-left: -5px;
}
#parachannel #jobs .detail .cont_box .slider_nav li {
  position: relative;
  padding: 0 0 75%;
  overflow: hidden;
}
#parachannel #jobs .detail .cont_box .slider_nav li > div {
  cursor: pointer;
}
#parachannel #jobs .detail .cont_box .slider_nav li > div img {
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .slider_nav li > div img {
    height: 14vw;
  }
}
#parachannel #jobs .detail .cont_box .slider_nav .slick-slide {
  padding: 0 5px;
}
#parachannel #jobs .detail .cont_box .slider_edit {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
}
#parachannel #jobs .detail .cont_box .slider_edit li {
  position: relative;
  width: 19.2%;
  margin: 2% 0 0;
  border: solid 1px #ccc;
}
#parachannel #jobs .detail .cont_box .slider_edit li.img {
  position: relative;
}
#parachannel #jobs .detail .cont_box .slider_edit li.img :first-child {
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}
#parachannel #jobs .detail .cont_box .slider_edit li p {
  position: relative;
  width: 100%;
  height: 100px;
  border: 1px #b3b3b3 solid;
}
#parachannel #jobs .detail .cont_box .slider_edit li p .add_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
}
#parachannel #jobs .detail .cont_box .note {
  font-size: 12px;
  color: #f00;
  margin-top: 10px;
}
#parachannel #jobs .detail .cont_box select {
  position: relative;
  margin-top: 30px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAANCAYAAABGkiVgAAAACXBIWXMAABCbAAAQmwF0iZxLAAABEUlEQVQ4jZ2T8VXCQAyHP7qAbuAI4ASygQxwecgGjuAIOIFAOkCZwI4gG+gGMEF96Qt9eddKkfzTS5P7Lr9cbrLbaQU8A1uR9MKNplpugCWwL4DaMUvV8u0WpGq5dqBZNWmaJp5ithJJm38ATd2Hu63aFupBq/jJg48i6esK4Bz4dHcvkha2KEKO/Tj4ulYtZyNAi1fu2r7uPrpKPfEe+AbuPHEuko4DQMszJQ/ADzCLebFSPGCSTsA0XGIOrB1oeYv84CLf5L08S5n6JUZb+4E4sNf7HtTB1quVu92oZaNjU9JTQt7TAalx1LZh/S6SXv/adxE6AG7hYy9vUH5mVtF51A7XPOVRaJgIk2/fywb8Ajl9dI573d3wAAAAAElFTkSuQmCC) no-repeat;
  background-size: 15px;
  background-position: right 10px center;
  cursor: pointer;
}
#parachannel #jobs .detail .cont_box h3 {
  padding-bottom: 10px;
  border-bottom: 1px #b3b3b3 solid;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 50px 0 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box h3 {
    padding-bottom: 5px;
    margin: 30px 0 10px;
  }
}
#parachannel #jobs .detail .cont_box h3 img {
  width: 15px;
}
#parachannel #jobs .detail .cont_box h3 + p {
  white-space: pre-wrap;
  line-height: 1.4;
  word-wrap: break-word;
}
#parachannel #jobs .detail .cont_box .tag_ttl {
  padding: 3px 10px;
  border-left: 5px #00a0e9 solid;
  font-weight: bold;
  line-height: 100%;
  margin: 40px 0 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .tag_ttl {
    margin: 20px 0 10px;
  }
}
#parachannel #jobs .detail .cont_box .tag {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #jobs .detail .cont_box .tag li {
  margin: 0 5px 5px 0;
  padding: 5px 10px;
  background-color: #00a0e9;
  border-radius: 5px;
  color: #fff;
}
#parachannel #jobs .detail .cont_box .tag li a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}
#parachannel #jobs .detail .cont_box .map {
  width: 100%;
  height: 300px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .map {
    height: 200px;
  }
}
#parachannel #jobs .detail .cont_box .appeal_area p {
  width: 100%;
  padding: 12px 0;
  background-color: #df0056;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin-top: 30px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .appeal_area p {
    padding: 7px 0;
    font-size: 14px;
    line-height: 18px;
  }
}
#parachannel #jobs .detail .cont_box .appeal_area p:after {
  content: "";
  width: 20px;
  height: 20px;
  border-top: 20px #df0056 solid;
  border-right: 10px transparent solid;
  border-left: 10px transparent solid;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: -20px;
  left: 0;
  margin: 0 auto;
}
#parachannel #jobs .detail .cont_box .consideration_area h3 {
  display: block;
}
#parachannel #jobs .detail .cont_box .consideration_area .edit_area {
  position: relative;
  padding-right: 50px;
}
#parachannel #jobs .detail .cont_box .consideration_area .edit_area .edit_icon {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  cursor: pointer;
}
#parachannel #jobs .detail .cont_box .consideration_area .edit_area .select_area_child {
  padding: 20px;
  background-color: #f2f2f2;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .cont_box .consideration_area .edit_area .select_area_child {
    padding: 10px;
  }
}
#parachannel #jobs .detail .cont_box .consideration_area .edit_area button {
  display: block;
  margin: 10px 0 0;
  padding: 2px 30px;
  border: none;
  background-color: #00a0e9;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
#parachannel #jobs .detail .cont_box .movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
#parachannel #jobs .detail .cont_box .movie iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#parachannel #jobs .detail .cont_box .edit {
  position: relative;
  display: block;
  padding-right: 50px;
  line-height: 1.5;
}
#parachannel #jobs .detail .cont_box .edit .edit_icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}
#parachannel #jobs .detail .cont_box .movie_box {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
#parachannel #jobs .detail .cont_box .movie_box input[type=text] {
  margin: 5px 0;
}
#parachannel #jobs .detail .cont_box .movie_box button {
  padding: 5px 20px;
  background-color: #00a0e9;
  border: none;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
}
#parachannel #jobs .detail .cont_box .movie_box > img {
  width: 30px;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 9;
}
#parachannel #jobs .detail .cont_box .movie_box > div {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  z-index: 8;
  overflow: hidden;
}
#parachannel #jobs .detail .cont_box .movie_box > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#parachannel #jobs .detail .sidebar + .cont_box {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail .sidebar + .cont_box {
    margin-top: 50px;
  }
}
#parachannel #jobs .detail .sidebar .back_btn {
  margin: 0 0 40px;
  font-size: 120%;
}
#parachannel #jobs .detail .sidebar .back_btn a {
  display: inline-block;
  text-decoration: underline;
}
#parachannel #jobs .detail .sidebar .back_btn a:hover {
  text-decoration: none;
}
#parachannel #jobs .detail .no_image {
  position: relative;
  width: 100%;
  padding-bottom: 75%;
  text-align: center;
  background-color: #f7f7f7;
}
#parachannel #jobs .detail .no_image span {
  display: block;
  margin-bottom: 20px;
  font-size: 110%;
}
#parachannel #jobs .detail .no_image a {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
#parachannel #jobs .detail .no_image a img {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

/* detail */
#parachannel #jobs .detail_other {
  margin-top: 50px;
  padding: 3%;
  background-color: #f2f2f2;
  border: solid 1px #f2f2f2;
  border-radius: 10px;
  justify-content: space-between;
  flex-direction: initial;
}
#parachannel #jobs .detail_other .add_item_box {
  width: 100%;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 10px;
  position: relative; /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box {
    margin-bottom: 15px;
  }
}
#parachannel #jobs .detail_other .add_item_box.half {
  width: 48%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box.half {
    width: 100%;
  }
}
#parachannel #jobs .detail_other .add_item_box.mb0_pc {
  margin-bottom: 0;
}
#parachannel #jobs .detail_other .add_item_box h2 {
  padding: 20px;
  font-size: 120%;
  font-weight: bold;
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  border-bottom: solid 2px #00a0e9;
}
#parachannel #jobs .detail_other .add_item_box ul {
  padding: 15px;
}
#parachannel #jobs .detail_other .add_item_box li {
  margin: 0 auto 10px;
}
#parachannel #jobs .detail_other .add_item_box li span {
  display: block;
  margin: 15px 0 5px;
}
#parachannel #jobs .detail_other .add_item_box li input {
  border-color: #efefef;
}
#parachannel #jobs .detail_other .add_item_box li:last-child {
  margin-bottom: 0;
}
#parachannel #jobs .detail_other .add_item_box p {
  text-align: center;
  margin: 5px auto;
  padding: 0 15px 15px;
  font-weight: bold;
}
#parachannel #jobs .detail_other .add_item_box p button {
  display: inline-block;
  margin-left: 10px;
  padding: 10px 30px;
  background-color: #fff;
  border: solid 1px #00a0e9;
  color: #00a0e9;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
#parachannel #jobs .detail_other .add_item_box dl {
  width: 100%;
  margin: 0 auto;
  padding: 2%;
  border-top: solid 1px #efefef;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail_other .add_item_box dl {
    display: flex;
    justify-content: space-between;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dl {
    padding: 2% 2% 5%;
  }
}
#parachannel #jobs .detail_other .add_item_box dt {
  width: 25%;
  line-height: 40px;
  color: #333;
  font-weight: bold;
  font-size: 110%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dt {
    width: 100%;
  }
}
#parachannel #jobs .detail_other .add_item_box dd {
  width: 75%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dd {
    width: 100%;
  }
}
#parachannel #jobs .detail_other .add_item_box dd label {
  position: relative;
  margin: 1% 0;
  padding-left: 25px;
  line-height: 1.5;
  font-size: 110%;
}
#parachannel #jobs .detail_other .add_item_box dd label input[type=checkbox] {
  position: absolute;
  left: 0;
  top: 1px;
  display: inline-block;
  vertical-align: middle;
}
#parachannel #jobs .detail_other .add_item_box dd ._flex {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #jobs .detail_other .add_item_box dd ._flex label {
  margin: 1% 0;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex label {
    width: 19%;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex label {
    width: 33.3333333333%;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex.no_size label {
    width: auto;
    margin-right: 30px;
  }
}
#parachannel #jobs .detail_other .add_item_box dd ._flex.double {
  justify-content: space-between;
}
#parachannel #jobs .detail_other .add_item_box dd ._flex.double label {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex.double label {
    width: 47%;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex.double label {
    width: 100%;
  }
}
#parachannel #jobs .detail_other .add_item_box dd ._flex.three {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex.three {
    justify-content: space-between;
  }
}
#parachannel #jobs .detail_other .add_item_box dd ._flex.three label {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex.three label {
    width: 33.3333333333%;
    padding-right: 10px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dd ._flex.three label {
    width: 48%;
  }
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area {
  position: relative;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area p.edit {
  margin: 0;
  padding: 13px 0;
  font-size: 110%;
  font-weight: normal;
  text-align: left;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area ul {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 50px 15px 0;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area ul.no_flex {
  display: block;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area li {
  margin: 0 0 5px;
  font-size: 110%;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area li::after {
  content: "、";
  display: inline-block;
  font-size: 100%;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area li:last-child::after {
  display: none;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area .no_flex li {
  position: relative;
  padding-left: 20px;
  line-height: 1.5;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area .no_flex li::after {
  content: "・";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area .edit_icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  margin: 0;
}
#parachannel #jobs .detail_other .add_item_box dd.edit_area .error {
  color: #f00;
}
#parachannel #jobs .detail_other .add_item_box dd .checkbox_list.area.area_child {
  margin-top: 30px;
  position: relative;
  z-index: 1;
  padding: 3%;
  background-color: #f8f8f8;
}
#parachannel #jobs .detail_other .add_item_box dd .checkbox_list.area.area_child label {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail_other .add_item_box dd .checkbox_list.area.area_child label {
    width: 15.8333333333%;
  }
  #parachannel #jobs .detail_other .add_item_box dd .checkbox_list.area.area_child label:nth-child(6n+1) {
    margin-left: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dd .checkbox_list.area.area_child label {
    width: 33.3333333333%;
  }
}
#parachannel #jobs .detail_other .add_item_box dd button {
  display: block;
  margin: 10px 0 0;
  padding: 2px 30px;
  border: none;
  background-color: #00a0e9;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box dd button {
    margin: 10px auto;
    padding: 5px 40px;
    font-size: 120%;
  }
}
#parachannel #jobs .detail_other .add_item_box dl.no_flex {
  display: block;
}
#parachannel #jobs .detail_other .add_item_box dl.no_flex dd {
  width: 100%;
}
@media print, screen and (min-width: 769px) {
  #parachannel #jobs .detail_other .add_item_box .preview_display {
    display: flex;
    align-items: center;
  }
}
#parachannel #jobs .detail_other .add_item_box .preview_display ul {
  width: 75%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box .preview_display ul {
    width: 100%;
  }
}
#parachannel #jobs .detail_other .add_item_box .preview_display li:last-child {
  margin-bottom: 0;
}
#parachannel #jobs .detail_other .add_item_box .preview_display p {
  width: 25%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box .preview_display p {
    width: 100%;
  }
}
#parachannel #jobs .detail_other .add_item_box .preview_display p button {
  margin: 10px auto 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box .preview_display p button {
    margin: 0 0 0 20px;
  }
}
#parachannel #jobs .detail_other .add_item_box .preview_display > .btn {
  width: 150px;
  padding: 13px 0 !important;
  background-color: #00a0e9 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  position: absolute;
  top: 10px;
  right: 20px;
  margin: 0 !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .add_item_box .preview_display > .btn {
    width: 100px !important;
    padding: 9px 0 !important;
    font-size: 12px !important;
    top: 14px;
    right: 10px;
  }
}
#parachannel #jobs .detail_other .required {
  position: relative;
  top: -1px;
  font-size: 70%;
}
#parachannel #jobs .detail_other .note {
  font-size: 12px;
  color: #f00;
  margin-left: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #jobs .detail_other .note {
    display: block;
    margin: 10px 0 0;
  }
}

#parachannel #mypage {
  /* 共通 */
  display: flex;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage {
    display: block;
  }
}
#parachannel #mypage_inner {
  width: calc(100% - 330px);
  padding: 40px 30px 140px;
  /*横向きの際に適用*/
}
#parachannel #mypage_inner.mypageSchedules {
  padding: 40px 30px 140px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #mypage_inner {
    max-width: 1000px;
    margin: 0 auto;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner {
    width: 100%;
    padding: 30px 3% 50px;
  }
}
#parachannel #mypage_inner .ttl_box {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: space-between;
  margin: 50px 0 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner .ttl_box {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 30px 0;
  }
  #parachannel #mypage_inner .ttl_box .ttl {
    width: 100%;
    margin-top: 20px;
  }
}
#parachannel #mypage_inner .ttl_box h2 {
  position: relative;
  padding: 5px 0 5px 10px;
  border-left: solid 10px #006496;
}
#parachannel #mypage_inner .ttl_box ul {
  height: 30px;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner .ttl_box ul {
    width: 100%;
    height: 15px;
  }
}
#parachannel #mypage_inner .ttl_box ul li {
  margin-right: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner .ttl_box ul li {
    margin-right: 15px;
  }
}
#parachannel #mypage_inner .ttl_box ul li a {
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner .ttl_box ul li a {
    font-size: 10px;
  }
}
#parachannel #mypage_inner .ttl_box ul li a img {
  width: 15px;
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner .ttl_box ul li a img {
    width: 10px;
  }
}
#parachannel #mypage_inner .ttl_box ul li a img.guide {
  width: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner .ttl_box ul li a img.guide {
    width: 15px;
  }
}
#parachannel #mypage_inner .ttl_box ul li:last-child {
  margin: 0;
}
#parachannel #mypage_inner > .btn_box {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner > .btn_box {
    display: block;
    margin-bottom: 30px;
  }
}
#parachannel #mypage_inner > .btn_box a {
  width: 300px;
  height: 50px;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0 15px;
  overflow: hidden;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage_inner > .btn_box a {
    margin: 0 auto 15px;
  }
  #parachannel #mypage_inner > .btn_box a:last-child {
    margin-bottom: 0;
  }
}
#parachannel #mypage_inner > .btn_box a span {
  width: calc(100% - 40px);
}
#parachannel #mypage_inner > .btn_box a img {
  width: auto;
  max-width: none;
  height: 50px;
  padding: 9px 10px;
  background-color: #fff;
}
#parachannel #mypage_inner > .btn_box a.blue {
  background-color: #00a0e9;
}
#parachannel #mypage_inner > .btn_box a.navyblue {
  background-color: #006496;
}
#parachannel #mypage_inner > .btn_box a.orange {
  background-color: #ff7d00;
}
#parachannel .link_box {
  width: 100%;
  padding-top: 2px;
  margin-bottom: 55px;
  /* overflow: scroll; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .link_box {
    margin-bottom: 30px;
  }
}
#parachannel .link_box::-webkit-scrollbar {
  display: none;
}
#parachannel .link_box ul {
  border-bottom: 8px #00a0e9 solid;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .link_box ul {
    width: 750px;
    border-bottom-width: 4px;
  }
}
#parachannel .link_box ul li {
  padding: 0 20px;
  box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px 5px 0 0;
  text-align: center;
  margin-right: 0.5%;
}
#parachannel .link_box ul li:last-child {
  margin: 0;
}
#parachannel .link_box ul li a {
  padding: 11px 0;
  font-weight: bold;
  color: #b3b3b3;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel .link_box ul li a {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel .link_box ul li a {
    padding: 9px 0;
  }
}
#parachannel .link_box ul li.small a {
  padding: 13px 0;
  font-size: 14px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .link_box ul li.small a {
    padding: 10px 0;
    font-size: 10px;
  }
}
#parachannel .link_box ul li.active {
  background-color: #00a0e9;
}
#parachannel .link_box ul li.active a {
  color: #fff;
}
#parachannel .completion {
  width: 100%;
  max-width: 850px;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  margin: 0 auto 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion {
    display: block;
    margin-bottom: 30px;
  }
}
#parachannel .completion > div {
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div {
    width: 100%;
    margin-bottom: 20px;
  }
}
#parachannel .completion > div h2 {
  font-size: 26px;
  margin-bottom: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div h2 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
}
#parachannel .completion > div .graph_box {
  width: 260px;
  height: 260px;
  pointer-events: none;
  margin: 0 auto;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div .graph_box {
    width: 200px;
    height: 200px;
  }
}
#parachannel .completion > div .graph_box > div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
#parachannel .completion > div .graph_box > div > div {
  width: 545px;
  height: 545px;
  position: absolute;
  top: -11px;
  left: -143px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div .graph_box > div > div {
    width: 425px;
    height: 425px;
    left: -113px;
  }
}
#parachannel .completion > div .graph_box > div > div > div {
  height: 272px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div .graph_box > div > div > div {
    height: 200px;
  }
}
#parachannel .completion > div .graph_box p {
  width: 100%;
  font-size: 42px;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div .graph_box p {
    font-size: 30px;
  }
}
#parachannel .completion > div .graph_box p span {
  font-size: 64px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div .graph_box p span {
    font-size: 40px;
  }
}
#parachannel .completion > div > p {
  width: 100%;
  padding: 12px 0;
  background-color: #df0056;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin-top: 10px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion > div > p {
    padding: 13px 0;
    font-size: 14px;
    margin: 0;
  }
}
#parachannel .completion > div > p:after {
  content: "";
  width: 20px;
  height: 20px;
  border-right: 10px transparent solid;
  border-bottom: 20px #df0056 solid;
  border-left: 10px transparent solid;
  box-sizing: border-box;
  position: absolute;
  top: -20px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
#parachannel .completion ul {
  width: 440px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .completion ul {
    width: 100%;
  }
}
#parachannel .completion ul li {
  border-bottom: 1px #b3b3b3 solid;
  background-color: rgba(219, 255, 255, 0.3);
}
#parachannel .completion ul li a {
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 15px 50px 15px 25px;
}
#parachannel .completion ul li a:last-child {
  border: none;
}
#parachannel .completion ul li a p {
  font-size: 14px;
}
#parachannel .completion ul li a span {
  display: inline-block;
  padding: 4px 10px;
  background-color: #df0056;
  border-radius: 5px;
  text-align: center;
  color: #fff;
}
#parachannel .completion ul li a span.complete {
  background-color: #00df89;
}
#parachannel .completion ul li a img {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
}
#parachannel .required {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 5px;
  padding: 4px 10px;
  color: #fff;
  font-size: 80%;
  line-height: 1;
  background-color: #f00;
  border-radius: 5px;
}
#parachannel .any {
  margin-left: 1em;
  padding: 4px 10px;
  font-size: 80%;
  color: #fff;
  background-color: #9e9f9f;
  border-radius: 5px;
}

#parachannel #mypage .profile {
  width: 100%;
  max-width: 750px;
  min-width: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile {
    max-width: none;
  }
}
#parachannel #mypage .profile > div {
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile > div {
    margin-bottom: 30px;
  }
}
#parachannel #mypage .profile > div:last-child {
  margin: 0;
}
#parachannel #mypage .profile .cont1 {
  position: relative;
}
#parachannel #mypage .profile .cont1 .ex_info {
  /*横向きの際に適用*/
  font-size: 90%;
}
@media print, screen and (min-width: 769px) {
  #parachannel #mypage .profile .cont1 .ex_info {
    position: absolute;
    right: 0;
    top: 25px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile .cont1 .ex_info {
    text-align: right;
    margin-bottom: 10px;
  }
}
#parachannel #mypage .profile .cont1 .ex_info span {
  margin: 0 3px;
  font-weight: bold;
}
#parachannel #mypage .profile .ttl {
  color: #00a0e9;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #mypage .profile .ttl {
    font-size: 22px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile .ttl {
    margin-bottom: 20px;
  }
}
#parachannel #mypage .profile .wrapper {
  width: 100%;
  max-width: 750px;
  min-width: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile .wrapper {
    max-width: none;
  }
}
#parachannel #mypage .profile .link_box {
  margin-bottom: 25px !important;
  overflow: hidden !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile .link_box {
    margin-bottom: 15px !important;
  }
}
#parachannel #mypage .profile table {
  width: 100%;
  font-weight: bold;
  display: block;
}
#parachannel #mypage .profile table tbody {
  padding: 0 5px;
  border: 1px #b3b3b3 solid;
  border-radius: 5px;
  display: block;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile table tbody {
    padding: 10px;
  }
}
#parachannel #mypage .profile table tbody.care td > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
#parachannel #mypage .profile table tbody.care td > div .edit_box {
  padding: 5px 80px 5px 0;
  margin: 0;
}
#parachannel #mypage .profile tr {
  padding: 5px 0;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile tr {
    display: block;
    padding: 0 0 10px;
    margin-bottom: 10px;
  }
}
#parachannel #mypage .profile tr:last-child {
  border: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile tr:last-child {
    padding: 0;
    margin: 0;
  }
}
#parachannel #mypage .profile th {
  width: 210px;
  padding: 15px 10px;
  background-color: #7fe4ff;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile th {
    width: 100%;
    padding: 10px;
    display: block;
    margin-bottom: 10px;
  }
}
#parachannel #mypage .profile th small {
  font-size: 90%;
}
#parachannel #mypage .profile td {
  width: calc(100% - 230px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
  /*
  a:not(.btn) {
    width: 100%;
    color: $blue;
    margin-top: 15px;

    @include mq(sp) {
      margin-top: 10px;
    }
  }
  */
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td {
    width: 100%;
    margin: 0;
  }
}
#parachannel #mypage .profile td.photo .img_box {
  width: 100%;
  padding-bottom: 0;
  background: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td.photo .img_box {
    margin-bottom: 10px;
  }
}
#parachannel #mypage .profile td.photo .img_box img {
  position: relative;
  left: 0;
  width: 160px;
  transform: translate(0, 0);
}
#parachannel #mypage .profile td.photo .btn {
  width: 130px;
  font-size: 12px;
  font-weight: bold;
  margin: 15px 0;
  /*横向きの際に適用*/
}
#parachannel #mypage .profile td.photo .btn label {
  position: relative;
  display: block;
  padding: 10px 0;
  color: #fff;
  cursor: pointer;
}
#parachannel #mypage .profile td.photo .btn input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 0;
  text-indent: -9999px;
  background: none;
}
#parachannel #mypage .profile td.photo .btn.type3 {
  margin-right: 10px;
  padding: 0;
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td.photo .btn {
    margin: 0;
  }
}
#parachannel #mypage .profile td.name label {
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td.name label {
    margin-right: 10px;
  }
}
#parachannel #mypage .profile td.name label input {
  width: 135px;
  margin-left: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td.name label input {
    width: 100px;
  }
}
#parachannel #mypage .profile td.zip {
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}
#parachannel #mypage .profile td.zip > .btn {
  width: 140px;
  padding: 8px 0;
  background-color: #dbffff;
  border: 1px #b3b3b3 solid;
  font-size: 12px;
  color: #00a0e9;
  margin: 0;
}
#parachannel #mypage .profile td.zip label {
  display: flex;
  margin: 0 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td.zip label {
    margin-top: 15px;
  }
}
#parachannel #mypage .profile td.zip label input {
  width: 135px;
  margin-right: 10px;
}
#parachannel #mypage .profile td.zip label .btn {
  width: 135px;
  padding: 9px 0;
  font-size: 12px;
}
#parachannel #mypage .profile td.address label {
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px #b3b3b3 solid;
  margin-bottom: 10px;
}
#parachannel #mypage .profile td.address label select,
#parachannel #mypage .profile td.address label input {
  width: 230px;
  margin-top: 5px;
}
#parachannel #mypage .profile td.address .nest_child:last-child label {
  padding: 0;
  border: none;
  margin: 0;
}
#parachannel #mypage .profile td.email > div {
  width: 100%;
}
#parachannel #mypage .profile td.email > div > label {
  display: block;
  margin-bottom: 15px;
}
#parachannel #mypage .profile td.email > div > label:last-child {
  margin: 0;
}
#parachannel #mypage .profile td.email > div > label > input {
  background-color: #dbffff;
  margin-top: 5px;
}
#parachannel #mypage .profile td.email > div > label > select {
  width: 270px;
  background-color: #fff;
  margin-top: 5px;
}
#parachannel #mypage .profile td.email > div > label .radio {
  margin-top: 5px;
}
#parachannel #mypage .profile td.tel > div {
  width: 100%;
}
#parachannel #mypage .profile td.tel > div > label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
}
#parachannel #mypage .profile td.tel > div > label:last-child {
  margin: 0;
}
#parachannel #mypage .profile td.tel > div > label p {
  width: 100%;
  margin-bottom: 5px;
}
#parachannel #mypage .profile td.tel > div > label span {
  margin: 0 10px;
}
#parachannel #mypage .profile td.tel > div > label > input {
  margin-top: 5px;
  background-color: #dbffff;
}
#parachannel #mypage .profile td.tel > div > label > input[type=tel] {
  background: none;
}
#parachannel #mypage .profile td.tel > div > label label {
  margin-left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td.tel > div > label label {
    width: 100%;
    margin: 10px 0 0;
  }
}
#parachannel #mypage .profile td.graduation input {
  width: 250px;
  margin: 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td.graduation input {
    width: 210px;
  }
}
#parachannel #mypage .profile td .copy_btn {
  width: auto;
  padding: 0;
  background: none;
  font-size: 16px;
  font-weight: bold;
  color: #00a0e9;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 5px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td .copy_btn {
    font-size: 14px;
  }
}
#parachannel #mypage .profile td .copy_btn span {
  width: 20px;
  height: 20px;
  border: 2px #00a0e9 solid;
  border-radius: 5px;
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile td .copy_btn span {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
#parachannel #mypage .profile .btn_box {
  display: flex;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile .btn_box {
    display: block;
  }
}
#parachannel #mypage .profile .btn_box .btn {
  border-radius: 30px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #mypage .profile .btn_box .btn {
    width: 220px;
    padding: 11px 0;
    font-size: 18px;
    margin: 0 10px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .profile .btn_box .btn {
    margin-bottom: 15px;
  }
  #parachannel #mypage .profile .btn_box .btn:last-child {
    margin-bottom: 0;
  }
}
#parachannel #mypage .profile .btn_box .btn img {
  width: 8px;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
}

#parachannel #mypage p.select {
  min-width: 135px;
  border: 1px #b3b3b3 solid;
  border-radius: 5px;
  cursor: pointer;
}
#parachannel #mypage p.select a:not(.btn) {
  display: block;
  margin: 0;
  color: inherit;
}
#parachannel #mypage p.select a {
  padding: 7px 15px;
}
#parachannel #mypage .other {
  padding-right: 0 !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .other {
    padding: 10px !important;
  }
}
#parachannel #mypage .other tr th {
  min-width: 210px;
}
#parachannel #mypage .other tr td {
  position: relative;
  width: 100%;
  padding: 5px 60px 5px 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .other tr td {
    padding: 5px 50px 5px 5px;
  }
}
#parachannel #mypage .other tr td.zip {
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}
#parachannel #mypage .other tr td .nest_child {
  margin: 5px 0;
  position: relative;
}
#parachannel #mypage .other tr td .nest_child .nest_child {
  margin-left: 0;
}
#parachannel #mypage .other tr td .error_box {
  width: 100%;
}
#parachannel #mypage .other tr td .error_box .error_msg {
  font-size: 12px;
  color: #f00;
  margin: 10px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .other tr td .error_box .error_msg {
    font-size: 10px;
  }
}
#parachannel #mypage .other .radio_cont .radio_list {
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  background-color: #dbffff;
  padding: 4px 10px;
  margin-bottom: 5px;
}
#parachannel #mypage .other .radio_cont .radio_list label {
  display: flex;
  cursor: pointer;
  margin-right: 10px;
}
#parachannel #mypage .other .radio_cont .radio_list label input {
  overflow: hidden;
  border-radius: 2px;
  width: 18px;
  height: 18px;
}
#parachannel #mypage .other .radio_cont .radio_list label span {
  margin-top: 1px;
}
#parachannel #mypage .other .checkbox {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
}
#parachannel #mypage .other .checkbox input {
  width: 18px;
  height: 18px;
  margin: 0 5px 0 0;
}
#parachannel #mypage .other .skill_list li {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 6px 20px;
  border-radius: 1000px;
  min-width: 105px;
  text-align: center;
  background-color: rgba(255, 255, 231, 0.75);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .other .skill_list li {
    min-width: 70px;
    font-size: 10px;
  }
}
#parachannel #mypage .other .skill_list li .del {
  position: absolute;
  top: -2px;
  right: 4px;
}
#parachannel #mypage .other .skill_list li .del img {
  cursor: pointer;
  width: 14px;
  height: 14px;
}
#parachannel #mypage .other input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .other input {
    max-width: none;
  }
}
#parachannel #mypage .other input[type=radio] {
  width: 20px;
  height: 20px;
}
#parachannel #mypage .other .red {
  color: #df0056;
}
#parachannel #mypage .other .radio {
  width: 100%;
  padding: 10px;
  background-color: #dbffff;
  display: flex;
  margin-bottom: 5px;
}
#parachannel #mypage .other .radio label {
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 25px;
  cursor: pointer;
}
#parachannel #mypage .other .radio label input {
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
}
#parachannel #mypage .other > p {
  line-height: 20px;
  color: #595757;
  margin-top: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .other > p {
    font-size: 10px;
    line-height: 16px;
  }
}
#parachannel #mypage .other.cont3 input {
  background-color: #dbffff;
}
#parachannel #mypage .other.cont3 select {
  background-color: #fff !important;
}
#parachannel #mypage .checkbox label {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  font-weight: normal;
}
#parachannel #mypage .checkbox label input {
  width: 15px;
  height: 15px;
  margin: 0 5px 0 0;
}
#parachannel #mypage .checkbox label::before {
  display: none;
}

#parachannel #mypage .recruit_management_cont {
  margin: 0 auto;
}
#parachannel #mypage .recruit_management_cont .tab_anchor {
  margin-bottom: 10px;
  display: flex;
  border-bottom: 1px solid #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .tab_anchor {
    margin-bottom: 24px;
  }
}
#parachannel #mypage .recruit_management_cont .tab_anchor a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;
  width: 25%;
  line-height: 1.45;
  text-align: center;
  font-size: 18px;
  color: #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .tab_anchor a {
    padding: 6px 4px;
    font-size: 12px;
  }
}
#parachannel #mypage .recruit_management_cont .tab_anchor a.active {
  background-color: #00a0e9;
  color: #fff;
}
#parachannel #mypage .recruit_management_cont .tab_anchor + .note {
  margin-bottom: 20px;
  text-align: right;
}
#parachannel #mypage .recruit_management_cont .recurit_list {
  padding-bottom: 20px;
  margin-bottom: 36px;
  border-bottom: 1px solid #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list {
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li {
  margin-bottom: 34px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li {
    margin-bottom: 22px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a {
    justify-content: space-between;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a > div {
  /*横向きの際に適用*/
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .img_cont {
  position: relative;
  margin-right: 40px;
  width: 50%;
  padding-bottom: 37.5%;
  overflow: hidden;
  /*横向きの際に適用*/
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .img_cont {
    width: 45%;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .img_cont img {
  position: absolute;
  left: 50%;
  top: 0;
  width: auto;
  max-width: none;
  height: 100%;
  transform: translateX(-50%);
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .img_cont {
    margin-right: 0;
    margin-bottom: 14px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .img_cont .like_btn {
  position: absolute;
  cursor: pointer;
  right: 18px;
  top: 18px;
  width: 36px;
  height: 33px;
  background-image: url(./img/mypage/like_btn_off.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .img_cont .like_btn {
    width: 24px;
    height: 20px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .img_cont .like_btn.on {
  background-image: url(./img/mypage/like_btn_on.svg);
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont {
  min-width: 320px;
  width: 45%;
  line-height: 1.6;
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont {
    width: 53%;
    min-width: auto;
    font-size: 12px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .update {
  display: inline-block;
  margin-bottom: 10px;
  padding: 2px 40px;
  background-color: #8cc63f;
  color: #fff;
  font-size: 18px;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .update {
    margin-bottom: 5px;
    padding: 2px 20px;
    font-size: 14px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .unread {
  padding: 7px 20px;
  margin-bottom: 7px;
  border: 2px solid white;
  border-radius: 1000px;
  background-color: red;
  color: white;
  line-height: 100%;
  font-size: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .unread {
    font-size: 12px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .company {
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .company {
    margin-bottom: 10px;
    font-size: 16px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .topic_ttl {
  font-size: 20px;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .topic_ttl {
    font-size: 14px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .outline {
  width: 100%;
  margin-bottom: 10px;
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .occupation {
  display: inline-block;
  padding: 5px 20px;
  border: 2px solid #00a0e9;
  border-radius: 1000px;
  color: #00a0e9;
  line-height: 100%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .occupation {
    margin: 3px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .category {
  width: auto;
  padding: 5px 10px;
  border: 2px solid;
  border-radius: 10px;
  line-height: 100%;
  text-align: center;
  display: block;
  margin-left: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .category {
    margin: 3px;
  }
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .category.type1 {
  border-color: #ea5514;
  color: #ea5514;
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .category.type4 {
  border-color: #f39800;
  color: #f39800;
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .work_ex {
  width: 100%;
  margin-top: 10px;
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .work_ex ul li {
  position: relative;
  padding-left: 1em;
}
#parachannel #mypage .recruit_management_cont .recurit_list > li a .txt_cont .work_ex ul li::before {
  content: "・";
  position: absolute;
  left: 0;
}
#parachannel #mypage .recruit_management_cont .pageer_cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
#parachannel #mypage .recruit_management_cont .pageer_cont > a {
  cursor: pointer;
  margin: 0 15px;
  color: #a2c2d4;
  font-size: 21px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .pageer_cont > a {
    margin: 0 7px;
    font-size: 12px;
  }
}
#parachannel #mypage .recruit_management_cont .pageer_cont ol {
  display: flex;
  align-items: center;
}
#parachannel #mypage .recruit_management_cont .pageer_cont ol li {
  margin: 0 6px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .pageer_cont ol li {
    margin: 0 6px;
  }
}
#parachannel #mypage .recruit_management_cont .pageer_cont ol li a {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c8c8c8;
  color: #fff;
  border-radius: 1000px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont .pageer_cont ol li a {
    width: 28px;
    height: 28px;
  }
}
#parachannel #mypage .recruit_management_cont .pageer_cont ol li a.current {
  background-color: #00a0e9;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont {
  width: 70%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont {
    width: 100%;
    margin-bottom: 40px;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages {
  max-height: 350px;
  padding-right: 10px;
  margin-bottom: 80px;
  overflow-y: scroll;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages::-webkit-scrollbar {
  width: 5px;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages::-webkit-scrollbar-thumb {
  background-color: #00a0e9;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages li {
  margin-bottom: 20px;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages li .date {
  color: #808080;
  font-size: 13px;
  margin-bottom: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages li .date {
    font-size: 12px;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages li .contents {
  border-radius: 6px;
  position: relative;
  padding: 20px;
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages li .contents {
    padding: 12px 16px;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .you {
  max-width: 75%;
  margin-left: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .you {
    max-width: 85%;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .you .contents {
  position: relative;
  margin-right: 30px;
  background-color: #dbffff;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .you .contents::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11.5px 0 11.5px 30px;
  border-color: transparent transparent transparent #dbffff;
  transform: translate(0, -50%);
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .company {
  max-width: 75%;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .company .contents {
  background-color: #f7f7f7;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .company .contents .btn_cont {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .company .contents .btn_cont a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 49%;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-size: 16px;
  padding: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .company .contents .btn_cont a {
    font-size: 12px;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .company .contents .btn_cont a:nth-child(1) {
  color: #00a0e9;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .messages .company .contents .btn_cont a:nth-child(2) {
  color: #8d8d8d;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont textarea {
  margin-bottom: 20px;
  padding: 20px 15px;
  border: none;
  background-color: #f2f2f2;
  font-size: 14px;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont .candidate_cont {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 30px;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont .btn_cont > a {
  margin-bottom: 20px;
  padding: 18px 40px;
  min-width: 180px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  background-color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont .btn_cont > a {
    min-width: 72px;
    padding: 12px 16px;
    font-size: 14px;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont .btn_cont > a.add_btn {
  position: relative;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #7fe4ff;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .message_cont .input_message_cont .btn_cont > a.add_btn img {
  position: absolute;
  left: 20px;
  top: 50%;
  display: inline-block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  transform: translate(0, -50%);
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont {
  width: 24%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont {
    width: 100%;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_ttl_cont {
  margin-top: 23px;
  padding-bottom: 20px;
  border-bottom: 1px solid #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_ttl_cont {
    margin-top: 12px;
    padding-bottom: 12px;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_ttl_cont p {
  display: flex;
  align-items: center;
  font-size: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_ttl_cont p {
    font-size: 14px;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_ttl_cont p img {
  margin-left: 10px;
  width: 32px;
  min-width: 32px;
  height: 32px;
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_list li {
  padding: 30px 0;
  line-height: 1.8;
  border-bottom: 1px solid #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_list li {
    padding: 20px 0;
  }
}
#parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_list li dl {
  font-size: 13px;
  color: #808080;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .recruit_management_cont.message .ui_cont .memo_cont .memo_list li dl {
    font-size: 12px;
  }
}

#parachannel #mypage .settings_cont .setting {
  margin-bottom: 72px;
  /*横向きの際に適用*/
}
#parachannel #mypage .settings_cont .setting:last-child {
  margin-bottom: 30px;
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont .setting {
    margin-bottom: 30px;
  }
}
#parachannel #mypage .settings_cont h2:not(.ttl) {
  color: #00a0e9;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont h2:not(.ttl) {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
#parachannel #mypage .settings_cont .info {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 18px;
  font-size: 16px;
  color: #9e9f9f;
  border-bottom: 1px solid #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont .info {
    padding-bottom: 12px;
    font-size: 14px;
  }
}
#parachannel #mypage .settings_cont .info.status {
  flex-direction: column;
  align-items: flex-start;
}
#parachannel #mypage .settings_cont .info.status .set_input {
  margin-bottom: 30px;
}
#parachannel #mypage .settings_cont .info.status .switch {
  background-color: #fff;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont .info.status .switch {
    width: 100%;
    display: flex;
  }
}
#parachannel #mypage .settings_cont .info.status .switch a {
  display: inline-block;
  padding: 18px 30px;
  min-width: 180px;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont .info.status .switch a {
    min-width: auto;
    padding: 12px 0;
    font-size: 14px;
    width: 50%;
  }
}
#parachannel #mypage .settings_cont .info.status .switch a.active {
  color: #fff;
  background-color: #39b54a;
}
#parachannel #mypage .settings_cont .info .set_input {
  line-height: 1.6;
}
#parachannel #mypage .settings_cont .info .set_input ul li {
  position: relative;
  padding-left: 2em;
}
#parachannel #mypage .settings_cont .info .set_input ul li::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 3px;
  width: 14px;
  height: 14px;
  border: 1px solid #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont .info .set_input ul li::before {
    top: 4px;
    width: 12px;
    height: 12px;
  }
}
#parachannel #mypage .settings_cont .set_btn {
  padding: 18px 60px;
  min-width: 180px;
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  background-color: #00a0e9;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont .set_btn {
    min-width: 72px;
    padding: 12px 16px;
    font-size: 10px;
  }
}
#parachannel #mypage .settings_cont .withdrawal {
  text-align: right;
}
#parachannel #mypage .settings_cont .withdrawal a {
  font-size: 16px;
  color: #9e9f9f;
  text-decoration: underline;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #mypage .settings_cont .withdrawal a {
    font-size: 12px;
  }
}

#parachannel #schedule {
  font-weight: bold;
}
#parachannel #schedule .pagination {
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule .pagination {
    margin-bottom: 25px;
  }
}
#parachannel #schedule .pagination a {
  display: flex;
  align-items: center;
  width: auto;
  padding: 5px 10px;
  background-color: #ecfbff;
  color: #00a0e9; /*横向きの際に適用*/
}
#parachannel #schedule .pagination a:first-child {
  padding-left: 5px;
}
#parachannel #schedule .pagination a:last-child {
  padding-right: 5px;
}
#parachannel #schedule .pagination a img {
  width: 10px;
  margin: 0 5px;
}
#parachannel #schedule .pagination p {
  font-size: 16px;
  margin: 0 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule .pagination p {
    font-size: 14px;
    margin: 0 10px;
  }
}
#parachannel #schedule > ul > li {
  padding-left: 30px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li {
    padding: 0;
    display: block;
    margin-bottom: 20px;
  }
  #parachannel #schedule > ul > li:last-child {
    margin: 0;
  }
}
#parachannel #schedule > ul > li .date {
  width: 120px;
  color: #b3b3b3;
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li .date {
    width: 100%;
    margin-bottom: 5px;
  }
}
#parachannel #schedule > ul > li .date span {
  width: 30px;
  padding: 7px 0;
  background-color: #f2f2f2;
  font-size: 16px;
  text-align: center;
  color: #00a0e9;
  margin-right: 10px;
}
#parachannel #schedule > ul > li ul {
  width: calc(100% - 120px);
  padding: 10px 0;
  border-bottom: 1px #b3b3b3 solid;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li ul {
    width: 100%;
    padding: 15px 0;
  }
}
#parachannel #schedule > ul > li ul li {
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li ul li {
    margin-bottom: 25px;
  }
}
#parachannel #schedule > ul > li {
  padding-left: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
}
#parachannel #schedule > ul > li ul li:last-child {
  margin: 0;
}
#parachannel #schedule > ul > li ul li a {
  padding: 0 55px 0 15px;
  background: url(./img/company/icon20.svg) no-repeat;
  background-size: 20px;
  background-position: right 5px center;
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li ul li a {
    padding: 0 30px 0 5px;
    flex-wrap: wrap;
  }
}
#parachannel #schedule > ul > li .time {
  width: 80px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li .time {
    width: 70px;
    order: 1;
  }
}
#parachannel #schedule > ul > li .img_box {
  width: 40px;
  height: 40px;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-color: #f2f2f2;
  border-radius: 50%;
  margin-left: 75px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li .img_box {
    margin: 10px 0 0;
    order: 4;
  }
}
#parachannel #schedule > ul > li .name {
  width: calc(100% - 320px);
  padding: 0 15px;
  font-size: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li .name {
    width: calc(100% - 40px);
    padding: 0 10px;
    margin-top: 10px;
    order: 5;
  }
}
#parachannel #schedule > ul > li .name span {
  font-size: 14px;
}
#parachannel #schedule > ul > li .name span:nth-of-type(1) {
  color: #b3b3b3;
  display: block;
  margin-bottom: 5px;
}
#parachannel #schedule > ul > li .category,
#parachannel #schedule > ul > li .tag {
  padding: 3px 0;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li .category,
  #parachannel #schedule > ul > li .tag {
    padding: 3px 0;
  }
}
#parachannel #schedule > ul > li .category {
  width: 50px;
  border-color: #ff4800;
  color: #ff4800;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li .category {
    width: 40px;
    margin-right: 15px;
    order: 2;
  }
}
#parachannel #schedule > ul > li .category.traning {
  border-color: #39b54a;
  color: #39b54a;
}
#parachannel #schedule > ul > li .tag {
  width: 75px;
  border-color: #b3b3b3;
  color: #b3b3b3;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule > ul > li .tag {
    width: 65px;
    margin-right: calc(100% - 190px);
    order: 3;
  }
}
#parachannel #schedule + div .btn.type3 {
  width: 215px;
  padding: 12px 0;
  background-color: #e6e6e6;
  color: #231815;
  margin-top: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #schedule + div .btn.type3 {
    width: 200px;
    padding: 13px 0;
    margin-top: 25px;
  }
}

#parachannel #company {
  /* 共通 */
  display: flex;
  margin: 0;
  /*横向きの際に適用*/
  /* top */
  /* outline */
  /* apply */
  /* message */
  /* setting */
}
@media print, screen and (max-width: 768px) {
  #parachannel #company {
    display: block;
  }
}
#parachannel #company .btn_preview {
  width: 360px;
  height: 50px;
  padding: 0;
  line-height: 50px;
  margin: 0 0 30px auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .btn_preview {
    width: 90%;
    margin: 0 auto 30px;
  }
}
#parachannel #company .btn_preview a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}
#parachannel #company .tooltip_box {
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .tooltip_box {
    flex-direction: row-reverse;
    position: absolute;
    top: 0;
    right: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .tooltip_box {
    margin-bottom: 10px;
  }
}
#parachannel #company .tooltip_box .countdown {
  width: 135px;
  padding: 6px 0;
  border: 1px #f00 solid;
  border-radius: 5px;
  line-height: 16px;
  text-align: center;
  color: #f00;
  margin: 0 !important;
}
#parachannel #company .tooltip_box .tooltip {
  margin-right: 10px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .tooltip_box .tooltip {
    margin: 0 0 0 10px;
  }
}
#parachannel #company .tooltip_box .tooltip .icon {
  width: 24px;
  cursor: pointer;
  margin: 0 !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .tooltip_box .tooltip .icon {
    width: 20px;
  }
}
#parachannel #company .tooltip_box .tooltip .text {
  width: 600px;
  padding: 10px 0;
  background-color: #00a0e9;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .tooltip_box .tooltip .text {
    width: 250px;
    padding: 10px;
    font-size: 10px;
    line-height: 16px;
    top: 35px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}
#parachannel #company .tooltip_box .tooltip .text:after {
  content: none;
  width: 10px;
  height: 20px;
  border-top: 10px transparent solid;
  border-bottom: 10px transparent solid;
  border-left: 10px #00a0e9 solid;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: -10px;
  bottom: 0;
  margin: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .tooltip_box .tooltip .text:after {
    width: 20px;
    height: 10px;
    border-top: none;
    border-right: 10px transparent solid;
    border-bottom: 10px #00a0e9 solid;
    border-left: 10px transparent solid;
    top: -10px;
    right: 0;
    bottom: auto;
    left: 0;
  }
}
#parachannel #company .tooltip_box .tooltip:hover .text {
  opacity: 1;
}
#parachannel #company > section:not(#sidebar) {
  width: calc(100% - 330px);
  padding: 40px 30px 140px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company > section:not(#sidebar) {
    max-width: 1000px;
    margin: 0 auto;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) {
    width: 100%;
    padding: 30px 3% 50px;
  }
}
#parachannel #company > section:not(#sidebar) .ttl_box {
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: space-between;
  margin: 50px 0 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .ttl_box {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 30px 0;
  }
  #parachannel #company > section:not(#sidebar) .ttl_box .ttl {
    width: 100%;
    margin-top: 20px;
  }
}
#parachannel #company > section:not(#sidebar) .ttl_box h2 {
  position: relative;
  padding: 5px 0 5px 10px;
  border-left: solid 10px #006496;
}
#parachannel #company > section:not(#sidebar) .ttl_box ul {
  height: 30px;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .ttl_box ul {
    width: 100%;
    height: 15px;
  }
}
#parachannel #company > section:not(#sidebar) .ttl_box ul li {
  margin-right: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .ttl_box ul li {
    margin-right: 15px;
  }
}
#parachannel #company > section:not(#sidebar) .ttl_box ul li a {
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .ttl_box ul li a {
    font-size: 10px;
  }
}
#parachannel #company > section:not(#sidebar) .ttl_box ul li a img {
  width: 15px;
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .ttl_box ul li a img {
    width: 10px;
  }
}
#parachannel #company > section:not(#sidebar) .ttl_box ul li a img.guide {
  width: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .ttl_box ul li a img.guide {
    width: 15px;
  }
}
#parachannel #company > section:not(#sidebar) .ttl_box ul li:last-child {
  margin: 0;
}
#parachannel #company > section:not(#sidebar) .edit_box {
  font-weight: bold;
}
#parachannel #company > section:not(#sidebar) .edit_box input {
  width: 15px;
  height: 15px;
  margin: 0;
  cursor: pointer;
}
#parachannel #company > section:not(#sidebar) .edit_box > div {
  padding-bottom: 5px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
#parachannel #company > section:not(#sidebar) .edit_box > div.bottom {
  padding: 5px 0 0;
  border: none;
  border-top: 1px #b3b3b3 solid;
  margin: 15px 0 0;
  justify-content: flex-end;
}
#parachannel #company > section:not(#sidebar) .edit_box > div > div:not(.icon) {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company > section:not(#sidebar) .edit_box > div > div:not(.icon) > a {
  color: #b3b3b3;
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 15px;
}
#parachannel #company > section:not(#sidebar) .edit_box > div > div:not(.icon) > a img {
  width: 20px;
  margin-left: 10px;
}
#parachannel #company > section:not(#sidebar) .edit_box .csv_download {
  display: inline-block;
  margin: 20px 0;
  width: 130px;
}
#parachannel #company > section:not(#sidebar) .edit_box .csv_download label input {
  margin-right: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .csv_download label input {
    margin-right: 10px;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .csv_download a {
  width: 15px;
}
#parachannel #company > section:not(#sidebar) .edit_box .csv_download .btn {
  width: 100%;
  padding: 5px 0;
  background-color: #325a78;
  border-radius: 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .csv_download .btn {
    width: 110px;
    font-size: 10px;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .csv_download .btn img {
  width: 10px;
  margin-left: 5px;
}
#parachannel #company > section:not(#sidebar) .edit_box .csv_download + .note {
  display: inline-block;
  width: calc(98% - 130px);
  text-align: right;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .csv_download + .note {
    vertical-align: middle;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .pagination,#parachannel .paginationWrapper .pagination {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company > section:not(#sidebar) .edit_box .pagination li,#parachannel .paginationWrapper .pagination li {
  width: 20px;
  margin: 0 5px 0 0;
}
#parachannel #company > section:not(#sidebar) .edit_box .pagination li:last-child,#parachannel .paginationWrapper .pagination li:last-child {
  margin: 0;
}
#parachannel #company > section:not(#sidebar) .edit_box .pagination li a,#parachannel .paginationWrapper .pagination li a {
  width: 100%;
  padding: 4px 0;
  text-align: center;
  color: #00a0e9;
}
#parachannel #company > section:not(#sidebar) .edit_box .pagination li.arrow a,#parachannel .paginationWrapper .pagination li.arrow a {
  padding: 0;
}
#parachannel #company > section:not(#sidebar) .edit_box .pagination li.active,#parachannel .paginationWrapper .pagination li.active {
  background-color: #00a0e9;
}
#parachannel #company > section:not(#sidebar) .edit_box .pagination li.active a,#parachannel .paginationWrapper .pagination li.active a {
  color: #fff;
}
#parachannel #company > section:not(#sidebar) .edit_box .category {
  display: flex;
}
#parachannel #company > section:not(#sidebar) .edit_box .category li {
  padding-bottom: 25px;
  margin-right: 20px;
}
#parachannel #company > section:not(#sidebar) .edit_box .category li:last-child {
  margin: 0;
}
#parachannel #company > section:not(#sidebar) .edit_box .category li a {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company > section:not(#sidebar) .edit_box .category li a img {
  width: 20px;
  margin-right: 10px;
}
#parachannel #company > section:not(#sidebar) .edit_box .category li.active {
  border-bottom: 3px #00a0e9 solid;
}
#parachannel #company > section:not(#sidebar) .edit_box .category li.active a {
  color: #00a0e9;
}
#parachannel #company > section:not(#sidebar) .edit_box .category.new {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  margin: 0;
  position: absolute;
  top: -15px;
  left: -105px;
  z-index: 9;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .category.new {
    top: -96px;
    left: -10px;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .category.new:after {
  content: "";
  width: 100%;
  height: 100%;
  border-top: 25px #f00 solid;
  border-right: 25px transparent solid;
  border-bottom: 25px transparent solid;
  border-left: 25px #f00 solid;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
#parachannel #company > section:not(#sidebar) .edit_box .category.new span {
  width: 100%;
  line-height: 30px;
  text-align: center;
  color: #fff;
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box {
  border: 1px #b3b3b3 solid;
  border-right: 3px #00a0e9 solid;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li {
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li:last-child {
  border: none;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li label {
  width: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li label {
    width: 30px;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li label input {
  display: block;
  margin: 0 auto;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div {
  width: 100%;
  /*width: calc(100% - 40px);
  border-left: 1px $lightgray solid;
  @include mq(sp) {
    width: calc(100% - 30px);
  }*/
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 {
  padding: 15px 10px 10px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 {
    padding: 15px 10px;
    display: block;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .booked {
  position: absolute;
  top: 0.25em;
  left: 0.25em;
  padding: 0.25em;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #595757;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .img_box {
  width: 95px;
  padding-bottom: 71.25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .img_box {
    width: 150px;
    margin: 0 auto 10px;
    float: left;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box {
  width: calc(100% - 95px);
  padding-left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box {
    width: 100%;
    padding: 0;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box h3 {
  width: 80%;
  font-size: 16px;
  line-height: 20px;
  color: #00a0e9;
  margin-bottom: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box h3 {
    width: calc(100% - 160px);
    padding: 20px 0;
    margin: 0;
    float: right;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box h3 .id_text {
  font-size: 14px;
  color: #231815;
  display: block;
  margin-bottom: 5px;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box h4 {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box h4 {
    clear: both;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box p {
  line-height: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box p {
    clear: both;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box .countdown {
  width: 135px;
  padding: 6px 0;
  border: 1px #f00 solid;
  border-radius: 5px;
  text-align: center;
  color: #f00;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont1 .text_box .countdown {
    margin-left: auto;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 {
  height: 35px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 {
    height: auto;
    padding: 10px;
    flex-wrap: wrap;
  }
}

#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 p {
  color: #b3b3b3;
  margin-right: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 p {
    font-size: 10px;
    margin-right: 10px;
    order: 3;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn {
  width: 80px;
  padding: 6px 0;
  font-size: 13px;
  margin: 0 10px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn {
    margin: 0 10px 5px 0;
    order: 2;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn:not(.orange):not(.gray):not(.blue) {
  width: 50px;
  padding: 5px 0;
  background: none;
  border: 1px #00a0e9 solid;
  color: #231815;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn:not(.orange):not(.gray):not(.blue) {
    margin-right: 0;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue, #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange {
  background-color: #ecfbff;
  color: #00a0e9;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue, #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange {
    margin-right: 15px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue, #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange {
    order: 1;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue img, #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange img {
  width: 10px;
  margin-right: 5px;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue:nth-of-type(2), #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange:nth-of-type(2) {
  width: auto;
  padding: 6px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue:nth-of-type(2), #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange:nth-of-type(2) {
    position: absolute;
    top: 45px;
    right: 10px;
    margin: 0;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue:nth-of-type(3), #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange:nth-of-type(3) {
  margin-right: 0;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.blue:nth-of-type(3), #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange:nth-of-type(3) {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange {
  background-color: #ff7d00;
  color: #fff;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange {
    width: 100px;
    font-size: 15px;
  }
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 .btn.orange img {
    width: 15px;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 a.del {
  width: 15px;
  margin: 0 10px 0 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 a.del {
    margin-right: 0;
    order: 4;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 p {
  font-size: 100%;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 p a {
  width: 100%;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 select[name=status] {
  width: 135px;
  height: 25px;
  background: url(./img/company/icon04.png) no-repeat;
  background-size: 10px;
  background-position: right 10px center;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li > div .cont2 select[name=status] {
    margin: 0 10px 5px calc(100% - 220px);
    order: 2;
  }
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li.end_cont {
  opacity: 0.3;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li.new_icon {
  position: relative;
}
#parachannel #company > section:not(#sidebar) .edit_box .list_box li.new_icon:after {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #f00;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .edit_box .list_box li.new_icon:after {
    top: 5px;
  }
}
#parachannel #company > section:not(#sidebar) .select_box {
  width: 200px;
  background-color: #f7f7f7;
  margin: 0 0 25px auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company > section:not(#sidebar) .select_box {
    width: 180px;
    margin: -45px 0 15px auto;
  }
}
#parachannel #company > section:not(#sidebar) .select_box select {
  width: 100%;
  height: 35px;
  padding: 0 30px 0 15px;
  background: url(./img/company/icon11.png) no-repeat;
  background-size: 10px;
  background-position: right 15px center;
  border: none;
  cursor: pointer;
}
#parachannel #company > section:not(#sidebar) .select_box p {
  padding: 0 15px 10px;
  font-size: 10px;
}
#parachannel #company .top {
  font-weight: bold;
}
#parachannel #company .top > div {
  margin-bottom: 90px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div {
    margin-bottom: 50px;
  }
}
#parachannel #company .top > div:last-child {
  margin: 0;
}
#parachannel #company .top > div h2 {
  font-size: 22px;
  color: #00a0e9;
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
#parachannel #company .top > div > ul {
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul {
    margin-bottom: 10px;
  }
}
#parachannel #company .top > div > ul li {
  border-bottom: 1px #b3b3b3 solid;
}
#parachannel #company .top > div > ul li a,
#parachannel #company .top > div > ul li > div {
  padding: 21px 60px 21px 25px;
  background: url(./img/company/icon18.png) no-repeat;
  background-size: 8px;
  background-position: right 10px center;
  display: flex;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul li a,
  #parachannel #company .top > div > ul li > div {
    flex-wrap: wrap;
    padding: 14px 20px 14px 5px;
    background-size: 6px;
    background-position: right 5px center;
  }
}
#parachannel #company .top > div > ul li a .date,
#parachannel #company .top > div > ul li > div .date {
  width: 210px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul li a .date,
  #parachannel #company .top > div > ul li > div .date {
    width: 140px;
  }
}
#parachannel #company .top > div > ul li a .name,
#parachannel #company .top > div > ul li > div .name {
  width: 140px;
}
#parachannel #company .top > div > ul li a .new,
#parachannel #company .top > div > ul li a .category,
#parachannel #company .top > div > ul li > div .new,
#parachannel #company .top > div > ul li > div .category {
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul li a .new,
  #parachannel #company .top > div > ul li a .category,
  #parachannel #company .top > div > ul li > div .new,
  #parachannel #company .top > div > ul li > div .category {
    font-size: 10px;
  }
}
#parachannel #company .top > div > ul li a .new,
#parachannel #company .top > div > ul li > div .new {
  width: 40px;
  padding: 4px 0;
  background-color: #df0056;
  color: #fff;
  left: 120px;
  font-size: 80%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul li a .new,
  #parachannel #company .top > div > ul li > div .new {
    left: 95px;
    padding: 5px 0;
  }
}
#parachannel #company .top > div > ul li a .category,
#parachannel #company .top > div > ul li > div .category {
  width: 50px;
  padding: 3px 0;
  border: 1px #ff4800 solid;
  color: #ff4800;
  left: 165px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul li a .category,
  #parachannel #company .top > div > ul li > div .category {
    width: 40px;
    padding: 4px 0;
    left: 125px;
  }
}
#parachannel #company .top > div > ul li a .category.type2,
#parachannel #company .top > div > ul li > div .category.type2 {
  border-color: #00a0e9;
  color: #00a0e9;
}
#parachannel #company .top > div > ul li a p,
#parachannel #company .top > div > ul li > div p {
  width: calc(100% - 210px);
  line-height: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul li a p,
  #parachannel #company .top > div > ul li > div p {
    width: calc(100% - 140px);
    line-height: 18px;
  }
}
#parachannel #company .top > div > ul li > div {
  background: none;
  align-items: center;
}
#parachannel #company .top > div > ul li.read p {
  color: #b3b3b3;
}
#parachannel #company .top > div > ul li.scout p:not(.date):not(.new) {
  color: #df0056;
}
#parachannel #company .top > div > ul.cont1.recommended p {
  width: 100%;
}
#parachannel #company .top > div > ul.cont1.news li > a {
  background-image: url(./img/lp/faq_icon01.png);
  background-size: 13px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont1.news li > a {
    background-size: 9px;
  }
}
#parachannel #company .top > div > ul.cont1.news li > a .new {
  line-height: 100%;
  top: 20px;
  transform: translateY(0);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont1.news li > a .new {
    top: 15px;
  }
}
#parachannel #company .top > div > ul.cont1.news li > div {
  width: 100%;
  padding: 0;
  display: block;
}
#parachannel #company .top > div > ul.cont1.news li > div > a {
  width: 100%;
  padding-top: 10px;
  background: none;
  line-height: 24px;
  text-decoration: underline;
  white-space: pre-wrap;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont1.news li > div > a {
    padding-top: 5px;
    line-height: 18px;
  }
}
#parachannel #company .top > div > ul.cont1.news li > div > p {
  width: 100%;
  padding: 10px 60px 21px 25px;
  line-height: 24px;
  white-space: pre;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont1.news li > div > p {
    padding: 5px 20px 14px 5px;
    line-height: 18px;
  }
}
#parachannel #company .top > div > ul.cont1.news li.active > a {
  background-image: url(./img/lp/faq_icon02.png);
}
#parachannel #company .top > div > ul.cont2 li a p:not(.name), #parachannel #company .top > div > ul.cont2 li > div p:not(.name) {
  width: calc(100% - 140px);
}
#parachannel #company .top > div > ul.cont3 li a .name,
#parachannel #company .top > div > ul.cont3 li a .date, #parachannel #company .top > div > ul.cont3 li > div .name,
#parachannel #company .top > div > ul.cont3 li > div .date {
  position: relative;
  left: 0;
  width: auto;
  margin-right: 20px;
}
#parachannel #company .top > div > ul.cont3 li a p:not(.name):not(.date):not(.category), #parachannel #company .top > div > ul.cont3 li > div p:not(.name):not(.date):not(.category) {
  width: calc(100% - 290px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont3 li a p:not(.name):not(.date):not(.category), #parachannel #company .top > div > ul.cont3 li > div p:not(.name):not(.date):not(.category) {
    width: calc(100% - 170px);
  }
}
#parachannel #company .top > div > ul.cont4 li a, #parachannel #company .top > div > ul.cont4 li > div {
  padding-left: 5px;
}
#parachannel #company .top > div > ul.cont4 li a .category, #parachannel #company .top > div > ul.cont4 li > div .category {
  width: 160px;
  position: static;
  transform: translateY(0);
  margin: -4px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont4 li a .category, #parachannel #company .top > div > ul.cont4 li > div .category {
    width: 130px;
  }
}
#parachannel #company .top > div > ul.cont4 li a .category.type1, #parachannel #company .top > div > ul.cont4 li > div .category.type1 {
  border-color: #c9bc16;
  color: #c9bc16;
}
#parachannel #company .top > div > ul.cont4 li a .category.type2, #parachannel #company .top > div > ul.cont4 li > div .category.type2 {
  border-color: #489ed9;
  color: #489ed9;
}
#parachannel #company .top > div > ul.cont4 li a .new, #parachannel #company .top > div > ul.cont4 li > div .new {
  left: 190px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont4 li a .new, #parachannel #company .top > div > ul.cont4 li > div .new {
    left: 145px;
  }
}
#parachannel #company .top > div > ul.cont4 li a p:not(.category):not(.new), #parachannel #company .top > div > ul.cont4 li > div p:not(.category):not(.new) {
  width: calc(100% - 160px);
  padding-left: 90px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > ul.cont4 li a p:not(.category):not(.new), #parachannel #company .top > div > ul.cont4 li > div p:not(.category):not(.new) {
    width: calc(100% - 130px);
    padding-left: 60px;
  }
}
#parachannel #company .top > div .btn_box {
  display: flex;
  justify-content: space-between;
}
#parachannel #company .top > div .btn_box a {
  width: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div .btn_box a {
    width: 20px;
  }
}
#parachannel #company .top > div .select_box {
  margin: -45px 0 20px auto !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div .select_box {
    margin: -30px 0 10px auto !important;
  }
}
#parachannel #company .top > div > div {
  margin-bottom: 75px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > div {
    margin-bottom: 50px;
  }
}
#parachannel #company .top > div > div:last-child {
  margin: 0;
}
#parachannel #company .top > div > div h3 {
  color: #606e84;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > div h3 {
    line-height: 24px;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}
#parachannel #company .top > div > div h3 span {
  width: 100px;
  padding: 3px 0;
  border: 1px #ff4800 solid;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  color: #ff4800;
  margin-left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > div h3 span {
    width: 80px;
    padding: 4px 0;
    font-size: 10px;
    line-height: 100%;
    margin-left: 10px;
  }
}
#parachannel #company .top > div > div > ul {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #company .top > div > div > ul li {
  width: 20%;
  border-right: 1px #b3b3b3 solid;
  text-align: center;
  margin-top: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .top > div > div > ul li:nth-child(-n+5) {
    margin-top: 0;
  }
  #parachannel #company .top > div > div > ul li:nth-child(5n+1) {
    border-left: 1px #b3b3b3 solid;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > div > ul li {
    width: 33.33%;
    margin-top: 20px;
  }
  #parachannel #company .top > div > div > ul li:nth-child(-n+3) {
    margin-top: 0;
  }
  #parachannel #company .top > div > div > ul li:nth-child(3n+1) {
    border-left: 1px #b3b3b3 solid;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .top > div > div > ul li p {
    font-size: 16px;
  }
}
#parachannel #company .top > div > div > ul li p:nth-child(2) {
  color: #606e84;
  margin-top: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > div > ul li p:nth-child(2) {
    margin-top: 5px;
  }
}
#parachannel #company .top > div > div > ul li p:nth-child(2) span {
  font-size: 50px;
  color: #00a0e9;
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top > div > div > ul li p:nth-child(2) span {
    font-size: 30px;
  }
}
#parachannel #company .top.mypage .category {
  position: relative;
  left: 0;
  top: 0;
  width: auto;
  margin-right: 10px;
  padding: 3px 10px;
  transform: translateY(0);
}
#parachannel #company .top.mypage .category + p {
  width: auto !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top.mypage .category + p {
    width: 100% !important;
    margin-top: 10px;
  }
}
#parachannel #company .top.mypage .category.traning {
  border-color: #39b54a;
  color: #39b54a;
}
#parachannel #company .top.mypage > div .btn {
  padding: 12px 0;
  border-radius: 30px;
  margin-top: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .top.mypage > div .btn {
    width: 215px;
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .top.mypage > div .btn {
    padding: 13px 0;
    margin-top: 25px;
  }
}
#parachannel #company .outline > div {
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div {
    margin-bottom: 30px;
  }
}
#parachannel #company .outline > div:last-child {
  margin: 0;
}
#parachannel #company .outline > div h3 {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 20px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div h3 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
#parachannel #company .outline > div h3 img {
  width: 20px;
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div h3 img {
    margin-right: 5px;
  }
}
#parachannel #company .outline > div h3 a {
  text-decoration: underline;
  position: absolute;
  top: 0;
  right: 0;
}
#parachannel #company .outline > div h3 span {
  padding: 4px 8px;
  background-color: #f00;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  margin-left: 10px;
}
#parachannel #company .outline > div h3 small {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
  font-weight: normal;
  font-size: 85%;
}
#parachannel #company .outline > div h3 .note {
  font-size: 12px;
  font-weight: normal;
  margin-left: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div h3 .note {
    font-size: 10px;
  }
}
#parachannel #company .outline > div .error_text {
  font-size: 12px;
  font-weight: bold;
  color: #f00;
  margin: 10px 0;
}
#parachannel #company .outline > div .member_cont {
  align-items: center;
  justify-content: flex-start !important;
}
#parachannel #company .outline > div .member_cont input {
  max-width: 100px;
  margin-right: 5px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div .disabled dt {
    width: 160px !important;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div .disabled dd {
    width: calc(100% - 160px) !important;
  }
}
#parachannel #company .outline > div .disabled dd select {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}
#parachannel #company .outline > div .disabled.results {
  padding-top: 20px;
  border: none;
}
#parachannel #company .outline > div .checkbox_cont {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #company .outline > div .checkbox_cont label {
  font-size: 12px;
  font-weight: normal;
  width: 48%;
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  margin-top: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div .checkbox_cont label:nth-child(-n+4) {
    margin: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div .checkbox_cont label {
    width: 100%;
    margin-top: 15px;
  }
  #parachannel #company .outline > div .checkbox_cont label:nth-child(-n+3) {
    margin: 0;
  }
}
#parachannel #company .outline > div .checkbox_cont label input {
  min-width: 15px !important;
  width: 15px !important;
  height: 15px !important;
  margin: 0 20px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div .checkbox_cont label input {
    margin-right: 5px;
  }
}
#parachannel #company .outline > div .select_cont {
  width: 100%;
}
#parachannel #company .outline > div .select_cont select {
  width: 100%;
  max-width: 400px;
  height: 35px;
  background: url(./img/company/icon04.png) no-repeat;
  background-size: 15px;
  background-position: right 10px center;
  cursor: pointer;
}
#parachannel #company .outline > div .select_cont label {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company .outline > div .select_cont.establishment_cont input {
  width: 100px;
  margin-right: 5px;
}
#parachannel #company .outline > div .select_cont.establishment_cont span {
  margin-right: 5px;
}
#parachannel #company .outline > div .select_cont.establishment_cont select {
  margin-right: 5px;
}
#parachannel #company .outline > div .select_cont.establishment_cont select {
  width: 100px;
}
#parachannel #company .outline > div.cont2 > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#parachannel #company .outline > div.cont2 > div button {
  width: 22.5%;
  padding: 30px 0 20px;
  background: none;
  border: 1px #b3b3b3 solid;
  font-weight: bold;
  line-height: 100%;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont2 > div button {
    width: 48.5%;
    margin-top: 10px;
  }
  #parachannel #company .outline > div.cont2 > div button:nth-child(-n+2) {
    margin: 0;
  }
}
#parachannel #company .outline > div.cont2 > div button img {
  width: 20px;
  margin: 0 auto 20px;
}
#parachannel #company .outline > div.cont2 > div > p {
  margin: 10px 0;
}
#parachannel #company .outline > div.cont2 > div > p a {
  text-decoration: underline;
  display: inline-block;
}
#parachannel #company .outline > div.cont2 .logo_edit button {
  padding: 0;
  border: none;
  cursor: auto;
}
#parachannel #company .outline > div.cont2 .logo_edit button img {
  width: 100%;
}
#parachannel #company .outline > div.cont2 .logo_edit button label {
  width: 80px;
  height: 80px;
  background: url(./img/company/icon03.svg) no-repeat;
  background-size: 30px;
  background-position: center;
  border: 1px #b3b3b3 solid;
  display: block;
  margin-top: 20px;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont2 .logo_edit button label {
    width: 60px;
    height: 60px;
    background-size: 20px;
  }
}
#parachannel #company .outline > div.cont2 .logo_edit button label input {
  display: none;
}
#parachannel #company .outline > div.cont2 .slider_edit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
#parachannel #company .outline > div.cont2 .slider_edit li {
  width: 32%;
  margin: 2% 2% 0 0;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div.cont2 .slider_edit li:nth-child(-n+3) {
    margin-top: 0;
  }
  #parachannel #company .outline > div.cont2 .slider_edit li:nth-child(3n) {
    margin-right: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont2 .slider_edit li {
    width: 49%;
  }
  #parachannel #company .outline > div.cont2 .slider_edit li:nth-child(-n+2) {
    margin-top: 0;
  }
  #parachannel #company .outline > div.cont2 .slider_edit li:nth-child(2n) {
    margin-right: 0;
  }
}
#parachannel #company .outline > div.cont2 .slider_edit li p {
  width: 100%;
  height: 180px;
  border: 1px #b3b3b3 solid;
}
#parachannel #company .outline > div.cont2 .slider_edit li p label {
  display: block;
  width: 100%;
  height: 100%;
  padding: 18% 5% 5%;
  cursor: pointer;
}
#parachannel #company .outline > div.cont2 .slider_edit li p label input[type=file] {
  display: none;
}
#parachannel #company .outline > div.cont2 .slider_edit li p label .add_icon {
  width: 50px;
  height: 50px;
  margin: 0 auto 30px;
}
#parachannel #company .outline > div.cont2 .slider_edit li p span {
  display: block;
  font-size: 15px;
  text-align: center;
  line-height: 1.2;
}
#parachannel #company .outline > div.cont2 .slider_edit li .trash {
  position: absolute;
  right: 3%;
  top: 3%;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
#parachannel #company .outline > div.cont2 .slider_edit li img:not(.trash) {
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont2 .slider_edit li img:not(.trash) {
    height: 150px;
  }
}
#parachannel #company .outline > div.cont2 .slider_edit li.add_img {
  background-color: #f7f7f7;
  cursor: pointer;
}
#parachannel #company .outline > div.cont2 .slider_edit li.add_img p {
  padding: 18% 5% 5%;
  border: none;
}
#parachannel #company .outline > div.cont2 .slider_edit li.add_img .add_icon {
  width: 50px;
  height: 50px;
  margin: 0 auto 30px;
}
#parachannel #company .outline > div.cont2 .small {
  max-width: 400px;
}
#parachannel #company .outline > div.cont3 .select {
  margin-bottom: 30px;
  padding: 10px;
  background-color: #ecfbff;
}
#parachannel #company .outline > div.cont3 .select li {
  padding: 5px;
  font-size: 110%;
}
#parachannel #company .outline > div.cont3 .select li::before {
  content: "・";
  display: inline-block;
  font-size: 100%;
}
#parachannel #company .outline > div.cont3 > div {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #company .outline > div.cont3 > div label {
  width: 21%;
  line-height: 16px;
  display: flex;
  align-content: center;
  padding-right: 10px;
  cursor: pointer;
  margin-top: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div.cont3 > div label:nth-child(-n+4) {
    margin: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont3 > div label {
    width: 33.33%;
    margin-top: 15px;
  }
  #parachannel #company .outline > div.cont3 > div label:nth-child(-n+3) {
    margin: 0;
  }
}
#parachannel #company .outline > div.cont3 > div label input {
  min-width: 15px;
  width: 15px;
  height: 15px;
  margin: 0 10px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont3 > div label input {
    margin-right: 5px;
  }
}
#parachannel #company .outline > div.cont3 button {
  width: 120px;
  height: 35px;
  background-color: #00a0e9;
  border-radius: 20px;
  border: none;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont3 button {
    width: 100px;
    height: 30px;
    font-size: 12px;
    display: block;
    margin: 10px 0 0 auto;
  }
}
#parachannel #company .outline > div.cont6 .message_ttl {
  display: inline-flex;
  align-self: flex-start;
  flex-wrap: wrap;
}
#parachannel #company .outline > div.cont6 .message_ttl span {
  font-size: 14px;
  color: #f00;
  margin: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 .message_ttl span {
    font-size: 12px;
  }
}
#parachannel #company .outline > div.cont6 .message_cont textarea {
  margin-bottom: 10px;
}
#parachannel #company .outline > div.cont6 .message_cont button {
  width: 100%;
  padding: 30px 0 20px;
  background: none;
  border: 1px #b3b3b3 solid;
  font-weight: bold;
  line-height: 100%;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 .message_cont button {
    margin-top: 10px;
  }
  #parachannel #company .outline > div.cont6 .message_cont button:nth-child(-n+2) {
    margin: 0;
  }
}
#parachannel #company .outline > div.cont6 .message_cont button img {
  width: 20px;
  margin: 0 auto 20px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div.cont6 .name_cont {
    display: flex;
  }
}
#parachannel #company .outline > div.cont6 .name_cont label {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 .name_cont label {
    flex-wrap: wrap;
  }
}
#parachannel #company .outline > div.cont6 .name_cont label:last-child {
  margin: 0 0 0 30px;
}
#parachannel #company .outline > div.cont6 .name_cont label span {
  width: auto;
  font-size: 12px;
  font-weight: normal;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div.cont6 .name_cont label span {
    margin-right: 10px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 .name_cont label span {
    width: 100px;
    margin-bottom: 5px;
  }
  #parachannel #company .outline > div.cont6 .name_cont label span:last-of-type {
    margin-bottom: 0;
  }
}
#parachannel #company .outline > div.cont6 .name_cont label input {
  margin-right: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 .name_cont label input {
    width: calc(100% - 100px) !important;
    margin: 0 0 5px;
  }
  #parachannel #company .outline > div.cont6 .name_cont label input:last-child {
    margin: 0;
  }
}
#parachannel #company .outline > div.cont6 dl {
  padding: 40px 0;
  border-top: 1px #b3b3b3 solid;
  border-bottom: 1px #b3b3b3 solid;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .outline > div.cont6 dl {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl {
    padding: 20px 0;
  }
}
#parachannel #company .outline > div.cont6 dl dt,
#parachannel #company .outline > div.cont6 dl dd {
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl dt,
  #parachannel #company .outline > div.cont6 dl dd {
    margin-bottom: 10px;
  }
}
#parachannel #company .outline > div.cont6 dl dt:last-of-type,
#parachannel #company .outline > div.cont6 dl dd:last-of-type {
  margin: 0;
}
#parachannel #company .outline > div.cont6 dl dt {
  width: 100px;
  line-height: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl dt {
    width: 70px;
    line-height: 16px;
  }
}
#parachannel #company .outline > div.cont6 dl dd {
  width: calc(100% - 100px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl dd {
    width: calc(100% - 70px);
  }
}
#parachannel #company .outline > div.cont6 dl dd input {
  width: 100%;
  max-width: 400px;
  height: 35px;
}
#parachannel #company .outline > div.cont6 dl dd select {
  width: 70px;
  height: 35px;
  background: url(./img/company/icon04.png) no-repeat;
  background-size: 15px;
  background-position: right 10px center;
  cursor: pointer;
}
#parachannel #company .outline > div.cont6 dl dd label {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company .outline > div.cont6 dl dd:nth-of-type(1) input {
  width: 165px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl dd:nth-of-type(1) input {
    width: 125px;
  }
}
#parachannel #company .outline > div.cont6 dl dd:nth-of-type(1) .btn {
  width: 155px;
  padding: 10px 0;
  font-size: 16px;
  margin: 0 0 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl dd:nth-of-type(1) .btn {
    width: 125px;
    padding: 12px 0;
    font-size: 11px;
    margin-left: 10px;
  }
}
#parachannel #company .outline > div.cont6 dl dd:nth-of-type(4) input {
  margin-right: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl dd:nth-of-type(4) input {
    margin-right: 10px;
  }
}
#parachannel #company .outline > div.cont6 dl dd:nth-of-type(4) select {
  margin: 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .outline > div.cont6 dl dd:nth-of-type(4) select {
    margin: 0 10px;
  }
}
#parachannel #company .outline > div.cont6 dl .checkbox_cont label {
  line-height: 16px;
  margin: 5px 0;
}
#parachannel #company #add_item {
  display: block;
  margin-top: 30px;
}
#parachannel #company #add_item .add_item_box {
  margin-bottom: 30px;
  border: solid 1px #f2f2f2;
  border-top: none;
}
#parachannel #company #add_item .add_item_box h2 {
  padding: 15px;
  font-weight: bold;
  background-color: #f2f2f2;
  border-radius: 5px 5px 0 0;
}
#parachannel #company #add_item .add_item_box ul {
  padding: 15px;
}
#parachannel #company #add_item .add_item_box li {
  margin: 0 auto 10px;
}
#parachannel #company #add_item .add_item_box li input {
  border-color: #efefef;
}
#parachannel #company #add_item .add_item_box p {
  text-align: center;
  margin: 5px auto;
  padding: 0 15px 15px;
  font-weight: bold;
}
#parachannel #company #add_item .add_item_box p button {
  display: inline-block;
  margin-left: 10px;
  padding: 10px 30px;
  background-color: #fff;
  border: solid 1px #00a0e9;
  color: #00a0e9;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
#parachannel #company #add_item .add_item_box dl {
  width: 95%;
  margin: 0 auto;
  padding: 2%;
  border-top: solid 1px #efefef;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #add_item .add_item_box dl {
    display: flex;
    justify-content: space-between;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #add_item .add_item_box dl {
    padding: 2% 2% 5%;
  }
}
#parachannel #company #add_item .add_item_box dt {
  width: 20%;
  line-height: 40px;
  color: #888;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #add_item .add_item_box dt {
    width: 100%;
  }
}
#parachannel #company #add_item .add_item_box dd {
  width: 80%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #add_item .add_item_box dd {
    width: 100%;
  }
}
#parachannel #company #add_item .add_item_box dd label {
  position: relative;
  margin: 1% 0;
  padding-left: 25px;
  line-height: 1.5;
}
#parachannel #company #add_item .add_item_box dd label input[type=checkbox] {
  position: absolute;
  left: 0;
  display: inline-block;
  vertical-align: middle;
}
#parachannel #company #add_item .add_item_box dd._flex {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #company #add_item .add_item_box dd._flex label {
  margin: 1% 0 1% 1%;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #add_item .add_item_box dd._flex label {
    width: 24.25%;
  }
  #parachannel #company #add_item .add_item_box dd._flex label:nth-child(4n+1) {
    margin-left: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #add_item .add_item_box dd._flex label {
    width: 32.6666666667%;
  }
  #parachannel #company #add_item .add_item_box dd._flex label:nth-child(3n+1) {
    margin-left: 0;
  }
}
#parachannel #company #add_item .add_item_box dl.no_flex {
  display: block;
}
#parachannel #company #add_item .add_item_box dl.no_flex dd {
  width: 100%;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #add_item .add_item_box dl.no_flex dd {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
#parachannel #company #add_item .add_item_box dl.no_flex dd label {
  display: inline-block;
  width: 47%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #add_item .add_item_box dl.no_flex dd label {
    width: 100%;
  }
}
#parachannel #company .apply .edit_box form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 60px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box form {
    margin-bottom: 30px;
  }
}
#parachannel #company .apply .edit_box form select {
  width: 155px;
  height: 35px;
  background: url(./img/company/icon04.png) no-repeat;
  background-size: 15px;
  background-position: right 10px center;
  border: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box form select {
    width: 48%;
  }
}
#parachannel #company .apply .edit_box form select:nth-child(1) {
  width: calc(100% - 340px);
  background-image: url(./img/company/icon11.png);
  background-color: #d9ecfa;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box form select:nth-child(1) {
    width: 100%;
    margin-bottom: 15px;
  }
}
#parachannel #company .apply .edit_box form .btn {
  width: 155px;
  padding: 10px 0;
  margin: 25px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box form .btn {
    width: 48%;
    margin-top: 15px;
  }
}
#parachannel #company .apply .edit_box form .btn.gray {
  padding: 11px 0;
  font-size: 14px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .apply .edit_box form .btn.gray {
    width: 200px;
    margin: 25px auto 0 15px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box form .btn.gray {
    font-size: 12px;
  }
}
#parachannel #company .apply .edit_box .note {
  text-align: right;
  color: #f00;
  margin-bottom: 10px;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .img_box {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .img_box {
    align-items: center;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .img_box {
    float: none !important;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box {
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box {
    display: block;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 {
  color: #231815 !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin: 0 0 20px !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 {
    width: 100% !important;
    float: none !important;
    margin-bottom: 0 !important;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 ._flex {
  display: flex;
  align-items: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 ._flex:first-child {
    margin-right: 20px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 ._flex {
    width: 100%;
    flex-wrap: wrap;
  }
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 ._flex:nth-of-type(2) {
    margin-top: 10px;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 span {
  line-height: 1.4;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .date {
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .category {
  margin-left: 10px;
  /*横向きの際に適用*/
  padding: 3px 10px;
  border: 1px solid;
  border-radius: 5px;
  font-size: 12px;
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .category {
    margin-left: 0;
    margin: 0 10px 5px 0;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .category:first-child {
  margin-left: 0;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .category.type1 {
  border-color: #ff4800;
  color: #ff4800;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .category.type2 {
  border-color: #8d8d8d;
  color: #8d8d8d;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .category.type3 {
  border-color: #e44e66;
  color: #e44e66;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h3 .category.type4 {
  border-color: #f39800;
  color: #f39800;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box h4 {
  color: #00a0e9;
  display: none;
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box select {
  display: block;
  width: 135px;
  height: 30px;
  margin: 10px 0 0 auto;
  background: url(./img/company/icon04.png) no-repeat;
  background-size: 10px;
  background-position: right 10px center;
  border-radius: 5px;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box select {
    position: absolute;
    right: 0;
    top: 50%;
    width: 155px;
    transform: translateY(-50%);
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box select {
    margin-left: auto;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont1 .text_box p {
  margin-top: 5px;
}
#parachannel #company .apply .edit_box .list_box li > div .cont2 {
  padding: 0 !important;
  align-items: stretch !important;
  align-content: stretch !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont2 {
    height: auto !important;
    display: block !important;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont2 p {
  width: 50%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0 !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont2 p {
    width: 100%;
    height: 45px;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont2 p:nth-child(1) {
  border-right: 1px #b3b3b3 solid;
  justify-content: center;
  color: #00a0e9 !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .apply .edit_box .list_box li > div .cont2 p:nth-child(1) {
    border: none;
    border-bottom: 1px #b3b3b3 solid;
  }
}
#parachannel #company .apply .edit_box .list_box li > div .cont2 p:nth-child(2) {
  justify-content: space-between;
}
#parachannel #company .apply .edit_box .list_box li > div .cont2 p:nth-child(2) img {
  width: 20px;
}
#parachannel #company .message {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message {
    display: block;
  }
}
#parachannel #company .message .ttl_box {
  width: 100%;
  border-bottom: 1px #b3b3b3 solid;
  justify-content: flex-start !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .ttl_box {
    flex-direction: row !important;
  }
}
#parachannel #company .message .ttl_box .ttl {
  padding: 15px;
  margin-bottom: -1px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .ttl_box .ttl {
    width: auto !important;
    padding: 10px;
    margin: 0 0 -1px !important;
  }
}
#parachannel #company .message .ttl_box .ttl.active {
  background-color: #fff;
  border-radius: 5px 5px 0 0;
  box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.3);
  color: #00a0e9;
  position: relative;
}
#parachannel #company .message .ttl_box .ttl.active:after {
  content: "";
  width: calc(100% - 30px);
  height: 2px;
  background-color: #00a0e9;
  position: absolute;
  bottom: 10px;
  left: 15px;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .ttl_box .ttl.active:after {
    width: calc(100% - 20px);
    bottom: 5px;
    left: 10px;
  }
}
#parachannel #company .message .ttl_box .ttl.new_icon {
  position: relative;
}
#parachannel #company .message .ttl_box .ttl.new_icon:before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: #f00;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -5px;
}
#parachannel #company .message .cont_box {
  width: calc(100% - 185px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box {
    width: 100%;
    margin-bottom: 50px;
  }
}
#parachannel #company .message .cont_box .message_box .view_box {
  max-height: 350px;
  padding-right: 10px;
  overflow-y: scroll;
}
#parachannel #company .message .cont_box .message_box .view_box::-webkit-scrollbar {
  width: 5px;
}
#parachannel #company .message .cont_box .message_box .view_box::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}
#parachannel #company .message .cont_box .message_box .view_box::-webkit-scrollbar-thumb {
  background-color: #00a0e9;
}
#parachannel #company .message .cont_box .message_box .view_box > div {
  width: 66%;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .view_box > div {
    width: 90%;
  }
}
#parachannel #company .message .cont_box .message_box .view_box > div:last-child {
  margin-bottom: 0;
}
#parachannel #company .message .cont_box .message_box .view_box > div > p {
  margin-bottom: 5px;
}
#parachannel #company .message .cont_box .message_box .view_box > div > div {
  padding: 20px;
  line-height: 20px;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .view_box > div > div {
    padding: 15px;
  }
}
#parachannel #company .message .cont_box .message_box .view_box > div > div:last-child {
  margin: 0;
}
#parachannel #company .message .cont_box .message_box .view_box > div > div form {
  margin-top: 15px;
}
#parachannel #company .message .cont_box .message_box .view_box > div > div form label {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company .message .cont_box .message_box .view_box > div > div form label input {
  width: 15px;
  height: 15px;
  margin: 0 10px 0 0;
}
#parachannel #company .message .cont_box .message_box .view_box > div > div form .btn_box {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
#parachannel #company .message .cont_box .message_box .view_box > div > div form .btn_box .btn {
  width: 49%;
  height: 50px;
  padding: 0;
  background-color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: 12px;
  line-height: 16px;
  color: #b3b3b3;
  margin: 0;
}
#parachannel #company .message .cont_box .message_box .view_box > div > div form .btn_box .btn:hover {
  color: #00a0e9;
}
#parachannel #company .message .cont_box .message_box .view_box > div.left > div {
  background-color: #f2f2f2;
}
#parachannel #company .message .cont_box .message_box .view_box > div.left > div.auto_message {
  background-color: #fceded;
}
#parachannel #company .message .cont_box .message_box .view_box > div.right {
  margin-left: auto;
}
#parachannel #company .message .cont_box .message_box .view_box > div.right > div {
  background-color: #dbffff;
}
#parachannel #company .message .cont_box .message_box .input_box {
  padding: 0;
  border: none;
  margin: 100px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .input_box {
    margin-top: 50px;
  }
}
#parachannel #company .message .cont_box .message_box .input_box ul {
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .input_box ul {
    margin-bottom: 20px;
  }
}
#parachannel #company .message .cont_box .message_box .input_box ul li {
  width: 110px;
  padding: 11px 0;
  border: 1px #fff solid;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  text-align: center;
  color: #595757;
  margin-bottom: -1px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .input_box ul li {
    width: 80px;
    padding: 8px 0;
  }
}
#parachannel #company .message .cont_box .message_box .input_box ul li.active {
  background-color: #fff;
  border-color: #b3b3b3;
}
#parachannel #company .message .cont_box .message_box .input_box form {
  width: 100%;
}
#parachannel #company .message .cont_box .message_box .input_box form textarea {
  background-color: #f2f2f2;
  border: none;
}
#parachannel #company .message .cont_box .message_box .input_box form .btn {
  width: 155px;
  padding: 15px 0;
  margin: 20px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .input_box form .btn {
    width: 120px;
    padding: 13px 0;
    margin-top: 15px;
  }
}
#parachannel #company .message .cont_box .message_box .input_box > div p {
  line-height: 20px;
  color: #b3b3b3;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .input_box > div p {
    margin-bottom: 15px;
  }
}
#parachannel #company .message .cont_box .message_box .input_box > div .btn {
  width: 400px;
  margin: 0 0 15px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .cont_box .message_box .input_box > div .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}
#parachannel #company .message .cont_box .message_box .input_box > div .btn:last-child {
  margin: 0;
}
#parachannel #company .message .cont_box .message_box .input_box > div .btn img {
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  margin: auto;
}
#parachannel #company .message .sidebar {
  width: 160px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message .sidebar {
    width: 100%;
  }
}
#parachannel #company .message .sidebar h4 {
  padding-bottom: 15px;
  border-bottom: 1px #b3b3b3 solid;
  font-size: 14px;
  text-align: center;
  color: #595757;
  margin-bottom: 20px;
}
#parachannel #company .message .sidebar h4.memo {
  padding: 0 5px 10px;
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company .message .sidebar h4.memo img {
  width: 25px;
  margin-left: 10px;
}
#parachannel #company .message .sidebar .btn_box1 {
  border: 1px #b3b3b3 solid;
  margin-bottom: 30px;
}
#parachannel #company .message .sidebar .btn_box1 a {
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px #b3b3b3 solid;
  text-align: center;
  color: #b3b3b3;
}
#parachannel #company .message .sidebar .btn_box1 a:last-child {
  border: none;
}
#parachannel #company .message .sidebar .btn_box1 a.active {
  background-color: #b3b3b3;
  color: #fff;
}
#parachannel #company .message .sidebar .btn_box2 a {
  width: 100%;
  padding: 8px 0;
  border: 1px #b3b3b3 solid;
  text-align: center;
  color: #00a0e9;
  margin-bottom: 10px;
}
#parachannel #company .message .sidebar .btn_box2 a:last-child {
  margin: 0;
}
#parachannel #company .message .sidebar ul {
  margin-bottom: 30px;
}
#parachannel #company .message .sidebar ul li {
  padding-bottom: 15px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
#parachannel #company .message .sidebar ul li:last-child {
  margin: 0;
}
#parachannel #company .message .sidebar ul li p:nth-of-type(2) {
  margin-left: 20px;
}
#parachannel #company .message .sidebar ul li p:nth-of-type(3) {
  width: 100%;
  font-weight: normal;
  margin-top: 10px;
}
#parachannel #company .message.cont1 .cont_box {
  position: relative;
}
#parachannel #company .message.cont1 .cont_box .tooltip_box {
  top: -30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message.cont1 .cont_box .tooltip_box {
    margin: 10px 50% 5px 0;
  }
}
#parachannel #company .message.cont1 .cont_box .detail_box > div {
  margin-bottom: 50px;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div:last-child {
  margin: 0;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div h4 {
  padding: 12px 15px;
  background-color: #f2f2f2;
  border-left: 4px #606e84 solid;
  font-size: 16px;
  color: #606e84;
  margin-bottom: 20px;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-wrap: wrap;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl {
    display: block;
  }
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl dt,
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl dd {
    width: 100% !important;
  }
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl dt {
    border: none !important;
  }
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl dd span {
  font-size: 12px;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dt,
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dd {
  padding: 13px 0;
  border-bottom: 1px #e6e6e6 solid;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dt,
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dd {
    padding: 0 15px;
  }
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dt:last-of-type,
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dd:last-of-type {
  border: none;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dt {
  width: 250px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dt {
    padding-left: 40px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dt {
    margin-bottom: 10px;
  }
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dd {
  width: calc(100% - 250px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dd {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type1 dd:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 {
  border: 1px #adadad solid;
  border-radius: 5px;
  overflow: hidden;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dt,
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dd {
  padding: 18px 20px;
  border-bottom: 1px #adadad solid;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dt,
  #parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dd {
    padding: 13px 15px;
  }
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dt:last-of-type,
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dd:last-of-type {
  border: none;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dt {
  width: 200px;
  background-color: #f2f2f2;
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dd {
  word-wrap: break-word;
  width: calc(100% - 200px);
}
#parachannel #company .message.cont1 .cont_box .detail_box > div dl.type2 dd span {
  color: #ccc;
}
#parachannel #company .message .name_box .note {
  color: #f00;
}
#parachannel #company .setting {
  font-weight: bold;
}
#parachannel #company .setting > div {
  margin-bottom: 75px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div {
    margin-bottom: 50px;
  }
}
#parachannel #company .setting > div h3 {
  padding-bottom: 20px;
  border-bottom: 1px #b3b3b3 solid;
  font-size: 16px;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div h3 {
    padding-bottom: 10px;
    font-size: 14px;
    margin-bottom: 15px;
  }
}
#parachannel #company .setting > div.cont1 > div > div {
  border: 1px #b3b3b3 solid;
}
#parachannel #company .setting > div.cont1 > div > div dl {
  display: flex;
  border-bottom: 1px #b3b3b3 solid;
}
#parachannel #company .setting > div.cont1 > div > div dl:last-of-type {
  border: none;
}
#parachannel #company .setting > div.cont1 > div > div dl dt,
#parachannel #company .setting > div.cont1 > div > div dl dd {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  font-size: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div.cont1 > div > div dl dt,
  #parachannel #company .setting > div.cont1 > div > div dl dd {
    padding: 10px;
    font-size: 12px;
    line-height: 20px;
    word-wrap: break-word;
  }
}
#parachannel #company .setting > div.cont1 > div > div dl dt {
  width: 40%;
  border-right: 1px #b3b3b3 solid;
}
#parachannel #company .setting > div.cont1 > div > div dl dd {
  justify-content: space-between;
  width: 60%;
}
#parachannel #company .setting > div.cont1 > div > div dl dd > .btn {
  padding: 0 10px;
  font-size: 14px;
  margin: unset;
}
#parachannel #company .setting > div.cont1 > div > div dl:nth-child(1) {
  text-align: center;
}
#parachannel #company .setting > div.cont1 > div > div dl:nth-child(1) dt,
#parachannel #company .setting > div.cont1 > div > div dl:nth-child(1) dd {
  background-color: #b3b3b3;
  border-color: #fff;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div.cont1 > div > div dl:nth-child(1) dt,
  #parachannel #company .setting > div.cont1 > div > div dl:nth-child(1) dd {
    font-size: 14px;
  }
}
#parachannel #company .setting > div.cont1 > div a {
  width: 80px;
  padding: 24px 0;
  border: 1px #b3b3b3 solid;
  margin-top: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div.cont1 > div a {
    width: 60px;
    padding: 19px 0;
    margin-top: 20px;
  }
}
#parachannel #company .setting > div.cont1 > div a img {
  width: 30px;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div.cont1 > div a img {
    width: 20px;
  }
}
#parachannel #company .setting > div.cont1 > div .note {
  margin-top: 5px;
}
#parachannel #company .setting > div.cont2 img {
  width: 100%;
  max-width: 600px;
}
#parachannel #company .setting > div.cont3 p {
  color: #b3b3b3;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .setting > div.cont3 p {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div.cont3 p {
    margin-bottom: 15px;
  }
}
#parachannel #company .setting > div.cont3 > div {
  display: flex;
}
#parachannel #company .setting > div.cont3 > div .btn {
  width: 140px;
  background-color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  color: #b3b3b3;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .setting > div.cont3 > div .btn {
    width: 100px;
    padding: 13px 0;
  }
}
#parachannel #company .setting > div.cont3 > div .btn.active {
  background-color: #00b900;
  color: #fff;
}
#parachannel #company .setting > a {
  text-decoration: underline;
  text-align: right;
  color: #b3b3b3;
}
#parachannel #company .step_box {
  position: relative;
  margin-bottom: 55px;
  /*横向きの際に適用*/
}
#parachannel #company .step_box::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 2px;
  background-color: #00a0e9;
  transform: translate(-50%, -50%);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .step_box::before {
    width: 90%;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .step_box {
    margin-bottom: 30px;
  }
}
#parachannel #company .step_box ul {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 1;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .step_box ul {
    justify-content: space-between;
  }
}
#parachannel #company .step_box li {
  height: 60px;
  padding: 0 40px;
  line-height: 60px;
  background-color: #c9f3ff;
  text-align: center;
  margin: 0 20px;
  font-size: 130%;
  border-radius: 30px;
  font-weight: bold;
  position: relative;
  /*横向きの際に適用*/
}
#parachannel #company .step_box li:after {
  font-size: 14px;
  line-height: 100%;
  position: absolute;
  top: -20px;
  left: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .step_box li:after {
    font-size: 12px;
    top: -15px;
    left: 0;
  }
}
#parachannel #company .step_box li.active {
  color: #fff;
  background-color: #00a0e9;
}
#parachannel #company .step_box li.active:after {
  color: #00a0e9;
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .step_box li {
    height: 36px;
    line-height: 36px;
    margin: 0;
    padding: 0 15px;
    font-size: 100%;
  }
}
#parachannel #company .step_box li:nth-child(1):after {
  content: "ステップ1";
}
#parachannel #company .step_box li:nth-child(2):after {
  content: "ステップ2";
}
#parachannel #company .step_box li:nth-child(3):after {
  content: "ステップ3";
}
#parachannel #company .modal_box .modal_inner .recruitment_inner {
  margin: 30px;
}
#parachannel #company .modal_box .modal_inner .recruitment_inner .img_box {
  background-color: #efefef;
}
#parachannel #company p.error_text {
  font-size: 12px;
  color: #f00;
  margin-top: 10px;
}
#parachannel #company p.error_text.nomargin {
  margin: unset;
}
#parachannel #company.column .columns .list_box .cont1 .img_box {
  width: 140px !important;
  padding-bottom: 105px !important;
}
#parachannel #company.column .columns .list_box .cont2 .date {
  display: flex;
  margin-top: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company.column .columns .list_box .cont2 .date {
    margin-bottom: 10px;
  }
  #parachannel #company.column .columns .list_box .cont2 .date p:last-child {
    margin-right: 0 !important;
  }
}
#parachannel #company.column .columns .list_box .cont2 .view {
  margin-right: auto !important;
}
#parachannel #company.column .columns .list_box .cont2 .view img {
  margin-right: 6px;
  width: 20px;
  height: 13px;
  display: inline-block;
}
#parachannel #company .medias .list_box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
#parachannel #company .medias .list_box li {
  position: relative;
  margin-right: 16px;
  width: 140px;
  height: 140px;
  border: 1px solid #b3b3b3;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .medias .list_box li {
    width: 28vw;
    height: 28vw;
  }
}
#parachannel #company .medias .list_box li > .thumb {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
#parachannel #company .medias .list_box li span {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5px;
  right: 5px;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 1000px;
}
#parachannel #company .medias .list_box li span img {
  width: 12px;
}
#parachannel #company .medias .list_box li.using::before {
  content: "使用中";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  color: #00a0e9;
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  box-sizing: border-box;
}
#parachannel #company .icatch_modal .medias {
  margin-bottom: 0;
  padding: 20px 20px 0;
  text-align: center;
}
#parachannel #company .icatch_modal .medias .list_box {
  margin-bottom: 0;
}
#parachannel #company .icatch_modal .medias .list_box li {
  /*横向きの際に適用*/
}
#parachannel #company .icatch_modal .medias .list_box li.active {
  border: 4px solid #00a0e9;
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .icatch_modal .medias .list_box li {
    width: 23vw;
    height: 23vw;
  }
}
#parachannel #company .icatch_modal .medias button {
  margin: 20px auto;
  border-radius: 5px;
  background-color: #00a0e9;
  font-size: 14px !important;
  padding: 0.5em 2em;
  color: #fff !important;
  cursor: pointer;
  border: 0;
}
#parachannel #company .pagination_common {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}
#parachannel #company .pagination_common li {
  margin: 0 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .pagination_common li {
    margin: 3px 5px;
  }
}
#parachannel #company .pagination_common li a {
  font-size: 14px;
  color: #00a0e9;
}
#parachannel #company .pagination_common li a span {
  width: 30px;
  padding: 8px 0;
  background-color: #c8c8c8;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  display: block;
}
#parachannel #company .pagination_common li.prev {
  margin: 0 10px 0 0;
}
#parachannel #company .pagination_common li.next {
  margin: 0 0 0 10px;
}
#parachannel #company .pagination_common li.active span {
  background-color: #00a0e9;
}
#parachannel #company .pagination_common.small {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .pagination_common.small {
    justify-content: flex-end;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .pagination_common.small {
    margin-top: 20px;
  }
}
#parachannel #company .pagination_common.small li a {
  font-size: 12px;
}
#parachannel #company .pagination_common.small li a span {
  width: 26px;
  padding: 7px 0;
}

#parachannel .column_preview .btn.type3.preview {
  background-color: #ea5514 !important;
  margin: 0 0 20px auto !important;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .column_preview .btn.type3.preview {
    width: 100% !important;
  }
}
#parachannel .column_preview .column_article {
  padding: 20px;
}

#parachannel .column_article h2 {
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  line-height: 1.6;
  margin-bottom: 30px;
}
#parachannel .column_article .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
#parachannel .column_article .info .view {
  display: flex;
  align-items: center;
}
#parachannel .column_article .info .view img {
  margin-right: 10px;
  width: 36px;
  height: 36px;
}
#parachannel .column_article .sns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}
#parachannel .column_article .sns li {
  width: 30px;
  height: 30px;
  margin-left: 12px;
}
#parachannel .column_article .article {
  line-height: 1.8;
  font-size: 16px;
}
#parachannel .column_article .article h3 {
  margin-bottom: 30px;
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-weight: bold;
  color: #00a0e9;
  font-size: 18px !important;
  border-bottom: 0 !important;
}
#parachannel .column_article .article p {
  margin-bottom: 30px;
}
#parachannel .column_article .article img {
  width: 100% !important;
  margin-bottom: 30px;
  height: auto !important;
}
#parachannel .column_article .article h1 {
  display: block;
  font-size: 2em;
  -webkit-margin-before: 0.67em;
          margin-block-start: 0.67em;
  -webkit-margin-after: 0.67em;
          margin-block-end: 0.67em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
}
#parachannel .column_article .article h2 {
  display: block;
  font-size: 1.5em;
  -webkit-margin-before: 0.83em;
          margin-block-start: 0.83em;
  -webkit-margin-after: 0.83em;
          margin-block-end: 0.83em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
  text-align: left;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif;
}
#parachannel .column_article .article h3 {
  display: block;
  font-size: 1.17em;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
  text-align: left;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif;
  color: #231815;
}
#parachannel .column_article .article strong {
  font-weight: bold;
}
#parachannel .column_article .article blockquote {
  display: block;
  margin-left: 40px;
}
#parachannel .column_article .article a {
  display: inline;
  color: #00a0e9;
  text-decoration: underline;
}

#parachannel .column_list h3 {
  font-size: 18px !important;
  font-weight: bold !important;
  text-align: center !important;
  border: none !important;
  justify-content: center !important;
}
#parachannel .column_list .btn.type3.back {
  background-color: #00a0e9 !important;
}
#parachannel .column_list ul {
  border-top: 2px solid #b3b3b3;
  margin-bottom: 30px;
}
#parachannel .column_list ul li {
  border-bottom: 2px solid #b3b3b3;
}
#parachannel .column_list ul li a {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
#parachannel .column_list ul li a .img_box {
  margin-right: 20px;
  width: 140px !important;
  min-width: 140px;
  padding-bottom: 105px !important;
}
#parachannel .column_list ul li a .img_box img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
#parachannel .column_list ul li a .txt_box {
  padding: 10px 0;
  width: 100% !important;
}
#parachannel .column_list ul li a .txt_box .date {
  margin-bottom: 14px;
  color: #595757;
}
#parachannel .column_list ul li a .txt_box .column_ttl {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  overflow-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .column_list ul li a .txt_box .column_ttl {
    font-size: 14px;
  }
}

#parachannel .column_editor .editor {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#parachannel .column_editor .editor .editor_box {
  width: calc(100% - 300px);
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .column_editor .editor .editor_box {
    width: 100%;
  }
}
#parachannel .column_editor .editor .editor_box > form {
  margin-bottom: 30px;
}
#parachannel .column_editor .editor .editor_box > form input {
  font-size: 18px;
  border: 1px solid #d1d1d1;
}
#parachannel .column_editor .editor .setting_box {
  width: 260px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .column_editor .editor .setting_box {
    width: 100%;
  }
}
#parachannel .column_editor .editor .setting_box a {
  cursor: pointer;
}
#parachannel .column_editor .editor .setting_box > div {
  border: 1px solid #d1d1d1;
  margin-bottom: 30px;
}
#parachannel .column_editor .editor .setting_box > div p {
  padding: 0.75em 1em;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #d1d1d1;
}
#parachannel .column_editor .editor .setting_box > div.icatch a {
  display: inline-block;
  text-decoration: underline;
  color: #00a0e9;
}
#parachannel .column_editor .editor .setting_box > div .cont {
  padding: 1em;
}
#parachannel .column_editor .editor .setting_box > div .cont > img {
  margin-bottom: 8px;
}
#parachannel .column_editor .editor .setting_box > div .cont * {
  font-size: 14px !important;
}
#parachannel .column_editor .editor .setting_box > div .cont .btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  flex-wrap: wrap;
}
#parachannel .column_editor .editor .setting_box > div .cont .btn li {
  border-radius: 1000px;
  width: 48%;
  text-align: center;
  margin-bottom: 10px;
  background-color: #39b54a;
}
#parachannel .column_editor .editor .setting_box > div .cont .btn li:last-child {
  width: 100%;
}
#parachannel .column_editor .editor .setting_box > div .cont .btn li a {
  padding: 0.5em;
  color: #fff !important;
}
#parachannel .column_editor .editor .setting_box > div .cont .btn li.draft {
  background-color: #ea5514;
}
#parachannel .column_editor .editor .setting_box > div .cont .btn li.preview {
  background-color: #00a0e9;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li {
  margin-bottom: 0.75em;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li:last-child {
  margin-bottom: 0;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li .vdp-datepicker__calendar {
  right: 0;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li span {
  font-weight: bold;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li dl {
  display: flex;
  align-items: center;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li dl dd a {
  margin-left: 7px;
  display: inline-block;
  text-decoration: underline;
  color: #00a0e9;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li dl dd > div {
  display: flex;
  flex-direction: column;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li dl dd > div input {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", sans-serif;
  font-size: 14px;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border: 1px #b3b3b3 solid;
  font-size: 14px;
  display: block;
  margin: 0;
  margin-bottom: 5px;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li dl dt {
  display: flex;
  align-items: center;
  min-width: 20px;
  margin-right: 4px;
}
#parachannel .column_editor .editor .setting_box > div .cont .status li dl dt img {
  display: inline-block;
  width: 20px;
  margin-right: 4px;
}

#parachannel #cke_editor1 *,
#parachannel .cke_dialog * {
  box-sizing: content-box !important;
}
#parachannel #cke_editor1 input,
#parachannel .cke_dialog input {
  box-sizing: border-box !important;
}
#parachannel a.cke_dialog_tab {
  height: 25px !important;
  padding: 4px 8px !important;
  display: inline-block !important;
  cursor: pointer !important;
  line-height: 26px !important;
  outline: 0 !important;
  color: #484848 !important;
  border: 1px solid #d1d1d1 !important;
  border-radius: 3px 3px 0 0 !important;
  background: #f8f8f8 !important;
  min-width: 90px !important;
  text-align: center !important;
  margin-left: -1px !important;
  letter-spacing: 0.3px !important;
}
#parachannel a.cke_dialog_tab_selected {
  background: #fff !important;
  border-bottom-color: #fff !important;
  cursor: default !important;
  filter: none !important;
}
#parachannel a.cke_button {
  display: inline-block !important;
  height: 18px !important;
  padding: 4px 6px !important;
  outline: 0 !important;
  cursor: default !important;
  float: left !important;
  border: 0 !important;
  position: relative !important;
  transition: none;
}
#parachannel a.cke_button_on {
  background: #fff !important;
  border: 1px #bcbcbc solid !important;
  padding: 3px 5px !important;
}
#parachannel a.cke_button_off:hover,
#parachannel a.cke_button_off:focus,
#parachannel a.cke_button_off:active {
  background: #e5e5e5 !important;
  border: 1px #bcbcbc solid !important;
  padding: 3px 5px !important;
}
#parachannel a.cke_combo_button {
  transition: none;
}

#parachannel .head_contact_link {
  margin-bottom: 20px;
}
#parachannel .icatch_modal {
  text-align: center;
  margin-bottom: 20px;
}
#parachannel .icatch_modal button {
  text-align: center;
  margin: 20px auto;
  border-radius: 5px;
  background-color: #00a0e9;
  font-size: 14px !important;
  padding: 0.5em 2em;
  color: #fff !important;
  cursor: pointer;
  border: 0;
}
#parachannel .icatch_modal .media_att a {
  display: inline;
  text-decoration: underline;
  color: #00a0e9;
}
#parachannel .icatch_modal h4 {
  text-align: center;
  color: #00a0e9;
  margin: 0 auto 20px;
}
#parachannel .icatch_modal .medias {
  max-width: 1200px;
  margin: 0 auto;
}
#parachannel .icatch_modal .medias .list_box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
#parachannel .icatch_modal .medias .list_box li {
  position: relative;
  margin-right: 16px;
  width: 140px;
  height: 140px;
  border: 1px solid #b3b3b3;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
#parachannel .icatch_modal .medias .list_box li.active {
  border: 4px solid #00a0e9;
}
@media print, screen and (max-width: 768px) {
  #parachannel .icatch_modal .medias .list_box li {
    width: 28vw;
    height: 28vw;
  }
}
#parachannel .icatch_modal .medias .list_box li > .thumb {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
#parachannel .icatch_modal .medias .list_box li span {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5px;
  right: 5px;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 1000px;
}
#parachannel .icatch_modal .medias .list_box li span img {
  width: 12px;
}
#parachannel .icatch_modal .medias .list_box li.using::before {
  content: "使用中";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  color: #00a0e9;
  font-size: 12px;
  font-weight: bold;
  padding: 4px;
  box-sizing: border-box;
}

#parachannel #company .jobs > .link {
  width: 250px;
  padding: 17px 0;
  background-color: #f15a24;
  position: absolute;
  top: 40px;
  right: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs > .link {
    width: 150px;
    padding: 9px 0;
    font-size: 12px;
    top: 105px;
    right: 3%;
  }
}
#parachannel #company .jobs.create1 {
  font-weight: bold;
  text-align: center;
}
#parachannel #company .jobs.create1 > div {
  padding-bottom: 40px;
  border: 1px #b3b3b3 solid;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 > div {
    padding-bottom: 25px;
    margin-bottom: 20px;
  }
}
#parachannel #company .jobs.create1 > div form > p:nth-of-type(n+2),
#parachannel #company .jobs.create1 > div form > div p {
  color: #f00;
}
#parachannel #company .jobs.create1 h3 {
  padding: 17px 0;
  background-color: #f7931e;
  font-size: 16px;
  color: #fff;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 h3 {
    padding: 13px 0;
    font-size: 14px;
    margin-bottom: 20px;
  }
}
#parachannel #company .jobs.create1 p {
  margin-bottom: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create1 p {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 p {
    margin-bottom: 20px;
  }
}
#parachannel #company .jobs.create1 p.note {
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 p.note {
    font-size: 10px;
  }
}
#parachannel #company .jobs.create1 p.note a {
  text-decoration: underline;
  color: #00a0e9;
  display: inline-block;
}
#parachannel #company .jobs.create1 label {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 label {
    margin-bottom: 20px;
  }
}
#parachannel #company .jobs.create1 label input {
  width: 210px;
  border-radius: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 label input {
    width: 150px;
  }
}
#parachannel #company .jobs.create1 label input:nth-child(1) {
  width: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 label input:nth-child(1) {
    width: 80px;
  }
}
#parachannel #company .jobs.create1 label span {
  margin: 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 label span {
    margin: 0 5px;
  }
}
#parachannel #company .jobs.create1 button.btn {
  width: 200px;
  background-color: #f15a24;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 button.btn {
    width: 150px;
    padding: 6px 0;
  }
}
#parachannel #company .jobs.create1 .btn {
  width: 450px;
  padding: 12px 0;
  border-radius: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create1 .btn {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create1 .btn {
    width: 250px;
    padding: 7px 0;
    line-height: 18px;
  }
}
#parachannel #company .jobs.create2, #parachannel #company .jobs.create3 {
  font-weight: bold;
}
#parachannel #company .jobs.create2 h3, #parachannel #company .jobs.create3 h3 {
  padding-bottom: 20px;
  border-bottom: 1px #00a0e9 solid;
  text-align: center;
  color: #00a0e9;
  margin-bottom: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 h3, #parachannel #company .jobs.create3 h3 {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}
#parachannel #company .jobs.create2 > p, #parachannel #company .jobs.create3 > p {
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create2 > p, #parachannel #company .jobs.create3 > p {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 > p, #parachannel #company .jobs.create3 > p {
    line-height: 20px;
    margin-bottom: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create2 form > dl:nth-of-type(1) > dd:nth-of-type(1), #parachannel #company .jobs.create3 form > dl:nth-of-type(1) > dd:nth-of-type(1) {
    width: 100%;
    margin-top: -10px;
  }
}
#parachannel #company .jobs.create2 form.type2 > div dl dd input, #parachannel #company .jobs.create2 form.type2 > div dl dd select.long, #parachannel #company .jobs.create3 form.type2 > div dl dd input, #parachannel #company .jobs.create3 form.type2 > div dl dd select.long {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create2 form.type2 > div dl dd input, #parachannel #company .jobs.create2 form.type2 > div dl dd select.long, #parachannel #company .jobs.create3 form.type2 > div dl dd input, #parachannel #company .jobs.create3 form.type2 > div dl dd select.long {
    width: 44%;
    max-width: 300px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 form.type2 > div dl dd input, #parachannel #company .jobs.create2 form.type2 > div dl dd select.long, #parachannel #company .jobs.create3 form.type2 > div dl dd input, #parachannel #company .jobs.create3 form.type2 > div dl dd select.long {
    margin-top: 10px;
  }
  #parachannel #company .jobs.create2 form.type2 > div dl dd input:nth-child(1), #parachannel #company .jobs.create2 form.type2 > div dl dd select.long:nth-child(1), #parachannel #company .jobs.create3 form.type2 > div dl dd input:nth-child(1), #parachannel #company .jobs.create3 form.type2 > div dl dd select.long:nth-child(1) {
    margin-top: 0;
  }
}
#parachannel #company .jobs.create2 dl, #parachannel #company .jobs.create3 dl {
  padding-bottom: 40px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create2 dl dt, #parachannel #company .jobs.create2 dl dd, #parachannel #company .jobs.create3 dl dt, #parachannel #company .jobs.create3 dl dd {
    margin-bottom: 20px;
  }
  #parachannel #company .jobs.create2 dl dt:last-of-type, #parachannel #company .jobs.create2 dl dd:last-of-type, #parachannel #company .jobs.create3 dl dt:last-of-type, #parachannel #company .jobs.create3 dl dd:last-of-type {
    margin: 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 dl, #parachannel #company .jobs.create3 dl {
    padding-bottom: 30px;
    display: block;
    margin-bottom: 30px;
  }
}
#parachannel #company .jobs.create2 dl:last-of-type, #parachannel #company .jobs.create3 dl:last-of-type {
  padding-bottom: 0;
  border: none;
  margin-bottom: 0;
}
#parachannel #company .jobs.create2 dt, #parachannel #company .jobs.create3 dt {
  width: 240px;
  height: 40px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 dt, #parachannel #company .jobs.create3 dt {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  #parachannel #company .jobs.create2 dt br, #parachannel #company .jobs.create3 dt br {
    display: none;
  }
}
#parachannel #company .jobs.create2 dt span, #parachannel #company .jobs.create3 dt span {
  padding: 4px 8px;
  background-color: #f00;
  border-radius: 20px;
  font-size: 12px;
  line-height: 100%;
  color: #fff;
  margin-left: 10px;
}
#parachannel #company .jobs.create2 dd, #parachannel #company .jobs.create3 dd {
  width: calc(100% - 240px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 dd, #parachannel #company .jobs.create3 dd {
    width: 100%;
    margin-bottom: 25px;
  }
  #parachannel #company .jobs.create2 dd:last-child, #parachannel #company .jobs.create3 dd:last-child {
    margin: 0;
  }
}
#parachannel #company .jobs.create2 dd input, #parachannel #company .jobs.create3 dd input {
  width: 300px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 dd input, #parachannel #company .jobs.create3 dd input {
    width: calc(100% - 90px);
  }
}
#parachannel #company .jobs.create2 dd input.small, #parachannel #company .jobs.create3 dd input.small {
  width: 80px;
  margin-right: 10px;
}
#parachannel #company .jobs.create2 dd select, #parachannel #company .jobs.create3 dd select {
  width: 150px;
  background: url(./img/company/icon04.png) no-repeat;
  background-size: 15px;
  background-position: right 10px center;
  cursor: pointer;
  margin: 0 10px;
}
#parachannel #company .jobs.create2 dd select:first-child, #parachannel #company .jobs.create3 dd select:first-child {
  margin-left: 0;
}
#parachannel #company .jobs.create2 dd dl, #parachannel #company .jobs.create3 dd dl {
  width: calc(100% + 240px);
  margin: 20px 0 0 -240px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 dd dl, #parachannel #company .jobs.create3 dd dl {
    width: 100%;
    margin: 25px 0 0;
    padding-left: 5%;
  }
}
#parachannel #company .jobs.create2 dd dt, #parachannel #company .jobs.create3 dd dt {
  padding-left: 30px;
  font-size: 14px;
  line-height: 16px;
  color: #606e84;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 dd dt, #parachannel #company .jobs.create3 dd dt {
    padding: 0;
  }
}
#parachannel #company .jobs.create2 dd.time_box > label, #parachannel #company .jobs.create3 dd.time_box > label {
  font-size: 14px;
  line-height: 16px;
  color: #606e84;
  display: block;
  margin: unset;
  cursor: auto;
}
#parachannel #company .jobs.create2 dd.time_box > label select, #parachannel #company .jobs.create3 dd.time_box > label select {
  margin: unset;
  width: 75px !important;
}
#parachannel #company .jobs.create2 dd.time_box > p, #parachannel #company .jobs.create3 dd.time_box > p {
  line-height: 10px;
  margin: 21px 10px 0;
}
#parachannel #company .jobs.create2 .btn_box, #parachannel #company .jobs.create3 .btn_box {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 .btn_box, #parachannel #company .jobs.create3 .btn_box {
    justify-content: space-between;
    margin-top: 30px;
  }
}
#parachannel #company .jobs.create2 .btn_box .btn, #parachannel #company .jobs.create3 .btn_box .btn {
  width: 220px;
  padding: 11px 0;
  border-radius: 30px;
  margin: 0 25px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create2 .btn_box .btn, #parachannel #company .jobs.create3 .btn_box .btn {
    font-size: 18px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 .btn_box .btn, #parachannel #company .jobs.create3 .btn_box .btn {
    width: 150px;
    padding: 11px 0;
    margin: 0 auto;
  }
}
#parachannel #company .jobs.create2 .btn_box .btn img, #parachannel #company .jobs.create3 .btn_box .btn img {
  width: 10px;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 .btn_box .btn img, #parachannel #company .jobs.create3 .btn_box .btn img {
    width: 8px;
    right: 10px;
  }
}
#parachannel #company .jobs.create2 .btn_box .btn:not(.orange) img, #parachannel #company .jobs.create3 .btn_box .btn:not(.orange) img {
  transform: scale(-1, 1);
  right: auto;
  left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create2 .btn_box .btn:not(.orange) img, #parachannel #company .jobs.create3 .btn_box .btn:not(.orange) img {
    left: 10px;
  }
}
#parachannel #company .jobs.create3 form > div {
  margin-top: 80px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create3 form > div {
    margin-top: 30px;
  }
}
#parachannel #company .jobs.create3 form > div h4 {
  padding: 0 10px 15px;
  border-bottom: 1px #606e84 solid;
  font-size: 16px;
  color: #606e84;
  display: flex;
  align-items: center;
  align-content: center;
  margin-bottom: 30px;
}
#parachannel #company .jobs.create3 form > div h4 span {
  font-size: 12px;
  color: #b3b3b3;
  margin-left: 15px;
}
#parachannel #company .jobs.create3 form > div h4 img {
  width: 15px;
  margin-left: 15px;
}
#parachannel #company .jobs.create3 form > div dl dd p {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company .jobs.create3 form > div dl dd p.dummy {
  width: 40px;
  height: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create3 form > div dl dd p.dummy {
    display: none;
  }
}
#parachannel #company .jobs.create3 form > div dl dd p.note {
  display: block;
  margin-top: 5px;
  font-size: 100%;
  font-weight: normal;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create3 form > div dl dd input[type=text], #parachannel #company .jobs.create3 form > div dl dd input[type=number] {
    margin: 0 10px;
  }
  #parachannel #company .jobs.create3 form > div dl dd input[type=text]:nth-child(1), #parachannel #company .jobs.create3 form > div dl dd input[type=number]:nth-child(1) {
    margin-left: 0;
  }
}
#parachannel #company .jobs.create3 form > div dl dd input[type=text].small, #parachannel #company .jobs.create3 form > div dl dd input[type=number].small {
  width: 100px;
}
#parachannel #company .jobs.create3 form > div dl dd input[type=text].long, #parachannel #company .jobs.create3 form > div dl dd input[type=number].long {
  width: 100%;
  max-width: 500px;
  margin: 0;
}
#parachannel #company .jobs.create3 form > div dl dd input[type=number] {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create3 form > div dl dd input[type=number] {
    width: 150px;
    margin: 0 10px;
  }
  #parachannel #company .jobs.create3 form > div dl dd input[type=number]:nth-child(1) {
    margin-left: 0;
  }
}
#parachannel #company .jobs.create3 form > div dl dd input[type=checkbox], #parachannel #company .jobs.create3 form > div dl dd input[type=radio] {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 5px 0 0;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create3 form > div dl dd select {
    width: 150px;
  }
}
#parachannel #company .jobs.create3 form > div dl dd select.long {
  width: 300px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create3 form > div dl dd select.long {
    margin-bottom: 10px;
  }
  #parachannel #company .jobs.create3 form > div dl dd select.long:last-child {
    margin: 0;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create3 form > div dl dd textarea {
    height: 140px;
  }
}
#parachannel #company .jobs.create3 form > div dl dd label {
  display: flex;
  align-items: center;
  align-content: center;
  margin: 5px 30px 5px 0;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create3 form > div dl dd label {
    margin-right: 20px;
  }
}
#parachannel #company .jobs.create3 form > div dl dd.no_flex {
  display: block;
}
#parachannel #company .jobs.create3 form > div dl dd.no_flex label {
  margin: 0 0 15px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.create3 form > div dl dd.max {
    width: 100%;
    margin-top: -10px;
  }
}
#parachannel #company .jobs.create3 form > div dl dd .check_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
#parachannel #company .jobs.create3 form > div dl dd .check_list li input {
  display: none;
}
#parachannel #company .jobs.create3 form > div dl dd .check_list li input:checked + label {
  background-color: #00a0e9;
  border-color: #00a0e9;
  color: #fff;
}
#parachannel #company .jobs.create3 form > div dl dd .check_list li label {
  min-width: 110px;
  padding: 8px 10px;
  border: 1px #9e9f9f solid;
  border-radius: 20px;
  text-align: center;
  color: #ccc;
  display: block;
  margin: 5px 10px 5px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.create3 form > div dl dd .check_list li label {
    min-width: 100px;
  }
}

#parachannel #company {
  /* jobs */
}
#parachannel #company .jobs.edit {
  position: relative;
}
#parachannel #company .jobs.edit .step_box {
  position: relative;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.edit .step_box::before {
    left: 30%;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.edit .step_box ul {
    justify-content: start;
  }
}
#parachannel #company .jobs.edit .step_box li:first-child {
  margin-left: 0;
}
#parachannel #company .jobs.edit .step_box li:last-child {
  margin-right: 0;
}
@media print, screen and (min-width: 768px) and (max-width: 1300px) {
  #parachannel #company .jobs.edit .step_box {
    margin-bottom: 30px;
  }
  #parachannel #company .jobs.edit .step_box ul {
    justify-content: center;
  }
  #parachannel #company .jobs.edit .step_box::before {
    left: 50%;
  }
}
#parachannel #company .jobs.edit #jobs {
  padding: 0;
}
#parachannel #company .jobs.edit #jobs > p:not(.red) {
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  margin: -40px 0 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.edit #jobs > p:not(.red) {
    font-size: 10px;
    margin: 0 0 20px;
  }
}
#parachannel #company .jobs.edit #jobs > p:not(.red) a {
  padding: 5px;
  border: 1px #00a0e9 solid;
  border-radius: 5px;
  color: #00a0e9;
  margin-left: 5px;
}
#parachannel #company .jobs.edit #jobs .red {
  font-weight: bold;
  text-align: center;
  color: #e44e66;
  margin: 50px 0 20px;
}
#parachannel #company .jobs.edit #jobs .btn_area {
  text-align: center;
}
#parachannel #company .jobs.edit #jobs .btn {
  display: inline-block;
  margin: 0 20px;
  padding: 18px 0;
  font-size: 20px;
  border-radius: 30px;
  background-color: #ea5514;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.edit #jobs .btn {
    transition: opacity 0.15s linear;
    -webkit-transition: opacity 0.15s linear;
  }
  #parachannel #company .jobs.edit #jobs .btn:hover {
    opacity: 0.7;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.edit #jobs .btn {
    width: 45%;
    margin: 0 3px;
    padding: 15px 0;
    font-size: 15px;
  }
}
#parachannel #company .jobs.edit #jobs .btn.head_btn {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .jobs.edit #jobs .btn.head_btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 200px;
    padding: 20px 0;
    z-index: 1;
  }
}
@media print, screen and (min-width: 768px) and (max-width: 1300px) {
  #parachannel #company .jobs.edit #jobs .btn.head_btn {
    position: relative;
    right: auto;
    top: 0;
    display: block;
    width: 300px;
    margin: 0 auto 50px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.edit #jobs .btn.head_btn {
    display: block;
    width: 200px;
    margin: 0 auto 30px;
  }
}
#parachannel #company .jobs.edit #jobs .btn.open_btn {
  width: 320px;
  margin-top: 20px;
  background-color: #39b54a;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.edit #jobs .btn.open_btn {
    width: 45%;
  }
}
#parachannel #company .jobs.edit span.edit_icon {
  padding: 3px 5px;
  font-size: 12px;
  vertical-align: middle;
  display: inline-block;
  margin: 5px 0 8px 10px;
  background-image: url("./img/company/icon13.svg");
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
}

/* initial_registration */
#parachannel #company .initial_registration {
  font-weight: bold;
}
#parachannel #company .initial_registration .cont_box {
  width: 100%;
  margin: 0 auto;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .initial_registration .cont_box {
    max-width: 850px;
  }
}
#parachannel #company .initial_registration .cont_box > p:not(.number) {
  font-size: 18px;
  font-family: dnp-shuei-mgothic-std, sans-serif;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .cont_box > p:not(.number) {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
#parachannel #company .initial_registration .cont_box > p:not(.number).read {
  margin: 0 0 40px;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .cont_box > p:not(.number).read {
    font-size: 14px;
  }
}
#parachannel #company .initial_registration .number {
  font-size: 18px;
  font-family: dnp-shuei-mgothic-std, sans-serif;
  color: #00a0e9;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .number {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
#parachannel #company .initial_registration .number span {
  font-size: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .number span {
    font-size: 30px;
  }
}
#parachannel #company .initial_registration form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
#parachannel #company .initial_registration form > p {
  line-height: 20px;
  margin: 10px 0;
}
#parachannel #company .initial_registration form > p a {
  text-decoration: underline;
  color: #00a0e9;
  display: inline-block;
}
#parachannel #company .initial_registration form .error_box {
  width: 100%;
}
#parachannel #company .initial_registration form .error_box .error_msg {
  font-size: 12px;
  color: #f00;
  margin-top: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration form .error_box .error_msg {
    font-size: 10px;
  }
}
#parachannel #company .initial_registration form > .note_text {
  width: 100%;
  margin: 50px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration form > .note_text {
    margin-top: 30px;
  }
}
#parachannel #company .initial_registration h3 {
  position: relative;
  width: 100%;
  padding-left: 30px;
  font-size: 20px;
  font-family: dnp-shuei-mgothic-std, sans-serif;
  margin: 60px auto 35px;
  /*横向きの際に適用*/
}
#parachannel #company .initial_registration h3::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 6px;
  width: 20px;
  height: 6px;
  background-color: #00a0e9;
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration h3 {
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
#parachannel #company .initial_registration h3:first-child {
  margin-top: 0;
}
#parachannel #company .initial_registration input {
  border-radius: 5px;
}
#parachannel #company .initial_registration select {
  width: 100%;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration select {
    margin-bottom: 10px;
  }
}
#parachannel #company .initial_registration select:last-of-type {
  margin: 0;
}
#parachannel #company .initial_registration label {
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
  line-height: 1.5;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration label {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
#parachannel #company .initial_registration label:last-of-type {
  margin: 0;
}
#parachannel #company .initial_registration label input {
  width: calc(100% - 80px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration label input {
    width: calc(100% - 70px);
  }
}
#parachannel #company .initial_registration label.step3 {
  margin: 20px 0 0;
}
#parachannel #company .initial_registration label.step3 > p {
  width: 180px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration label.step3 > p {
    width: 140px;
  }
}
#parachannel #company .initial_registration label.step3 > div {
  width: calc(100% - 180px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration label.step3 > div {
    width: calc(100% - 140px);
  }
}
#parachannel #company .initial_registration label.step3:nth-of-type(2) > div {
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company .initial_registration label.step3:nth-of-type(2) > div select {
  width: 150px;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration label.step3:nth-of-type(2) > div select {
    width: 70px;
  }
}
#parachannel #company .initial_registration label.step3:nth-of-type(2) > div p {
  margin: 0 20px 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration label.step3:nth-of-type(2) > div p {
    margin: 0 10px 0 5px;
  }
}
#parachannel #company .initial_registration .gray_box {
  padding: 20px;
  background-color: #f2f2f2;
  color: #595757;
  margin-top: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .gray_box {
    padding: 15px;
  }
}
#parachannel #company .initial_registration .gray_box h4 {
  margin-bottom: 15px;
}
#parachannel #company .initial_registration .gray_box ul {
  padding: 0 45px;
  list-style-type: disc;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .gray_box ul {
    padding: 0;
  }
}
#parachannel #company .initial_registration .gray_box ul li {
  margin: 0 0 10px 1.5em;
}
#parachannel #company .initial_registration .gray_box ul li:last-child {
  margin-bottom: 0;
}
#parachannel #company .initial_registration .radio,
#parachannel #company .initial_registration .checkbox_list {
  width: 100%;
}
#parachannel #company .initial_registration .radio label,
#parachannel #company .initial_registration .checkbox_list label {
  justify-content: flex-start;
}
#parachannel #company .initial_registration .radio label input,
#parachannel #company .initial_registration .checkbox_list label input {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0 10px 0 0;
}
#parachannel #company .initial_registration .radio label {
  display: inline-block;
  width: auto;
  margin-right: 50px;
}
#parachannel #company .initial_registration .checkbox_list.area,
#parachannel #company .initial_registration .checkbox_list.double,
#parachannel #company .initial_registration .checkbox_list.short {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #company .initial_registration .checkbox_list.area label,
#parachannel #company .initial_registration .checkbox_list.double label,
#parachannel #company .initial_registration .checkbox_list.short label {
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .checkbox_list.area label,
  #parachannel #company .initial_registration .checkbox_list.double label,
  #parachannel #company .initial_registration .checkbox_list.short label {
    margin-bottom: 10px;
  }
}
#parachannel #company .initial_registration .checkbox_list.double label {
  width: 50%;
  line-height: 1.5;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .checkbox_list.double label {
    width: 100%;
  }
}
#parachannel #company .initial_registration .checkbox_list.short label {
  width: auto;
  margin-right: 50px;
  line-height: 1.5;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .checkbox_list.short label {
    width: 33.3333333333%;
    margin-right: 0;
  }
}
#parachannel #company .initial_registration .checkbox_list button {
  width: 120px;
  height: 35px;
  background-color: #00a0e9;
  border-radius: 20px;
  border: none;
  font-weight: bold;
  color: #fff;
  margin-top: 0;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .checkbox_list button {
    width: 100px;
    height: 30px;
    font-size: 12px;
    display: block;
    margin: 10px 0 0 auto;
  }
}
#parachannel #company .initial_registration .name,
#parachannel #company .initial_registration .birthday {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
#parachannel #company .initial_registration .name label,
#parachannel #company .initial_registration .birthday label {
  width: 48%;
  margin: 0;
}
#parachannel #company .initial_registration .name label input,
#parachannel #company .initial_registration .birthday label input {
  width: calc(100% - 40px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .name label input,
  #parachannel #company .initial_registration .birthday label input {
    width: calc(100% - 35px);
  }
}
#parachannel #company .initial_registration .name.type2 {
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .initial_registration .name.type2 {
    justify-content: space-between;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .name.type2 {
    display: block;
  }
}
#parachannel #company .initial_registration .name.type2:last-child {
  margin: 0;
}
#parachannel #company .initial_registration .name.type2 label {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .name.type2 label {
    width: 100%;
  }
  #parachannel #company .initial_registration .name.type2 label:last-child {
    margin-top: 10px;
  }
}
#parachannel #company .initial_registration .name.type2 input {
  width: calc(100% - 120px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .name.type2 input {
    width: calc(100% - 120px);
  }
}
#parachannel #company .initial_registration .birthday {
  justify-content: flex-start;
}
#parachannel #company .initial_registration .birthday select {
  width: 100px;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .birthday select {
    width: 80px;
  }
}
#parachannel #company .initial_registration .birthday p {
  font-size: 16px;
  margin: 0 20px 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .birthday p {
    font-size: 14px;
    margin: 0 10px 0 5px;
  }
}
#parachannel #company .initial_registration dl.address {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  align-items: center;
  align-content: center;
  line-height: 1.5;
}
#parachannel #company .initial_registration dl.address dt {
  width: 130px;
  font-size: 16px;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
#parachannel #company .initial_registration dl.address dt:last-of-type {
  margin-bottom: 0;
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dt {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
#parachannel #company .initial_registration dl.address dd {
  width: calc(100% - 130px);
  margin-bottom: 20px;
}
#parachannel #company .initial_registration dl.address dd label input {
  width: 100%;
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(-n+2) label {
  justify-content: unset;
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(1) input[type=text] {
  width: calc(100% - 160px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dd:nth-of-type(1) input[type=text] {
    width: calc(100% - 120px);
  }
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(1) button {
  width: 150px;
  padding: 13px 0;
  font-size: 14px;
  margin: 0 0 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dd:nth-of-type(1) button {
    width: 120px;
    padding: 12px 0;
    font-size: 11px;
    margin-left: 5px;
  }
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(2) {
  display: flex;
  /*横向きの際に適用*/
  justify-content: space-between;
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dd:nth-of-type(2) {
    flex-wrap: wrap;
  }
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(2) label {
  margin-right: 10px;
  margin-bottom: 0;
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(2) label.city {
  width: 800px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dd:nth-of-type(2) label.city {
    width: 100%;
  }
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(2) label.prefecture {
  width: 500px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dd:nth-of-type(2) label.prefecture {
    margin-bottom: 10px;
  }
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(2) label.check {
  margin-right: 0;
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dd:nth-of-type(2) label.check {
    margin: 10px 0 0 0;
  }
}
#parachannel #company .initial_registration dl.address dd:nth-of-type(2) input[type=checkbox] {
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 14px;
  margin: 0 5px 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration dl.address dd:nth-of-type(2) input[type=checkbox] {
    margin: 0 5px 0 0;
  }
}
#parachannel #company .initial_registration dl.address dd:last-of-type {
  margin-bottom: 0;
}
#parachannel #company .initial_registration .btn {
  width: 220px;
  padding: 11px 0;
  border-radius: 30px;
  margin-top: 65px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company .initial_registration .btn {
    font-size: 18px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .btn {
    width: 150px;
    padding: 11px 0;
    margin-top: 30px;
  }
}
#parachannel #company .initial_registration .btn img {
  width: 10px;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .btn img {
    width: 8px;
    right: 10px;
  }
}
#parachannel #company .initial_registration .btn:not(.orange) img {
  transform: scale(-1, 1);
  right: auto;
  left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .btn:not(.orange) img {
    left: 10px;
  }
}
#parachannel #company .initial_registration .btn_box {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .btn_box {
    margin-top: 30px;
  }
}
#parachannel #company .initial_registration .btn_box .btn {
  margin: 0;
}
#parachannel #company .initial_registration .privacy_policy {
  width: 100%;
  margin-top: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .privacy_policy {
    margin-top: 30px;
  }
}
#parachannel #company .initial_registration .privacy_policy p {
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .privacy_policy p {
    line-height: 16px;
  }
}
#parachannel #company .initial_registration .privacy_policy p a {
  display: inline-block;
  text-decoration: underline;
}
#parachannel #company .initial_registration .privacy_policy label {
  justify-content: center;
  margin-top: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .privacy_policy label {
    margin-top: 20px;
  }
}
#parachannel #company .initial_registration .privacy_policy label input {
  width: 15px;
  height: 15px;
  margin: 0 15px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .privacy_policy label input {
    margin-right: 15px;
  }
}
#parachannel #company .initial_registration .privacy_policy label span {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .initial_registration .privacy_policy label span {
    line-height: 18px;
  }
}

#parachannel #company {
  /* jobs */
}
#parachannel #company .jobs.edit .sidebar {
  width: 38%;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.edit .sidebar {
    width: 100%;
  }
}
#parachannel #company .jobs.edit .sidebar .tab_box {
  position: relative;
}
#parachannel #company .jobs.edit .sidebar .tab_box .btn_box {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}
#parachannel #company .jobs.edit .sidebar .tab_box .btn_box a {
  width: 25px;
  margin-right: 10px;
}
#parachannel #company .jobs.edit .sidebar .tab_box .btn_box a:last-child {
  margin: 0;
}
#parachannel #company .jobs.edit .sidebar > a {
  font-size: 16px;
  font-weight: bold;
  color: #00a0e9 !important;
}
#parachannel #company .jobs.edit .sidebar .sample_text {
  width: 250px;
  padding: 10px 0;
  background-color: #df0056;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 99;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.edit .sidebar .sample_text {
    padding: 8px 0;
  }
}
#parachannel #company .jobs.edit .sidebar .sample_text:after {
  content: "";
  width: 20px;
  height: 20px;
  border-right: 10px transparent solid;
  border-bottom: 20px #df0056 solid;
  border-left: 10px transparent solid;
  box-sizing: border-box;
  position: absolute;
  top: -20px;
  right: 0;
  left: 0;
  margin: 0 auto;
}
#parachannel #company .jobs.edit .sidebar .sample_text p {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company .jobs.edit .sidebar .sample_text p {
    font-size: 14px;
    line-height: 18px;
  }
}
#parachannel #company .jobs.edit .sidebar .sample_text span {
  width: 30px;
  padding: 1px 0 5px;
  background-color: #fff;
  border: 2px #df0056 solid;
  border-radius: 50%;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #df0056;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}

#parachannel #privacy,
#parachannel #terms {
  padding: 35px 0 85px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #privacy,
  #parachannel #terms {
    padding: 20px 0 0;
  }
}
#parachannel #privacy h3,
#parachannel #terms h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 30px 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #privacy h3,
  #parachannel #terms h3 {
    font-size: 14px;
  }
}
#parachannel #privacy p,
#parachannel #terms p {
  line-height: 24px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #privacy p,
  #parachannel #terms p {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #privacy p,
  #parachannel #terms p {
    line-height: 20px;
  }
}
#parachannel #privacy p.wrap,
#parachannel #terms p.wrap {
  white-space: pre-wrap;
}
#parachannel #privacy p.left,
#parachannel #terms p.left {
  text-align: right;
  margin-top: 40px;
}
#parachannel #privacy strong,
#parachannel #terms strong {
  font-weight: bold;
}

#parachannel #search {
  padding-top: 35px;
}
#parachannel #search ul li {
  padding: 26px 20px;
  border-bottom: 1px #b3b3b3 solid;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #search ul li {
    padding: 15px 10px;
    display: block;
  }
}
#parachannel #search ul li a:nth-child(1) {
  width: calc(100% - 140px);
  font-size: 16px;
  line-height: 34px;
  word-break: break-all;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #search ul li a:nth-child(1) {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
  }
}
#parachannel #search ul li a:nth-child(1):hover {
  color: #00a0e9;
  opacity: 1;
}
#parachannel #search ul li a:nth-child(2) {
  width: 120px;
  padding: 13px 0;
  background-color: #e6e6e6;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #search ul li a:nth-child(2) {
    width: 100px;
    padding: 9px 0;
    border-radius: 5px;
    font-size: 12px;
    margin: 10px 0 0 auto;
  }
}

#parachannel {
  /* 共通 */
}
#parachannel .lp_header {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .lp_header {
    padding-bottom: 0;
  }
}
#parachannel .lp_footer {
  margin-bottom: 165px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .lp_footer {
    margin-bottom: 115px;
  }
}
#parachannel .lp_footer .top_btn {
  width: 90px;
  position: fixed;
  right: 15px;
  bottom: 180px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel .lp_footer .top_btn {
    width: 60px;
    right: 5px;
    bottom: 120px;
  }
}
#parachannel .lp_footer .cont2 .wrapper {
  padding: 30px 0;
  justify-content: center;
}
#parachannel #lp {
  /* mv */
  /* worry */
  /* feature */
  /* flow */
  /* project */
  /* about */
  /* voice */
  /* comparison */
  /* faq */
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp .wrapper {
    max-width: 960px;
  }
}
#parachannel #lp .ttl_lp {
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: flex-end;
  justify-content: center;
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp .ttl_lp {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 25px;
  }
  #parachannel #lp .ttl_lp:before {
    margin-left: 0 !important;
  }
  #parachannel #lp .ttl_lp:after {
    margin-right: 0 !important;
  }
}
#parachannel #lp .ttl_lp img {
  width: 120px;
  height: 80px;
  margin: 0 10px 5px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp .ttl_lp img {
    width: 80px;
    margin-bottom: 5px;
  }
}
#parachannel #lp .ttl_lp span {
  font-size: 40px;
  margin: 0 5px 3px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp .ttl_lp span {
    font-size: 30px;
  }
}
#parachannel #lp .ttl_lp:before, #parachannel #lp .ttl_lp:after {
  content: "";
  width: 20px;
  height: 27px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp .ttl_lp:before, #parachannel #lp .ttl_lp:after {
    width: 15px;
    height: 20px;
    margin: 0 10px;
  }
}
#parachannel #lp .ttl_lp.type1 {
  color: #fff;
}
#parachannel #lp .ttl_lp.type1:before {
  background-image: url(./img/lp/ttl_icon01.png);
}
#parachannel #lp .ttl_lp.type1:after {
  background-image: url(./img/lp/ttl_icon02.png);
}
#parachannel #lp .ttl_lp.type2 {
  color: #00a0e9;
}
#parachannel #lp .ttl_lp.type2:before {
  background-image: url(./img/lp/ttl_icon03.png);
}
#parachannel #lp .ttl_lp.type2:after {
  background-image: url(./img/lp/ttl_icon04.png);
}
#parachannel #lp .ttl_lp.type3 {
  color: #006496;
}
#parachannel #lp .ttl_lp.type3:before {
  background-image: url(./img/lp/ttl_icon05.png);
}
#parachannel #lp .ttl_lp.type3:after {
  background-image: url(./img/lp/ttl_icon06.png);
}
#parachannel #lp .btn {
  width: 300px;
  padding: 21px 0;
  background: #e51b5e url(./img/lp/fixed_icon.png) no-repeat;
  background-size: 10px;
  background-position: right 30px center;
  border-radius: 10px;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp .btn {
    width: 150px;
    padding: 8px 0;
    background-size: 6px;
    background-position: right 10px center;
    border-radius: 5px;
    font-size: 14px;
  }
}
#parachannel #lp #mv {
  font-weight: bold;
  text-align: center;
  position: relative;
  overflow: hidden;
}
#parachannel #lp #mv > img {
  width: 106%;
  max-width: none;
  margin: 0 -3%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #mv > img {
    width: 160%;
    margin: 0 -30%;
  }
}
#parachannel #lp #mv .link_box {
  width: 100%;
  padding: 0;
  position: absolute;
  bottom: 2.5%;
  left: 0;
  margin: 0;
  overflow: initial;
}
#parachannel #lp #mv .link_box .btn {
  width: 250px;
  padding: 23px 0;
  background-size: 8px;
  background-position: right 25px center;
  box-shadow: 0 0 5px 1px #fff inset, 3px 3px 5px rgba(90, 90, 90, 0.6);
  font-size: 14px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #mv .link_box .btn {
    width: 160px;
    padding: 7px 0;
    background-size: 5px;
    background-position: right 6px center;
    font-size: 12px;
  }
}
#parachannel #lp #mv .link_box .btn:nth-of-type(2) {
  padding: 24px 0;
  background-image: url(./img/guide/arrow.png);
  background-color: #d6f4ff;
  font-size: 12px;
  color: #00a0e9;
  margin-top: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #mv .link_box .btn:nth-of-type(2) {
    padding: 8px 0;
    font-size: 10px;
    margin-top: 5px;
  }
}
#parachannel #lp #mv .fixed_box {
  width: 100%;
  padding: 19px 0;
  background-color: #013f8a;
  font-size: 18px;
  color: #fff;
  position: fixed;
  bottom: -200px;
  left: 0;
  z-index: 999;
  transition: all 0.5s;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #mv .fixed_box {
    padding: 13px 0;
    font-size: 14px;
    bottom: -115px;
  }
}
#parachannel #lp #mv .fixed_box a {
  color: #fff;
}
#parachannel #lp #mv .fixed_box a.btn {
  width: 400px;
  padding: 18px 0;
  box-shadow: 0 5px 0 #c90144;
  border-radius: 15px;
  font-size: 24px;
  margin-top: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #mv .fixed_box a.btn:hover {
    transform: translateY(5px);
    box-shadow: none;
    opacity: 1;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #mv .fixed_box a.btn {
    width: 250px;
    padding: 11px 0;
    background-size: 10px;
    background-position: right 20px center;
    box-shadow: 0 3px 0 #c90144;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 10px;
  }
}
#parachannel #lp #mv .fixed_box a:nth-of-type(2) {
  font-size: 14px;
  text-decoration: underline;
  margin-top: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #mv .fixed_box a:nth-of-type(2) {
    font-size: 12px;
    margin-top: 13px;
  }
}
#parachannel #lp #mv .fixed_box.active {
  bottom: 0;
}
#parachannel #lp #worry {
  padding: 75px 0 60px;
  background-color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry {
    padding: 30px 0;
  }
}
#parachannel #lp #worry .wrapper {
  padding: 65px 80px 70px;
  background-color: #fff;
  border-radius: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper {
    padding: 20px;
    border-radius: 20px;
  }
}
#parachannel #lp #worry .wrapper .cont1 {
  display: flex;
  margin-bottom: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont1 {
    display: block;
    margin-bottom: 30px;
  }
}
#parachannel #lp #worry .wrapper .cont1 > div {
  width: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont1 > div {
    width: 100%;
    margin-bottom: 15px;
  }
  #parachannel #lp #worry .wrapper .cont1 > div:last-child {
    margin: 0;
  }
}
#parachannel #lp #worry .wrapper .cont1 > div ul {
  width: 68%;
}
#parachannel #lp #worry .wrapper .cont1 > div ul li {
  height: 100px;
  padding-right: 15px;
  background: url(./img/lp/worry_bg01.png) no-repeat;
  background-size: 100% 100%;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont1 > div ul li {
    height: 80px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
#parachannel #lp #worry .wrapper .cont1 > div ul li:last-child {
  margin: 0;
}
#parachannel #lp #worry .wrapper .cont1 > div > img {
  width: 32%;
}
#parachannel #lp #worry .wrapper .cont1 > div:nth-child(2) {
  flex-direction: row-reverse;
}
#parachannel #lp #worry .wrapper .cont1 > div:nth-child(2) ul li {
  padding: 0 0 0 15px;
  background-image: url(./img/lp/worry_bg02.png);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont1 > div:nth-child(2) ul li {
    padding-left: 10px;
  }
}
#parachannel #lp #worry .wrapper .cont2 h3 {
  padding-bottom: 40px;
  background: url(./img/lp/worry_bg03.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont2 h3 {
    padding-bottom: 25px;
    background-size: auto 15px;
    font-size: 16px;
    line-height: 24px;
  }
}
#parachannel #lp #worry .wrapper .cont2 ul {
  display: flex;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont2 ul {
    display: block;
  }
}
#parachannel #lp #worry .wrapper .cont2 ul li {
  width: 30.5%;
  padding: 40px 0 20px;
  background-color: rgba(252, 238, 33, 0.7);
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont2 ul li {
    width: 100%;
    padding: 20px 0;
    margin-bottom: 20px;
  }
  #parachannel #lp #worry .wrapper .cont2 ul li:last-child {
    margin: 0;
  }
}
#parachannel #lp #worry .wrapper .cont2 ul li:after {
  content: "";
  width: 40px;
  height: 100%;
  background: url(./img/lp/worry_icon03.png) no-repeat;
  background-size: 100%;
  background-position: center;
  position: absolute;
  right: -37px;
  bottom: 0;
  z-index: 9;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont2 ul li:after {
    width: 100%;
    height: 30px;
    background-size: auto 100%;
    right: 0;
    bottom: -25px;
  }
}
#parachannel #lp #worry .wrapper .cont2 ul li:last-child:after {
  display: none;
}
#parachannel #lp #worry .wrapper .cont2 ul li h4 {
  width: 130px;
  padding: 8px 0;
  background-color: #fff;
  border-radius: 30px;
  font-size: 24px;
  margin: 0 auto 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont2 ul li h4 {
    width: 100px;
    padding: 7px 0;
    font-size: 16px;
    margin-bottom: 15px;
  }
}
#parachannel #lp #worry .wrapper .cont2 ul li p {
  height: 60px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont2 ul li p {
    height: auto;
    font-size: 14px;
  }
}
#parachannel #lp #worry .wrapper .cont3 h3 {
  padding: 28px 0;
  background: url(./img/lp/worry_bg04.png) no-repeat;
  background-size: 100% 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #worry .wrapper .cont3 h3 {
    padding: 12px 0;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
#parachannel #lp #feature {
  padding-bottom: 40px;
  background: #00a0e9 url(./img/lp/feature_bg.png) no-repeat;
  background-size: 100%;
  background-position: bottom;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature {
    padding-bottom: 30px;
  }
}
#parachannel #lp #feature ul {
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature ul {
    display: block;
  }
}
#parachannel #lp #feature ul li {
  width: 32.5%;
  padding: 40px 0;
  background-color: #fff;
  line-height: 34px;
  text-align: center;
  font-feature-settings: "palt";
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature ul li {
    width: 100%;
    padding: 30px 0;
    margin-bottom: 20px;
  }
  #parachannel #lp #feature ul li:last-child {
    margin: 0;
  }
}
#parachannel #lp #feature ul li .number {
  width: 55px;
  position: absolute;
  top: -20px;
  left: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature ul li .number {
    width: 40px;
    top: -10px;
  }
}
#parachannel #lp #feature ul li h3 {
  width: 100%;
  font-size: 22px;
  font-weight: bold;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature ul li h3 {
    font-size: 16px;
    line-height: 30px;
  }
}
#parachannel #lp #feature ul li h3 > span {
  background: linear-gradient(transparent 60%, #ff0 60%);
  line-height: 100%;
  display: inline-block;
}
#parachannel #lp #feature ul li h3 > span > span {
  font-size: 26px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature ul li h3 > span > span {
    font-size: 22px;
  }
}
#parachannel #lp #feature ul li p:not(.number) {
  width: 100%;
  padding-top: 20px;
  border-top: 2px #e5e5e5 dotted;
  margin: 25px 25px 0;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #feature ul li p:not(.number) {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature ul li p:not(.number) {
    padding-top: 10px;
    line-height: 24px;
    margin: 15px 15px 0;
  }
}
#parachannel #lp #feature > div {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  margin-top: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature > div {
    display: block;
    margin-top: 10px;
  }
}
#parachannel #lp #feature > div h3 {
  width: 270px;
  margin-top: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature > div h3 {
    width: 42%;
    margin: 0 0 -20% -3%;
    position: relative;
  }
}
#parachannel #lp #feature > div > img {
  width: 675px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature > div > img {
    width: 95%;
    margin-left: auto;
  }
}
#parachannel #lp #feature .btn {
  width: 350px;
  padding: 30px 0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  margin-top: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #feature .btn {
    width: 200px;
    padding: 18px 0;
    font-size: 14px;
    margin-top: 10px;
  }
}
#parachannel #lp #flow {
  padding: 80px 0 60px;
  background-color: #d7f5ff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow {
    padding: 30px 0;
  }
}
#parachannel #lp #flow ul {
  display: flex;
  justify-content: center;
  margin-bottom: 65px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow ul {
    display: block;
    margin-bottom: 25px;
  }
}
#parachannel #lp #flow ul li {
  width: 25%;
  padding: 35px 20px 20px;
  background-color: #fff;
  margin: 0 8px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow ul li {
    width: 100%;
    padding: 30px;
    margin: 0 0 20px;
  }
  #parachannel #lp #flow ul li:last-child {
    margin: 0;
  }
}
#parachannel #lp #flow ul li:after {
  content: "";
  width: 40px;
  height: 100%;
  background: url(./img/lp/flow_icon01.png) no-repeat;
  background-size: 100%;
  background-position: center;
  position: absolute;
  right: -25px;
  bottom: 0;
  z-index: 9;
  pointer-events: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow ul li:after {
    width: 100%;
    height: 40px;
    background-size: auto 100%;
    right: 0;
    bottom: -30px;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}
#parachannel #lp #flow ul li:last-child:after {
  display: none;
}
#parachannel #lp #flow ul li p {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow ul li p {
    font-size: 16px;
    margin-bottom: 25px;
  }
}
#parachannel #lp #flow .wrapper > div {
  width: 100vw;
  padding: 30px 0 15px;
  background: url(./img/lp/flow_bg01.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: -15px 0 35px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #flow .wrapper > div {
    min-width: 1000px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .wrapper > div {
    padding: 20px 3%;
    margin: 0 0 25px;
  }
}
#parachannel #lp #flow .wrapper > div > img {
  width: 48.5%;
  max-width: 620px;
  margin-right: 3.5%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .wrapper > div > img {
    width: 100%;
    margin: 0;
  }
}
#parachannel #lp #flow .wrapper > div .text_box {
  width: 430px;
  padding: 40px 0 30px;
  background-color: #fff;
  text-align: center;
  margin-top: 35px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .wrapper > div .text_box {
    width: 100%;
  }
}
#parachannel #lp #flow .wrapper > div .text_box h3 {
  font-size: 28px;
  font-weight: bold;
  line-height: 48px;
  color: #006496;
  margin-bottom: 20px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .wrapper > div .text_box h3 {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 15px;
  }
}
#parachannel #lp #flow .wrapper > div .text_box h3:after {
  content: "";
  width: 30px;
  height: 10px;
  background-color: #fff100;
  position: absolute;
  top: 20px;
  left: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .wrapper > div .text_box h3:after {
    width: 20px;
    height: 5px;
  }
}
#parachannel #lp #flow .wrapper > div .text_box h3 span {
  background: linear-gradient(transparent 90%, #fff100 90%);
  display: inline-block;
}
#parachannel #lp #flow .wrapper > div .text_box p {
  font-size: 18px;
  line-height: 33px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .wrapper > div .text_box p {
    font-size: 14px;
    line-height: 24px;
  }
}
#parachannel #lp #flow .wrapper > div .text_box p:nth-of-type(1) {
  width: 100%;
  padding: 15px 0 30px;
  background: url(./img/lp/flow_bg02.png) no-repeat;
  background-size: auto 100%;
  background-position: center;
  font-weight: bold;
  line-height: 25px;
  color: #fff;
  position: absolute;
  top: -50px;
  left: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .wrapper > div .text_box p:nth-of-type(1) {
    padding: 10px 0 22px;
    line-height: 20px;
    top: -25px;
  }
}
#parachannel #lp #flow .btn {
  width: 640px;
  padding: 28px 0;
  background-image: none;
  background-color: #f05a24;
  box-shadow: 0 0 15px 5px #fff inset, 3px 3px 5px rgba(90, 90, 90, 0.8);
  border-radius: 100px;
  font-size: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .btn {
    width: 100%;
    padding: 20px 0;
    font-size: 20px;
  }
}
#parachannel #lp #flow .btn span {
  font-size: 20px;
  display: block;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .btn span {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
#parachannel #lp #flow .btn img {
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #flow .btn img {
    width: 25px;
    margin-left: 10px;
  }
}
#parachannel #lp #project {
  background-color: #d7f5ff;
}
#parachannel #lp #project .wrapper {
  max-width: 1000px;
  padding: 35px 45px;
  background-color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper {
    padding: 30px 15px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #project .wrapper .ttl_lp {
    font-size: 26px;
    line-height: 44px;
    margin-bottom: 25px;
  }
}
#parachannel #lp #project .wrapper p {
  line-height: 36px;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #project .wrapper p {
    font-size: 18px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper p {
    line-height: 24px;
  }
}
#parachannel #lp #project .wrapper p span {
  font-weight: bold;
  color: #00a0e9;
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) {
  margin: 65px 0 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) {
    margin: 30px 0;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) ul {
    display: block;
    margin-bottom: 30px;
    position: relative;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul li {
  width: 48.5%;
  padding: 30px;
  border: 10px solid;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) ul li {
    width: 100%;
    padding: 20px;
    border-width: 5px;
    margin-bottom: 15px;
    position: static;
  }
  #parachannel #lp #project .wrapper > div:nth-of-type(1) ul li:last-child {
    margin: 0;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul li p {
  margin-top: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) ul li p {
    margin-top: 15px;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul li > img {
  width: 75px;
  position: absolute;
  bottom: -80px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) ul li > img {
    width: 40px;
    bottom: -35px;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul li:nth-child(1) {
  border-color: #d7f5ff;
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul li:nth-child(1) > img {
  right: 90px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) ul li:nth-child(1) > img {
    right: none;
    left: 20px;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul li:nth-child(2) {
  border-color: rgba(5, 106, 135, 0.1);
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) ul li:nth-child(2) > img {
  left: 90px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) ul li:nth-child(2) > img {
    right: 20px;
    left: auto;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(1) > h3 {
  width: 440px;
  margin: 0 auto 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(1) > h3 {
    width: 100%;
    margin-bottom: 25px;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(2) h3 {
  padding: 30px 0 55px;
  background: url(./img/lp/project_bg.png) no-repeat;
  background-size: 100% 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(2) h3 {
    padding: 18px 0 30px;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(2) ul {
  display: flex;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(2) ul {
    display: block;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(2) ul li {
  width: 48.5%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(2) ul li {
    width: 100%;
    margin-bottom: 20px;
  }
  #parachannel #lp #project .wrapper > div:nth-of-type(2) ul li:last-child {
    margin: 0;
  }
}
#parachannel #lp #project .wrapper > div:nth-of-type(2) ul li > div {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}
#parachannel #lp #project .wrapper > div:nth-of-type(2) ul li > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#parachannel #lp #project .wrapper > div:nth-of-type(2) ul li p {
  line-height: 24px;
  font-weight: bold;
  color: #006496;
  margin-top: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(2) ul li p {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #project .wrapper > div:nth-of-type(2) ul li p {
    line-height: 20px;
    margin-top: 10px;
  }
}
#parachannel #lp #about {
  padding: 30px 0;
  background: url(./img/lp/about_bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #about {
    padding: 20px 0;
  }
}
#parachannel #lp #about .btn {
  width: 350px;
  padding: 30px 0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #about .btn {
    width: 200px;
    padding: 18px 0;
    font-size: 14px;
  }
}
#parachannel #lp #about > img {
  width: 105px;
  margin: 0 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #about > img {
    width: 50px;
    margin: 0 10px;
  }
}
#parachannel #lp #voice {
  background-color: #d7f5ff;
}
#parachannel #lp #voice .wrapper {
  max-width: 1000px;
  padding: 65px 50px 0;
  background-color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper {
    padding: 30px 15px 0;
  }
}
#parachannel #lp #voice .wrapper ul {
  padding: 45px 50px;
  background-color: #d7f5ff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul {
    padding: 20px;
  }
}
#parachannel #lp #voice .wrapper ul li {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul li {
    margin-bottom: 15px;
  }
}
#parachannel #lp #voice .wrapper ul li:nth-child(2n) {
  flex-direction: row-reverse;
}
#parachannel #lp #voice .wrapper ul li:nth-child(2n) > div > p {
  text-align: right;
}
#parachannel #lp #voice .wrapper ul li:nth-child(2n) > div > div {
  padding: 0 20px 0 0;
  background-image: url(./img/lp/voice_bg02_pc.png);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul li:nth-child(2n) > div > div {
    padding: 5px 15px 5px 5px;
    background-image: url(./img/lp/voice_bg02_sp.png);
  }
}
#parachannel #lp #voice .wrapper ul li:last-child {
  margin: 0;
}
#parachannel #lp #voice .wrapper ul li img {
  width: 120px;
  border-radius: 50%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul li img {
    width: 60px;
  }
}
#parachannel #lp #voice .wrapper ul li > div {
  width: calc(100% - 135px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul li > div {
    width: calc(100% - 70px);
  }
}
#parachannel #lp #voice .wrapper ul li > div > p {
  padding: 0 25px;
  font-weight: bold;
  color: #006496;
  margin-bottom: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #voice .wrapper ul li > div > p {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul li > div > p {
    padding: 0 10px;
    margin-bottom: 5px;
  }
}
#parachannel #lp #voice .wrapper ul li > div > div {
  width: 100%;
  height: 90px;
  padding-left: 20px;
  background: url(./img/lp/voice_bg01_pc.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul li > div > div {
    height: auto;
    padding: 5px 5px 5px 15px;
    background-image: url(./img/lp/voice_bg01_sp.png);
  }
}
#parachannel #lp #voice .wrapper ul li > div > div p {
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #voice .wrapper ul li > div > div p {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #voice .wrapper ul li > div > div p {
    line-height: 18px;
    text-align: left;
  }
  #parachannel #lp #voice .wrapper ul li > div > div p br {
    display: none;
  }
}
#parachannel #lp #voice .wrapper ul li > div > div p span {
  color: #00a0e9;
}
#parachannel #lp #comparison {
  background-color: #d7f5ff;
}
#parachannel #lp #comparison .wrapper {
  max-width: 1000px;
  padding: 80px 50px 70px;
  background-color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper {
    padding: 30px 15px;
  }
}
#parachannel #lp #comparison .wrapper table {
  width: 100%;
}
#parachannel #lp #comparison .wrapper table tr {
  background-color: #fff;
  border-bottom: 1px #fff solid;
}
#parachannel #lp #comparison .wrapper table tr:last-child {
  border: none;
}
#parachannel #lp #comparison .wrapper table tr th, #parachannel #lp #comparison .wrapper table tr td {
  border-right: 1px #fff solid;
  text-align: center;
  vertical-align: middle;
}
#parachannel #lp #comparison .wrapper table tr th:last-child, #parachannel #lp #comparison .wrapper table tr td:last-child {
  border: none;
}
#parachannel #lp #comparison .wrapper table tr th {
  background-color: #4472c4;
  font-weight: bold;
  color: #fff;
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #comparison .wrapper table tr th {
    font-size: 16px;
  }
}
#parachannel #lp #comparison .wrapper table tr td {
  height: 125px;
  background-color: rgba(240, 240, 240, 0.2);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper table tr td {
    height: 110px;
  }
}
#parachannel #lp #comparison .wrapper table tr td img {
  width: 55px;
  margin: 0 auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper table tr td img {
    width: 40px;
  }
}
#parachannel #lp #comparison .wrapper table tr td p {
  line-height: 18px;
  margin-top: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper table tr td p {
    font-size: 10px;
    line-height: 12px;
    margin-top: 5px;
  }
}
#parachannel #lp #comparison .wrapper table tr td:nth-child(2) p {
  font-size: 16px;
  font-weight: bold;
  color: #e51b5e;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper table tr td:nth-child(2) p {
    font-size: 12px;
    line-height: 14px;
  }
}
#parachannel #lp #comparison .wrapper table tr:nth-child(1) th {
  width: 28%;
  height: 65px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper table tr:nth-child(1) th {
    width: 25%;
    height: 50px;
  }
}
#parachannel #lp #comparison .wrapper table tr:nth-child(1) th:nth-child(1) {
  width: 12%;
  background: none;
  background-color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper table tr:nth-child(1) th:nth-child(1) {
    width: 20%;
  }
}
#parachannel #lp #comparison .wrapper table tr:nth-child(1) th:nth-child(2) {
  width: 32%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper table tr:nth-child(1) th:nth-child(2) {
    width: 30%;
  }
}
#parachannel #lp #comparison .wrapper table tr th:nth-child(1), #parachannel #lp #comparison .wrapper table tr:nth-child(1) th:nth-child(2) {
  background: url(./img/lp/about_bg.jpg) no-repeat;
  color: #006496;
}
#parachannel #lp #comparison .wrapper table tr:nth-child(2n+1) td {
  background-color: rgba(207, 213, 234, 0.3);
}
#parachannel #lp #comparison .wrapper > p {
  text-align: right;
  margin-top: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #comparison .wrapper > p {
    font-size: 10px;
    margin-top: 10px;
  }
}
#parachannel #lp #comparison .wrapper > p a {
  text-decoration: underline;
  display: inline-block;
}
#parachannel #lp #faq {
  padding-top: 70px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq {
    padding-top: 30px;
  }
}
#parachannel #lp #faq ul {
  padding: 60px 100px 110px;
  border: 8px #d7f5ff solid;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq ul {
    padding: 20px;
    border-width: 4px;
  }
}
#parachannel #lp #faq ul li {
  margin-bottom: 65px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq ul li {
    margin-bottom: 30px;
  }
}
#parachannel #lp #faq ul li:last-child {
  margin: 0;
}
#parachannel #lp #faq ul li h3, #parachannel #lp #faq ul li p {
  padding: 0 60px 0 145px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq ul li h3, #parachannel #lp #faq ul li p {
    padding: 0 5px 0 30px;
  }
}
#parachannel #lp #faq ul li h3:after, #parachannel #lp #faq ul li p:after {
  position: absolute;
  top: 0;
  left: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq ul li h3:after, #parachannel #lp #faq ul li p:after {
    left: 5px;
  }
}
#parachannel #lp #faq ul li h3 {
  padding-bottom: 20px;
  background: url(./img/lp/faq_icon01.png) no-repeat;
  background-size: 10px;
  background-position: top 10px right 15px;
  border-bottom: 1px #006496 solid;
  font-size: 20px;
  font-weight: bold;
  color: #00a0e9;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq ul li h3 {
    padding-bottom: 10px;
    background-position: top 5px right 5px;
    font-size: 14px;
  }
}
#parachannel #lp #faq ul li h3:after {
  content: "Q.";
}
#parachannel #lp #faq ul li h3.active {
  background-image: url(./img/lp/faq_icon02.png);
}
#parachannel #lp #faq ul li p {
  display: none;
  line-height: 28px;
  margin-top: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #lp #faq ul li p {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq ul li p {
    line-height: 24px;
    margin-top: 10px;
  }
}
#parachannel #lp #faq ul li p:after {
  content: "A.";
  font-size: 20px;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #lp #faq ul li p:after {
    font-size: 14px;
  }
}
#parachannel #lp #faq ul li p a {
  text-decoration: underline;
  word-wrap: break-word;
  display: inline-block;
}

#parachannel #contact .list_box {
  height: 80px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .list_box {
    height: 65px;
    padding: 0 3%;
    flex-wrap: wrap;
  }
}
#parachannel #contact .list_box p {
  width: 130px;
  margin-right: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .list_box p {
    width: 120px;
    margin: 0 auto 10px;
  }
}
#parachannel #contact .list_box > div {
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .list_box > div {
    overflow: scroll;
  }
}
#parachannel #contact .list_box > div ul {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .list_box > div ul {
    width: 620px;
  }
}
#parachannel #contact .list_box > div ul li {
  margin-right: 10px;
}
#parachannel #contact .list_box > div ul li:last-child {
  margin: 0;
}
#parachannel #contact .list_box > div ul li a {
  width: 60px;
  padding: 5px 0;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #contact .list_box > div ul li a {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .list_box > div ul li a {
    padding: 4px 0;
  }
}
#parachannel #contact .list_box > div ul li a:hover {
  background-color: #00a0e9;
  color: #fff;
  opacity: 1;
}
#parachannel #contact h2 {
  margin: 50px auto 30px;
  font-size: 180%;
  font-weight: bold;
  text-align: center;
}
#parachannel #contact h3 {
  margin: 50px auto 10px;
  font-size: 150%;
  font-weight: bold;
  text-align: center;
}
#parachannel #contact .read {
  font-size: 120%;
  text-align: center;
  line-height: 1.5;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .read {
    font-size: 100%;
  }
}
#parachannel #contact .guide_bnr {
  width: 600px;
  margin: 50px auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .guide_bnr {
    width: 90%;
    margin: 20px auto 30px;
  }
}
#parachannel #contact .mail {
  margin: 50px auto 0;
  padding-top: 50px;
  border-top: solid 1px #ccc;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .mail {
    margin-top: 30px;
    padding-top: 30px;
  }
  #parachannel #contact .mail .read {
    text-align: justify;
  }
}
#parachannel #contact .mail_area {
  width: 100%;
  /*横向きの際に適用*/
}
#parachannel #contact .mail_area iframe {
  width: 100%;
  height: 100%;
}
@media print, screen and (min-width: 769px) {
  #parachannel #contact .mail_area {
    width: 640px;
    height: 60vh;
    margin: 0 auto;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .mail_area {
    width: 100%;
    height: 500px;
  }
}
#parachannel #contact .tel {
  margin: 30px auto 0;
  padding-top: 0;
  border-top: solid 1px #ccc;
}
#parachannel #contact .tel ._flex {
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #contact .tel ._flex {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .tel ._flex {
    margin-top: 30px;
  }
}
#parachannel #contact .tel ._flex div {
  width: 40%;
  margin: 0 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .tel ._flex div {
    width: 100%;
    margin: 20px auto;
  }
}
#parachannel #contact .tel ._flex ._left {
  padding: 2%;
  border: solid 1px #000;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .tel ._flex ._left {
    padding: 5%;
  }
}
#parachannel #contact .tel ._flex ._left h4 {
  text-align: center;
  font-size: 120%;
  font-weight: bold;
  line-height: 1.5;
}
#parachannel #contact .tel ._flex ._left p {
  margin: 30px 0;
  font-size: 180%;
  font-weight: bold;
  text-align: center;
  color: #c30d23;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .tel ._flex ._left p {
    margin: 20px 0;
  }
}
#parachannel #contact .tel ._flex ._left small {
  display: block;
  text-align: center;
}
#parachannel #contact .tel ._flex ._right h4 {
  font-size: 120%;
  font-weight: bold;
  line-height: 1.5;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .tel ._flex ._right h4 {
    margin-top: 30px;
    text-align: center;
  }
}
#parachannel #contact .tel ._flex ._right p {
  margin: 10px 0 30px;
  font-size: 120%;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #contact .tel ._flex ._right p {
    text-align: center;
  }
}
#parachannel #contact .tel ._flex ._right small {
  display: block;
  text-align: center;
}
#parachannel #contact .tel ._flex ._right ul li {
  position: relative;
  margin: 5px 0;
  padding-left: 20px;
  line-height: 1.4;
}
#parachannel #contact .tel ._flex ._right ul li span {
  position: absolute;
  left: 0;
}

#parachannel #company #scouts {
  /* 共通 */
  /* top */
  /* candidate */
  /* applicant */
  /* entry */
  /* confirm */
  /* complete */
  /* history */
  /* saved_conditions */
  /* mypage */
  /* mypage top */
  /* mypage company */
}
#parachannel #company #scouts a {
  cursor: pointer;
}
#parachannel #company #scouts .btn {
  width: 225px;
  padding: 13px 0;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .btn {
    width: 200px;
  }
}
#parachannel #company #scouts .btn.green {
  background-color: #76debf;
}
#parachannel #company #scouts .btn.orange {
  background-color: #f15a24;
}
#parachannel #company #scouts .btn.none {
  background-color: #f2f2f2;
  pointer-events: none;
}
#parachannel #company #scouts .edit_box .list_item {
  position: relative;
}
#parachannel #company #scouts .edit_box .list_item select {
  width: 200px;
  height: 35px;
  background: url(./img/top/arrow04.png) no-repeat;
  background-size: 12px;
  background-position: right 15px center;
  border: 1px #b3b3b3 solid;
  position: absolute;
  top: 0;
  right: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box .list_item select {
    margin: 0 0 15px auto;
    position: static;
  }
}
#parachannel #company #scouts .edit_box .list_item ul {
  display: flex;
}
#parachannel #company #scouts .edit_box .list_item ul li {
  width: 180px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box .list_item ul li {
    width: 50%;
  }
}
#parachannel #company #scouts .edit_box .list_item ul li a {
  width: 100%;
  padding: 17px 0;
  background-color: #f2f2f2;
  font-size: 16px;
  text-align: center;
  color: #9e9f9f;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box .list_item ul li a {
    padding: 13px 0;
    font-size: 14px;
  }
}
#parachannel #company #scouts .edit_box .list_item ul li.active a {
  background-color: #00a0e9;
  color: #fff;
}
#parachannel #company #scouts .edit_box .list_item > div {
  padding: 14px 0;
  border: 1px #b3b3b3 solid;
  border-bottom: none;
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box .list_item > div {
    padding: 12px 0;
  }
}
#parachannel #company #scouts .edit_box .list_item > div label {
  width: 40px;
  display: block;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box .list_item > div label {
    width: 30px;
  }
}
#parachannel #company #scouts .edit_box .list_item > div label input {
  display: block;
  margin: 0 auto;
}
#parachannel #company #scouts .edit_box .list_item > div .btn {
  margin: 0 0 0 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box .list_item > div .btn {
    width: 150px;
    padding: 9px 0;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    margin-left: 10px;
  }
}
#parachannel #company #scouts .edit_box > p {
  text-align: center;
  color: #325a78;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box > p {
    margin-bottom: 10px;
  }
}
#parachannel #company #scouts .edit_box > p span {
  font-size: 30px;
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .edit_box > p span {
    font-size: 24px;
  }
}
#parachannel #company #scouts .edit_box .btn {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
}
#parachannel #company #scouts .list_box {
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .list_box {
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts .list_box + div {
  padding: 0 !important;
  border: none !important;
}
#parachannel #company #scouts .item_box {
  width: calc(100% - 40px) !important;
  padding: 20px 30px 20px 20px;
  border-left: 1px #b3b3b3 solid;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box {
    width: calc(100% - 30px) !important;
    padding: 15px 10px;
    display: block;
  }
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) {
  width: calc(100% - 200px);
  padding-right: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) {
    width: 100%;
    padding: 0;
  }
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div {
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div .img_box {
  width: 50px;
  height: 50px;
  padding: 0;
  border-radius: 50%;
  margin-right: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div .img_box {
    margin-right: 10px;
  }
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div .img_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: static;
  transform: translateX(0);
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div h4 {
  line-height: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div h4 {
    line-height: 18px;
  }
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div p {
  width: auto;
  color: #e44e66;
  margin-left: 10px;
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) > div > a {
  width: 100%;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  align-content: center;
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) ul {
    margin: 10px 0 15px;
  }
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) ul li {
  width: 49%;
  padding: 10px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) ul li {
    width: 100%;
    line-height: 16px;
  }
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) ul li:nth-child(1) {
  width: 100%;
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) ul li:last-child {
  border-bottom: 1px #b3b3b3 solid;
}
#parachannel #company #scouts .item_box > div:not(.btn_box):not(.tag_box) ul li span {
  color: #b3b3b3;
}
#parachannel #company #scouts .item_box .btn_box {
  width: 200px;
  display: block;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box .btn_box {
    width: 100%;
  }
}
#parachannel #company #scouts .item_box .btn_box .btn {
  background-image: none;
  box-shadow: none;
  text-align: center;
  display: block;
  margin: 0 auto 10px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts .item_box .btn_box .btn {
    width: 100%;
  }
}
#parachannel #company #scouts .item_box .btn_box .btn:last-child {
  margin-bottom: 0;
}
#parachannel #company #scouts .item_box .tag_box {
  width: calc(100% + 50px);
  padding: 10px 30px 10px 20px;
  border-top: 1px #b3b3b3 solid;
  margin: 20px -30px -20px -20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box .tag_box {
    width: calc(100% + 20px);
    padding: 15px 10px;
    margin: 15px -10px -15px;
  }
}
#parachannel #company #scouts .item_box .tag_box ul li {
  border: none;
}
#parachannel #company #scouts .item_box.online > div:not(.btn_box):not(.tag_box) {
  position: relative;
}
#parachannel #company #scouts .item_box.online > div:not(.btn_box):not(.tag_box):after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #00b900;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 0;
}
#parachannel #company #scouts .item_box .tooltip_box {
  margin-left: 10px;
}
#parachannel #company #scouts .item_box .tooltip_box .countdown {
  margin: 0;
}
#parachannel #company #scouts .item_box .tooltip_box .tooltip {
  position: relative;
  z-index: 999;
}
#parachannel #company #scouts .item_box .tooltip_box .tooltip .icon {
  width: 20px;
  cursor: pointer;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box .tooltip_box .tooltip .icon {
    width: 20px;
  }
}
#parachannel #company #scouts .item_box .tooltip_box .tooltip .text {
  width: 345px;
  padding: 10px;
  background-color: #00a0e9;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 35px;
  z-index: 999;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box .tooltip_box .tooltip .text {
    width: 250px;
    top: -115px;
    left: 50%;
    transform: translateX(-50%);
  }
  #parachannel #company #scouts .item_box .tooltip_box .tooltip .text br:nth-of-type(2) {
    display: none;
  }
}
#parachannel #company #scouts .item_box .tooltip_box .tooltip .text:after {
  content: none;
  width: 10px;
  height: 20px;
  border-top: 10px transparent solid;
  border-bottom: 10px transparent solid;
  border-right: 10px #00a0e9 solid;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: -10px;
  bottom: 0;
  margin: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .item_box .tooltip_box .tooltip .text:after {
    width: 20px;
    height: 10px;
    border-top: 10px #00a0e9 solid;
    border-right: 10px transparent solid;
    border-bottom: none;
    border-left: 10px transparent solid;
    top: auto;
    right: 0;
    bottom: -10px;
    left: 0;
  }
}
#parachannel #company #scouts .item_box .tooltip_box .tooltip:hover .text {
  opacity: 1;
}
#parachannel #company #scouts .tag_box {
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .tag_box {
    display: block;
  }
}
#parachannel #company #scouts .tag_box h5 {
  font-size: 15px;
  color: #606e84;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .tag_box h5 {
    font-size: 13px;
  }
}
#parachannel #company #scouts .tag_box ul {
  display: flex;
  margin-left: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .tag_box ul {
    flex-wrap: wrap;
    margin: 5px 0 0;
  }
}
#parachannel #company #scouts .tag_box ul li {
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .tag_box ul li {
    margin: 5px 5px 0 0;
  }
}
#parachannel #company #scouts .tag_box ul li a {
  padding: 9px 15px;
  background: none;
  background-color: #f2f2f2;
  border-radius: 5px;
  font-size: 12px;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts .tag_box ul li a {
    padding: 10px;
    font-size: 12px;
  }
}
#parachannel #company #scouts.saved_conditions ul li {
  padding: 26px 20px;
  border-bottom: 1px #b3b3b3 solid;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions ul li {
    padding: 15px 10px;
    display: block;
  }
}
#parachannel #company #scouts.saved_conditions ul li a:nth-child(1) {
  width: calc(100% - 140px);
  font-size: 16px;
  line-height: 34px;
  word-break: break-all;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions ul li a:nth-child(1) {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
  }
}
#parachannel #company #scouts.saved_conditions ul li a:nth-child(1):hover {
  color: #00a0e9;
  opacity: 1;
}
#parachannel #company #scouts.saved_conditions ul li a:nth-child(2) {
  width: 120px;
  padding: 13px 0;
  background-color: #e6e6e6;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions ul li a:nth-child(2) {
    width: 100px;
    padding: 9px 0;
    border-radius: 5px;
    font-size: 12px;
    margin: 10px 0 0 auto;
  }
}
#parachannel #company #scouts.top .edit_box .pagination li {
  border: none;
}
#parachannel #company #scouts.top .edit_box .cont {
  padding: 0;
  border: none;
  display: block;
  margin: 30px 0 20px;
}
#parachannel #company #scouts.top .edit_box .cont h3 {
  font-size: 20px;
  text-align: center;
  display: block;
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont h3 {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.top .edit_box .cont h3 span {
  width: auto;
  padding: 0;
  border: none;
  font-size: 14px;
  color: #606e84;
  margin: 0;
}
#parachannel #company #scouts.top .edit_box .cont h3 span span {
  font-size: 50px;
  color: #00a0e9;
  margin: 0 5px 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont h3 span span {
    font-size: 30px;
    margin-left: 5px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form {
  padding: 20px 40px 30px;
  background-color: #f2f2f2;
  font-size: 16px;
  display: block;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form {
    padding: 20px;
    font-size: 14px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form h4 {
  color: #606e84;
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form h4 {
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form ul {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #company #scouts.top .edit_box .cont > form ul li {
  line-height: 28px;
  color: #9e9f9f;
  margin-right: 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form ul li {
    margin-right: 20px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form > div > a {
  width: 50px;
  position: absolute;
  right: 15px;
  bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form > div > a {
    width: 45px;
    right: 10px;
    bottom: 10px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box {
    margin: 30px 0;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dt, #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd {
  margin-bottom: 20px;
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dt:last-of-type, #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd:last-of-type {
  margin: 0;
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dt {
  width: 170px;
  line-height: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dt {
    width: 120px;
    line-height: 35px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dd {
  width: calc(100% - 170px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd {
    width: calc(100% - 120px);
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dd select {
  background: #fff url(./img/top/arrow04.png) no-repeat;
  background-size: 12px;
  background-position: right 15px center;
  margin-right: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd select {
    margin: 0 10px 10px 0;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dd select.type1 {
  width: 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd select.type1 {
    width: 75px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dd select.type2 {
  width: 170px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd select.type2 {
    width: 145px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dd .btn {
  width: auto;
  padding: 10px 20px;
  background-color: #325a78;
  box-shadow: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd .btn {
    padding: 10px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dd .btn:before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(./img/company/icon17.png) no-repeat;
  background-size: 100%;
  display: block;
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd .btn:before {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .edit_box dd > span {
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  margin-top: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .edit_box dd > span {
    margin: 0;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .btn_box1 {
  display: flex;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 15px;
  right: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .btn_box1 {
    flex-wrap: wrap;
    position: static;
    margin-bottom: 15px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .btn_box1 a {
  text-decoration: underline;
  color: #00a0e9;
  margin-left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .btn_box1 a {
    margin: 0 10px 0 0;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .btn_box1 a.reset {
  padding: 10px;
  background-color: #00a0e9;
  color: #fff;
  text-decoration: none;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .btn_box1 a.reset {
    margin: 15px 0 0;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .btn_box2 {
  width: 200px;
  margin: 15px auto 0;
  position: relative;
}
#parachannel #company #scouts.top .edit_box .cont > form .btn_box2 .btn {
  width: 100%;
  background-color: #e44e66;
  box-shadow: none;
}
#parachannel #company #scouts.top .edit_box .cont > form .btn_box2 a:not(.btn) {
  font-size: 14px;
  text-decoration: underline;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.top .edit_box .cont > form .btn_box2 a:not(.btn) {
    position: absolute;
    top: 50%;
    right: -160px;
    transform: translateY(-50%);
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top .edit_box .cont > form .btn_box2 a:not(.btn) {
    text-align: center;
    margin-top: 15px;
  }
}
#parachannel #company #scouts.top .edit_box .cont > form .btn_box2 a:not(.btn) img {
  width: 8px;
  display: inline-block;
  margin-right: 5px;
}
#parachannel #company #scouts.top > div:not(.edit_box) .btn {
  width: 300px;
  background-color: #00a0e9;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  margin: -50px auto 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.top > div:not(.edit_box) .btn {
    margin-top: -25px;
  }
}
#parachannel #company #scouts.top > div:not(.edit_box) .btn br {
  display: none;
}
#parachannel #company #scouts.candidate .edit_box .cont {
  padding: 0;
  border: none;
  display: block;
  margin: 30px 0;
}
#parachannel #company #scouts.candidate .edit_box .cont h3 {
  font-size: 20px;
  color: #606e84;
  text-align: center;
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.candidate .edit_box .cont h3 {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.candidate .edit_box .cont h3 > span {
  font-size: 14px;
  margin: 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.candidate .edit_box .cont h3 > span {
    margin: 0 5px;
  }
}
#parachannel #company #scouts.candidate .edit_box .cont h3 > span span {
  font-size: 50px;
  color: #00a0e9;
  margin-right: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.candidate .edit_box .cont h3 > span span {
    font-size: 30px;
    margin-left: 5px;
  }
}
#parachannel #company #scouts.candidate .edit_box .cont h3:nth-of-type(2) {
  margin: 35px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.candidate .edit_box .cont h3:nth-of-type(2) {
    margin-top: 20px;
  }
}
#parachannel #company #scouts.candidate .edit_box .cont h3:nth-of-type(2) span span {
  color: #f15a24;
}
#parachannel #company #scouts.candidate .edit_box .cont form {
  width: 100%;
}
#parachannel #company #scouts.candidate .edit_box .cont form select {
  width: 140px;
  height: 30px;
  background: url(./img/top/arrow04.png) no-repeat;
  background-size: 12px;
  background-position: right 15px center;
  border-radius: 5px;
  margin-bottom: 10px;
}
#parachannel #company #scouts.candidate .edit_box .cont form select:nth-of-type(2) {
  width: 520px;
  background-image: url(./img/mypage/arrow01.png);
  background-color: #ecfbff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.candidate .edit_box .cont form select:nth-of-type(2) {
    width: 100%;
  }
}
#parachannel #company #scouts.applicant .item_box {
  width: 100% !important;
  padding: 0;
  border: none;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.applicant .item_box {
    font-size: 16px;
  }
  #parachannel #company #scouts.applicant .item_box > div:not(.btn_box):not(.tag_box) > div:nth-of-type(1) .img_box {
    width: 60px;
    height: 60px;
    margin-right: 30px;
  }
  #parachannel #company #scouts.applicant .item_box > div:not(.btn_box):not(.tag_box) > div p {
    margin-left: 20px;
  }
}
#parachannel #company #scouts.applicant .cont {
  margin-top: 35px;
  position: relative;
}
#parachannel #company #scouts.applicant .cont > div:not(.btn_box) {
  margin-bottom: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.applicant .cont > div:not(.btn_box) {
    margin-bottom: 30px;
  }
}
#parachannel #company #scouts.applicant .cont > div:not(.btn_box) h3 {
  padding-left: 15px;
  border-left: 8px #606e84 solid;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.applicant .cont > div:not(.btn_box) h3 {
    padding-left: 10px;
    border-left-width: 5px;
    font-size: 16px;
    margin-bottom: 15px;
  }
}
#parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl {
  display: flex;
  flex-wrap: wrap;
}
#parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dt, #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dd {
  padding: 25px 30px;
  border-top: 1px #b3b3b3 solid;
  line-height: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dt, #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dd {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dt, #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dd {
    padding: 10px 15px;
    line-height: 20px;
  }
}
#parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dt:last-of-type, #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dd:last-of-type {
  border-bottom: 1px #b3b3b3 solid;
}
#parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dt {
  width: 270px;
  background-color: #f1f1f1;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dt {
    width: 100px;
  }
}
#parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dd {
  width: calc(100% - 270px);
  word-wrap: break-word;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.applicant .cont > div:not(.btn_box) dl dd {
    width: calc(100% - 100px);
  }
}
#parachannel #company #scouts.applicant .cont .btn_box {
  display: flex;
  justify-content: center;
  margin: 60px 0 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.applicant .cont .btn_box {
    display: block;
    margin: 30px 0 15px;
  }
}
#parachannel #company #scouts.applicant .cont .btn_box .btn {
  margin: 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.applicant .cont .btn_box .btn {
    margin: 0 auto 10px;
  }
  #parachannel #company #scouts.applicant .cont .btn_box .btn:last-child {
    margin-bottom: 0;
  }
}
#parachannel #company #scouts.applicant .cont > a {
  text-align: center;
  text-decoration: underline;
  color: #00a0e9;
}
#parachannel #company #scouts.applicant .cont > a:nth-of-type(1) {
  position: absolute;
  top: 0;
  right: 0;
}
#parachannel #company #scouts.entry form > div {
  margin-bottom: 60px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form > div {
    margin-bottom: 40px;
  }
}
#parachannel #company #scouts.entry form > div h3 {
  font-size: 20px;
  font-weight: bold;
  color: #606e84;
  margin-bottom: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form > div h3 {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.entry form > div h3 span {
  color: #00a0e9;
  margin-right: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form > div h3 span {
    margin-right: 10px;
  }
}
#parachannel #company #scouts.entry form .cont1 {
  padding: 0;
}
#parachannel #company #scouts.entry form .cont1:after {
  display: none;
}
#parachannel #company #scouts.entry form .cont1 ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont1 ul {
    margin-top: -20px;
  }
}
#parachannel #company #scouts.entry form .cont1 ul li {
  margin: 30px 9px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont1 ul li {
    width: 33.33%;
    margin: 20px 0 0;
  }
}
#parachannel #company #scouts.entry form .cont1 ul li > div {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin-bottom: 15px;
  overflow: hidden;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont1 ul li > div {
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
  }
}
#parachannel #company #scouts.entry form .cont1 ul li img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#parachannel #company #scouts.entry form .cont1 ul li p {
  text-align: center;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.entry form .cont1 ul li p {
    font-size: 15px;
  }
}
#parachannel #company #scouts.entry form .cont2 .checkbox {
  display: flex;
  flex-wrap: wrap;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont2 .checkbox {
    flex-wrap: wrap;
  }
}
#parachannel #company #scouts.entry form .cont2 .checkbox > div {
  margin: 0 10px 10px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont2 .checkbox > div {
    margin: 0 5px 5px 0;
  }
}
#parachannel #company #scouts.entry form .cont2 .checkbox > div input {
  display: none;
}
#parachannel #company #scouts.entry form .cont2 .checkbox > div input:checked + label {
  background-color: #76debf;
  border-color: #76debf;
  color: #fff;
}
#parachannel #company #scouts.entry form .cont2 .checkbox > div label {
  padding: 10px 20px;
  border: 1px #ccc solid;
  border-radius: 30px;
  color: #ccc;
  display: block;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.entry form .cont2 .checkbox > div label {
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont2 .checkbox > div label {
    padding: 8px 10px;
  }
}
#parachannel #company #scouts.entry form .cont2 > a {
  text-decoration: underline;
  color: #00a0e9;
  margin-top: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont2 > a {
    margin-top: 10px;
  }
}
#parachannel #company #scouts.entry form .cont3 label {
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont3 label {
    margin: 0 0 5px;
  }
}
#parachannel #company #scouts.entry form .cont3 label input {
  width: 15px;
  height: 15px;
  margin: 0 10px 0 0;
}
#parachannel #company #scouts.entry form .cont3 textarea {
  height: 300px;
  border-radius: 0;
  margin: 30px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont3 textarea {
    height: 150px;
    margin: 15px 0;
  }
}
#parachannel #company #scouts.entry form .cont3 dl {
  display: flex;
  align-items: center;
  align-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont3 dl {
    display: block;
  }
}
#parachannel #company #scouts.entry form .cont3 dl dt {
  margin-right: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont3 dl dt {
    font-size: 14px;
    margin: 0 0 10px;
  }
}
#parachannel #company #scouts.entry form .cont3 dl dd {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont3 dl dd {
    display: block;
  }
}
#parachannel #company #scouts.entry form .cont3 dl dd span {
  padding: 3px 6px;
  border: 1px #e44e66 solid;
  border-radius: 30px;
  font-size: 12px;
  color: #e44e66;
  margin-left: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont3 dl dd span {
    margin: 0 0 0 10px;
  }
}
#parachannel #company #scouts.entry form .cont3 dl:nth-of-type(2) {
  margin: 20px 0 0 25px;
}
#parachannel #company #scouts.entry form .cont3 dl:nth-of-type(2) input {
  width: 380px;
  height: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.entry form .cont3 dl:nth-of-type(2) input {
    width: 100%;
    height: 30px;
  }
}
#parachannel #company #scouts.entry form .btn_box {
  margin: 0;
}
#parachannel #company #scouts.entry form .btn_box .btn.back {
  background-color: #ccc;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.entry form .btn_box .btn.back {
    width: 130px;
  }
}
#parachannel #company #scouts.confirm form .cont3 h4 {
  font-weight: bold;
  color: #00a0e9;
  margin: 50px 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.confirm form .cont3 h4 {
    font-size: 14px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.confirm form .cont3 h4 {
    margin: 20px 0 5px;
  }
}
#parachannel #company #scouts.confirm form .cont3 .indent {
  padding-left: 1em;
}
#parachannel #company #scouts.confirm form .cont3 .indent img {
  width: 300px;
  margin: 20px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.confirm form .cont3 .indent img {
    width: 250px;
  }
}
#parachannel #company #scouts.confirm form .cont3 .indent p {
  text-align: left;
  margin: 0;
}
#parachannel #company #scouts.confirm form .cont3 p {
  font-weight: normal;
  line-height: 24px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.confirm form .cont3 p {
    font-size: 14px;
  }
}
#parachannel #company #scouts.confirm form .cont3 p span {
  display: block;
}
#parachannel #company #scouts.confirm form .cont3 p a {
  text-decoration: underline;
  color: #00a0e9;
  display: inline-block;
}
#parachannel #company #scouts.confirm form .cont3 p.cont3__content {
  white-space: pre-line;
}
#parachannel #company #scouts.confirm form .cont3 dl {
  padding: 40px 10px;
  border-top: 1px #b3b3b3 solid;
  border-bottom: 1px #b3b3b3 solid;
  color: #606e84;
  margin: 35px 0 30px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.confirm form .cont3 dl {
    font-size: 18px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.confirm form .cont3 dl {
    padding: 20px 0;
    margin: 20px 0;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.confirm form .cont3 dl dt {
    margin: 0;
  }
}
#parachannel #company #scouts.confirm form .cont3 > div p {
  text-align: center;
  margin-bottom: 40px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.confirm form .cont3 > div p {
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.confirm form .cont3 > div label {
  justify-content: center;
}
#parachannel #company #scouts.complete {
  padding: 130px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.complete {
    padding: 30px 0;
  }
}
#parachannel #company #scouts.complete p {
  font-size: 18px;
  line-height: 100%;
  color: #606e84;
  margin-bottom: 60px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.complete p {
    font-size: 14px;
    margin-bottom: 30px;
  }
}
#parachannel #company #scouts.complete .btn_box {
  display: block;
  margin: 0;
}
#parachannel #company #scouts.complete .btn_box .btn {
  height: auto;
  background-color: #00a0e9;
  line-height: 100%;
  margin: 0 auto 10px;
}
#parachannel #company #scouts.complete .btn_box .btn.back {
  background-color: #8d8d8d;
  margin-bottom: 0;
}
#parachannel #company #scouts.history h3 {
  font-size: 20px;
  font-weight: bold;
  color: #606e84;
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history h3 {
    font-size: 15px;
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.history .link {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .link {
    margin-top: 15px;
  }
}
#parachannel #company #scouts.history .link li {
  margin-left: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .link li {
    margin: 0 15px 0 0;
  }
}
#parachannel #company #scouts.history .link li a {
  font-weight: bold;
  color: #606e84;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
}
#parachannel #company #scouts.history .link li a:before {
  content: "";
  width: 16px;
  height: 16px;
  background: #ccc url(./img/lp/fixed_icon.png) no-repeat;
  background-size: 5px;
  background-position: center;
  border-radius: 50%;
  display: block;
  margin-right: 10px;
  transition: all 0.2s;
}
#parachannel #company #scouts.history .link li a > span {
  font-size: 12px;
  color: #00a0e9;
}
#parachannel #company #scouts.history .link li a > span span {
  font-size: 26px;
  margin: 0 5px 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .link li a > span span {
    font-size: 20px;
    margin: 0 5px;
  }
}
#parachannel #company #scouts.history .link li a:hover:before {
  background-color: #00a0e9;
}
#parachannel #company #scouts.history .cont1 {
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont1 {
    margin-bottom: 30px;
  }
}
#parachannel #company #scouts.history .cont1 ul {
  display: flex;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont1 ul {
    display: block;
  }
}
#parachannel #company #scouts.history .cont1 ul li {
  width: 32%;
  border: 1px #b3b3b3 solid;
  font-weight: bold;
  text-align: center;
  margin-right: 2%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont1 ul li {
    width: 100%;
    margin-bottom: 15px;
  }
}
#parachannel #company #scouts.history .cont1 ul li:last-child {
  margin: 0;
}
#parachannel #company #scouts.history .cont1 ul li h4 {
  padding: 17px 0;
  background-color: #606e84;
  font-size: 16px;
  color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont1 ul li h4 {
    padding: 13px 0;
    font-size: 14px;
  }
}
#parachannel #company #scouts.history .cont1 ul li p {
  margin: 25px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont1 ul li p {
    margin: 20px 0;
  }
}
#parachannel #company #scouts.history .cont1 ul li p:nth-of-type(1) {
  font-size: 26px;
  color: #ccc;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont1 ul li p:nth-of-type(1) {
    font-size: 16px;
  }
}
#parachannel #company #scouts.history .cont1 ul li p:nth-of-type(1) span {
  font-size: 50px;
  font-weight: normal;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont1 ul li p:nth-of-type(1) span {
    font-size: 30px;
  }
}
#parachannel #company #scouts.history .cont1 ul li p:nth-of-type(1) span span {
  font-weight: bold;
  color: #00a0e9;
}
#parachannel #company #scouts.history .cont1 ul li p:nth-of-type(2) {
  font-weight: normal;
  margin-top: -5px;
}
#parachannel #company #scouts.history .cont2 > ul:not(.pagination) > li {
  padding: 20px 0 15px 10px;
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont2 > ul:not(.pagination) > li {
    padding: 15px 0;
    display: block;
  }
}
#parachannel #company #scouts.history .cont2 > ul:not(.pagination) > li > div p {
  color: #b3b3b3;
  margin-bottom: 10px;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.history .cont2 > ul:not(.pagination) > li > div p {
    font-size: 18px;
  }
}
#parachannel #company #scouts.history .cont2 > ul:not(.pagination) > li > div .tag_box:after {
  content: "";
  width: 8px;
  height: 12px;
  background: url(./img/company/icon18.png) no-repeat;
  background-size: 100%;
  display: inline-block;
  margin-left: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont2 > ul:not(.pagination) > li > div .tag_box:after {
    display: none;
  }
}
#parachannel #company #scouts.history .cont2 .pagination {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  margin-top: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history .cont2 .pagination {
    margin-top: 15px;
  }
}
#parachannel #company #scouts.history .cont2 .pagination li {
  width: 20px;
  margin-right: 5px;
}
#parachannel #company #scouts.history .cont2 .pagination li:last-child {
  margin: 0;
}
#parachannel #company #scouts.history .cont2 .pagination li a {
  width: 100%;
  padding: 4px 0;
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
}
#parachannel #company #scouts.history .cont2 .pagination li.arrow a {
  padding: 0;
}
#parachannel #company #scouts.history .cont2 .pagination li.active {
  background-color: #00a0e9;
}
#parachannel #company #scouts.history .cont2 .pagination li.active a {
  color: #fff;
}
#parachannel #company #scouts.history.detail h3 {
  padding: 16px 30px;
  background-color: #006496;
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history.detail h3 {
    padding: 13px 15px;
    font-size: 14px;
  }
}
#parachannel #company #scouts.history.detail h3 span {
  font-size: 16px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history.detail h3 span {
    font-size: 12px;
  }
}
#parachannel #company #scouts.history.detail > div {
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history.detail > div {
    display: block;
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.history.detail p {
  padding: 30px;
  border: 1px #b3b3b3 solid;
  line-height: 24px;
  white-space: pre-line;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history.detail p {
    padding: 15px;
  }
}
#parachannel #company #scouts.history.detail .btn {
  background-color: #8d8d8d;
  margin: 65px auto 0;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.history.detail .btn {
    width: 300px;
    padding: 17px 0;
    font-size: 16px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.history.detail .btn {
    margin-top: 30px;
  }
}
#parachannel #company #scouts.saved_conditions {
  position: relative;
}
#parachannel #company #scouts.saved_conditions > a {
  text-decoration: underline;
  color: #00a0e9;
  display: flex;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions > a {
    position: static;
  }
}
#parachannel #company #scouts.saved_conditions > a img {
  width: 25px;
  margin-right: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions > a img {
    width: 20px;
    margin-right: 10px;
  }
}
#parachannel #company #scouts.saved_conditions h3 {
  padding-bottom: 30px;
  border-bottom: 1px #606e84 solid;
  font-size: 22px;
  text-align: center;
  color: #606e84;
  margin-bottom: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions h3 {
    padding-bottom: 15px;
    font-size: 16px;
    margin: 30px 0;
  }
}
#parachannel #company #scouts.saved_conditions form dl {
  display: flex;
  flex-wrap: wrap;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl {
    display: block;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.saved_conditions form dl dt, #parachannel #company #scouts.saved_conditions form dl dd {
    margin-bottom: 35px;
  }
  #parachannel #company #scouts.saved_conditions form dl dt:last-of-type, #parachannel #company #scouts.saved_conditions form dl dd:last-of-type {
    margin: 0;
  }
}
#parachannel #company #scouts.saved_conditions form dl dt {
  font-size: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.saved_conditions form dl dt {
    width: 180px;
    line-height: 40px;
    padding: 0 10px;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dt {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd {
  width: calc(100% - 180px);
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd {
    width: 100%;
    margin-bottom: 30px;
  }
  #parachannel #company #scouts.saved_conditions form dl dd:last-of-type {
    margin: 0;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd select {
  background: url(./img/top/arrow04.png) no-repeat;
  background-size: 12px;
  background-position: right 15px center;
}
#parachannel #company #scouts.saved_conditions form dl dd select.type1 {
  width: 90px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd select.type1 {
    width: 70px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd select.type2 {
  width: 180px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd select.type2 {
    width: 140px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd select.type3 {
  width: 360px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd select.type3 {
    width: 100%;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .flex_box {
  display: flex;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .flex_box {
    display: block;
    margin-bottom: 20px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .flex_box:last-of-type {
  margin: 0;
}
#parachannel #company #scouts.saved_conditions form dl dd .flex_box select {
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .flex_box select {
    margin: 0 0 10px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .flex_box select:last-of-type {
  margin: 0;
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box {
  margin-bottom: 30px;
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box:last-of-type {
  margin: 0;
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box .radio_box {
  padding: 10px;
  background-color: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .skill_box .radio_box {
    margin-bottom: 15px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box .radio_box label {
  display: flex;
  align-items: center;
  align-content: center;
  margin: 5px 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .skill_box .radio_box label {
    margin: 5px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box .radio_box label input {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .skill_box .radio_box label input {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box select {
  margin-bottom: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .skill_box select {
    margin-bottom: 15px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box a {
  width: 190px;
  padding: 14px 0;
  background-color: #325a78;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .skill_box a {
    width: 160px;
    padding: 10px 0;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .skill_box a:before {
  content: "";
  width: 20px;
  height: 20px;
  background: url(./img/company/icon17.png) no-repeat;
  background-size: 100%;
  display: block;
  margin-right: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .skill_box a:before {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd > span {
  font-size: 12px;
  display: block;
  margin-top: 10px;
}
#parachannel #company #scouts.saved_conditions form dl dd .checkbox_box {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
}
#parachannel #company #scouts.saved_conditions form dl dd .checkbox_box label {
  width: 75px !important;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .checkbox_box label {
    width: 70px !important;
    margin-bottom: 10px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .checkbox_box label input {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0 5px 0 0;
}
#parachannel #company #scouts.saved_conditions form dl dd .checkbox_box .checkbox_list {
  width: calc(100% - 75px);
  display: flex;
  flex-wrap: wrap;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .checkbox_box .checkbox_list {
    width: calc(100% - 70px);
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .checkbox_box .checkbox_list .area_child {
  width: calc(100% + 75px);
  padding: 5px;
  margin: 15px 0 0 -75px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .checkbox_box .checkbox_list .area_child {
    width: calc(100% + 70px);
    margin: 5px 0 0 -70px;
  }
}
#parachannel #company #scouts.saved_conditions form dl dd .checkbox_box .checkbox_list .area_child label {
  width: auto !important;
  margin: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl dd .checkbox_box .checkbox_list .area_child label {
    margin: 5px;
  }
}
#parachannel #company #scouts.saved_conditions form dl .half {
  margin-bottom: 35px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.saved_conditions form dl .half {
    width: 42%;
    display: flex;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form dl .half {
    margin-bottom: 30px;
  }
}
#parachannel #company #scouts.saved_conditions form dl .half select {
  max-width: 100%;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.saved_conditions form dl .half:nth-of-type(2n) {
    width: 58%;
  }
  #parachannel #company #scouts.saved_conditions form dl .half:nth-of-type(2n) dt {
    width: 130px;
  }
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.saved_conditions form dl .half:nth-of-type(2n) dd {
    width: calc(100% - 130px);
  }
}
#parachannel #company #scouts.saved_conditions form dl .half:nth-of-type(2n) dd select {
  margin-bottom: 10px;
}
#parachannel #company #scouts.saved_conditions form dl .half:nth-of-type(2n) dd select:last-child {
  margin: 0;
}
#parachannel #company #scouts.saved_conditions form .link_box {
  padding: 0;
  margin: 55px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form .link_box {
    margin-top: 40px;
  }
}
#parachannel #company #scouts.saved_conditions form .link_box .btn {
  background-color: #00a0e9;
  margin: 0 auto 25px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.saved_conditions form .link_box .btn {
    width: 250px;
    padding: 18px 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.saved_conditions form .link_box .btn {
    margin-bottom: 15px;
  }
}
#parachannel #company #scouts.saved_conditions form .link_box a:not(.btn) {
  text-align: center;
  text-decoration: underline;
  color: #00a0e9;
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.saved_conditions form .link_box a:not(.btn) {
    font-size: 15px;
  }
}
#parachannel #company #scouts.mypage .clip {
  padding-left: 25px;
  background: url(./img/mypage/scouts_icon.png) no-repeat;
  background-size: 15px;
  background-position: left center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage .clip {
    padding-left: 20px;
    margin-top: 5px;
  }
}
#parachannel #company #scouts.mypage .clip li {
  padding: 9px 15px;
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: 12px;
  color: #00a0e9;
  margin: 5px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage .clip li {
    padding: 5px 10px;
    font-size: 10px;
    margin: 3px;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li {
  border-bottom: 1px #b3b3b3 solid;
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a {
  padding: 15px 80px 5px 10px;
  background: url(./img/company/icon18.png) no-repeat;
  background-size: 8px;
  background-position: right 20px center;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a {
    padding-right: 20px;
    background-position: right 5px center;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .img_box {
  width: 80px;
  height: 80px;
  padding: 0;
  border-radius: 50%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .img_box {
    width: 60px;
    height: 60px;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box {
  width: calc(100% - 80px);
  padding-left: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box {
    width: calc(100% - 60px);
    padding-left: 10px;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > div {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > div {
    flex-wrap: wrap;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > div h3 {
  font-size: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > div h3 {
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > div p {
  font-size: 12px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > div p {
    font-size: 10px;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > p {
  font-size: 12px;
  line-height: 20px;
  color: #b2b2b2;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top > ul:not(.pagination) > li a .text_box > p {
    font-size: 10px;
    line-height: 18px;
  }
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li.online {
  position: relative;
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li.online:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #df0056;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  left: 0;
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li:not(.online) {
  position: relative;
}
#parachannel #company #scouts.mypage.top > ul:not(.pagination) > li:not(.online):after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
#parachannel #company #scouts.mypage.top .pagination {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  margin-top: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.top .pagination {
    margin-top: 15px;
  }
}
#parachannel #company #scouts.mypage.top .pagination li {
  width: 20px;
  margin-right: 5px;
}
#parachannel #company #scouts.mypage.top .pagination li:last-child {
  margin: 0;
}
#parachannel #company #scouts.mypage.top .pagination li a {
  width: 100%;
  padding: 4px 0;
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
}
#parachannel #company #scouts.mypage.top .pagination li.arrow a {
  padding: 0;
}
#parachannel #company #scouts.mypage.top .pagination li.active {
  background-color: #00a0e9;
}
#parachannel #company #scouts.mypage.top .pagination li.active a {
  color: #fff;
}
#parachannel #company #scouts.mypage.company .btn {
  width: 250px;
  border: 1px #8d8d8d solid;
  color: #8d8d8d;
  margin-left: auto;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .btn {
    margin: 0 auto;
  }
}
#parachannel #company #scouts.mypage.company .cont1 {
  padding-top: 20px;
  border-top: 1px #b3b3b3 solid;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 15px 0;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont1 {
    flex-wrap: wrap;
  }
}
#parachannel #company #scouts.mypage.company .cont1 .img_box {
  width: 120px;
  height: 120px;
  padding: 0;
  border-radius: 50%;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont1 .img_box {
    width: 80px;
    height: 80px;
  }
}
#parachannel #company #scouts.mypage.company .cont1 .text_box {
  width: calc(100% - 370px);
  padding: 0 30px;
  font-weight: bold;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont1 .text_box {
    width: calc(100% - 80px);
    padding: 0 0 0 10px;
  }
}
#parachannel #company #scouts.mypage.company .cont1 .text_box h3 {
  font-size: 20px;
  margin-bottom: 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont1 .text_box h3 {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
#parachannel #company #scouts.mypage.company .cont1 .text_box .clip li {
  padding: 0;
  background: none;
}
#parachannel #company #scouts.mypage.company .cont1 .text_box .clip li a {
  padding: 9px 15px;
  background-color: #f1f1f1;
  border-radius: 5px;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont1 .text_box .clip li a {
    padding: 5px 10px;
  }
}
#parachannel #company #scouts.mypage.company .cont1 .btn {
  background-color: #00a0e9;
  border-color: #00a0e9;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont1 .btn {
    margin: 20px auto 0;
    position: static;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type1 {
  padding: 26px 60px 26px 0;
  border-bottom: 1px #b3b3b3 solid;
  background: url(./img/lp/faq_icon01.png) no-repeat;
  background-size: 20px;
  background-position: right 20px center;
  display: flex;
  cursor: pointer;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type1 {
    padding: 15px 35px 15px 0;
    background-size: 15px;
    background-position: right 10px center;
    flex-wrap: wrap;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type1 p {
  font-size: 18px;
  color: #8d8d8d;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type1 p {
    font-size: 14px;
    line-height: 20px;
  }
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type1 p:last-of-type {
    width: 100%;
    margin-top: 5px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type1 p.date {
  margin-right: 20px;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type1 p.time {
  margin-right: 55px;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 {
  line-height: 24px;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 p a:not(.btn) {
  text-decoration: underline;
  color: #00a0e9;
  display: inline;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 p span {
  display: flex;
  margin-top: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 p span {
    justify-content: space-between;
    margin-top: 20px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 p span .btn {
  width: 225px;
  border: none;
  color: #fff;
  margin: 0 15px 0 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 p span .btn {
    width: 155px;
    margin: 0;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 p span .btn:nth-of-type(2) {
  background-color: #ff7d00;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) {
  padding: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) {
    padding: 10px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) p.main_cont {
  white-space: pre-line;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) h4 {
  font-weight: bold;
  color: #00a0e9;
  margin: 50px 0 10px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) h4 {
    margin: 20px 0 5px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) h4 span {
  padding: 4px 3px 4px 6px;
  background-color: #e44e66;
  font-size: 12px;
  line-height: 100%;
  color: #fff;
  display: inline-block;
  margin-left: 15px;
  position: relative;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) h4 span {
    padding: 5px 3px 5px 5px;
    font-size: 10px;
    margin-left: 10px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) h4 span:after {
  content: "";
  width: 10px;
  height: 20px;
  border-left: 10px #e44e66 solid;
  border-top: 10px transparent solid;
  border-bottom: 10px transparent solid;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: -10px;
  pointer-events: none;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) .indent {
  padding-left: 1em;
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) .indent img {
  width: 300px;
  margin: 20px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 > div:not(.company_box) .indent img {
    width: 250px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box {
  padding: 20px;
  border: 1px #b3b3b3 solid;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 35px 0 45px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box {
    padding: 20px;
    display: block;
    margin: 20px 0;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .img_box {
  width: 220px;
  height: 155px;
  padding: 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .img_box {
    width: 200px;
    height: 140px;
    margin: 0 auto 15px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box {
  width: calc(100% - 220px);
  padding-left: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box {
    width: 100%;
    padding: 0;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box h5 {
  font-size: 20px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box h5 {
    font-size: 16px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box h6 {
  font-size: 15px;
  margin: 15px 0;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box h6 {
    font-size: 14px;
    margin: 5px 0;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box p {
  line-height: 26px;
  color: #adadad;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li .type2 .company_box .text_box p {
    font-size: 12px;
    line-height: 20px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 ul:not(.pagination) li.active .type1 {
  background-image: url(./img/lp/faq_icon02.png);
}
#parachannel #company #scouts.mypage.company .cont2 .pagination {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  margin-top: 30px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont2 .pagination {
    margin-top: 15px;
  }
}
#parachannel #company #scouts.mypage.company .cont2 .pagination li {
  width: 20px;
  margin-right: 5px;
}
#parachannel #company #scouts.mypage.company .cont2 .pagination li:last-child {
  margin: 0;
}
#parachannel #company #scouts.mypage.company .cont2 .pagination li a {
  width: 100%;
  padding: 4px 0;
  font-weight: bold;
  text-align: center;
  color: #00a0e9;
}
#parachannel #company #scouts.mypage.company .cont2 .pagination li.arrow a {
  padding: 0;
}
#parachannel #company #scouts.mypage.company .cont2 .pagination li.active {
  background-color: #00a0e9;
}
#parachannel #company #scouts.mypage.company .cont2 .pagination li.active a {
  color: #fff;
}
#parachannel #company #scouts.mypage.company .cont3 {
  margin: 85px 0 100px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont3 {
    margin: 50px 0;
  }
}
#parachannel #company #scouts.mypage.company .cont3 h3 {
  font-size: 20px;
  font-weight: bold;
  color: #00a0e9;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont3 h3 {
    font-size: 15px;
  }
}
#parachannel #company #scouts.mypage.company .cont3 .slider {
  width: 100%;
  display: flex;
  margin-top: 25px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont3 .slider {
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
#parachannel #company #scouts.mypage.company .cont3 .slider li {
  width: 25%;
  padding: 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont3 .slider li {
    width: 50%;
    padding: 0 10px;
    margin-top: 20px;
  }
  #parachannel #company #scouts.mypage.company .cont3 .slider li:nth-child(-n+2) {
    margin: 0;
  }
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a {
  position: relative;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a h4 {
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #00a0e9;
  margin: 10px 0 5px;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a p {
  font-size: 12px;
  line-height: 18px;
  color: #595757;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a p:last-child {
  margin: 0;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a p.category {
  padding: 0 10px;
  border: 1px #00a0e9 solid;
  border-radius: 20px;
  color: #00a0e9;
  display: inline-block;
  margin: 5px 0;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a p.new_graduate {
  padding: 1px 10px;
  background-color: #00a0e9;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  margin: 5px 0 5px 5px;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a p.done {
  padding: 6px 0;
  background-color: #e6e6e6;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  color: #231815;
  margin-top: 10px;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a .heart {
  width: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li a ul li {
  width: auto;
  padding: 0 5px;
  border: 1px #b3b3b3 solid;
  border-radius: 5px;
  margin: 3px;
}
#parachannel #company #scouts.mypage.company .cont3 .slider li.new a:after {
  content: "NEW";
  width: 45px;
  padding: 5px 0;
  background-color: #231815;
  font-weight: bold;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.qa-wrapper{
  padding: 56px 8px 24px 8px;
  max-width: 800px;
  margin: auto;
}
.qa-wrapper .header{
  text-align: center;
  margin-bottom: 16px;
}
.qa-wrapper .header .title{
  font-size: 24px;
  font-weight: bold;
}
.qa-wrapper .qa-block{
  margin-bottom: 16px;
}
.qa-wrapper .qa-block .question-wrapper{
  padding: 8px 4px;
  background-color: #f0f0f0;
}
.qa-wrapper .qa-block .question-wrapper .text{
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
}
.qa-wrapper .qa-block .answer-wrapper{
  padding: 8px 4px;
}
.qa-wrapper .qa-block .answer-wrapper .text{
  padding-left: 4px;
  font-size: 16px;
  line-height: 1.5;
  color: #444;
}

#parachannel .adminPageWrapper{
  padding: 32px;
}
#parachannel .adminPageWrapper > .header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 2px solid #00A0E9;
}
#parachannel .adminPageWrapper > .header .title{
  font-size: 24px;
  font-weight: bold;
  color: #434;
  width: 40%;
}
#parachannel .adminPageWrapper > .header .inputBox{
  flex: 1;
  text-align: right;
}
#parachannel .adminPageWrapper > .header .inputBox .inputText{
  display: inline-block;
  min-width: 220px;
  max-width: 400px;
  font-size: 16px;
  border-radius: 4px;
}
#parachannel .adminPageWrapper > .header .inputBox .label1{
  text-align: right;
}
#parachannel .adminPageWrapper > .header .inputBox .label1 span{
  font-size: 16px;
  font-weight: 500;
}
#parachannel .adminPageWrapper > .body{
  padding: 32px 8px;
}
#parachannel .adminTable1Wrapper .header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}
#parachannel .adminTable1Wrapper .header .text{
  font-size: 16px;
  font-weight: bold;
  color: #666;
}
#parachannel .adminTable1Wrapper .table th{
  background-color: #f0f0f0;
  font-weight: bold;
  color: #595757;
}
#parachannel .adminTable1Wrapper .table td,#parachannel .adminTable1Wrapper .table th{
  border: 1px solid #606e84;
  line-height: 1.5;
  vertical-align: top;
}
#parachannel .adminTable1Wrapper .table .professional{
  color: #f81b84;
  font-weight: bold;
}
#parachannel .adminTable1Wrapper .table .standard{
  color: #43c0f6;
  font-weight: bold;
}
#parachannel .adminTable1Wrapper .table tbody tr td:last-child{
  min-width: 84px;
}
#parachannel .adminTable1Wrapper .table .btn{
  text-align: center;
  font-size: 12px;
  color: white;
  font-weight: bold;
  background-color: #006496;
  padding: 4px 8px;
  border-radius: 4px;
  border: #fafafa;
  margin-top: 0;
}

#parachannel .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #333;
  vertical-align: top;
  border-color: #dee2e6;
}
#parachannel .tableBordered > :not(caption) > * {
  border-width: 1px 0;
}
#parachannel .tableBordered > :not(caption) > * > * {
  border-width: 0 1px;
}
#parachannel .table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  border-bottom-width: 1px;
}
#parachannel .table > tbody {
  vertical-align: inherit;
}
#parachannel .table > thead {
  vertical-align: bottom;
}
#parachannel .table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}
#parachannel .paginationWrapper .pagination{
  float: right;
}
#parachannel .inputBox .inputBtn{
  font-size: 14px;
  font-weight: bold;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  margin-left: 6px;
}
#parachannel #top .article > div .slider li a h3.ttl_jobs2 {
  font-family: dnp-shuei-mgothic-std, sans-serif;
  font-size: 22px;
  line-height: 1.4;
  color: #00699F;
  padding-top: 4px;
  padding-bottom: 4px;
  /*横向きの際に適用*/
}
#parachannel .premium_jobs_wrapper{
  width: 100%;
}
#parachannel .premium_jobs_wrapper .ttl_jobs2{
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4;
  padding-top: 4px;
  padding-bottom: 4px;
}
#parachannel .premium_jobs_wrapper p{
  font-size: 16px;
  line-height: 1.4;
}
#parachannel .job_address{
  margin-top: 8px;
}
#parachannel .sm_jobs_wrapper{
  margin-bottom: 40px;
}
@media print, screen and (min-width: 769px) {
  #parahcannel .swiper-wrapper {
    display: flex;
    align-items: stretch;
  }
  #parachannel #top .article > div .slider{
    height: auto !important;
    display: flex;
  }
}
#parachannel #top .article > div .slider .card_job_wrapper{
  border: 1px solid #34332E;
  padding: 16px 16px 8px 16px;
  width: 100%;
}
#parachannel #top .article > div .slider .card_job_wrapper .title{
  font-family: 'Habibi';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #00699F ;
  margin-top: 0;
  margin-bottom: 16px;
}
#parachannel #top .article > div .slider .card_job_wrapper .text{
  font-family: 'Hiragino Kaku Gothic ProN';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #595757;
  margin-bottom: 16px;
}
#parachannel #top .article > div .slider .card_job_wrapper .tag_wrapper{
  margin-bottom: 16px;
}
#parachannel #top .article > div .slider .card_job_wrapper .tag_wrapper .tag_text{
  font-family: 'Hiragino Kaku Gothic ProN';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #00699F ;
  margin-right: 8px;
  margin-bottom: 8px;
}
#parachannel #top .article > div .slider .card_job_wrapper .sub_text{
  font-family: 'Habibi';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #00699F ;
}
#parachannel #top .article > div .slider .card_job_wrapper a span.done{
  position: absolute;
  top: -8px;
  right: -8px;
  display: inline-block;
  padding: 6px 8px;
  background-color: #e6e6e6;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  color: #231815;
  font-size: 14px;
}

@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.mypage.company .cont4 a {
    font-size: 16px;
  }
}
#parachannel #company #scouts.mypage.company .cont4 a.btn {
  background-color: #8d8d8d;
  color: #fff;
  margin: 0 auto 25px;
  /*横向きの際に適用*/
}
@media print, screen and (min-width: 769px) {
  #parachannel #company #scouts.mypage.company .cont4 a.btn {
    width: 300px;
    padding: 17px 0;
  }
}
@media print, screen and (max-width: 768px) {
  #parachannel #company #scouts.mypage.company .cont4 a.btn {
    margin-bottom: 15px;
  }
  #parachannel .premium_jobs__wrapper .ttl_jobs2{
    font-size: 20px;
  }
  #parachannel #top .article > div .slider li a h3.ttl_jobs2 {
    font-size: 18px;
  }
  #parachannel .premium_jobs__wrapper p{
    font-size: 14px;
  }
  #parachannel #top .article > div .slider .card_job_wrapper .title{
    font-size: 20px;
  }
  #parachannel #top .article > div .slider .card_job_wrapper .text,#parachannel #top .article > div .slider .card_job_wrapper .tag_wrapper .tag_text {
    font-size: 16px;
  }
}
#parachannel #company #scouts.mypage.company .cont4 a:not(.btn) {
  text-align: center;
  text-decoration: underline;
}/*# sourceMappingURL=style.css.map */

#parachannel .plan_main_title{
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  color: "#5b5b5b";
}
#parachannel .plan_sub_title_wrapper{
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 1.4;
  color: "#5b5b5b";
}

@page {
	size: auto;
	margin-top: 30px;
  margin-bottom: 0;
}
#parachannel #top .mv .cont_box .wrapper form ul li img {
  width: 20px;
  margin-right: 10px;
}

#parachannel #top .mv .cont_box .wrapper form .tab_btn {
  border-bottom: 1px #b3b3b3 solid;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

#parachannel #top .mv .cont_box .wrapper form .tab_btn li {
  display: flex;
  align-items: center;
  width: 25%;
  background-color: #f8f8f8;
  border-left: solid 1px #ddd;
  justify-content: center;
  padding: 15px 0;
  font-weight: bold;
}

#parachannel #top .mv .cont_box .wrapper form .tab_btn li a {
  color: #333;
}
#parachannel #top .mv .cont_box .wrapper form .tab_btn li a:hover{
  color: #00699F !important;
}
#parachannel #top .prefectureInfo{
  margin-bottom: 4px;
}

#parachannel #top .column1 {
  padding: 25px 0;
  background: url(./img/top/column01_bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 55px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column1 {
    margin-bottom: 30px;
  }
}
#parachannel #top .column1 h3 {
  margin: 45px 0 15px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column1 h3 {
    margin: 25px 0 10px;
  }
}
#parachannel #top .column1 .wrapper > ul {
  display: flex;
  justify-content: space-between;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column1 .wrapper > ul {
    flex-wrap: wrap;
  }
}
#parachannel #top .column1 .wrapper > ul > li {
  width: 22.5%;
  background-color: #fff;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column1 .wrapper > ul > li {
    width: 48.5%;
    margin-top: 10px;
  }
  #parachannel #top .column1 .wrapper > ul > li:nth-child(-n+2) {
    margin-top: 0;
  }
}
#parachannel #top .column1 .wrapper > ul > li .img_box img {
  -o-object-fit: cover;
     object-fit: cover;
}
#parachannel #top .column1 .wrapper > ul > li .text_box {
  padding: 10px 10px 5px;
  display: flex;
  flex-wrap: wrap;
}
#parachannel #top .column1 .wrapper > ul > li .text_box h4 {
  width: 100%;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 10px;
}
#parachannel #top .column1 .wrapper > ul > li .text_box p {
  width: 100%;
  margin-bottom: 10px;
}
#parachannel #top .column1 .wrapper > ul > li .text_box ul {
  display: flex;
}
#parachannel #top .column1 .wrapper > ul > li .text_box ul li {
  padding: 2px;
  border: 1px #595757 solid;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  margin: 2px;
}
#parachannel #top .column1 .btn.type2 {
  margin-top: 50px;
  /*横向きの際に適用*/
}
@media print, screen and (max-width: 768px) {
  #parachannel #top .column1 .btn.type2 {
    margin-top: 25px;
  }
}
.columnHead {
  margin-left: 18px;
  float: left;
  width: 48%;
  padding: 20px;
  height: 216px;
}

.rowHead:after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

.flex {
  display: flex;
  width: 25% !important;
  margin-top: -20px;
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .headerButton {
    height: 40px !important;
    white-space: nowrap;
    margin: 10px !important;
    width: 50% !important;
    color: #000;
    font-size: 12px !important;
  }
}

@media(max-width: 1200px) {
  .headerButton {
    height: 40px !important;
    white-space: nowrap;
    margin: 10px !important;
    width: 50% !important;
    color: #000;
    font-size: 10px !important;
  }
   
  .headerImg {
    width: 10px !important;
  }
}


@media screen and (min-width: 1500px) {
  .headerButton {
    height: 40px !important;
    white-space: nowrap;
    margin: 10px !important;
    width: 50% !important;
    color: #000;
    font-size: larger !important;
  }
   
  .headerImg {
    width: 15px !important;
  }
}

.colorBlue {
  color: #00699F !important;
}

.columnLine {
  float: left;
  width: 48%;
  padding: 20px;
  height: 200px;
}

.rowLine:after {
  content: "";
  display: table;
  clear: both;
}


@media print, screen and (max-width: 768px) {
  .prefectureInfo {
    display: flex;
  }

  .formFontTitle {
    font-size: inherit;
  }
}

@media print, screen and (min-width: 769px) {
  .prefectureInfo {
    display: flex;
    flex-wrap: wrap;
  }
  
  .formFontTitle {
    font-size: large;
  }
}

.colorBlack {
  color: #000 !important;
}


@media print, screen and (max-width: 768px) {
  .top__region_a_tag {
    font-weight: bold;
    font-size: medium;
    color: #0017ff !important;
  }
  .top__prefecture_a_tag {
    font-size: small;
    color: #0017ff !important;
  }
}

@media print, screen and (min-width: 769px) {
  .top__region_a_tag {
    color: #0017ff !important;
    font-weight: bold;
    font-size: large;
  }
  .top__prefecture_a_tag {
    font-size: medium;
    color: #0017ff !important;
  }
}

.borderNone {
  border: none !important;
}