.none {
    display: block !important;
}

.none-candidate {
    display: block !important;
    background-color: #f2f2f2 !important;
    pointer-events: none !important;
}

.candidate_scout_btn_disabled {
    opacity: 0.3;
    cursor: not-allowed;
}