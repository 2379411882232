.time_selector {
    display: flex;
    justify-content: flex-start!important;
    & > select {
      width: 60px!important;
    }
    & > span {
      display: flex;
      align-items: center;
      margin: 0px 5px;
    }
  }
  .checkbox_list.area label {
    width: calc(100%/6) !important;
    margin-bottom: 20px;
  }
  .dBlockTextbox {
    display: block !important;
    padding: 0 !important;
  }
  .p0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  

  #parachannel .create1 .jobs.create2 dd dt, #parachannel #company .jobs.create3 dd dt {
    padding-left: 0 !important;
  }
  
  @media screen and (max-width: YOUR_SP_MEDIA_QUERY_VALUE) {
    .checkbox_list.area label {
      width: calc(100%/3) !important;
      margin-bottom: 10px;
    }
  }
  
  .checkbox_list.area .area_child {
    background-color: #f2f2f2;
    padding: 20px 20px 0;
  }

  .display_none {
    display: none !important;
  }   