@import "/src/styles/module/mixins";
@import "/src/styles/common/color";

.link_box_jobs .img_box {
    background-color: #f7f8f8;
    overflow: hidden;
    padding-bottom: 75%;
    position: relative;
    width: 100%;
}

.link_box_jobs .ttl_jobs {
    font-weight: 700;
    font-size: 16px !important;
}
.relat {
    position: relative;
  }

.link_box_jobs .img_box {
    height: 100%;
    left: 50%;
    max-width: none;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: auto;
}
.link_box_jobs li a h3 {
    color: #00699F;
    font-family: dnp-shuei-mgothic-std, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0;
}

.link_box_jobs li ul {
    border-bottom: 1px #00699F solid;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.link_box_jobs li a p {
    color: #595757;
    line-height: 16px;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
}

.link_box_jobs li a p.category {
    border: 1px solid #00699F;
    border-radius: 20px;
    color: #00a0e9;
    display: inline-block;
    margin: 5px 0;
    padding: 0 10px;
}

.link_box_jobs li a p.new_graduate {
    background-color: #00699F;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    margin: 5px 0 5px 5px;
    padding: 1px 10px;
}

.link_box_jobs li {
    padding: 0 20px;
}

.link_box_jobs ul li {
    padding: 0 20px;
    box-shadow: 0 0 0 !important;
    border-radius: 0 !important;;
    text-align: center;
    margin-right: 0.5%;
}

.pagination_circle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.link_box_jobs a {
    color: #231815;
    display: block;
    text-decoration: none;
    transition: all .2s;
    text-align: left;
}

.ribbon19-wrapper {
    display: block;
    position: relative;
    margin: 15px auto;
    padding: 10px 0;
    box-sizing: border-box;
  }
  
  .ribbon19-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 85px;
    height: 85px;
    overflow: hidden;
    }
  
  .ribbon19 {
    display: inline-block;
    position: absolute;
    z-index: 9999;
    padding: 5px 0;
    left: -24px;
    top: 20px;
    width: 160px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    background: #18A1E8;
    color: #fff;
    letter-spacing: 0.05em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: 0 0 0 2px rgb(24, 161, 232);
    border-top: dashed 1px rgba(255, 255, 255, 0.65);
    border-bottom: dashed 1px rgba(255, 255, 255, 0.65);
  }

  @media (max-width: 768px) {
    
    .ribbon19-content {
      width: 65px !important;
      height: 65px !important;
      }
    
    .ribbon19 {
      display: inline-block;
      position: absolute;
      z-index: 9999;
      padding: 5px 0;
      left: -35px !important;
      top: 13px !important;
      width: 160px;
      text-align: center;
      font-size: 10px !important;
      line-height: 12px !important;
      background: #18A1E8 ;
      color: #fff;
      letter-spacing: 0.05em;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      box-shadow: 0 0 0 2px rgb(24, 161, 232);
      border-top: dashed 1px rgba(255, 255, 255, 0.65);
      border-bottom: dashed 1px rgba(255, 255, 255, 0.65);
    }
 
  }