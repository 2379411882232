#parachannel #company #scouts {
  /* 共通 */
  a {
    cursor: pointer;
  }
  .btn {
    width: 225px;
    padding: 13px 0;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    @include mq(sp) {
      width: 200px;
    }
    &.green {
      background-color: #76debf;
    }
    &.orange {
      background-color: #f15a24;
    }
    &.none {
      background-color: #f2f2f2;
      pointer-events: none;
    }
  }
  .edit_box {
    .list_item {
      position: relative;
      select {
        width: 200px;
        height: 35px;
        background: url(../img/top/arrow04.png) no-repeat;
        background-size: 12px;
        background-position: right 15px center;
        border: 1px $lightgray solid;
        position: absolute;
        top: 0;
        right: 0;
        @include mq(sp) {
          margin: 0 0 15px auto;
          position: static;
        }
      }
      ul {
        display: flex;
        li {
          width: 180px;
          @include mq(sp) {
            width: 50%;
          }
          a {
            width: 100%;
            padding: 17px 0;
            background-color: #f2f2f2;
            font-size: 16px;
            text-align: center;
            color: #9e9f9f;
            @include mq(sp) {
              padding: 13px 0;
              font-size: 14px;
            }
          }
          &.active a {
            background-color: $blue;
            color: #fff;
          }
        }
      }
      > div {
        padding: 14px 0;
        border: 1px $lightgray solid;
        border-bottom: none;
        display: flex;
        align-items: center;
        align-content: center;
        @include mq(sp) {
          padding: 12px 0;
        }
        label {
          width: 40px;
          display: block;
          @include mq(sp) {
            width: 30px;
          }
          input {
            display: block;
            margin: 0 auto;
          }
        }
        .btn {
          margin: 0 0 0 20px;
          @include mq(sp) {
            width: 150px;
            padding: 9px 0;
            box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
            font-size: 12px;
            margin-left: 10px;
          }
        }
      }
    }
    > p {
      text-align: center;
      color: #325a78;
      margin-bottom: 15px;
      @include mq(sp) {
        margin-bottom: 10px;
      }
      span {
        font-size: 30px;
        margin-right: 5px;
        @include mq(sp) {
          font-size: 24px;
        }
      }
    }
    .btn {
      box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
      margin: 0 auto;
    }
  }
  .list_box {
    margin-bottom: 35px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
    & + div {
      padding: 0 !important;
      border: none !important;
    }
  }
  .item_box {
    width: calc(100% - 40px) !important;
    padding: 20px 30px 20px 20px;
    border-left: 1px $lightgray solid;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    @include mq(sp) {
      width: calc(100% - 30px) !important;
      padding: 15px 10px;
      display: block;
    }
    > div:not(.btn_box):not(.tag_box) {
      width: calc(100% - 200px);
      padding-right: 35px;
      @include mq(sp) {
        width: 100%;
        padding: 0;
      }
      > div {
        display: flex;
        align-items: center;
        align-content: center;
        position: relative;
        .img_box {
          width: 50px;
          height: 50px;
          padding: 0;
          border-radius: 50%;
          margin-right: 15px;
          @include mq(sp) {
            margin-right: 10px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: static;
            transform: translateX(0);
          }
        }
        h4 {
          line-height: 20px;
          @include mq(sp) {
            line-height: 18px;
          }
        }
        p {
          width: auto;
          color: #e44e66;
          margin-left: 10px;
        }
        > a {
          width: 100%;
          padding: 0;
          background: none;
          display: flex;
          align-items: center;
          align-content: center;
        }
      }
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
        @include mq(sp) {
          margin: 10px 0 15px;
        }
        li {
          width: 49%;
          padding: 10px;
          border-bottom: 1px $lightgray solid;
          display: flex;
          justify-content: space-between;
          @include mq(sp) {
            width: 100%;
            line-height: 16px;
          }
          &:nth-child(1) {
            width: 100%;
          }
          &:last-child {
            border-bottom: 1px $lightgray solid;
          }
          span {
            color: $lightgray;
          }
        }
      }
    }
    .btn_box {
      width: 200px;
      display: block;
      @include mq(sp) {
        width: 100%;
      }
      .btn {
        background-image: none;
        box-shadow: none;
        text-align: center;
        display: block;
        margin: 0 auto 10px;
        @include mq(pc) {
          width: 100%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .tag_box {
      width: calc(100% + 50px);
      padding: 10px 30px 10px 20px;
      border-top: 1px $lightgray solid;
      margin: 20px -30px -20px -20px;
      @include mq(sp) {
        width: calc(100% + 20px);
        padding: 15px 10px;
        margin: 15px -10px -15px;
      }
      ul li {
        border: none;
      }
    }
    &.online > div:not(.btn_box):not(.tag_box) {
      position: relative;
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #00b900;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
    .tooltip_box {
      margin-left: 10px;
      .countdown {
        margin: 0;
      }
      .tooltip {
        position: relative;
        z-index: 999;
        .icon {
          width: 20px;
          cursor: pointer;
          margin: 0;
          @include mq(sp) {
            width: 20px;
          }
        }
        .text {
          width: 345px;
          padding: 10px;
          background-color: #00a0e9;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 35px;
          z-index: 999;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          pointer-events: none;
          opacity: 0;
          transition: all .2s;
          margin: 0;
          @include mq(sp) {
            width: 250px;
            top: -115px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            br:nth-of-type(2) {
              display: none;
            }
          }
          &:after {
            content: none;
            width: 10px;
            height: 20px;
            border-top: 10px transparent solid;
            border-bottom: 10px transparent solid;
            border-right: 10px $blue solid;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: -10px;
            bottom: 0;
            margin: auto;
            @include mq(sp) {
              width: 20px;
              height: 10px;
              border-top: 10px $blue solid;
              border-right: 10px transparent solid;
              border-bottom: none;
              border-left: 10px transparent solid;
              top: auto;
              right: 0;
              bottom: -10px;
              left: 0;
            }
          }
        }
        &:hover .text {
          opacity: 1;
        }
      }
    }
  }
  .tag_box {
    display: flex;
    align-items: center;
    align-content: center;
    @include mq(sp) {
      display: block;
    }
    h5 {
      font-size: 15px;
      color: #606e84;
      @include mq(sp) {
        font-size: 13px;
      }
    }
    ul {
      display: flex;
      margin-left: 20px;
      @include mq(sp) {
        flex-wrap: wrap;
        margin: 5px 0 0;
      }
      li {
        margin-right: 5px;
        @include mq(sp) {
          margin: 5px 5px 0 0;
        }
        a {
          padding: 9px 15px;
          background: none;
          background-color: #f2f2f2;
          border-radius: 5px;
          font-size: 12px;
          color: $blue;
          @include mq(sp) {
            padding: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }
  &.saved_conditions ul li {
    padding: 26px 20px;
    border-bottom: 1px $lightgray solid;
    font-weight: bold;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    @include mq(sp) {
      padding: 15px 10px;
      display: block;
    }
    a {
      &:nth-child(1) {
        width: calc(100% - 140px);
        font-size: 16px;
        line-height: 34px;
        word-break: break-all;
        @include mq(sp) {
          width: 100%;
          font-size: 14px;
          line-height: 24px;
        }
        &:hover {
          color: $blue;
          opacity: 1;
        }
      }
      &:nth-child(2) {
        width: 120px;
        padding: 13px 0;
        background-color: #e6e6e6;
        border-radius: 10px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        @include mq(sp) {
          width: 100px;
          padding: 9px 0;
          border-radius: 5px;
          font-size: 12px;
          margin: 10px 0 0 auto;
        }
      }
    }
  }
  /* top */
  &.top {
    .edit_box {
      .pagination li {
        border: none;
      }
      .cont {
        padding: 0;
        border: none;
        display: block;
        margin: 30px 0 20px;
        h3 {
          font-size: 20px;
          text-align: center;
          display: block;
          margin-bottom: 25px;
          @include mq(sp) {
            font-size: 14px;
            margin-bottom: 20px;
          }
          span {
            width: auto;
            padding: 0;
            border: none;
            font-size: 14px;
            color: #606e84;
            margin: 0;
            span {
              font-size: 50px;
              color: $blue;
              margin: 0 5px 0 10px;
              @include mq(sp) {
                font-size: 30px;
                margin-left: 5px;
              }
            }
          }
        }
        > form {
          padding: 20px 40px 30px;
          background-color: #f2f2f2;
          font-size: 16px;
          display: block;
          position: relative;
          @include mq(sp) {
            padding: 20px;
            font-size: 14px;
          }
          h4 {
            color: #606e84;
            margin-bottom: 35px;
            @include mq(sp) {
              margin-bottom: 20px;
            }
          }
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              line-height: 28px;
              color: #9e9f9f;
              margin-right: 45px;
              @include mq(sp) {
                margin-right: 20px;
              }
            }
          }
          > div > a {
            width: 50px;
            position: absolute;
            right: 15px;
            bottom: 15px;
            @include mq(sp) {
              width: 45px;
              right: 10px;
              bottom: 10px;
            }
          }
          .edit_box {
            display: flex;
            flex-wrap: wrap;
            margin: 40px 0 30px;
            @include mq(sp) {
              margin: 30px 0;
            }
            dt,dd {
              margin-bottom: 20px;
              &:last-of-type {
                margin: 0;
              }
            }
            dt {
              width: 170px;
              line-height: 40px;
              @include mq(sp) {
                width: 120px;
                line-height: 35px;
              }
            }
            dd {
              width: calc(100% - 170px);
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              align-content: center;
              @include mq(sp) {
                width: calc(100% - 120px);
              }
              select {
                background: #fff url(../img/top/arrow04.png) no-repeat;
                background-size: 12px;
                background-position: right 15px center;
                margin-right: 15px;
                @include mq(sp) {
                  margin: 0 10px 10px 0;
                }
                &.type1 {
                  width: 100px;
                  @include mq(sp) {
                    width: 75px;
                  }
                }
                &.type2 {
                  width: 170px;
                  @include mq(sp) {
                    width: 145px;
                  }
                }
              }
              .btn {
                width: auto;
                padding: 10px 20px;
                background-color: #325a78;
                box-shadow: none;
                font-size: 12px;
                display: flex;
                align-items: center;
                align-content: center;
                margin: 0;
                @include mq(sp) {
                  padding: 10px;
                }
                &:before {
                  content: "";
                  width: 20px;
                  height: 20px;
                  background: url(../img/company/icon17.png) no-repeat;
                  background-size: 100%;
                  display: block;
                  margin-right: 10px;
                  @include mq(sp) {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                  }
                }              
              }
              > span {
                width: 100%;
                font-size: 12px;
                font-weight: normal;
                margin-top: 10px;
                @include mq(sp) {
                  margin: 0;
                }
              }
            }
          }
          .btn_box1 {
            display: flex;
            align-items: center;
            align-content: center;
            position: absolute;
            top: 15px;
            right: 15px;
            @include mq(sp) {
              flex-wrap: wrap;
              position: static;
              margin-bottom: 15px;
            }
            a {
              text-decoration: underline;
              color: $blue;
              margin-left: 15px;
              @include mq(sp) {
                margin: 0 10px 0 0;
              }
              &.reset {
                padding: 10px;
                background-color: $blue;
                color: #fff;
                text-decoration: none;
                @include mq(sp) {
                  margin: 15px 0 0;
                }
              }
            }
          }
          .btn_box2 {
            width: 200px;
            margin: 15px auto 0;
            position: relative;
            .btn {
              width: 100%;
              background-color: #e44e66;
              box-shadow: none;
            }
            a:not(.btn) {
              font-size: 14px;
              text-decoration: underline;
              color: $blue;
              @include mq(pc) {
                position: absolute;
                top: 50%;
                right: -160px;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
              }
              @include mq(sp) {
                text-align: center;
                margin-top: 15px;
              }
              img {
                width: 8px;
                display: inline-block;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    > div:not(.edit_box) .btn {
      width: 300px;
      background-color: $blue;
      box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
      margin: -50px auto 0;
      @include mq(sp) {
        margin-top: -25px;
      }
      br {
        display: none;
      }
    }
  }
  /* candidate */
  &.candidate .edit_box .cont {
    padding: 0;
    border: none;
    display: block;
    margin: 30px 0;
    h3 {
      font-size: 20px;
      color: #606e84;
      text-align: center;
      margin-bottom: 25px;
      @include mq(sp) {
        font-size: 14px;
        margin-bottom: 20px;
      }
      > span {
        font-size: 14px;
        margin: 0 10px;
        @include mq(sp) {
          margin: 0 5px;
        }
        span {
          font-size: 50px;
          color: $blue;
          margin-right: 5px;
          @include mq(sp) {
            font-size: 30px;
            margin-left: 5px;
          }
        }
      }
      &:nth-of-type(2) {
        margin: 35px 0 0;
        @include mq(sp) {
          margin-top: 20px;
        }
        span span {
          color: #f15a24;
        }
      }
    }
    form {
      width: 100%;
      select {
        width: 140px;
        height: 30px;
        background: url(../img/top/arrow04.png) no-repeat;
        background-size: 12px;
        background-position: right 15px center;
        border-radius: 5px;
        margin-bottom: 10px;
        &:nth-of-type(2) {
          width: 520px;
          background-image: url(../img/mypage/arrow01.png);
          background-color: $lightblue;
          @include mq(sp) {
            width: 100%;
          }
        }
      }
    }
  }
  /* applicant */
  &.applicant {
    .item_box {
      width: 100% !important;
      padding: 0;
      border: none;
      @include mq(pc) {
        font-size: 16px;
        > div:not(.btn_box):not(.tag_box) > div {
          &:nth-of-type(1) {
            .img_box {
              width: 60px;
              height: 60px;
              margin-right: 30px;
            }
          }
          p {
            margin-left: 20px;
          }
        }
      }
    }
    .cont {
      margin-top: 35px;
      position: relative;
      > div:not(.btn_box) {
        margin-bottom: 40px;
        @include mq(sp) {
          margin-bottom: 30px;
        }
        h3 {
          padding-left: 15px;
          border-left: 8px #606e84 solid;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 30px;
          @include mq(sp) {
            padding-left: 10px;
            border-left-width: 5px;
            font-size: 16px;
            margin-bottom: 15px;
          }
        }
        dl {
          display: flex;
          flex-wrap: wrap;
          dt,dd {
            padding: 25px 30px;
            border-top: 1px $lightgray solid;
            line-height: 30px;
            @include mq(pc) {
              font-size: 16px;
            }
            @include mq(sp) {
              padding: 10px 15px;
              line-height: 20px;
            }
            &:last-of-type {
              border-bottom: 1px $lightgray solid;
            }
          }
          dt {
            width: 270px;
            background-color: #f1f1f1;
            @include mq(sp) {
              width: 100px;
            }
          }
          dd {
            width: calc(100% - 270px);
            @include mq(sp) {
              width: calc(100% - 100px);
            }
          }
        }
      }
      .btn_box {
        display: flex;
        justify-content: center;
        margin: 60px 0 25px;
        @include mq(sp) {
          display: block;
          margin: 30px 0 15px;
        }
        .btn {
          margin: 0 10px;
          @include mq(sp) {
            margin: 0 auto 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      > a {
        text-align: center;
        text-decoration: underline;
        color: $blue;
        &:nth-of-type(1) {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
  /* entry */
  &.entry form {
    > div {
      margin-bottom: 60px;
      @include mq(sp) {
        margin-bottom: 40px;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #606e84;
        margin-bottom: 30px;
        @include mq(sp) {
          font-size: 15px;
          margin-bottom: 20px;
        }
        span {
          color: $blue;
          margin-right: 20px;
          @include mq(sp) {
            margin-right: 10px;
          }
        }
      }
    }
    .cont1 {
      padding: 0;
      &:after {
        display: none;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: -30px;
        @include mq(sp) {
          margin-top: -20px;
        }
        li {
          margin: 30px 9px 0;
          @include mq(sp) {
            width: 33.33%;
            margin: 20px 0 0;
          }
          > div {
            width: 130px;
            height: 130px;
            border-radius: 50%;
            margin-bottom: 15px;
            overflow: hidden;
            @include mq(sp) {
              width: 100px;
              height: 100px;
              margin: 0 auto 10px;
            }
          }
          img {
            height: 100%;
            object-fit: cover;
          }
          p {
            text-align: center;
            @include mq(pc) {
              font-size: 15px;
            }
          }
        }
      }
    }
    .cont2 {
      .checkbox {
        display: flex;
        flex-wrap: wrap;
        @include mq(sp) {
          flex-wrap: wrap;
        }
        > div {
          margin: 0 10px 10px 0;
          @include mq(sp) {
            margin: 0 5px 5px 0;
          }
          input {
            display: none;
            &:checked + label {
              background-color: #76debf;
              border-color: #76debf;
              color: #fff;
            }
          }
          label {
            padding: 10px 20px;
            border: 1px #ccc solid;
            border-radius: 30px;
            color: #ccc;
            display: block;
            cursor: pointer;
            @include mq(pc) {
              font-size: 16px;
            }
            @include mq(sp) {
              padding: 8px 10px;
            }
          }
        }
      }
      > a {
        text-decoration: underline;
        color: $blue;
        margin-top: 15px;
        @include mq(sp) {
          margin-top: 10px;
        }
      }
    }
    .cont3 {
      label {
        display: flex;
        align-items: center;
        align-content: center;
        margin-right: 20px;
        @include mq(sp) {
          margin: 0 0 5px;
        }
        input {
          width: 15px;
          height: 15px;
          margin: 0 10px 0 0;
        }
      }
      textarea {
        height: 300px;
        border-radius: 0;
        margin: 30px 0;
        @include mq(sp) {
          height: 150px;
          margin: 15px 0;
        }
      }
      dl {
        display: flex;
        align-items: center;
        align-content: center;
        @include mq(sp) {
          display: block;
        }
        dt {
          margin-right: 20px;
          @include mq(sp) {
            font-size: 14px;
            margin: 0 0 10px;
          }
        }
        dd {
          display: flex;
          @include mq(sp) {
            display: block;
          }
          span {
            padding: 3px 6px;
            border: 1px #e44e66 solid;
            border-radius: 30px;
            font-size: 12px;
            color: #e44e66;
            margin-left: 20px;
            @include mq(sp) {
              margin: 0 0 0 10px;
            }
          }
        }
        &:nth-of-type(2) {
          margin: 20px 0 0 25px;
          input {
            width: 380px;
            height: 35px;
            @include mq(sp) {
              width: 100%;
              height: 30px;
            }
          }
        }
      }
    }
    .btn_box {
      margin: 0;
      .btn.back {
        background-color: #ccc;
        @include mq(pc) {
          width: 130px;
        }
      }
    }
  }
  /* confirm */
  &.confirm form .cont3 {
    h4 {
      font-weight: bold;
      color: $blue;
      margin: 50px 0 10px;
      @include mq(pc) {
        font-size: 14px;
      }
      @include mq(sp) {
        margin: 20px 0 5px;
      }
    }
    .indent {
      padding-left: 1em;
      img {
        width: 300px;
        margin: 20px 0;
        @include mq(sp) {
          width: 250px;
        }
      }
      p {
        text-align: left;
        margin: 0;
      }
    }
    p {
      font-weight: normal;
      line-height: 24px;
      @include mq(pc) {
        font-size: 14px;
      }
      span {
        display: block;
      }
      a {
        text-decoration: underline;
        color: $blue;
        display: inline-block;
      }
      &.cont3__content {
        white-space: pre-line;
      }
    }
    dl {
      padding: 40px 10px;
      border-top: 1px $lightgray solid;
      border-bottom: 1px $lightgray solid;
      color: #606e84;
      margin: 35px 0 30px;
      @include mq(pc) {
        font-size: 18px;
      }
      @include mq(sp) {
        padding: 20px 0;
        margin: 20px 0;
      }
      dt {
        @include mq(pc) {
          margin: 0;
        }
      }
    }
    > div {
      p {
        text-align: center;
        margin-bottom: 40px;
        @include mq(sp) {
          margin-bottom: 20px;
        }
      }
      label {
        justify-content: center;
      }
    }
  }
  /* complete */
  &.complete {
    padding: 130px 0;
    @include mq(sp) {
      padding: 30px 0;
    }
    p {
      font-size: 18px;
      line-height: 100%;
      color: #606e84;
      margin-bottom: 60px;
      @include mq(sp) {
        font-size: 14px;
        margin-bottom: 30px;
      }
    }
    .btn_box {
      display: block;
      margin: 0;
      .btn {
        height: auto;
        background-color: $blue;
        line-height: 100%;
        margin: 0 auto 10px;
        &.back {
          background-color: #8d8d8d;
          margin-bottom: 0;
        }
      }
    }
  }
  /* history */
  &.history {
    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #606e84;
      margin-bottom: 25px;
      @include mq(sp) {
        font-size: 15px;
        margin-bottom: 20px;
      }
    }
    .link {
      display: flex;
      @include mq(sp) {
        margin-top: 15px;
      }
      li {
        margin-left: 20px;
        @include mq(sp) {
          margin: 0 15px 0 0;
        }
        a {
          font-weight: bold;
          color: #606e84;
          display: flex;
          align-items: flex-end;
          align-content: flex-end;
          &:before {
            content: "";
            width: 16px;
            height: 16px;
            background: #ccc url(../img/lp/fixed_icon.png) no-repeat;
            background-size: 5px;
            background-position: center;
            border-radius: 50%;
            display: block;
            margin-right: 10px;
            transition: all .2s;
          }
          > span {
            font-size: 12px;
            color: $blue;
            span {
              font-size: 26px;
              margin: 0 5px 0 10px;
              @include mq(sp) {
                font-size: 20px;
                margin: 0 5px;
              }
            }
          }
          &:hover:before {
            background-color: $blue;
          }
        }
      }
    }
    .cont1 {
      margin-bottom: 50px;
      @include mq(sp) {
        margin-bottom: 30px;
      }
      ul {
        display: flex;
        @include mq(sp) {
          display: block;
        }
        li {
          width: 32%;
          border: 1px $lightgray solid;
          font-weight: bold;
          text-align: center;
          margin-right: 2%;
          @include mq(sp) {
            width: 100%;
            margin-bottom: 15px;
          }
          &:last-child {
            margin: 0;
          }
          h4 {
            padding: 17px 0;
            background-color: #606e84;
            font-size: 16px;
            color: #fff;
            @include mq(sp) {
              padding: 13px 0;
              font-size: 14px;
            }
          }
          p {
            margin: 25px 0;
            @include mq(sp) {
              margin: 20px 0;
            }
            &:nth-of-type(1) {
              font-size: 26px;
              color: #ccc;
              @include mq(sp) {
                font-size: 16px;
              }
              span {
                font-size: 50px;
                font-weight: normal;
                @include mq(sp) {
                  font-size: 30px;
                }
                span {
                  font-weight: bold;
                  color: $blue;
                }
              }
            }
            &:nth-of-type(2) {
              font-weight: normal;
              margin-top: -5px;
            }
          }
        }
      }
    }
    .cont2 {
      > ul:not(.pagination) > li {
        padding: 20px 0 15px 10px;
        border-bottom: 1px $lightgray solid;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        @include mq(sp) {
          padding: 15px 0;
          display: block;
        }
        > div {
          p {
            color: $lightgray;
            margin-bottom: 10px;
            @include mq(pc) {
              font-size: 18px;
            }
          }
          .tag_box:after {
            content: "";
            width: 8px;
            height: 12px;
            background: url(../img/company/icon18.png) no-repeat;
            background-size: 100%;
            display: inline-block;
            margin-left: 10px;
            @include mq(sp) {
              display: none;
            }
          }
        }
      }
      .pagination {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-end;
        margin-top: 30px;
        @include mq(sp) {
          margin-top: 15px;
        }
        li {
          width: 20px;
          margin-right: 5px;
          &:last-child {
            margin: 0;
          }
          a {
            width: 100%;
            padding: 4px 0;
            font-weight: bold;
            text-align: center;
            color: $blue;
          }
          &.arrow a {
            padding: 0;
          }
          &.active {
            background-color: $blue;
            a {
              color: #fff;
            }
          }
        }
      }
    }
    &.detail {
      h3 {
        padding: 16px 30px;
        background-color: $navyblue;
        font-size: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        @include mq(sp) {
          padding: 13px 15px;
          font-size: 14px;
        }
        span {
          font-size: 16px;
          @include mq(sp) {
            font-size: 12px;
          }
        }
      }
      > div {
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        align-content: flex-end;
        justify-content: space-between;
        margin-bottom: 15px;
        @include mq(sp) {
          display: block;
          margin-bottom: 20px;
        }
      }
      p {
        padding: 30px;
        border: 1px $lightgray solid;
        line-height: 24px;
        white-space: pre-line;
        @include mq(sp) {
          padding: 15px;
        }
      }
      .btn {
        background-color: #8d8d8d;
        margin: 65px auto 0;
        @include mq(pc) {
          width: 300px;
          padding: 17px 0;
          font-size: 16px;
        }
        @include mq(sp) {
          margin-top: 30px;
        }
      }
    }
  }
  /* saved_conditions */
  &.saved_conditions {
    position: relative;
    > a {
      text-decoration: underline;
      color: $blue;
      display: flex;
      align-items: center;
      align-content: center;
      position: absolute;
      top: 0;
      left: 0;
      @include mq(sp) {
        position: static;
      }
      img {
        width: 25px;
        margin-right: 15px;
        @include mq(sp) {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
    h3 {
      padding-bottom: 30px;
      border-bottom: 1px #606e84 solid;
      font-size: 22px;
      text-align: center;
      color: #606e84;
      margin-bottom: 50px;
      @include mq(sp) {
        padding-bottom: 15px;
        font-size: 16px;
        margin: 30px 0;
      }
    }
    form {
      dl {
        display: flex;
        flex-wrap: wrap;
        @include mq(sp) {
          display: block;
        }
        dt,dd {
          @include mq(pc) {
            margin-bottom: 35px;
            &:last-of-type {
              margin: 0;
            }
          }
        }
        dt {
          font-size: 15px;
          @include mq(pc) {
            width: 180px;
            line-height: 40px;
            padding: 0 10px;
          }
          @include mq(sp) {
            font-size: 14px;
            margin-bottom: 15px;
          }
        }
        dd {
          width: calc(100% - 180px);
          @include mq(sp) {
            width: 100%;
            margin-bottom: 30px;
            &:last-of-type {
              margin: 0;
            }
          }
          select {
            background: url(../img/top/arrow04.png) no-repeat;
            background-size: 12px;
            background-position: right 15px center;
            &.type1 {
              width: 90px;
              @include mq(sp) {
                width: 70px;
              }
            }
            &.type2 {
              width: 180px;
              @include mq(sp) {
                width: 140px;
              }
            }
            &.type3 {
              width: 360px;
              @include mq(sp) {
                width: 100%;
              }
            }
          }
          .flex_box {
            display: flex;
            margin-bottom: 15px;
            @include mq(sp) {
              display: block;
              margin-bottom: 20px;
            }
            &:last-of-type {
              margin: 0;
            }
            select {
              margin-right: 10px;
              @include mq(sp) {
                margin: 0 0 10px;
              }
              &:last-of-type {
                margin: 0;
              }
            }
          }
          .skill_box {
            margin-bottom: 30px;
            &:last-of-type {
              margin: 0;
            }
            .radio_box {
              padding: 10px;
              background-color: #f2f2f2;
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 25px;
              @include mq(sp) {
                margin-bottom: 15px;
              }
              label {
                display: flex;
                align-items: center;
                align-content: center;
                margin: 5px 10px;
                @include mq(sp) {
                  margin: 5px;
                }
                input {
                  width: 20px;
                  height: 20px;
                  margin: 0 10px 0 0;
                  @include mq(sp) {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                  }
                }
              }
            }
            select {
              margin-bottom: 25px;
              @include mq(sp) {
                margin-bottom: 15px;
              }
            }
            a {
              width: 190px;
              padding: 14px 0;
              background-color: #325a78;
              border-radius: 5px;
              font-size: 12px;
              text-align: center;
              color: #fff;
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              @include mq(sp) {
                width: 160px;
                padding: 10px 0;
              }
              &:before {
                content: "";
                width: 20px;
                height: 20px;
                background: url(../img/company/icon17.png) no-repeat;
                background-size: 100%;
                display: block;
                margin-right: 10px;
                @include mq(sp) {
                  width: 15px;
                  height: 15px;
                  margin-right: 5px;
                }
              }              
            }
          }
          > span {
            font-size: 12px;
            display: block;
            margin-top: 10px;
          }
          .checkbox_box {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            label {
              width: 75px !important;
              display: flex;
              align-items: center;
              align-content: center;
              margin: 0;
              cursor: pointer;
              @include mq(sp) {
                width: 70px !important;
                margin-bottom: 10px;
              }
              input {
                width: 20px;
                height: 20px;
                padding: 0;
                margin: 0 5px 0 0;
              }
            }
            .checkbox_list {
              width: calc(100% - 75px);
              display: flex;
              flex-wrap: wrap;
              @include mq(sp) {
                width: calc(100% - 70px);
              }
              .area_child {
                width: calc(100% + 75px);
                padding: 5px;
                margin: 15px 0 0 -75px;
                @include mq(sp) {
                  width: calc(100% + 70px);
                  margin: 5px 0 0 -70px;
                }
                label {
                  width: auto !important;
                  margin: 10px;
                  @include mq(sp) {
                    margin: 5px;
                  }
                }
              }
            }
          }
        }
        .half {
          margin-bottom: 35px;
          @include mq(pc) {
            width: 42%;
            display: flex;
          }
          @include mq(sp) {
            margin-bottom: 30px;
          }
          select {
            max-width: 100%;
          }
          &:nth-of-type(2n) {
            @include mq(pc) {
              width: 58%;
              dt {
                width: 130px;
              }
            }
            dd {
              @include mq(pc) {
                width: calc(100% - 130px);
              }
              select {
                margin-bottom: 10px;
                &:last-child {
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .link_box {
        padding: 0;
        margin: 55px 0 0;
        @include mq(sp) {
          margin-top: 40px;
        }
        .btn {
          background-color: $blue;
          margin: 0 auto 25px;
          @include mq(pc) {
            width: 250px;
            padding: 18px 0;
          }
          @include mq(sp) {
            margin-bottom: 15px;
          }
        }
        a:not(.btn ) {
          text-align: center;
          text-decoration: underline;
          color: $blue;
          @include mq(pc) {
            font-size: 15px;
          }
        }
      }
    }
  }
  /* mypage */
  &.mypage .clip {
    padding-left: 25px;
    background: url(../img/mypage/scouts_icon.png) no-repeat;
    background-size: 15px;
    background-position: left center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    @include mq(sp) {
      padding-left: 20px;
      margin-top: 5px;
    }
    li {
      padding: 9px 15px;
      background-color: #f1f1f1;
      border-radius: 5px;
      font-size: 12px;
      color: $blue;
      margin: 5px;
      @include mq(sp) {
        padding: 5px 10px;
        font-size: 10px;
        margin: 3px;
      }
    }
  }
  /* mypage top */
  &.mypage.top {
    > ul:not(.pagination) > li {
      border-bottom: 1px $lightgray solid;
      a {
        padding: 15px 80px 5px 10px;
        background: url(../img/company/icon18.png) no-repeat;
        background-size: 8px;
        background-position: right 20px center;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        @include mq(sp) {
          padding-right: 20px;
          background-position: right 5px center;
        }
        .img_box {
          width: 80px;
          height: 80px;
          padding: 0;
          border-radius: 50%;
          @include mq(sp) {
            width: 60px;
            height: 60px;
          }
        }
        .text_box {
          width: calc(100% - 80px);
          padding-left: 15px;
          @include mq(sp) {
            width: calc(100% - 60px);
            padding-left: 10px;
          }
          > div {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            margin-bottom: 10px;
            @include mq(sp) {
              flex-wrap: wrap;
            }
            h3 {
              font-size: 15px;
              @include mq(sp) {
                width: 100%;
                font-size: 13px;
                line-height: 20px;
                margin-bottom: 5px;
              }
            }
            p {
              font-size: 12px;
              @include mq(sp) {
                font-size: 10px;
              }
            }
          }
          > p {
            font-size: 12px;
            line-height: 20px;
            color: #b2b2b2;
            @include mq(sp) {
              font-size: 10px;
              line-height: 18px;
            }
          }
        }
      }
      &.online {
        position: relative;
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: #df0056;
          border-radius: 50%;
          position: absolute;
          top: 25px;
          left: 0;
        }
      }
      &:not(.online) {
        position: relative;
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.1);
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
        }
      }
    }
    .pagination {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: flex-end;
      margin-top: 30px;
      @include mq(sp) {
        margin-top: 15px;
      }
      li {
        width: 20px;
        margin-right: 5px;
        &:last-child {
          margin: 0;
        }
        a {
          width: 100%;
          padding: 4px 0;
          font-weight: bold;
          text-align: center;
          color: $blue;
        }
        &.arrow a {
          padding: 0;
        }
        &.active {
          background-color: $blue;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  /* mypage company */
  &.mypage.company {
    .btn {
      width: 250px;
      border: 1px #8d8d8d solid;
      color: #8d8d8d;
      margin-left: auto;
      @include mq(sp) {
        margin: 0 auto;
      }
    }
    .cont1 {
      padding-top: 20px;
      border-top: 1px $lightgray solid;
      display: flex;
      align-items: center;
      align-content: center;
      margin: 15px 0;
      position: relative;
      @include mq(sp) {
        flex-wrap: wrap;
      }
      .img_box {
        width: 120px;
        height: 120px;
        padding: 0;
        border-radius: 50%;
        @include mq(sp) {
          width: 80px;
          height: 80px;
        }
      }
      .text_box {
        width: calc(100% - 370px);
        padding: 0 30px;
        font-weight: bold;
        @include mq(sp) {
          width: calc(100% - 80px);
          padding: 0 0 0 10px;
        }
        h3 {
          font-size: 20px;
          margin-bottom: 15px;
          @include mq(sp) {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        .clip li {
          padding: 0;
          background: none;
          a {
            padding: 9px 15px;
            background-color: #f1f1f1;
            border-radius: 5px;
            color: $blue;
            @include mq(sp) {
              padding: 5px 10px;
            }
          }
        }
      }
      .btn {
        background-color: $blue;
        border-color: $blue;
        color: #fff;
        position: absolute;
        top: 20px;
        right: 0;
        @include mq(sp) {
          margin: 20px auto 0;
          position: static;
        }
      }
    }
    .cont2 {
      ul:not(.pagination) li {
        .type1 {
          padding: 26px 60px 26px 0;
          border-bottom: 1px $lightgray solid;
          background: url(../img/lp/faq_icon01.png) no-repeat;
          background-size: 20px;
          background-position: right 20px center;
          display: flex;
          cursor: pointer;
          @include mq(sp) {
            padding: 15px 35px 15px 0;
            background-size: 15px;
            background-position: right 10px center;
            flex-wrap: wrap;
          }
          p {
            font-size: 18px;
            color: #8d8d8d;
            @include mq(sp) {
              font-size: 14px;
              line-height: 20px;
              &:last-of-type {
                width: 100%;
                margin-top: 5px;
              }
            }
            &.date {
              margin-right: 20px;
            }
            &.time {
              margin-right: 55px;
            }
          }
        }
        .type2 {
          line-height: 24px;
          p {
            a:not(.btn) {
              text-decoration: underline;
              color: $blue;
              display: inline;
            }
            span {
              display: flex;
              margin-top: 30px;
              @include mq(sp) {
                justify-content: space-between;
                margin-top: 20px;
              }
              .btn {
                width: 225px;
                border: none;
                color: #fff;
                margin: 0 15px 0 0;
                @include mq(sp) {
                  width: 155px;
                  margin: 0;
                }
                &:nth-of-type(2) {
                  background-color: #ff7d00;
                }
              }
            }
          }
          > div:not(.company_box) {
            padding: 30px;
            @include mq(sp) {
              padding: 10px;
            }
            p.main_cont {
              white-space: pre-line;
            }
            h4 {
              font-weight: bold;
              color: $blue;
              margin: 50px 0 10px;
              @include mq(sp) {
                margin: 20px 0 5px;
              }
              span {
                padding: 4px 3px 4px 6px;
                background-color: #e44e66;
                font-size: 12px;
                line-height: 100%;
                color: #fff;
                display: inline-block;
                margin-left: 15px;
                position: relative;
                @include mq(sp) {
                  padding: 5px 3px 5px 5px;
                  font-size: 10px;
                  margin-left: 10px;
                }
                &:after {
                  content: "";
                  width: 10px;
                  height: 20px;
                  border-left: 10px #e44e66 solid;
                  border-top: 10px transparent solid;
                  border-bottom: 10px transparent solid;
                  box-sizing: border-box;
                  position: absolute;
                  top: 0;
                  right: -10px;
                  pointer-events: none;
                }
              }
            }
            .indent {
              padding-left: 1em;
              img {
                width: 300px;
                margin: 20px 0;
                @include mq(sp) {
                  width: 250px;
                }
              }
            }
          }
          .company_box {
            padding: 20px;
            border: 1px $lightgray solid;
            display: flex;
            align-items: center;
            align-content: center;
            margin: 35px 0 45px;
            @include mq(sp) {
              padding: 20px;
              display: block;
              margin: 20px 0;
            }
            .img_box {
              width: 220px;
              height: 155px;
              padding: 0;
              @include mq(sp) {
                width: 200px;
                height: 140px;
                margin: 0 auto 15px;
              }
            }
            .text_box {
              width: calc(100% - 220px);
              padding-left: 30px;
              @include mq(sp) {
                width: 100%;
                padding: 0;
              }
              h5 {
                font-size: 20px;
                @include mq(sp) {
                  font-size: 16px;
                }
              }
              h6 {
                font-size: 15px;
                margin: 15px 0;
                @include mq(sp) {
                  font-size: 14px;
                  margin: 5px 0;
                }
              }
              p {
                line-height: 26px;
                color: #adadad;
                @include mq(sp) {
                  font-size: 12px;
                  line-height: 20px;
                }
              }
            }
          }
        }
        &.active .type1 {
          background-image: url(../img/lp/faq_icon02.png);
        }
      }
      .pagination {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-end;
        margin-top: 30px;
        @include mq(sp) {
          margin-top: 15px;
        }
        li {
          width: 20px;
          margin-right: 5px;
          &:last-child {
            margin: 0;
          }
          a {
            width: 100%;
            padding: 4px 0;
            font-weight: bold;
            text-align: center;
            color: $blue;
          }
          &.arrow a {
            padding: 0;
          }
          &.active {
            background-color: $blue;
            a {
              color: #fff;
            }
          }
        }
      }
    }
    .cont3 {
      margin: 85px 0 100px;
      @include mq(sp) {
        margin: 50px 0;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        color: $blue;
        @include mq(sp) {
          font-size: 15px;
        }
      }
      .slider {
        width: 100%;
        display: flex;
        margin-top: 25px;
        @include mq(sp) {
          flex-wrap: wrap;
          margin-top: 20px;
        }
        li {
          width: 25%;
          padding: 0 15px;
          @include mq(sp) {
            width: 50%;
            padding: 0 10px;
            margin-top: 20px;
            &:nth-child(-n+2) {
              margin: 0;
            }
          }
          a {
            position: relative;
            h4 {
              font-family: $shuei;
              font-size: 14px;
              line-height: 20px;
              color: $blue;
              margin: 10px 0 5px;
            }
            p {
              font-size: 12px;
              line-height: 18px;
              color: $gray;
              &:last-child {
                margin: 0;
              }
              &.category {
                padding: 0 10px;
                border: 1px $blue solid;
                border-radius: 20px;
                color: $blue;
                display: inline-block;
                margin: 5px 0;
              }
              &.new_graduate {
                padding: 1px 10px;
                background-color: $blue;
                border-radius: 5px;
                color: #fff;
                display: inline-block;
                margin: 5px 0 5px 5px;
              }
              &.done {
                padding: 6px 0;
                background-color: #e6e6e6;
                border-radius: 5px;
                font-weight: bold;
                text-align: center;
                color: $black;
                margin-top: 10px;
              }
            }
            .heart {
              width: 20px;
              position: absolute;
              top: 5px;
              right: 5px;
            }
            ul {
              display: flex;
              flex-wrap: wrap;
              margin-top: 5px;
              li {
                width: auto;
                padding: 0 5px;
                border: 1px $lightgray solid;
                border-radius: 5px;
                margin: 3px;
              }
            }
          }
          &.new a:after {
            content: "NEW";
            width: 45px;
            padding: 5px 0;
            background-color: $black;
            font-weight: bold;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
    .cont4 a {
      @include mq(pc) {
        font-size: 16px;
      }
      &.btn {
        background-color: #8d8d8d;
        color: #fff;
        margin: 0 auto 25px;
        @include mq(pc) {
          width: 300px;
          padding: 17px 0;
        }
        @include mq(sp) {
          margin-bottom: 15px;
        }
      }
      &:not(.btn) {
        text-align: center;
        text-decoration: underline;
      }
    }
  }
}
