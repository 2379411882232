#parachannel #search {
  padding-top: 35px;
  ul li {
    padding: 26px 20px;
    border-bottom: 1px $lightgray solid;
    font-weight: bold;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    @include mq(sp) {
      padding: 15px 10px;
      display: block;
    }
    a {
      &:nth-child(1) {
        width: calc(100% - 140px);
        font-size: 16px;
        line-height: 34px;
        word-break: break-all;
        @include mq(sp) {
          width: 100%;
          font-size: 14px;
          line-height: 24px;
        }
        &:hover {
          color: $blue;
          opacity: 1;
        }
      }
      &:nth-child(2) {
        width: 120px;
        padding: 13px 0;
        background-color: #e6e6e6;
        border-radius: 10px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        @include mq(sp) {
          width: 100px;
          padding: 9px 0;
          border-radius: 5px;
          font-size: 12px;
          margin: 10px 0 0 auto;
        }
      }
    }
  }
}