#parachannel #company .top > div .btn_box {
    .disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

#parachannel #company #scouts.mypage.top .pagination li.active a {
  display: block;
}

#parachannel #company #scouts.mypage.top .pagination li {
  margin: 0;
}