// jobs - entry

#parachannel .entry {

  font-weight: bold;
  display: block;

  > p {
    width: 100%;
    line-height: 30px;
    text-align: center;
    margin-bottom: 45px;
    @include mq(pc) {
      font-size: 16px;
    }
    @include mq(sp) {
      line-height: 24px;
      margin-bottom: 20px;
    }
    span {
      color: $lightgray;
    }
  }

  .note {
    margin-top: 5px;
  }

  .entry_cont {

    .ttl_box {
      padding-bottom: 20px;
      border-bottom: 1px $lightgray solid;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 45px;
      position: relative;
      @include mq(sp) {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
      h3 {
        line-height: 35px;
        color: #606e84;
        @include mq(sp) {
          width: 100%;
          line-height: 25px;
        }
      }
      a {
        width: 260px;
        padding: 9px 0;
        border: 1px $blue solid;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
        color: $blue;
        @include mq(sp) {
          width: 160px;
          padding: 4px 0;
          font-size: 10px;
        }
        span {
          width: 14px;
          padding: 2px 0;
          background-color: $blue;
          border-radius: 50%;
          font-size: 12px;
          text-align: center;
          color: #fff;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    dl {
      padding: 0 45px;
      line-height: 30px;
      display: flex;
      flex-wrap: wrap;

      @include mq(pc) {
        dt,dd {
          //margin-bottom: 50px;
          margin-top: 20px;
          padding-top: 20px;
          &:first-of-type {
            margin-top: 0;
            padding-top: 0;
          }
        }
      }
      @include mq(sp) {
        padding: 0 30px 0 0;
        line-height: 24px;
        display: block;
      }
    }

    dt, dd {
      border-top: dotted 1px #ccc;
      &:first-of-type {
        border: none;
      }
    }

    dt {
      position: relative;
      width: 290px;
      font-size: 18px;


      @include mq(sp) {
        width: 100%;
        font-size: 14px;
        margin-bottom: 5px;
      }

      a {
        display: block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
        font-size: 80%;
        color: $blue;
        text-align: center;
        background-color: #fff100;
        border-radius: 20px;

        span {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }

      &:nth-child(n + 2) {
        a {
          top: 20px;
        }
      }
    }

    dd {
      width: calc(100% - 290px);
      font-size: 16px;
      position: relative;
      @include mq(sp) {
        width: 100%;
        font-size: 12px;
        margin-bottom: 25px;
        &:last-child {
          margin: 0;
        }
      }
      /*span {
        font-size: 14px;
        @include mq(sp) {
          font-size: 10px;
        }
      }*/
      .edit_box {
        margin: 0 0 10px;
        padding: 10px;
        background-color: $lightblue;
        border: none;
      }
      // a:not(.edit_box) {
      //   width: 30px;
      //   position: absolute;
      //   right: -45px;
      //   bottom: 0;
      //   @include mq(sp) {
      //     width: 24px;
      //     right: -30px;
      //   }
      // }

      .nest_child {
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .cont1:not(.btn_box) {
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border: 10px rgba(255,215,200,0.5) solid;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      pointer-events: none;
      @include mq(sp) {
        border-width: 5px;
      }
    }
  }

  .cont1 {
    padding: 35px 80px 90px;
    margin-bottom: 75px;
    position: relative;
    z-index: 9;
    @include mq(sp) {
      padding: 20px;
      margin-bottom: 30px;
    }

    > div {
      margin-top: 100px;
      @include mq(sp) {
        margin-top: 50px;
      }
      &:nth-of-type(1) {
        margin: 0;
      }
      .not_set {
        .red {
          width: 100%;
          font-size: 16px;
          line-height: 26px;
          text-align: center;
          color: #ed1c24;
          margin-bottom: 40px;
          @include mq(sp) {
            font-size: 13px;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }
        .btn {
          width: 150px;
          padding: 10px 0;
          border-radius: 30px;
        }
      }
      dl dd.not_set {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        @include mq(sp) {
          display: block;
        }
        div,
        p {
          width: calc(100% - 150px);
          color: #ccc;
          @include mq(sp) {
            width: 100%;
          }
        }
        .btn {
          margin: 0;
          position: static;
          @include mq(sp) {
            margin-top: 10px;
          }
        }
      }
    }

    dd {
      .textarea_cont {
        margin-top: 20px;
      }

      .upload_cont.btn.type3 {
        width: 100%;
        @include mq(sp) {
          width: 250px;
          padding: 0;
          margin: 15px 0 0;
          label {
            padding: 16px 0;
          }
        }
      }
    }
  }

  .cont2 dl {
    margin-bottom: 80px;
    @include mq(sp) {
      padding: 0;
      margin-bottom: 50px;
    }

    .radio_cont {
      .radio_list {
        display: flex;
        flex-wrap: wrap;
        border-radius: 6px;
        padding: 4px 10px;
        label {
          display: flex;
          cursor: pointer;
          margin-right: 10px;
          input {
            overflow: hidden;
            border-radius: 2px;
            width: 18px;
            height: 18px;
          }
          span {
            color: unset;
            margin-top: 1px;
          }
        }
      }
    }

    dd span {
      color: #8d8d8d;

      &.any {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        padding: 5px 10px 4px;
        background-color: #8d8d8d;
        color: #fff;
        font-size: 70%;
        line-height: 1;
        border-radius: 2px;
      }

      &.ex {
        display: block;
        margin-top: 10px;
        line-height: 1.5;
        font-size: 90%;
      }
    }

    .preview span {
      color: unset;
      font-size: 16px;

      @include mq(sp) {
        font-size: 100%;
      }
    }

    &.type2 {
      padding: 0;
      border: 1px #adadad solid;
      border-radius: 5px;
      margin-bottom: 50px;
      overflow: hidden;
      @include mq(sp) {
        margin-bottom: 30px;
      }
      &:last-child {
        margin: 0;
      }
      dt,dd {
        padding: 20px;
        border-bottom: 1px #adadad solid;
        margin: 0;
        @include mq(sp) {
          width: 100% !important;
          padding: 13px 15px !important;
        }
        &:last-of-type {
          border: none;
        }
      }
      dt {
        width: 270px;
        padding-right: 30px;
        padding-left: 30px;
        background-color: rgba(127,228,255,0.7);
        color: #658e99;

        @include mq(sp) {
          border: none;
        }

        small {
          font-size: 80%;
        }

        span {
          padding: 4px 8px;
          background-color: #f00;
          border-radius: 20px;
          font-size: 12px;
          line-height: 100%;
          white-space: nowrap;
          color: #fff;
          margin-left: 10px;
          &.any {
            background-color: #9e9f9f;
          }
        }
      }
      dd {
        width: calc(100% - 270px);
        padding-right: 100px;
        font-weight: normal;

        h4 {
          margin-bottom: 5px;
          font-size: 18px;
          color: $black;
          //font-weight: bold;

          @include mq(sp) {
            font-size: 13px;
          }
        }
        p {
          font-weight: normal;

          &.acc_ttl {
            font-weight: bold;

            & + div {
              margin-top: 10px;
            }
          }
        }

        select {
          width: 280px;
          background: #dbffff url(../../img/company/icon11.png) no-repeat;
          background-size: 15px;
          background-position: right 15px center;
          border-radius: 5px;
        }
        > div {
          padding-bottom: 15px;
          border-bottom: 1px #adadad solid;
          margin-bottom: 15px;
          &:last-of-type {
            padding: 0;
            border: none;
            margin: 0;
          }
        }

        &.error_box {
          background-color: rgba(255, 215, 200, 0.5);
          position: relative;
          .error_item {
            display: flex;
            align-items: center;
            align-content: center;
            position: absolute;
            top: 10px;
            right: 10px;

            // img {
            //   margin-left: 5px;
            //   &:nth-of-type(1) {
            //     width: 21px;
            //   }
            //   &:nth-of-type(2) {
            //     width: 30px;
            //   }
            // }
          }

          &::after {
            content: '未入力です。';
            display: block;
            font-size: 90%;
            font-weight: bold;
            color: #f00;
          }
        }

        .select {
          width: 100%;
          background: $lightblue url(../../img/mypage/arrow01.png) no-repeat;
          background-size: 12px;
          background-position: right 15px center;
          border: 1px $lightgray solid;
          border-radius: 5px;
          cursor: pointer;

          a {
            padding: 4px 15px;
          }

          &.comp {
            background-color: #fff;
          }
        }
        &.photo {
          .img_box {
            padding: 0;
            background: none;
            border: none;
            img {
              position: static;
              transform: translateX(0);
            }
          }

          .btn {
            position: relative;
            display: block;
            width: 160px;
            margin: 10px 0 0;
            padding: 15px 0;
            font-size: 12px;
            color: #fff;
            cursor: pointer;

            input {
              //display: none;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              appearance: none;
              font-size: 0;
              text-indent: -9999px;
              background: none;
            }
          }
        }
        &.zip label {
          display: flex;
          align-items: center;
          align-content: center;
          input {
            width: 150px;
          }
          .btn {
            width: 130px;
            padding: 10px 0;
            font-size: 12px;
            margin: 0 0 0 10px;
          }
        }
        &.tel > div:not(.error_item) {
          padding: 0;
          border: none;
          margin: 0;
        }
      }
    }
  }

  .btn_box {
    display: flex;
    justify-content: center;
    margin-top: 95px;
    @include mq(sp) {
      display: block;
      margin-top: 50px;
    }
    .btn {
      margin: 0 10px;
      @include mq(sp) {
        margin: 0 auto 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.cont1 .btn {
      background-color: #f2f2f2;
      color: $blue;
      &:hover {
        box-shadow: 3px 3px 5px rgba(0,0,0,0.3);
      }
    }
    &.cont2 {
      margin-top: 115px;
      @include mq(sp) {
        margin-top: 50px;
      }
      .btn {
        width: 215px;
        padding: 11px 0;
        background-color: #fff;
        border: 1px #ff9d3f solid;
        border-radius: 30px;
        font-size: 16px;
        color: #ff9d3f;
        position: relative;
        span {
          line-height: 38px;
          position: absolute;
          top: 0;
          right: 15px;
        }
        &.back {
          border-color: $blue;
          color: $blue;
        }
        &.submit {
          background-color: #ff9d3f;
          color: #fff;
        }
      }
    }
  }

  .complete_cont > div {
    padding: 50px 150px;
    border: 1px $lightgray solid;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    @include mq(sp) {
      padding: 20px;
      margin-bottom: 30px;
    }
    label {
      width: 33.33%;
      display: flex;
      align-items: center;
      align-content: center;
      margin: 10px 0;
      cursor: pointer;
      @include mq(pc) {
        font-size: 16px;
      }
      @include mq(sp) {
        width: 50%;
      }
      input {
        width: 20px;
        height: 20px;
        margin: 0 20px 0 0;
        @include mq(sp) {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }
  }

  &.complete {
    display: block!important;

    p {
      font-size: 105%;
    }

    h3 {
      margin-bottom: 60px;
      font-size: 150%;
      text-align: center;

      @include mq(sp) {
        margin: 40px 0 20px;
        font-size: 130%;
      }
    }

    .btn {
      width: 400px;
      height: 60px;
      margin: 0 auto 20px;
      line-height: 60px;
      text-align: center;
      background-color: $navyblue;
      border-radius: 5px;
      font-size: 130%;

      @include mq(sp) {
        width: 80%;
        height: 50px;
        margin: 0 auto 10px;
        line-height: 50px;
        font-size: 110%;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        color: #fff;
      }
    }
  }

}
