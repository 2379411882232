#parachannel footer {
  font-weight: bold;
  .cont1 {
    background-color: $yellow;
    .wrapper {
      padding: 50px 0;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      position: relative;
      z-index: 9;
      @include mq(sp) {
        padding: 30px 0;
        display: block;
      }
      > p {
        width: 800px;
        position: absolute;
        top: -30px;
        left: 0;
        z-index: -1;
        @include mq(sp) {
          width: 100%;
          top: -3.5vw;
        }
      }
      .category_box {
        @include mq(sp) {
          margin-bottom: 20px;
        }
        h2 {
          font-size: 18px;
          margin-bottom: 15px;
          @include mq(sp) {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        ul {
          display: flex;
          @include mq(sp) {
            flex-wrap: wrap;
          }
          li {
            margin-right: 10px;
            @include mq(pc) {
              &:last-child {
                margin: 0;
              }
            }
            @include mq(sp) {
              margin: 5px;
            }
            a {
              min-width: 130px;
              padding: 8px 20px;
              background-color: #fff;
              border-radius: 20px;
              text-align: center;
              color: $gray;
              @include mq(sp) {
                min-width: 100px;
                padding: 9px 10px;
              }
            }
          }
        }
      }
      .link_box {
        width: auto;
        display: flex;
        align-items: center;
        align-content: center;
        margin: 0;
        @include mq(sp) {
          justify-content: center;
        }
        li {
          width: 220px;
          @include mq(sp) {
            width: 180px;
          }
          &.sns {
            width: 50px;
            margin-left: 20px;
            @include mq(sp) {
              width: 40px;
            }
          }
        }
      }
    }
  }
  .cont2 {
    background-color: $blue;
    .wrapper {
      padding: 50px 0 20px;
      color: #fff;
      @include mq(sp) {
        padding: 30px 0 15px;
        text-align: center;
      }
      a {
        color: #fff;
      }
      nav {
        margin-bottom: 50px;
        @include mq(sp) {
          margin-bottom: 30px;
        }
        ul {
          @include mq(pc) {
            display: flex;
          }
          li {
            display: flex;
            @include mq(sp) {
              margin-bottom: 5px;
            }
            &:after {
              content: "";
              width: 1px;
              height: 100%;
              background-color: #fff;
              display: block;
              margin: 0 5px;
            }
            &:last-child:after {
              display: none;
            }
          }
        }
      }
      p {
        font-size: 12px;
        text-align: center;
        @include mq(sp) {
          font-size: 10px;
        }
      }
    }
  }
}
