#parachannel #company {
  
  /* jobs */
  .jobs.edit {
    position: relative;

    .step_box {
      position: relative;

      &::before {
        @include mq(pc) {
          left: 30%;
        }
      }
      ul {
        @include mq(pc) {
          justify-content: start;
        }
      }

      li {
        &:first-child {margin-left: 0;}
        &:last-child {margin-right: 0;}
      }

      @media print, screen and (min-width: 768px) and (max-width: 1300px) {
        margin-bottom: 30px;

        ul {
          justify-content: center;
        }
        &::before {
          left: 50%;
        }
      }
    }

    #jobs {
      padding: 0;

      > p:not(.red) {
        font-weight: bold;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-end;
        margin: -40px 0 25px;
        @include mq(sp) {
          font-size: 10px;
          margin: 0 0 20px;
        }
        a {
          padding: 5px;
          border: 1px $blue solid;
          border-radius: 5px;
          color: $blue;
          margin-left: 5px;
        }
      }
      .red {
        font-weight: bold;
        text-align: center;
        color: #e44e66;
        margin: 50px 0 20px;
      }

      .btn_area {
        text-align: center;
      }

      .btn {
        display: inline-block;
        margin: 0 20px;
        padding: 18px 0;
        font-size: 20px;
        border-radius: 30px;
        background-color: $orange;

        @include mq(pc) {
          transition: opacity .15s linear;
					-webkit-transition: opacity .15s linear;

          &:hover {
            opacity: .7;
          }
        }

        @include mq(sp) {
          width: 45%;
          margin: 0 3px;
          padding: 15px 0;
          font-size: 15px;
        }

        &.head_btn {
          @include mq(pc) {
            position: absolute;
            right: 0;
            top: 0;
            width: 200px;
            padding: 20px 0;
            z-index: 1;
          }
          @media print, screen and (min-width: 768px) and (max-width: 1300px) {
            position: relative;
            right: auto;
            top: 0;
            display: block;
            width: 300px;
            margin: 0 auto 50px;
          }

          @include mq(sp) {
            display: block;
            width: 200px;
            margin: 0 auto 30px;
          }
        }

        &.open_btn {
          width: 320px;
          margin-top: 20px;
          background-color: $green;

          @include mq(sp) {
            width: 45%;
          }
        }
      }

    }

    

    // .addition_box {
    //   border: 1px #f2f2f2 solid;
    //   border-radius: 5px;
    //   font-weight: bold;
    //   margin-top: 60px;
    //   @include mq(sp) {
    //     margin-top: 50px;
    //   }
    //   li {
    //     p {
    //       padding: 8px 15px;
    //       background-color: #f2f2f2;
    //       border-radius: 5px;
    //       display: flex;
    //       align-items: center;
    //       align-content: center;
    //       img {
    //         width: 20px;
    //         margin-right: 15px;
    //       }
    //     }
    //     ul {
    //       padding: 10px;
    //       display: flex;
    //       flex-wrap: wrap;
    //     }
    //     li {
    //       margin: 5px;
    //       @include mq(pc) {
    //         min-width: 150px;
    //       }
    //     }
    //   }
    // }
    
    span.edit_icon {
      padding: 3px 5px;
      font-size: 12px;
      vertical-align: middle;
      display: inline-block;
      margin: 5px 0 8px 10px;
      background-image: url("../../img/company/icon13.svg");
      background-repeat: no-repeat;
      width: 25px;
      height: 25px;
    }
    
  }

}
