#parachannel #jobs {
  padding-top: 35px;
  @include mq(sp) {
    padding-top: 20px;
  }
  > section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row-reverse;
    @include mq(sp) {
      display: block;
    }
    .sidebar {
      width: 35%;
      font-size: 14px;
      font-weight: bold;
      @include mq(sp) {
        width: 100%;
      }
      > .btn {
        width: 100%;
        padding: 14px 0;
        background: none;
        border: 1px $navyblue solid;
        color: $navyblue;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-bottom: 45px;
        @include mq(sp) {
          margin-bottom: 30px;
        }
        img {
          width: 20px;
          margin-right: 35px;
        }
      }
      > div {
        margin-bottom: 30px;
        .ttl_box {
          padding-bottom: 15px;
          border-bottom: 1px $lightgray solid;
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-bottom: 15px;
          h3 {
            font-size: 16px;
          }
          a {
            color: $lightgray;
            display: flex;
            align-items: center;
            align-content: center;
            img {
              width: 6px;
              margin-left: 15px;
            }
          }
        }
        p,ul {
          line-height: 20px;
          color: $blue;
          &.largecategory:before,
          &.smallcategory:before {
            content: "・";
            display: inline-block;
          }
          &.smallcategory {
            color: $navyblue;
            margin-left: 1em;
          }
        }
        ul {
          list-style-type: disc;
          li {
            margin: 0 0 5px 1.5em;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        > form {
          input {
            height: 35px;
          }
        }
        &.category {
          .ttl_box {
            padding: 0;
            border: none;
          }
          ul {
            list-style-type: none;
            display: flex;
            li {
              margin: 0 10px 0 0;
              &:last-child {
                margin: 0;
              }
              .btn {
                width: auto;
                min-width: 120px;
                padding: 10px;
                background-color: $lightgray;
                border-radius: 10px;
                margin: 0;
                &.active {
                  background-color: $blue;
                }
              }
            }
          }
        }
        &.btn_box {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          margin-top: 40px;
          .btn {
            width: 49%;
            margin: 0;
            &.gray {
              padding: 21px 0;
              font-size: 14px;
              @include mq(sp) {
                padding: 19px 0;
                font-size: 12px;
              }
            }
          }
        }
      }
      > p {
        font-weight: normal;
        line-height: 20px;
        margin: -10px 0 30px;
      }
      > a {
        color: $blue;
        display: flex;
        align-items: center;
        align-content: center;
        margin-bottom: 15px;
        &:last-of-type {
          margin: 0;
        }
        img {
          width: 6px;
          margin-left: 15px;
        }
      }
    }
    .cont_box {
      width: 62%;
      @include mq(sp) {
        width: 100%;
        margin-bottom: 30px;
      }
      .pagination {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        li {
          margin: 0 5px;
          @include mq(sp) {
            margin: 3px 5px;
          }
          a {
            font-size: 14px;
            color: $blue;
            span {
              width: 30px;
              padding: 8px 0;
              background-color: #c8c8c8;
              border-radius: 50%;
              text-align: center;
              color: #fff;
              display: block;
            }
          }
          &.prev {
            margin: 0 10px 0 0;
          }
          &.next {
            margin: 0 0 0 10px;
          }
          &.active span {
            background-color: $blue;
          }
        }
        &.small {
          @include mq(pc) {
            justify-content: flex-end;
          }
          @include mq(sp) {
            margin-top: 20px;
          }
          li a {
            font-size: 12px;
            span {
              width: 26px;
              padding: 7px 0;
            }
          }
        }
      }
      .tab_btn {
        //padding: 0 15px 20px;
        border-bottom: 1px $lightgray solid;
        display: flex;
        justify-content: center;
        margin: 40px 0 25px;

        @include mq(sp) {
          padding: 0;
          margin: 30px 0 0;
        }
        li {
          width: calc(100%/4);
          background-color: #f8f8f8;
          border-left: solid 1px #ddd;
          &:first-child {
            border-left: none;
          }
          @include mq(pc) {

          }
          @include mq(sp) {
            //width: 20%;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
          }
          a {
            display: block;
            padding: 15px 0;
            font-weight: bold;
            text-align: center;
            color: $lightgray;
            @include mq(pc) {
              font-size: 14px;
            }
            @include mq(sp) {
              line-height: 16px;
            }
          }
          &.active {
            background-color: $blue;
            a {
              color: #fff;
            }
          }
        }
      }
      .tab_box {
        padding-bottom: 40px;
        border-bottom: 1px $lightgray solid;
        margin-bottom: 25px;
        @include mq(sp) {
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        > li {
          display: none;
          &.active {
            display: block;
          }
          .jobs_list {

            li {
              margin-bottom: 30px;
              @include mq(pc) {
                &:last-child {
                  margin: 0;
                }
              }
              @include mq(sp) {
                //margin: 20px 0 0;
                margin: 0 auto;
                padding: 20px 0;
                border-top: solid 1px #efefef;
              }
            }
          }
        }
      }
    }
  }

  // エラーメッセージ用
  p.error_text {
    font-size: 12px;
    color: #f00;
    margin-top: 10px;
  }
  .ttl .error_text {
    text-align: right;
    font-family: initial;
  }
  .detail .sidebar .tab_box div.error_box {
    display: block;
    text-align: right;
    p.error_text {
      color: #f00;
    }
  }
  .detail_other .add_item_box .preview_display p.error_text {
    width: 100%;
    text-align: right;
    margin: 10px 0 0;
    padding: 0
  }
  .detail .cont_box p.error_text {
    text-align: right;
  }
  button:disabled {
    background-color: lightgray!important;
  }
}
