#parachannel #mypage {

  .recruit_management_cont {
    //max-width: 1480px;
    margin: 0 auto;

    .tab_anchor {
      //margin-bottom: 36px;
      margin-bottom: 10px;
      display: flex;
      border-bottom: 1px solid $p_gray;

      @include mq(sp) {
        margin-bottom: 24px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 5px;
        width: 25%;
        line-height: 1.45;
        text-align: center;
        font-size: 18px;
        color: $p_gray;

        @include mq(sp) {
          padding: 6px 4px;
          font-size: 12px;
        }

        &.active {
          background-color: $blue;
          color: #fff;
        }
      }

      & + .note {
        margin-bottom: 20px;
        text-align: right;
      }
    }

    .recurit_list {
      padding-bottom: 20px;
      margin-bottom: 36px;
      border-bottom: 1px solid $p_gray;

      @include mq(sp) {
        padding-bottom: 10px;
        margin-bottom: 30px;
      }

      >li {
        margin-bottom: 34px;

        @include mq(sp) {
          margin-bottom: 22px;
        }

        a {
          display: flex;

          @include mq(sp) {
            //flex-wrap: wrap;
            justify-content: space-between;
          }

          >div {
            @include mq(sp) {
              //width: 100% !important;
            }
          }

          .img_cont {
            position: relative;
            margin-right: 40px;
            width: 50%;
            padding-bottom: calc(50%/4*3);
            overflow: hidden;
            @include mq(sp) {
              width: 45%;
            }

            img {
              position: absolute;
              left: 50%;
              top: 0;
              width: auto;
              max-width: none;
              height: 100%;
              transform: translateX(-50%);
            }

            @include mq(sp) {
              margin-right: 0;
              margin-bottom: 14px;
            }

            .like_btn {
              position: absolute;
              cursor: pointer;
              right: 18px;
              top: 18px;
              width: 36px;
              height: 33px;
              background-image: url(../../img/mypage/like_btn_off.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;

              @include mq(sp) {
                width: 24px;
                height: 20px;
              }

              &.on {
                background-image: url(../../img/mypage/like_btn_on.svg);
              }
            }

          }

          .txt_cont {
            min-width: 320px;
            width: 45%;
            line-height: 1.6;
            font-size: 16px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;

            @include mq(sp) {
              width: 53%;
              min-width: auto;
              font-size: 12px;
            }

            .update {
              display: inline-block;
              margin-bottom: 10px;
              padding: 2px 40px;
              background-color: #8cc63f;
              color: #fff;
              font-size: 18px;
              text-align: center;

              @include mq(sp) {
                margin-bottom: 5px;
                padding: 2px 20px;
                font-size: 14px;
              }
            }

            .unread {
              padding: 7px 20px;
              margin-bottom: 7px;
              border: 2px solid white;
              border-radius: 1000px;
              background-color: red;
              color: white;
              line-height: 100%;
              font-size: 16px;
              @include mq(sp) {
                font-size: 12px;
              }
            }

            .company {
              width: 100%;
              margin-bottom: 20px;
              font-size: 20px;
              font-weight: bold;
              color: $blue;

              @include mq(sp) {
                margin-bottom: 10px;
                font-size: 16px;
              }
            }

            .topic_ttl {
              font-size: 20px;
              font-weight: bold;

              @include mq(sp) {
                font-size: 14px;
              }
            }

            .outline {
              width: 100%;
              margin-bottom: 10px;
            }

            .occupation {
              display: inline-block;
              padding: 5px 20px;
              border: 2px solid $blue;
              border-radius: 1000px;
              color: $blue;
              line-height: 100%;
              @include mq(sp) {
                margin: 3px;
              }
            }

            .category {
              //width: 100px;
              width: auto;
              padding: 5px 10px;
              border: 2px solid;
              border-radius: 10px;
              line-height: 100%;
              text-align: center;
              display: block;
              margin-left: 10px;
              @include mq(sp) {
                margin: 3px;
              }
              &.type1 {
                border-color: $orange;
                color: $orange;
              }
              &.type4 {
                border-color: #f39800;
                color: #f39800;
              }
            }

            .work_ex {
              width: 100%;
              margin-top: 10px;
              ul {
                li {
                  position: relative;
                  padding-left: 1em;

                  &::before {
                    content: '・';
                    position: absolute;
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    .pageer_cont {
      display: flex;
      align-items: center;
      justify-content: center;

      >a {
        cursor: pointer;
        margin: 0 15px;
        color: #a2c2d4;
        font-size: 21px;

        @include mq(sp) {
          margin: 0 7px;
          font-size: 12px;
        }
      }

      ol {
        display: flex;
        align-items: center;

        li {
          margin: 0 6px;

          @include mq(sp) {
            margin: 0 6px;
          }

          a {
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #c8c8c8;
            color: #fff;
            border-radius: 1000px;

            @include mq(sp) {
              width: 28px;
              height: 28px;
            }

            &.current {
              background-color: $blue;

            }
          }
        }
      }
    }

    // メッセージ
    &.message {
      .ui_cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .message_cont {
          width: 70%;

          @include mq(sp) {
            width: 100%;
            margin-bottom: 40px;
          }

          .messages {
            max-height: 350px;
            padding-right: 10px;
            margin-bottom: 80px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-track {
              background-color: #f2f2f2;
            }
            &::-webkit-scrollbar-thumb {
              background-color: $blue;
            }

            li {
              margin-bottom: 20px;

              .date {
                color: #808080;
                font-size: 13px;
                margin-bottom: 5px;

                @include mq(sp) {
                  font-size: 12px;
                }
              }

              .contents {
                border-radius: 6px;
                position: relative;
                padding: 20px;
                font-size: 14px;
                line-height: 1.6;
                white-space: pre-wrap;

                @include mq(sp) {
                  padding: 12px 16px;
                }

              }
            }

            .you {
              max-width: 75%;
              margin-left: auto;

              @include mq(sp) {
                max-width: 85%;
              }

              .contents {
                position: relative;
                margin-right: 30px;
                background-color: #dbffff;

                &::after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: -30px;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 11.5px 0 11.5px 30px;
                  border-color: transparent transparent transparent #dbffff;
                  transform: translate(0, -50%);
                }
              }
            }

            .company {
              max-width: 75%;

              .contents {
                background-color: #f7f7f7;

                .btn_cont {
                  margin-top: 30px;
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;

                  a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    width: 49%;
                    @include mypage_shadow;
                    background-color: #fff;
                    font-size: 16px;
                    padding: 10px;

                    @include mq(sp) {
                      font-size: 12px;
                    }

                    &:nth-child(1) {
                      color: $blue;
                    }

                    &:nth-child(2) {
                      color: #8d8d8d;
                    }
                  }
                }
              }
            }
          }

          .input_message_cont {
            textarea {
              margin-bottom: 20px;
              padding: 20px 15px;
              border: none;
              background-color: #f2f2f2;
              font-size: 14px;
              font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif;
            }

            .candidate_cont {
              font-size: 16px;
              font-weight: bold;
              line-height: 1.6;
              margin-bottom: 30px;
            }

            .btn_cont {
              >a {
                margin-bottom: 20px;
                padding: 18px 40px;
                min-width: 180px;
                text-align: center;
                color: #fff;
                font-size: 16px;
                font-weight: bold;
                background-color: $blue;


                @include mq(sp) {
                  min-width: 72px;
                  padding: 12px 16px;
                  font-size: 14px;
                }

                &.add_btn {
                  position: relative;
                  padding-right: 40px;
                  padding-left: 40px;
                  background-color: #7fe4ff;

                  img {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    display: inline-block;
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    transform: translate(0, -50%);
                  }
                }
              }

            }
          }
        }

        .memo_cont {
          width: 24%;

          @include mq(sp) {
            width: 100%;
          }

          .memo_ttl_cont {
            margin-top: 23px;
            padding-bottom: 20px;
            border-bottom: 1px solid $p_gray;

            @include mq(sp) {
              margin-top: 12px;
              padding-bottom: 12px;
            }

            p {
              display: flex;
              align-items: center;
              font-size: 16px;

              @include mq(sp) {
                font-size: 14px;
              }

              img {
                margin-left: 10px;
                width: 32px;
                min-width: 32px;
                height: 32px;
              }

            }
          }

          .memo_list {
            li {
              padding: 30px 0;
              line-height: 1.8;
              border-bottom: 1px solid $p_gray;

              @include mq(sp) {
                padding: 20px 0;
              }

              dl {
                font-size: 13px;
                color: #808080;

                @include mq(sp) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

  }

}
