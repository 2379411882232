$p_gray : #9e9f9f;
$pink : #df0056;


// @mixin mypage_shadow {
//   box-shadow: 2px 2px 3px rgba(0, 0, 0, .1);
// }

//#parachannel #mypage {

#parachannel {

  #mypage {
    /* 共通 */
    display: flex;
    margin: 0;

    @include mq(sp) {
      display: block;
    }
  }

  //>section:not(#sidebar) {
  #mypage_inner {
    width: calc(100% - 330px);
    //padding: 140px 50px;
    padding: 40px 30px 140px;

    &.mypageSchedules {
      padding: 40px 30px 140px;
    }

    @include mq(pc) {
      max-width: 1000px;
      margin: 0 auto;
    }

    @include mq(sp) {
      width: 100%;
      padding: 30px 3% 50px;
    }

    .ttl_box {
      display: flex;
      align-items: flex-end;
      align-content: flex-end;
      justify-content: space-between;
      margin: 50px 0 40px;

      @include mq(sp) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin: 30px 0;
        .ttl {
          width: 100%;
          margin-top: 20px;
        }
      }

      h2 {
        position: relative;
        padding: 5px 0 5px 10px;
        border-left: solid 10px #006496;
      }
      ul {
        height: 30px;
        display: flex;
        align-items: flex-end;
        align-content: flex-end;
        @include mq(sp) {
          width: 100%;
          height: 15px;
        }
        li {
          margin-right: 25px;
          @include mq(sp) {
            margin-right: 15px;
          }
          a {
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            align-content: flex-end;
            @include mq(sp) {
              font-size: 10px;
            }
            img {
              width: 15px;
              margin-right: 5px;
              @include mq(sp) {
                width: 10px;
              }
              &.guide {
                width: 30px;
                @include mq(sp) {
                  width: 15px;
                }
              }
            }
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }

    > .btn_box {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-bottom: 50px;
      @include mq(sp) {
        display: block;
        margin-bottom: 30px;
      }
      a {
        width: 300px;
        height: 50px;
        box-shadow: 2px 0 2px rgba(0,0,0,0.3);
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        display: flex;
        align-items: center;
        align-content: center;
        margin: 0 15px;
        overflow: hidden;
        @include mq(sp) {
          margin: 0 auto 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        span {
          width: calc(100% - 40px);
        }
        img {
          width: auto;
          max-width: none;
          height: 50px;
          padding: 9px 10px;
          background-color: #fff;
        }
        &.blue {
          background-color: $blue;
        }
        &.navyblue {
          background-color: $navyblue;
        }
        &.orange {
          background-color: #ff7d00;
        }
      }
    }


  }

  //タブメニュー
  .link_box {
    width: 100%;
    padding-top: 2px;
    margin-bottom: 55px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include mq(sp) {
      margin-bottom: 30px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      //width: 980px;
      border-bottom: 8px $blue solid;
      display: flex;
      justify-content: center;
      margin: 0 auto;

      @include mq(sp) {
        width: 750px;
        border-bottom-width: 4px;
      }

      li {
        //width: 16.25%;
        padding: 0 20px;
        box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3);
        border-radius: 5px 5px 0 0;
        text-align: center;
        margin-right: 0.5%;

        &:last-child {
          margin: 0;
        }

        a {
          padding: 11px 0;
          font-weight: bold;
          color: $lightgray;

          @include mq(pc) {
            font-size: 16px;
          }

          @include mq(sp) {
            padding: 9px 0;
          }
        }

        &.small a {
          padding: 13px 0;
          font-size: 14px;

          @include mq(sp) {
            padding: 10px 0;
            font-size: 10px;
          }
        }

        &.active {
          background-color: $blue;

          a {
            color: #fff;
          }
        }
      }
    }
  }

  .completion {
    width: 100%;
    max-width: 850px;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    margin: 0 auto 50px;

    @include mq(sp) {
      display: block;
      margin-bottom: 30px;
    }

    >div {
      color: $blue;

      @include mq(sp) {
        width: 100%;
        margin-bottom: 20px;
      }

      h2 {
        font-size: 26px;
        margin-bottom: 45px;

        @include mq(sp) {
          font-size: 18px;
          text-align: center;
          margin-bottom: 20px;
        }
      }

      .graph_box {
        width: 260px;
        height: 260px;
        pointer-events: none;
        margin: 0 auto;
        position: relative;

        @include mq(sp) {
          width: 200px;
          height: 200px;
        }

        >div {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;

          >div {
            width: 545px;
            height: 545px;
            position: absolute;
            top: -11px;
            left: -143px;

            @include mq(sp) {
              width: 425px;
              height: 425px;
              left: -113px;
            }

            > div {
              height: 272px;
              > canvas {
                margin: auto;
              }

              @include mq(sp) {
                height: 200px;
              }
            }
          }
        }

        p {
          width: 100%;
          font-size: 42px;
          text-align: center;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

          @include mq(sp) {
            font-size: 30px;
          }

          span {
            font-size: 64px;

            @include mq(sp) {
              font-size: 40px;
            }
          }
        }
      }

      > p {
        width: 100%;
        padding: 12px 0;
        background-color: #df0056;
        border-radius: 10px;
        font-size: 16px;
        text-align: center;
        color: #fff;
        margin-top: 10px;
        position: relative;
        @include mq(sp) {
          padding: 13px 0;
          font-size: 14px;
          margin: 0;
        }
        &:after {
          content: "";
          width: 20px;
          height: 20px;
          border-right: 10px transparent solid;
          border-bottom: 20px #df0056 solid;
          border-left: 10px transparent solid;
          box-sizing: border-box;
          position: absolute;
          top: -20px;
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }
    }

    ul {
      width: 440px;

      @include mq(sp) {
        width: 100%;
      }

      li {
        border-bottom: 1px $lightgray solid;
        background-color: rgba(219, 255, 255, 0.3);
        a {
          position: relative;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          padding: 15px 50px 15px 25px;

          &:last-child {
            border: none;
          }
          p {
            //width: calc(100% - 120px);
            font-size: 14px;
          }
          span {
            //width: 90px;
            display: inline-block;
            padding: 4px 10px;
            background-color: $pink;
            border-radius: 5px;
            text-align: center;
            color: #fff;
            //margin-right: 20px;
            &.complete {
              background-color: #00df89
            }
          }
          img {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 10px;
          }
        }
      }
    }
  }

  .required {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 5px;
    padding: 4px 10px;
    color: #fff;
    font-size: 80%;
    line-height: 1;
    background-color: #f00;
    border-radius: 5px;
  }

  .any {
    margin-left: 1em;
    padding: 4px 10px;
    font-size: 80%;
    color: #fff;
    background-color: $p_gray;
    border-radius: 5px;
  }
}
