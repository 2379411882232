
@media screen and (max-width: 1499px) { 
    .planCard {
        padding: 50px;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin: 25px;
    }
}
@media only screen and (max-width: 399px) {
    .planCard {
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 50px;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin: 15px;
    }
}
@media screen and (min-width: 1500px) and (max-width: 2500px) { 
    .planCard {
        padding: 100px;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin: 25px;
    }
}