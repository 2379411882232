.btnDelete{
    background: #e57b79;
    color: white;
    padding: 5px !important;
    border-radius: 5px;
    max-width: 100px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}
dl {
    padding: 15px !important;
    margin: 0 !important;
}