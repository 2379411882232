
#parachannel #company .initial_registration {
  .error {
    color: #8a0421;
    border-color: #dd0f3b;
    background-color: #ffd9d9;
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
 
}

.displayNone {
  display: "none";
};