#parachannel #mypage {

  p.select {
    min-width: 135px;
    //padding: 7px 15px;
    border: 1px $lightgray solid;
    border-radius: 5px;
    cursor: pointer;

    a:not(.btn) {
      display: block;
      //padding: 7px 15px;
      margin: 0;
      color: inherit;
    }

    a {
      padding: 7px 15px;
    }
  }

  .other {
    padding-right: 0 !important;

    @include mq(sp) {
      padding: 10px !important;
    }

    tr {
      th {
        min-width: 210px;
      }

      td {
        position: relative;
        width: 100%;
        padding: 5px 60px 5px 10px;

        @include mq(sp) {
          padding: 5px 50px 5px 5px;
        }

        &.zip {
          flex-direction: column;
          align-content: flex-start;
          align-items: flex-start;
        }

        .nest_child {
          //margin: 5px 15px 5px 10px;
          margin: 5px 0;
          position: relative;

          .nest_child {
            margin-left: 0;
          }
        }

        .error_box {
          width: 100%;
          .error_msg {
            font-size: 12px;
            color: #f00;
            margin: 10px 0;
            @include mq(sp) {
              font-size: 10px;
            }
          }
        }
      }
    }

    .radio_cont {
      .radio_list {
        display: flex;
        flex-wrap: wrap;
        border-radius: 6px;
        background-color: #dbffff;
        padding: 4px 10px;
        margin-bottom: 5px;
        label {
          display: flex;
          cursor: pointer;
          margin-right: 10px;
          input {
            overflow: hidden;
            border-radius: 2px;
            width: 18px;
            height: 18px;
          }
          span {
            margin-top: 1px;
          }
        }
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      align-content: center;
      cursor: pointer;
      input {
        width: 18px;
        height: 18px;
        margin: 0 5px 0 0;
      }
    }

    .skill_list {
      li {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 5px;
        padding: 6px 20px;
        border-radius: 1000px;
        min-width: 105px;
        text-align: center;
        background-color: rgba(255, 255, 231, .75);

        @include mq(sp) {
          min-width: 70px;
          font-size: 10px;
        }

        .del {
          position: absolute;
          top: -2px;
          right: 4px;

          img {
            cursor: pointer;
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    input {
      width: 100%;
      //max-width: 370px;
      height: 30px;
      border-radius: 5px;

      @include mq(sp) {
        max-width: none;
      }

      &[type=radio] {
        width: 20px;
        height: 20px;
      }
    }

    // select {
    //   width: 210px;
    //   height: 30px;
    //   background: #dbffff url(../img/mypage/arrow01.png) no-repeat;
    //   background-size: 10px;
    //   background-position: right 10px center;
    //   border-radius: 5px;
    //   cursor: pointer;
    // }

    .red {
      color: $pink;
    }

    .radio {
      width: 100%;
      padding: 10px;
      background-color: #dbffff;
      display: flex;
      margin-bottom: 5px;

      label {
        display: flex;
        align-items: center;
        align-content: center;
        margin-right: 25px;
        cursor: pointer;

        input {
          width: 15px;
          height: 15px;
          margin: 0 5px 0 0;
        }
      }
    }

    

    >p {
      line-height: 20px;
      color: $gray;
      margin-top: 5px;

      @include mq(sp) {
        font-size: 10px;
        line-height: 16px;
      }
    }

    &.cont3 {
      input {
        background-color: #dbffff;
      }

      select {
        background-color: #fff !important;
      }
    }
  }

  .checkbox label {
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
    font-weight: normal;

    input {
      width: 15px;
      height: 15px;
      margin: 0 5px 0 0;
    }

    &::before {
      display: none;
    }
  }
}
