.load-overlay{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
}
.load-text {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.load-text h2 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 20px;
}
.load-text p {
  color: #fff;
}
.load-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 80px;
  height: 80px;
  border: 4px #ddd solid;
  border-top: 4px #999 solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}
@keyframes sp-anime {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}