//	編集終了済み表示

#parachannel .edit_box {
	/*cursor: pointer;
	position: relative;
	margin-top: 0!important;
	margin-bottom: 20px;
	width: 100%;
	padding: 24px;
	border-radius: 2px;
	background-color: #dbffff;
	@include mypage_shadow();*/

	position: relative;

	.txt_cont {
		h4 {
			position: relative;
			color: #888;

			@include mq(sp) {
				font-size: 110%;
			}
		}

		p {
			color: #000;
			font-weight: normal;
			line-height: 1.4;
			padding-bottom: 10px;
		}
	}

	&.fixed {
		h4 {
			padding-right: 60px;
		}
	}

	&.not_enter {
		background-color: rgba(255, 215, 200, .5);

		h4 {
			padding-right: 200px;

			@include mq(sp) {
				margin-top: 36px;
				padding-right: 0;
			}
		}
	}

	.icon_cont {
		position: absolute;
		right: -50px;
		top: 0;
		display: flex;
		align-items: center;
		cursor: pointer;

		@include mq(sp) {
			right: -40px;
		}

		span {
		  //margin-left: 5px;
		}

		&.uninput {
			display: flex;
			align-items: center;
			justify-content: space-between;
			// width: 200px;
			background: #fff;
			overflow: hidden;
			border-radius: 4px;

			p {
				padding: 0 10px;
				padding-left: 15px;
				color: $orange;
			}

			.error {
				display: block;
				padding: 10px;
				background: $orange;

				img {
					width: 15px;
					height: 13px;
				}
			}
		}

		// 完了アイコンサイズ
		.check {
			img {
				width: 21px;
				height: 21px;
			}
		}

		// 編集アイコンサイズ
		.edit {
			width: 46px;
			height: 46px;

			@include mq(sp) {
				width: 36px;
				height: 36px;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}
	}  
}