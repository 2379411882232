.none-applicant {
    display: block !important;
    background-color: #f2f2f2 !important;
    pointer-events: none !important;
}

.isMark {
    margin: -15px 0px 0px -20px;
    background-color: rgb(57 244 242 / 19%);
    height: calc(100% + 100%);
    width: calc(100% - -45px);
}

.isMarkHeigth {
    margin: -15px 0px 0px -20px;
    background-color: rgb(57 244 242 / 19%);
    height: calc(100% + 45%);
    width: calc(100% - -45px);
}