#parachannel  {
    .select_area_child {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > label {
        position: relative;
        width: 96%;
        display: block;
        margin-bottom: 10px;
        padding: 0 5px 0 20px;
        line-height: 1.4;
        input {
          position: absolute;
          left: 0;
        }
      }
    }
}

#parachannel  {
    .consideration_condition_type > a {
      color: #00a0e9;
      padding: 7px 0;
    }
    .select_area_child {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > label {
        position: relative;
        width: 48%;
        display: block;
        margin-bottom: 10px;
        padding: 0 5px 0 20px;
        line-height: 1.4;
        input {
          position: absolute;
          left: 0;
        }
      }
    }
}

#parachannel  {
    .select_area_child {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      & > label {
        position: relative;
        width: 48%;
        display: block;
        margin-bottom: 10px;
        padding: 0 5px 0 20px;
        line-height: 1.4;
        input {
          position: absolute;
          left: 0;
        }
      }
    }
  }

  #parachannel {
    .free_tag_input_area {
      display: flex;
      margin-bottom: 0.5rem;
      input {
        width: 200px;
        margin-right: 0.5rem;
      }
      button {
        background-color: white!important;
        color: #00a0e9!important;
        margin: 5px 0!important;
        border: solid 1px #00a0e9!important;
      }
    }
    .free_tag_display_area {
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 5px 10px 0 0;
        display: flex;
        a {
          margin-right: 0.1rem;
          i {
            width: 18px;
            color: #00a0e9;
            align-items: center;
          }
        }
      }
    }
  }

  #parachannel .recruitment_status {
    padding: 15px;
  }
  #parachannel .attention {
    display: block!important;
    margin: 30px 0 0!important;
    text-align: center;
    line-height: 1.5;
  }
  #parachannel .sidebar1 > a {
    text-decoration: underline;
    position: absolute;
    margin-top: -35px !important;
    color: black !important;
    font-weight: normal;
  }
  #parachannel #jobs .detail .sidebar .tab_box dd label p.note {
    font-size: 80%;
    color: #888;
    line-height: 1.4;
  }
  .load-area-warp {
    position: relative;
  }
  .load-area-warp .load-area {
    position: absolute;
    top: 0;
    left: 0;
  }
  .openai_link {
    font-size: 16px;
    font-weight: bold;
    // color: #00a0e9;
    color: gray;
    text-decoration: underline;
    cursor: pointer ;
  }

  // .icon {
  //   margin-left: 0%;
  //   height: 100px;
  //   margin-top: 25%;
  // } 

  .list {
      overflow: hidden;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: inline-block;
      background-color: #f8f8f8;
  }

.advice_txt {
  position: absolute;
  left: 50%;
  top: 10%;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  transform: translateX(-50%);
}

.null_txt {
  color: red;
}
.optionGrey {
  color: lightgray;
}
.mdi-close-circle::before {
  content: "\F0159";
}

.mdi:before, .mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#parachannel .blue_tag {
  width: 18px;
  color: #00a0e9;
}
#parachannel .imgtrash {
  position: absolute;
  left: auto;
  right: 10px;
  top: 10px;
  width: 50px;
  height: 50px;
  padding: 8px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  z-index: 1;
  transform: translate(0, 0);
}
.swImg {
  object-fit: contain;
}

#parachannel a[data-v-7f9abc22] {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

#parachannel a .img_box[data-v-7f9abc22] {
  width: 48%;
  padding-bottom: calc(48%/4*3);
  position: relative;
  overflow: hidden;
}

#parachannel a .text_box[data-v-7f9abc22] {
  width: 47%;
}

#parachannel a .text_box h3[data-v-7f9abc22] {
  color: #00a0e9;
  margin-bottom: 10px;
}
#parachannel a .text_box h3[data-v-7f9abc22], #parachannel a .text_box h4[data-v-7f9abc22] {
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

#parachannel a .text_box p[data-v-7f9abc22] {
  line-height: 18px;
  width: auto !important;
  text-align: left !important;
  margin: 0 !important;
  padding: 0 !important;
}

#parachannel a .text_box p.category[data-v-7f9abc22] {
  border: 1px #00a0e9 solid;
  color: #00a0e9;
}
#parachannel a .text_box p.category[data-v-7f9abc22], #parachannel a .text_box p.type[data-v-7f9abc22] {
  padding: 0 10px !important;
  border-radius: 20px;
  display: inline-block;
  margin: 5px 5px 5px 0 !important;
}

#parachannel a .text_box p.new_graduate[data-v-7f9abc22] {
  padding: 1px 10px !important;
  background-color: #00a0e9;
  margin: 5px 5px 5px 0;
}
#parachannel a .text_box p.new_graduate[data-v-7f9abc22], #parachannel a .text_box p.publish[data-v-7f9abc22] {
  border-radius: 5px;
  color: #fff;
  display: inline-block;
}

#parachannel a .text_box p.type[data-v-7f9abc22] {
  border: 1px #ea5514 solid;
  color: #ea5514;
}
#parachannel a .text_box p.category[data-v-7f9abc22], #parachannel a .text_box p.type[data-v-7f9abc22] {
  padding: 0 10px;
  border-radius: 20px;
  display: inline-block;
  margin: 5px 5px 5px 0;
}

#parachannel a .text_box p.address[data-v-7f9abc22] {
  margin-top: 5px;
}

#parachannel a .text_box p strong[data-v-7f9abc22] {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

#parachannel a .text_box ul[data-v-7f9abc22] {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  padding: 0 !important;
}

#parachannel a .text_box ul li[data-v-7f9abc22] {
  width: auto;
  padding: 0 5px !important;
  border-top: 1px #b3b3b3 solid !important;
  border: 1px #b3b3b3 solid;
  border-radius: 5px;
  margin: 0 5px 5px 0 !important;
}

#parachannel .modal_inner p.close[data-v-47758f5f] {
  width: auto !important;
}
.load-area {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px #b3b3b3 solid;
  border-radius: 6px;
  display: grid;
  justify-content: center;
  align-content: center;
}
.load-text {
  width: 100%;
  margin: auto;
  text-align: center;
}
.load-text h2 {
  padding-top: 10px;;
  font-size: 14px !important;
  color: black !important;
}
.load-spinner {
  padding-top: 10px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 40px;
  height: 40px;
  border: 4px #999 solid;
  border-top: 4px #ddd solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
}
@keyframes sp-anime {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
  