#parachannel #company .top > div .btn_box {
    .disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
    .flexRow{
      display: flex;
      justify-content: space-between;
      width: 100%;
      -webkit-box-shadow: 1px 2px 8px -1px rgba(74,67,74,1);
      -moz-box-shadow: 1px 2px 8px -1px rgba(74,67,74,1);
      box-shadow: 1px 2px 8px -1px rgba(74,67,74,1);
      border-radius: 5px;
    }
    .customTextDiv {
      color: #006496;
      font-size: 15px;
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 5px;
      margin: 0 5px;
    }
    .wid50 {
      width: 50%;
      clear: both;
      float: left;
      padding-bottom: 5px;
      border-bottom: 1px solid #dfdfdf;
      padding: 2.5px;
    }

    .flexRow1{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 5px;
      position: relative;
      z-index: 999;
    }
    .pb30 {
      padding-bottom: 20px;
    }

    .paddingnone{
      padding: 0 !important;
      border-bottom: none !important;
    }
    .pr-5 {
      padding-right: 10px;

    }
    .paramText {
      font-weight: 500;
    }
    .titleText{
      font-size: 18px;
      font-weight: 500;
      padding: 0;
    }
    .pb-5 {
      padding-bottom: 8px;
    }
    .backImage {
    }
    .wid70 {
      width: 60%;
      position: relative;
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 20px;
    }
    .wid70 textarea {
      font-family: "Noto Sans JP", sans-serif !important;
    }
    .wid30 {
      position: relative;
      padding: 20px 0px 0px 0px;
      width: 40%;
      border-bottom: none;
      background-position: center;
      background-repeat: no-repeat;
      background: linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8));
      background-size: cover;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    .textaria {
      height: 100px !important;
      padding: 15px;
      resize: none;
      font-size: 12px;
      font-weight: 500;
      border: none !important;
      background: #f1f1f1;
      border-radius: 0% !important;
    }
    .slide{
      position:relative;
      width: 40%;
      margin:0 auto;
    }
    .right-btn{
      color: #00a0e9 !important;
      position: absolute;
      right: 10px;
      top: 50%;
      font-size: 50px;
      font-weight: bold;
      color:black;
      cursor: pointer;
      user-select: none;
    }
    .left-btn{
      color: #00a0e9 !important;
      position: absolute;
      left: 10px;
      top: 50%;
      font-size: 25px;
      font-size: 50px;
      font-weight: bold;
      color: black;
      cursor: pointer;
      user-select: none;
    }
  
    .item_box {
      padding: 15px;
    }
  
    .item_box > a > img {
      max-width: 80% !important;
      max-height: 250px !important;
      object-fit: cover;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    .text_box {
      width: 80%;
      margin: 15px auto;
      h3, h4 {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }
      h3 {
        color: #00a0e9;
        margin-bottom: 10px;
      }
      line-height: 18px;
      
      .category, .type {
        padding: 0 10px;
        border-radius: 20px;
        display: inline-block;
        margin: 5px 5px 5px 0;
      }
      .category {
        border: 1px #00a0e9 solid;
        color: #00a0e9;
      }
      .type {
        border: 1px #ea5514 solid;
        color: #ea5514;
      }
      .new_graduate {
        border-radius: 5px;
        color: #fff;
        display: inline-block;
      }
      .new_graduate {
        padding: 1px 10px;
        background-color: #00a0e9;
        margin: 5px 5px 5px 0;
      }
      .address {
        margin-top: 5px;
      }
      strong {
        display: block;
        margin-top: 10px;
        font-weight: bold;
      }
      .done {
        padding: 6px 0;
        background-color: #e6e6e6;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        color: black;
        margin-top: 10px;
      }
    }
  
    .text_box > p > ul{
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
  
      li{
        width: auto;
        padding: 0 5px!important;
        border-top: 1px #b3b3b3 solid!important;
        border: 1px #b3b3b3 solid;
        border-radius: 5px;
        margin: 0 5px 5px 0!important;
      }
    }
  
    .item_box > div > .btn_box > a{
      background-color: #73dfbd;
      width: 225px;
      border-radius: 5px;
      font-size: 17px !important;
      color: #fff !important;
      text-align: center;
      padding: 13px 0;
      margin: 0 10px 10px 10px;
    }
    .modal_inner > h4{
      background-color: transparent;
      color: #6c6c6c ;
      margin-top: 10px;
    }
    .recruitment_inner{
      margin: 10px 30px 30px 30px !important;
    }
    .slider_main_content{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      /* margin: 0 auto; */
      justify-content: center;
      align-items: center;
      margin: 70px 0;
    }
    .none{
      background-color: #f2f2f2 !important;
      pointer-events: none !important;
    }
    .modal_inner > h4 {
      margin-top: 10px ;
    }
    .only-sp{
      display: none;
    }
    .item_box_maincontent{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 30px 0;
    }
    .item_box_maincontent > a{
      width: 50%;
      padding: 0 15px;
    }
    .item_box_maincontent > a > img{
      margin-left: 10px;
      padding: 10px;
    }
    .item_box_maincontent > .text_box{
      width: 50%;
      padding: 0 10px;
    }
    @media screen and (max-width: 769px) {

      .column_A {
        float: left;
        width: 50% !important;
        padding: 10px;
      }
  
      .column_B {
        float: left;
        width: 50% !important;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
      }
      .flexRow{
        display: block;
        width: 100%;
        -webkit-box-shadow: 1px 2px 8px -1px rgba(74,67,74,1);
        -moz-box-shadow: 1px 2px 8px -1px rgba(74,67,74,1);
        box-shadow: 1px 2px 8px -1px rgba(74,67,74,1);
        border-radius: 5px;
      }
      // .box {
      //   flex: 0 0 25%;
      //   text-align: center;
      // }
      .wid70 {
        width: 100%;
        position: relative;
        padding-top: 0;
      }
      .paramText {
        font-weight: 500;
        font-size: 12px;
      }
      .wid30 {
        margin-bottom: 0px;
        width: 100%;
        border-bottom: none;
        background-position: center;
        background-repeat: no-repeat;
        // background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url("../../../../public/assets/img/newpage1.png");
        background-size: cover;
      }
      .titleText{
        font-size: 12px;
        font-weight: 500;
        padding: 0;
      }
      .right-btn{
        font-size: 39px;
      }
      .left-btn{
        font-size: 39px;
      }
      .modal_inner > h4{
        padding: 0 35px !important;
        font-size: 13px !important;
        line-height: 39px !important;
        display: block !important;
        height: auto !important;
        margin-top: 10px !important;
      }
      .modal_inner{
        margin-bottom: 70%;
      }
      .item_box_maincontent {
        display: block !important;
        margin: 0 !important;
      }
      .item_box_maincontent > a{
        width: 100% !important;
      }
      .item_box_maincontent > a > img{
        margin: 10px 0 !important;
      }
      .text_box{
        width: 90% !important;
      }
    }

    .borderBottomNone {
      border-bottom: none !important;
    }

    .row {
      content: none;
      display: table;
      clear: both;
      width: 100%;
      margin-bottom: 0px !important;
    }

    .column_A {
      float: left;
      width: 70%;
      padding: 10px;
    }

    .column_B {
      float: left;
      width: 30%;
      padding: 10px;
      display: flex;
      justify-content: flex-end;
    }