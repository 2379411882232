#loader {
	content: '';
	display: block;
	position: fixed;
	z-index: 2000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: white;
	transition: opacity 1s linear;
	-webkit-transition: opacity 1s linear;
}

#loader.loaded {
	opacity: 0;
}

body.loaded::after {
	content: none;
}