@import "common";

/* reset */
.matomeBody:root {
  @include root_setting;
}

*,
.matomeBody::before,
.matomeBody::after {
  @include all_setting;
}

.matomeBody html {
  @include html_setting;

  @include mq_down_lg() {
    height: -webkit-fill-available;
  }
}

.matomeBody html:focus-within {
  scroll-behavior: auto;
  //scroll-behavior: smooth;
}

.matomeBody {
  @include body_setting;
  @include mq_down_lg() {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  //font-feature-settings: "palt";
}

@import "matomeReset";

/* style */
.matomeBody .pc_only {
  @include mq_down_lg() {
    display: none;
  }
  @include mq_up_lg() {
    //display: unset;
    display: inherit;
  }
}

.matomeBody .sp_only {
  @include mq_up_lg() {
    display: none;
  }
}

/****************************
parts
****************************/
.matomeBody .page_list-wrap {
  .page_list {
    display: grid;
    justify-content: flex-start;
    @include mq_down_lg() {
      gap: 5px;
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq_up_lg() {
      gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    }
  }

  .matomeBody .page_card {
    display: grid;
    height: 100%;
    position: relative;
    background-color: var(--color-white);
    border: 1px solid var(--color03);
    @include mq_down_lg() {
      padding: 5px 5px 15px;
    }
    @include mq_up_lg() {
      padding: 20px 10px 30px;
    }

    &:after {
      content: "";
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(0, -50%);
      left: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../public/assets/img/icon_arrow.svg");
      @include mq_down_lg() {
        bottom: 2px;
        background-size: 12px;
        width: 14px;
        height: 10px;
      }
      @include mq_up_lg() {
        bottom: 4px;
        background-size: 18px;
        width: 20px;
        height: 16px;
      }
    }

    .matomeBody &:hover {
      background-color: var(--color04);
    }

    .matomeBody .card_body {
      @include mq_down_lg() {
        padding: 5px 0 10px;
      }
      @include mq_up_lg() {
        padding: 20px 0 30px;
      }
    }

    .matomeBody .count {
      line-height: 1.55;
      text-align: right;
      font-family: 'Maven Pro', sans-serif;
      @include mq_down_lg() {
        font-size: rem(13);
      }
      @include mq_up_lg() {
        font-size: rem(15);
      }

      > span {
        @include mq_down_lg() {
          font-size: rem(12);
        }
        @include mq_up_lg() {
          font-size: rem(14);
        }
      }
    }

    .matomeBody .title {
      line-height: 1.55;
      text-align: center;
      @include mq_down_lg() {
        font-size: rem(16);
      }
      @include mq_up_lg() {
        font-size: rem(30);
      }
    }

    .matomeBody .sub_title {
      line-height: 1.55;
      text-align: center;
      font-weight: 300;
      @include mq_down_lg() {
        font-size: rem(12);
      }
      @include mq_up_lg() {
        font-size: rem(18);
      }
    }
  }
}

.matomeBody .list_toolbox {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  @include mq_down_lg() {
    max-width: 560px;
    margin: 0 auto;
  }

  .matomeBody .pagination_count-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .matomeBody .pagination_count {
      color: var(--color08);
      font-size: rem(14);
      font-weight: 700;
      text-align: left;
      line-height: 1.55;
    }
  }

  .matomeBody .order_link-wrap {
    @include mq_down_lg() {
      display: none;
    }
    @include mq_up_lg() {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 5px 15px;
    }

    .matomeBody .order_link {
      color: var(--color08);
      font-size: rem(14);
      font-weight: 700;
      text-align: left;
      line-height: 1.55;

      &:hover {
        text-decoration: underline;
      }

      &.is_active {
        color: var(--color04);
      }
    }
  }

  .matomeBody .order_link_select-wrap {
    position: relative;
    text-align: center;
    border: 1px solid var(--color03);
    border-radius: 25px;
    width: 60%;
    max-width: 240px;
    @include mq_down_lg() {
      display: block;
    }
    @include mq_up_lg() {
      display: none;
    }
    &:before {
      transform: translate(0, -50%) rotate(45deg);
      position: absolute;
      display: inline-block;
      border-right: 2px solid var(--color-black);
      border-bottom: 2px solid var(--color-black);
      content: "";
      right: 10px;
      top: calc(50% + 1px);
      width: 8px;
      height: 8px;
    }

    .matomeBody .order_link_select {
      width: 100%;
      line-height: 1;
      padding: 15px 30px 15px 20px;
      font-size: rem(16);
      font-weight: bold;
      border-radius: 25px;
      &:focus  {
        outline: revert
      }
    }
  }
}

.matomeBody .pagination-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px 16px;

  .matomeBody .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px 8px;
  }

  .matomeBody .pagination_number {
    width: max-content;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    font-weight: 500;
    @include mq_down_lg() {
      font-size: rem(16);
      width: 34px;
      height: 34px;
    }
    @include mq_up_lg() {
      font-size: rem(18);
      width: 40px;
      height: 40px;
    }

    .matomeBody &.is_active {
      color: var(--color-white);
      background-color: var(--color-black);
    }

    .matomeBody &.is_disable {
      pointer-events: none;
      color: var(--color09);
      background-color: unset;
    }
  }


  .matomeBody .pagination_link {

  }

  .matomeBody .pagination_prev,
  .matomeBody .pagination_next {
    width: max-content;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 500;

    &:before {
      position: absolute;
      display: inline-block;
      border-right: 2px solid var(--color-black);
      border-bottom: 2px solid var(--color-black);
      content: "";
      top: calc(50% + 1px);
      width: 8px;
      height: 8px;
    }

    &.is_disable {
      pointer-events: none;
      color: var(--color09);

      &:before {
        border-right: 2px solid var(--color09);
        border-bottom: 2px solid var(--color09);
      }
    }
  }

  .matomeBody .pagination_prev {
    padding-left: 12px;

    &:before {
      left: 2px;
      transform: translate(0, -50%) rotate(135deg);
    }
  }

  .matomeBody .pagination_next {
    padding-right: 12px;

    &:before {
      right: 2px;
      transform: translate(0, -50%) rotate(-45deg)
    }
  }

  .matomeBody .pagination_number:not(.is_active):not(.is_disable),
  .matomeBody .pagination_prev:not(.is_disable),
  .matomeBody .pagination_next:not(.is_disable) {
    &:hover,
    &:focus {
      text-decoration: underline;
      opacity: 0.9;
    }
  }
}

/****************************
contents_container
****************************/
.matomeBody .contents_container {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  @include mq_down_lg() {
    max-width: 680px;
  }
  @include mq_up_lg() {
    max-width: 1440px;
  }
}

/****************************
header
****************************/
.matomeBody #header {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-black);
  inset-block-start: 0;
  position: sticky;
  z-index: 100;

  .matomeBody .header_container {
    position: relative;
    max-width: 1600px;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    @include mq_up(pc_headar) {
      align-items: center;
    }
  }

  .matomeBody .header_logo-wrap {
    display: grid;
    @include mq_down(pc_headar) {
      padding: 8px 5px 8px 20px;
    }
    @include mq_up(pc_headar) {
      padding: 15px 0 15px 20px;
    }
  }

  .matomeBody .header_logo {
    display: grid;
    width: max-content;
    white-space: nowrap;
    font-feature-settings: "palt";

    .matomeBody .top {
      width: 100%;
      line-height: 1;
      margin-bottom: 10px;

      .matomeBody .text {
        display: inline-block;
        font-weight: 300;

        transform-origin: left bottom;
        transform: scaleY(0.8);
        width: 100%;
        letter-spacing: calc(0.01em * 1.2);
        @include mq_down(pc_headar) {
          $d: (17* 1.2);
          $a: rem(17);
          $b: vw_size($d, 750);
          $c: rem($d);
          font-size: clamp($a, $b, $c);
        }
        @include mq_up(pc_headar) {
          $d: (22* 1.2);
          $a: rem(22);
          $b: vw_size($d, 1600);
          $c: rem($d);
          font-size: clamp($a, $b, $c);
        }

      }
    }

    .matomeBody .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      line-height: 1;
      @include mq_down(pc_headar) {
        gap: 6px;
      }
      @include mq_up(pc_headar) {
        gap: 10px;
      }

      .matomeBody .text1 {
        display: inline-block;
        font-weight: 300;
        transform-origin: left bottom;
        letter-spacing: calc(0.01em * 1.2);
        transform: scaleY(0.8);
        width: 100%;
        @include mq_down(pc_headar) {
          $d: (17* 1.2);
          $a: rem(17);
          $b: vw_size($d, 750);
          $c: rem($d);
          font-size: clamp($a, $b, $c);

          $m1: ((17/750) * 100);
          $m2: ((17 * 0.2));
          margin-top: max(calc((#{$m1}vw * 0.2) * -1), calc(#{$m2} * -1px));
        }
        @include mq_up(pc_headar) {
          $d: (22* 1.2);
          $a: rem(22);
          $b: vw_size($d, 1600);
          $c: rem($d);
          font-size: clamp($a, $b, $c);

          $m1: ((23/1600) * 100);
          $m2: ((23 * 0.2));
          margin-top: max(calc((#{$m1}vw * 0.2) * -1), calc(#{$m2} * -1px));
        }
      }

      .matomeBody .text2 {
        display: inline-block;
        font-weight: 500;
        @include mq_down(pc_headar) {
          font-size: rem(10);
        }
        @include mq_up(pc_headar) {
          font-size: rem(12);
        }
      }
    }
  }

  .matomeBody .sp_menu_button-wrap {
    @include mq_up(pc_headar) {
      display: none;
    }

    margin-left: auto;
    border-left: 1px solid var(--color-black);

    .matomeBody .sp_menu_button {
      cursor: pointer;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 3px;
      height: 100%;

      > span {
        background-color: var(--color-black);
        display: inline-block;
        width: 25%;
        height: 4px;
        font-size: 0;
      }

      &:hover,
      &:focus ,
      &.is_open {
        background-color: var(--color05);
      }

      &:focus  {
        outline: revert
      }

      &.is_open {
        > span:nth-child(1) {
          transform: rotate(45deg) translate(2px, 3px);
          transform-origin: center;
        }
        > span:nth-child(2) {
          display: none;
        }
        > span:nth-child(3) {
          transform: rotate(-45deg) translate(2px, -3px);
          transform-origin: center;
        }
      }
    }
  }

  .matomeBody .pc_menu-wrap {
    margin-left: auto;
    padding-left: 20px;
    @include mq_down(pc_headar) {
      display: none;
    }

    @include mq_up(pc_headar) {
      padding: 15px 20px 15px 0;
    }

    .matomeBody .pc_menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 0 14px;

      > li {
        cursor: pointer;
        position: relative;
      }
    }

    .matomeBody .pc_menu_link {
      position: relative;
      display: inline-block;
      text-decoration: none;
      line-height: 1;
      font-weight: 500;
      margin: 0 !important;

      @include mq_up(pc_headar) {
        font-size: rem(19);
      }

      .matomeBody span {
        padding: 6px 10px;
        display: inline-block;
      }
    }

    .matomeBody .has_sub_menu {
      padding-bottom: 14px;
    }

    .matomeBody .has_sub_menu:after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../public/assets/img/icon_arrow.svg");
      width: 16px;
      height: 8px;
    }

    .matomeBody .pc_menu {
      > li {
        .pc_menu_link.is_active,
        &:hover .pc_menu_link,
        &:focus .pc_menu_link,
        .pc_menu_link:focus {
          span {
            background-color: var(--color05);
          }
        }
      }
    }
  }

  .matomeBody .pc_sub_menu-wrap {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 100%;
    padding-top: 10px;
    z-index: 110;

    &.last_pc_sub_menu {
      right: 0;
      left: unset;
      transform: unset;
    }

    &.is_active {
      display: block;
    }

    .matomeBody .pc_sub_menu {
      position: relative;
      width: max-content;
      white-space: nowrap;
      border: 1px solid var(--color-black);
      background-color: var(--color-white);

      > li {
        &:not(:last-child) {
          border-bottom: 1px solid var(--color-black);
        }
      }
    }

    .matomeBody .pc_sub_menu_link {
      position: relative;
      display: inline-block;
      text-decoration: none;
      line-height: 1.55;
      padding: 10px 15px;
      width: 100%;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: var(--color04);
      }
    }
  }

  .matomeBody .has_sub_menu.is_action_click {
    .matomeBody .pc_sub_menu-wrap {
      display: block;
    }
  }
}

.matomeBody .sp_menu-wrap {
  display: none;
  overflow: hidden;
  position: sticky;
  z-index: 90;
  //padding-top: 10px;
  inset-block-start: 58px;
  height:100vh;
  //height: calc(100vh - 58px);
  background-color: #ffffff;

  &.is_open {
    display: block;
  }

  @include mq_up(pc_headar) {
    display: none;
  }

  .matomeBody .sp_menu_logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    >.sp_menu_logo {
      max-width: 160px;
    }
  }

  .matomeBody .sp_menu-inner {
    background-color: var(--color02);
    overflow: hidden;
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
    line-height: 1.55;
    padding-bottom: 60px;
  }

  .matomeBody .sp_menu {
    > .matomeBody li {
      background-color: #fbf2f2;
      border-bottom: 1px solid var(--color11);

      //&:first-child {
      //  border-top: 1px solid var(--color03);
      //}
    }

    .matomeBody .sp_menu_link {
      //padding-left: 5px;
      padding: 15px 20px;
      width: 100%;
      display: block;
    }

    .matomeBody .has_sub_menu.sp_menu_link_arrow {
      &.is_open:after {
        transform: translate(0px, -50%) rotate(360deg);
      }

      &.is_open + .sp_sub_menu-wrap {
        display: block;
      }
    }
  }

  .matomeBody .sp_sub_menu-wrap {
    display: none;
    padding-left: 20px;
    background-color: #ffffff;
    border-top: 1px solid var(--color11);
  }

  .matomeBody .sp_menu_link_arrow{
    position: relative;
    &:hover,
    &:focus ,
    &.is_open {
      background-color: var(--color12);
    }

    &:after {
      content: "";
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(0px, -50%) rotate(-90deg);
      top: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../public/assets/img/icon_arrow.svg");
      right: 2px;
      background-size: 14px;
      width: 16px;
      height: 16px;
    }
  }

  .matomeBody .sp_sub_menu {
    border-left: 1px solid var(--color11);

    > li {
      &:not(:first-child) {
        border-top: 1px solid var(--color11);
      }
    }

    .matomeBody .sp_sub_menu_link {
      padding: 15px 20px 15px 40px;
      width: 100%;
      display: block;
      position: relative;
      color: var(--color04);
      text-decoration: underline;
      &:hover ,
      &:focus {
        text-decoration: none;
      }

      &:before {
        content: "";
        display: inline-block;
        color: var(--color-black);
        background-color:  var(--color-black);
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
  }
}

/****************************
footer
****************************/
.matomeBody .sub_footer {

  .matomeBody .sub_footer_mv-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    @include mq_up_lg() {
      max-width: 1440px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .matomeBody .sub_footer_mv {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .matomeBody .title-wrap {
    line-height: 1;
    @include mq_down_lg() {
      padding-top: 50px;
      margin-bottom: 30px;
    }
    @include mq_up_lg() {
      padding-top: 80px;
      margin-bottom: 50px;
    }

    .matomeBody .title {
      @include mq_down_lg() {
        $a: rem(22);
        $b: vw_size(60, 750);
        $c: rem(30);
        font-size: clamp($a, $b, $c);
      }
      @include mq_up_lg() {
        font-size: rem(60);
      }

      .matomeBody .title_bg {
        //background: linear-gradient(transparent 0%, var(--color02) 0%);
        //display: inline-block;
        background: linear-gradient(transparent 10%, var(--color02) 0%, var(--color02) 90%, transparent 0%);
        display: inline;
        line-height: 1.2em;
        vertical-align: top;
      }

      > span {
        line-height: 1.2em;
        display: inline-block;
        vertical-align: top;

        &:not(:last-child) {
          @include mq_down_lg() {
            margin-bottom: 10px;
          }
          @include mq_up_lg() {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .matomeBody .message_list-wrap {
    @include mq_down_lg() {
      margin-bottom: 50px;
    }
    @include mq_up_lg() {
      margin-bottom: 60px;
    }

    .matomeBody .message_list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include mq_down_lg() {
        gap: 5px 0;
      }
      @include mq_up_lg() {
        gap: 5px 0;
      }

      .matomeBody li {
        width: 100%;
        @include mq_up_lg() {
          font-size: rem(30);
        }
      }

      .matomeBody .emoji {
        padding-right: 5px;
        font-family: 'Noto Color Emoji', sans-serif;
      }
    }
  }

  .matomeBody .voice_group {
    @include mq_down_lg() {
      margin-bottom: 40px;
    }
    @include mq_up_lg() {
      margin-bottom: 50px;
    }

    .matomeBody .voice_title-wrap {
      @include mq_down_lg() {
        margin-bottom: 20px;
      }
      @include mq_up_lg() {
        margin-bottom: 30px;
      }

      .matomeBody .voice_title {
        font-size: rem(20);
      }

      .matomeBody .emoji {
        padding-right: 5px;
        font-family: 'Noto Color Emoji', sans-serif;
      }
    }

    .matomeBody .voice_list-wrap {
      display: grid;
      justify-content: center;

      @include mq_down_lg() {
        gap: 5px;
        grid-template-columns: 1fr;
      }
      @include mq_up_lg() {
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .matomeBody .voice_list {
      border: 1px solid var(--color03);
      background-color: var(--color-white);
      @include mq_down_lg() {
        border-radius: 10px;
        padding: 20px 15px 15px;
      }
      @include mq_up_lg() {
        border-radius: 20px;
        padding: 30px 25px 25px;
      }

      .matomeBody .top {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include mq_down_lg() {
          margin-bottom: 15px;
        }
        @include mq_up_lg() {
          margin-bottom: 25px;
        }
      }

      .matomeBody .bottom {
        display: block;
      }

      .matomeBody .img {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include mq_down_lg() {
          flex-basis: 44px;
        }
        @include mq_up_lg() {
          flex-basis: 54px;
        }
      }

      .matomeBody .profile {
        flex: 1;
        display: flex;
        flex-direction: column;
        @include mq_down_lg() {
          padding-left: 10px;
        }
        @include mq_up_lg() {
          padding-left: 20px;
        }

        .matomeBody .name {
          font-weight: 500;
          line-height: 1.5;
          margin-bottom: 5px;
          @include mq_down_lg() {
            font-size: rem(13);
          }
          @include mq_up_lg() {
            font-size: rem(18);
          }
        }

        .matomeBody .text {
          line-height: 1.5;
          @include mq_down_lg() {
            font-size: rem(12);
          }
          @include mq_up_lg() {
            font-size: rem(14);
          }
        }
      }

      .matomeBody .profile_text {
        @include mq_down_lg() {
          font-size: rem(12);
        }
      }
    }
  }
}

.matomeBody .footer {
  position: relative;
  @include mq_down_lg() {
    padding-bottom: 15px;
  }
  @include mq_up_lg() {
    padding-bottom: 30px;
  }

  .matomeBody .contents_container {
    position: relative;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color02);

    @include mq_down_lg() {
      top: 15px;
    }
    @include mq_up_lg() {
      top: 30px;
    }
  }

  .matomeBody .footer_logo-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include mq_down_lg() {
      margin-bottom: 10px;
    }
    @include mq_up_lg() {
      margin-bottom: 15px;
    }

    .matomeBody .footer_logo_link {
      &:hover {
        opacity: 0.8;
      }
    }

    .matomeBody .footer_logo {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      @include mq_down_lg() {
        max-width: 220px;
      }
    }
  }

  .matomeBody .footer_register-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @include mq_down_lg() {
      margin-bottom: 60px;
    }
    @include mq_up_lg() {
      margin-bottom: 45px;
    }

    .matomeBody .footer_register_btn {
      &:hover {
        opacity: 0.8;
      }
    }

    .matomeBody .footer_register {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .matomeBody .footer_message-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include mq_up_lg() {
      padding-left: 15em;
    }

    .matomeBody .footer_message {
      @include mq_down_lg() {
        font-size: rem(14);
      }
      @include mq_up_lg() {
        display: block;
        font-size: rem(18);
      }
    }

    .matomeBody .message_block {

      @include mq_up_lg() {
        display: block;
        &:nth-of-type(2) {
          padding-left: 3em;
        }
        &:nth-of-type(3) {
          padding-left: 6em;
        }
        &:nth-of-type(4) {
          padding-left: 9em;
        }
        &:nth-of-type(5) {
          padding-left: 12em;
        }
      }
    }

    .matomeBody .footer_copy {
      display: block;
      text-align: center;
      @include mq_down_lg() {
        margin-top: 40px;
      }
      @include mq_up_lg() {
        margin-top: 20px;
        padding-left: 15em;
        text-align: left;
      }
    }
  }
}

/****************************
top
****************************/
.matomeBody .section_mv {
  @include mq_down_lg() {
    padding-top: 50px;
    margin-bottom: 40px;
  }
  @include mq_up_lg() {
    padding-top: 90px;
    margin-bottom: 80px;
  }

  .matomeBody .mv_title-wrap {
    @include mq_down_lg() {
      padding-left: 10px;
      padding-right: 10px;
    }

    .matomeBody .mv_title {
      font-feature-settings: "palt";
      line-height: 1.3;
      font-weight: 100;

      transform-origin: left bottom;
      transform: scaleY(0.8);
      width: 100%;
      @include mq_down_lg() {
        $d: (42* 1.2);
        $a: rem(40);
        $b: vw_size($d, 350);
        $c: rem($d);
        font-size: clamp($a, $b, $c);
        letter-spacing: calc(0.01em * 1.2);

        $m1: ((450/750) * 100);
        $m2: ((450 * 0.2));
        margin-top: max(calc((#{$m1}vw * 0.2) * -1), calc(#{$m2} * -1px));
      }
      @include mq_up_lg() {
        $d: (164* 1.2);
        $a: rem(46);
        $b: vw_size($d, 1600);
        $c: rem($d);
        font-size: clamp($a, $b, $c);
        letter-spacing: calc(0.01em * 1.2);

        $m1: ((770/1600) * 100);
        $m2: ((770 * 0.2));
        margin-top: max(calc((#{$m1}vw * 0.2) * -1), calc(#{$m2} * -1px));
      }
    }
  }
}

.matomeBody .section01 {
  @include mq_down_lg() {
    margin-bottom: 30px;
  }
  @include mq_up_lg() {
    margin-bottom: 50px;
  }

  .matomeBody .title-wrap {
    display: grid;
    @include mq_down_lg() {
      gap: 15px;
    }
    @include mq_up_lg() {
      gap: 25px;
    }

    line-height: 1;
    @include mq_down_lg() {
      margin-bottom: 30px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @include mq_up_lg() {
      margin-bottom: 60px;
    }

    .matomeBody .title {
      font-feature-settings: "palt";
      font-weight: 200;
      @include mq_down_lg() {
        $a: rem(22);
        $b: vw_size(60, 750);
        $c: rem(30);
        font-size: clamp($a, $b, $c);
      }
      @include mq_up_lg() {
        font-size: rem(60);
      }

      .matomeBody .title_bg {
        //background: linear-gradient(transparent 0%, var(--color02) 0%);
        background: linear-gradient(transparent 10%, var(--color02) 0%, var(--color02) 90%, transparent 0%);
        line-height: 1.2em;
        vertical-align: top;
        display: inline;
        @include mq_down_lg() {
          padding-left: 6px;
        }
        @include mq_up_lg() {
          padding-left: 10px;
        }
      }

      .matomeBody span {
        line-height: 1.2em;
        display: inline-block;
        vertical-align: top;

        &:not(:last-child) {
          @include mq_down_lg() {
            margin-bottom: 15px;
          }
          @include mq_up_lg() {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  .text1 {
    line-height: 2.23;
    @include mq_down_lg() {
      font-size: rem(16);
      margin-bottom: 40px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @include mq_up_lg() {
      font-size: rem(30);
      margin-bottom: 60px;
      padding-right: 150px;
    }
  }

  .matomeBody .image1-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    @include mq_up_lg() {
      max-width: 1440px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .matomeBody .image1 {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.matomeBody .section02 {
  @include mq_down_lg() {
    margin-bottom: 40px;
  }
  @include mq_up_lg() {
    margin-bottom: 40px;
  }

  .matomeBody .text1 {
    @include mq_down_lg() {
      font-size: rem(12);
      margin-bottom: 30px;
      padding: 0 10px;
    }
    @include mq_up_lg() {
      font-size: rem(20);
      margin-bottom: 40px;
      padding-right: 150px;
    }
  }

  .matomeBody .text2 {
    @include mq_down_lg() {
      font-size: rem(12);
      margin-top: 30px;
      padding: 0 10px;
    }
    @include mq_up_lg() {
      font-size: rem(20);
      margin-top: 60px;
    }
  }
}

/****************************
detail
****************************/
.matomeBody .section_detail_mv {
  position: relative;
  @include mq_down_lg() {
    padding-top: 30px;
    padding-bottom: 40px;
  }
  @include mq_up_lg() {
    padding-top: 70px;
    padding-bottom: 90px;
  }

  .matomeBody .contents_container {
    max-width: 1200px;
  }

  .matomeBody .mv_title-wrap {
    .matomeBody .mv_title {
      font-feature-settings: "palt";
      line-height: 1.4;
      font-weight: 100;

      transform-origin: left bottom;
      transform: scaleY(0.8);
      width: 100%;
      @include mq_down_lg() {
        $d: (38* 1.2);
        $a: rem(28);
        $b: vw_size($d, 350);
        $c: rem($d);
        font-size: clamp($a, $b, $c);
        letter-spacing: calc(0.01em * 1.2);

        $m1: ((160/750) * 100);
        $m2: ((160 * 0.2));
        margin-top: max(calc((#{$m1}vw * 0.2) * -1), calc(#{$m2} * -1px));
      }
      @include mq_up_lg() {
        $d: (90 * 1.2);
        $a: rem(46);
        $b: vw_size($d, 1600);
        $c: rem($d);
        font-size: clamp($a, $b, $c);
        letter-spacing: calc(0.01em * 1.2);

        $m1: ((380/1600) * 100);
        $m2: ((380 * 0.2));
        margin-top: max(calc((#{$m1}vw * 0.2) * -1), calc(#{$m2} * -1px));
      }
    }
  }

  .matomeBody .lead_text-wrap {
    display: grid;
    @include mq_down_lg() {
      gap: 15px;
      margin-top: 30px;
    }
    @include mq_up_lg() {
      gap: 25px;
      margin-top: 60px;
    }

    line-height: 1;
    @include mq_down_lg() {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include mq_up_lg() {
      margin-bottom: 10px;
    }

    .matomeBody .lead_text {
      font-feature-settings: "palt";

      @include mq_down_lg() {
        $a: rem(18);
        $b: vw_size(26, 750);
        $c: rem(26);
        font-size: clamp($a, $b, $c);
      }
      @include mq_up_lg() {
        $a: rem(18);
        $b: vw_size(26, 750);
        $c: rem(26);
        font-size: clamp($a, $b, $c);
      }

      .matomeBody .title_bg {
        line-height: 2;
        //background: linear-gradient(transparent 0%, var(--color02) 0%);
        background: linear-gradient(transparent 0%, var(--color02) 0%, var(--color02) 100%, transparent 0%);
        vertical-align: top;
        display: inline;
        @include mq_down_lg() {
          padding-left: 6px;
          padding-right: 3px;
          padding-top: 3px;
          padding-bottom: 3px;
        }
        @include mq_up_lg() {
          padding-left: 10px;
          padding-right: 5px;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        //~ .title_bg {
        //  @include mq_down_lg() {
        //    padding-left: 0;
        //    margin-left: -3px;
        //  }
        //}
      }
    }
  }

  .matomeBody .note_text-wrap {
    margin-top: 10px;

    .matomeBody .note_text {
      color: var(--color10);
      @include mq_down_lg() {
        font-size: rem(12);
      }
      @include mq_up_lg() {
        font-size: rem(14);
      }

      + .matomeBody .note_text {
        margin-top: 10px;
      }
    }
  }

  .matomeBody .mv_logo {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq_down_lg() {
      margin-top: 10px;
    }
    @include mq_up_lg() {
      position: absolute;
      bottom: 30px;
      right: max(30px, calc((100% - 1600px) / 2));
    }
  }

  .matomeBody .update_balloon-wrap {
    display: inline-block;
    transform: scaleY(1.2);
    vertical-align: middle;
    margin-left: 10px;

    .matomeBody .update_balloon {
      display: flex;
      flex-direction: column;
      gap: 2px;
      line-height: 1;
      padding: 8px 16px;
      border-radius: 20px;
      border: 1px solid  #8f8f8f;
      color: #8f8f8f;
      @include mq_down_lg() {
        font-size: 14px;
      }
      @include mq_up_lg() {
        font-size: 16px;
      }
    }
  }
}

.matomeBody .section03 {
  background-color: var(--color-white);
  @include mq_down_lg() {
    padding: 25px 0 45px;
  }
  @include mq_up_lg() {
    padding: 45px 0 65px;
  }

  .matomeBody .contents_container {
    max-width: 1200px;
  }

  .matomeBody .job_list-wrap {
    display: flex;
    justify-content: center;

    .matomeBody .job_list {
      display: grid;
      justify-content: flex-start;
      @include mq_down_lg() {
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(320px, 560px));
        //grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      }
      @include mq_up_lg() {
        gap: 40px;
        grid-template-columns: repeat(2, 1fr);
        //grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
      }

    }
  }

  .matomeBody .job_card {
    border: 1px solid var(--color03);
    background-color: var(--color-white);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;

    &:hover,
    &:focus {
      //background-color: var(--color04);
      opacity: 0.9;
    }

    .matomeBody .card_top {
      border-bottom: 1px solid var(--color03);
      background-color: var(--color01);
      width: 100%;
      display: grid;
    }

    .matomeBody .card_bottom {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      height: 100%;
      @include mq_down_lg() {
        padding: 20px 10px 10px;
      }
      @include mq_up_lg() {
        padding: 30px 15px 20px;
      }
    }


    .matomeBody .job_status-wrap_layer {
      grid-area: 1/1;
      z-index: 20;
    }

    .matomeBody .job_status-wrap {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-flow: column;
      gap: 4px;

      @include mq_down_lg() {
        padding-top: 4px;
        padding-right: 4px;
      }
      @include mq_up_lg() {
        padding-top: 8px;
        padding-right: 8px;
      }

      .matomeBody .job_status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
        font-size: rem(12);
        color: var(--color06);
        border: 1px solid var(--color03);
        background-color: var(--color-white);
        text-align: center;
        padding: 2px 6px;
        line-height: 1.55;
      }
    }

    .matomeBody .job_thumbnail-wrap {
      grid-area: 1/1;
      z-index: 10;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .matomeBody .job_thumbnail {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        aspect-ratio: 4/3;

        @include mq_down_lg() {
          max-width: 63%;
        }
        @include mq_up_lg() {
          max-width: 320px;
        }

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .matomeBody .job_tag-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
      @include mq_down_lg() {
        margin-bottom: 8px;
      }
      @include mq_up_lg() {
        margin-bottom: 15px;
      }

      .matomeBody .job_tag {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
        font-size: rem(12);
        color: var(--color-white);
        background-color: var(--color07);
        text-align: center;
        padding: 2px 20px;
        border-radius: 10px;
        line-height: 1.55;
      }
    }

    .matomeBody .job_detail_item-wrap {
      line-height: 1.75;
      @include mq_down_lg() {
        font-size: rem(12);
        margin-bottom: 6px;
      }
      @include mq_up_lg() {
        margin-bottom: 10px;
      }

      > li {
        &:before {
          content: "●";
        }

      }
    }

    .matomeBody .job_title {
      line-height: 1.55;
      margin-bottom: 10px;
      @include mq_down_lg() {
        font-size: rem(22);
      }
      @include mq_up_lg() {
        font-size: rem(24);
      }
    }

    .matomeBody .job_company_name {
      line-height: 1.55;
      margin-top: auto;
      @include mq_down_lg() {
        font-size: rem(16);
      }
      @include mq_up_lg() {
        font-size: rem(18);
      }
    }
  }

  .matomeBody .list_toolbox {
    @include mq_down_lg() {
      margin-bottom: 20px;
    }
    @include mq_up_lg() {
      margin-bottom: 30px;
    }
  }

  .matomeBody .pagination-wrap {
    @include mq_down_lg() {
      margin-top: 30px;
    }
    @include mq_up_lg() {
      margin-top: 50px;
    }
  }
}

/****************************
is_page
****************************/

.matomeBody .is_page_detail .sub_footer_mv-wrap {
  display: none;
}
