.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.c_label{
  margin-right: 10px;
}

#parachannel #company .outline {
  .error {
    color: #8a0421;
    border-color: #dd0f3b;
    background-color: #ffd9d9;
  }
  button.disabled {
      cursor: not-allowed;
      opacity: 0.3;
  }
  & > div.cont6 dl dd.city {
    display: flex;
    input[type="checkbox"] {
      width: unset;
      margin-left: 10px;
    }
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      input[name="prefecture"] {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 769px) {
      input[name="prefecture"] {
        width: 100px;
        margin-right: 10px;
      }
      input[name="city"] {
        max-width: 290px;
      }
    }
  }
}