
#parachannel #top {
    .no-contents {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      h3 {
        font-family: dnp-shuei-mgothic-std,sans-serif;
        margin: 10px 0 5px;
        font-size: 20px;
        font-weight: bold;
      }
    }
    // .ribbon {
    //   position: absolute;
    //   right: 0; top: 0px;
    //   z-index: 1;
    //   overflow: hidden;
    //   width: 100px; 
    //   height: 100px;
    //   text-align: right;
    // }
    // .ribbon span {
    //   text-transform: uppercase;
    //   text-align: center;
    //   line-height: 25px;
    //   transform: rotate(45deg);
    //   -webkit-transform: rotate(45deg);
    //   width: 115px;
    //   display: block;
    //   color: #ffffff;
    //   background: #00699F;
    //   // box-shadow: 0 0 10px 3px #00699F;
    //   position: absolute;
    //   top: 20px; right: -25px;
    // }

    .ribbon19-wrapper {
      display: block;
      position: relative;
      margin: 15px auto;
      padding: 10px 0;
      box-sizing: border-box;
    }
    
    .ribbon19-content {
      margin: 0 !important;
      position: absolute;
      top: 0;
      right: 0;
      width: 85px;
      height: 85px;
      overflow: hidden;
      }
    
    .ribbon19 {
      display: inline-block;
      position: absolute;
      z-index: 9999;
      padding: 5px 0;
      left: -24px;
      top: 20px;
      width: 160px;
      text-align: center;
      font-size: 14px;
      line-height: 16px;
      background: #18A1E8;
      color: #fff;
      letter-spacing: 0.05em;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      box-shadow: 0 0 0 2px rgb(24, 161, 232);
      border-top: dashed 1px rgba(255, 255, 255, 0.65);
      border-bottom: dashed 1px rgba(255, 255, 255, 0.65);
    }
    .recruitmentBtn {
      height: 50px; width:100%; color: #00699F; background: #f3e122; font-weight: bold; font-size: 20px;
    }
    .relat {
      position: relative;
    }
    .rowLine {
      display: flex;
      justify-content: space-between;
    }
    .borderBottom {
      font-size: 20px;
      color: #00699F;
      font-weight: 500;
    }
    .d-flex {
      display: flex;
    }
    .d-flex button {
      width: auto !important;
      background: #ffffff !important;
      // border-top: 1px solid black;
      border-bottom: 1px solid rgb(156, 149, 149);
      // border-right: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      padding: 5px;
      white-space: nowrap;
      font-size: 18px;
      margin-right: 5px;
    }
    .smallheart img{
      width: 20px;
      padding: 2px;
    }
    .smallheart p{
      color: #3387b2 !important;
      margin: 0 !important;
      font-size: 14px;
    }
    .smallheart {
      display: flex;
    }
    .paddTop {
      padding-top: 5px;
    }
    .borderedBox {
      border : 1px solid black;
      min-height: 200px;
      display: flex;
      align-items: center;
      width: 100% !important;
    }
    .borderBottom:hover {
      background:#00699F !important;
      color: white;
      border : 1px solid #00699F;
    }

    .d-flex button:hover {
      background:#00699F !important;
      color: white;
    }
    .top__region_a_tag {
      color: black !important;
    }
    .cont_box form button .disabled {
      background-color: gray !important;
    }
    .disabledGrey {
      background-color: gray !important;
    }

    .swiper-button-prev {
      text-rendering: auto;
      left: 0px;
      color: #b6acac !important;
    }

    .swiper-button-next {
      text-rendering: auto;
      right: 0px;
      color: #b6acac !important;
    }

    .dflex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
    }
    .marginAuto {
      margin: auto !important;
      padding: 0 !important;
    }
    .dflex button {
      min-width: 100px !important;
      width: 100% !important;
      padding: 10px !important;
      background: #f9f058 !important;
      color: #00699F !important;
      font-size: 12px !important;
      font-weight: 600 !important;
    }
    .searchButtun {
      width: 100%;
      height: auto;
      display: block;
      margin: auto;
      padding: 10px;
      background: #00699F;
      color: #ffffff;
      font-weight: 600;
    }
    .buttonCont {
      position: relative;
      display: block;
      margin: auto;
      width: 80%;
      margin-bottom: 20px;
    }
    @media  (max-width: 768px) {
      .mv {
          margin-bottom: 15px !important;
      }
    }
  }

  @media (max-width: 768px) {
    
    .ribbon19-content {
      width: 65px !important;
      height: 65px !important;
      }
    
    .ribbon19 {
      display: inline-block;
      position: absolute;
      z-index: 9999;
      padding: 5px 0;
      left: -35px !important;
      top: 13px !important;
      width: 160px;
      text-align: center;
      font-size: 10px !important;
      line-height: 12px !important;
      background: #18A1E8;
      color: #fff;
      letter-spacing: 0.05em;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      box-shadow: 0 0 0 2px rgb(24, 161, 232);
      border-top: dashed 1px rgba(255, 255, 255, 0.65);
      border-bottom: dashed 1px rgba(255, 255, 255, 0.65);
    }

    .borderBottom {
      font-size: 16px !important;
    }

    .d-flex button {
      font-size: 14px !important;
    }
    .columnHead {
      margin-left: 18px;
      float: left;
      width: auto !important;
      padding: 20px;
      height: auto !important;
  }
  .recruitmentBtn {
    font-size: 14px !important;
  }
  }