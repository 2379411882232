@import "/src/styles/module/mixins";
@import "/src/styles/common/color";
.displayNone {
  display: none;
}
#parachannel .resume_tab_list {
  width: 100%;
  padding-top: 2px;
  margin-bottom: 55px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @include mq(sp) {
    margin-bottom: 30px;
    overflow: scroll;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    border-bottom: 8px $blue solid;
    display: flex;
    margin: 0 auto;

    @include mq(pc) {
      justify-content: center;
    }
    
    @include mq(sp) {
      width: 750px;
      border-bottom-width: 4px;
    }
    li {
      //width: 16.25%;
      box-shadow: 0 -2px 10px 2px rgba(0, 0, 0, 0.1);
      border-radius: 5px 5px 0 0;
      text-align: center;
      margin-right: 0.5%;
      
      @include mq(sp) {
        width: 16.25%;
      }

      &:last-child {
        margin: 0;
      }
      a {
        padding: 11px 20px;
        font-weight: bold;
        color: $lightgray;

        @include mq(pc) {
          font-size: 16px;
        }
        @include mq(sp) {
          padding: 9px 0;
        }
      }
      &.small a {
        //padding: 13px 25px;
        //font-size: 14px;
        @include mq(sp) {
          padding: 10px 0;
          font-size: 10px;
        }
      }
      &.active {
        background-color: $blue;
        a {
          color: #fff;
        }
      }
    }
  }
}
//  mypage - profile
#parachannel {
#mypage_inner {
  width: calc(100% - 330px);
  //padding: 140px 50px;
  padding: 40px 30px 140px;

  &.mypageSchedules {
    padding: 40px 30px 140px;
  }

  @include mq(pc) {
    max-width: 1000px;
    margin: 0 auto;
  }

  @include mq(sp) {
    width: 100%;
    padding: 30px 3% 50px;
  }

  .ttl_box {
    display: flex;
    align-items: flex-end;
    align-content: flex-end;
    justify-content: space-between;
    margin: 50px 0 40px;

    @include mq(sp) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin: 30px 0;
      .ttl {
        width: 100%;
        margin-top: 20px;
      }
    }

    h2 {
      position: relative;
      padding: 5px 0 5px 10px;
      border-left: solid 10px #006496;
    }
    ul {
      height: 30px;
      display: flex;
      align-items: flex-end;
      align-content: flex-end;
      @include mq(sp) {
        width: 100%;
        height: 15px;
      }
      li {
        margin-right: 25px;
        @include mq(sp) {
          margin-right: 15px;
        }
        a {
          font-weight: bold;
          display: flex;
          align-items: flex-end;
          align-content: flex-end;
          @include mq(sp) {
            font-size: 10px;
          }
          img {
            width: 15px;
            margin-right: 5px;
            @include mq(sp) {
              width: 10px;
            }
            &.guide {
              width: 30px;
              @include mq(sp) {
                width: 15px;
              }
            }
          }
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  > div .btn_box {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 50px;
    @include mq(sp) {
      display: block;
      margin-bottom: 30px;
    }
    a {
      width: 300px;
      height: 50px;
      box-shadow: 2px 0 2px rgba(0,0,0,0.3);
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      align-content: center;
      margin: 0 15px;
      overflow: hidden;
      @include mq(sp) {
        margin: 0 auto 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      span {
        width: calc(100% - 40px);
      }
      img {
        width: auto;
        max-width: none;
        height: 50px;
        padding: 9px 10px;
        background-color: #fff;
      }
      &.blue {
        background-color: $blue;
      }
      &.navyblue {
        background-color: $navyblue;
      }
      &.orange {
        background-color: #ff7d00;
      }
    }
  }

}
}

#parachannel #mypage {

    .profile {
      width: 100%;
      max-width: 750px;
      min-width: auto;
  
      @include mq(sp) {
        max-width: none;
      }
  
      >div {
        margin-bottom: 50px;
  
        @include mq(sp) {
          margin-bottom: 30px;
        }
  
        &:last-child {
          margin: 0;
        }
      }
  
      .cont1 {
        position: relative;
  
        .ex_info {
          @include mq(pc) {
            position: absolute;
            right: 0;
            top: 25px;
          }
  
          @include mq(sp) {
            text-align: right;
            margin-bottom: 10px;
          }
          font-size: 90%;
  
          span {
            margin: 0 3px;
            font-weight: bold;
          }
        }
      }
  
      .ttl {
        color: $blue;
        margin-bottom: 30px;
  
        @include mq(pc) {
          font-size: 22px;
        }
  
        @include mq(sp) {
          margin-bottom: 20px;
        }
      }
  
      .wrapper {
        width: 100%;
        max-width: 750px;
        min-width: auto;
  
        @include mq(sp) {
          max-width: none;
        }
      }
  
      .link_box {
        margin-bottom: 25px !important;
        overflow: hidden !important;
  
        @include mq(sp) {
          margin-bottom: 15px !important;
        }
      }
  
      table {
        width: 100%;
        font-weight: bold;
        display: block;
  
        tbody {
          padding: 0 5px;
          border: 1px $lightgray solid;
          border-radius: 5px;
          display: block;
  
          @include mq(sp) {
            padding: 10px;
          }
  
          &.care td > div {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .edit_box {
              padding: 5px 80px 5px 0;
              margin: 0;
  
              // .icon_cont {
              //   top: 0;
              //   right: 0;
              // }
            }
          }
        }
      }
  
      tr {
        padding: 5px 0;
        border-bottom: 1px $lightgray solid;
        display: flex;
  
        @include mq(sp) {
          display: block;
          padding: 0 0 10px;
          margin-bottom: 10px;
        }
  
        &:last-child {
          border: none;
  
          @include mq(sp) {
            padding: 0;
            margin: 0;
          }
        }
      }
  
      th {
        width: 210px;
        padding: 15px 10px;
        background-color: #7fe4ff;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
  
        @include mq(sp) {
          width: 100%;
          padding: 10px;
          display: block;
          margin-bottom: 10px;
        }
  
        small {
          font-size: 90%;
        }
      }
  
      td {
        width: calc(100% - 230px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        //margin: 5px 10px;
  
        @include mq(sp) {
          width: 100%;
          margin: 0;
        }
  
        /*
        a:not(.btn) {
          width: 100%;
          color: $blue;
          margin-top: 15px;
  
          @include mq(sp) {
            margin-top: 10px;
          }
        }
        */
        &.photo {
          .img_box {
            width: 100%;
            padding-bottom: 0;
            background: none;
  
            @include mq(sp) {
              margin-bottom: 10px;
            }
  
            img {
              position: relative;
              left: 0;
              width: 160px;
              transform: translate(0,0);
            }
          }
  
          .btn {
            width: 130px;
            //padding: 9px 0;
            font-size: 12px;
            font-weight: bold;
            margin: 15px 0;
  
            label {
              position: relative;
              display: block;
              padding: 10px 0;
              color: #fff;
              cursor: pointer;
            }
  
            input {
              //display: none;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              appearance: none;
              font-size: 0;
              text-indent: -9999px;
              background: none;
            }
  
            &.type3 {
              margin-right: 10px;
              padding: 0;
            }
  
            @include mq(sp) {
              margin: 0;
            }
          }
        }
  
        &.name label {
          display: flex;
          align-items: center;
          align-content: center;
          margin-right: 20px;
  
          @include mq(sp) {
            margin-right: 10px;
          }
  
          input {
            width: 135px;
            margin-left: 5px;
  
            @include mq(sp) {
              width: 100px;
            }
          }
        }
  
        &.zip {
  
          flex-direction: column;
          align-content: flex-start;
          align-items: flex-start;
  
          >.btn {
            width: 140px;
            padding: 8px 0;
            background-color: #dbffff;
            border: 1px $lightgray solid;
            font-size: 12px;
            color: $blue;
            margin: 0;
          }
  
          label {
            display: flex;
            margin: 0 0 10px;
  
            @include mq(sp) {
              margin-top: 15px;
            }
  
            input {
              width: 135px;
              margin-right: 10px;
            }
  
            .btn {
              width: 135px;
              padding: 9px 0;
              font-size: 12px;
            }
          }
        }
  
  
        &.address {
          label {
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 1px $lightgray solid;
            margin-bottom: 10px;
  
            // &:last-child {
            //   padding: 0;
            //   border: none;
            //   margin: 0;
            // }
  
            select,
            input {
              width: 230px;
              margin-top: 5px;
            }
          }
  
          .nest_child {
            &:last-child {
              label {
                padding: 0;
                border: none;
                margin: 0;
              }
            }
          }
        }
  
        &.email>div {
          width: 100%;
  
          // &:nth-of-type(1) {
          //   padding-bottom: 15px;
          //   border-bottom: 1px $lightgray solid;
          //   margin: 20px 0 15px;
          // }
  
          >label {
            display: block;
            margin-bottom: 15px;
  
            &:last-child {
              margin: 0;
            }
  
            >input {
              background-color: #dbffff;
              margin-top: 5px;
            }
  
            >select {
              width: 270px;
              background-color: #fff;
              margin-top: 5px;
            }
  
            .radio {
              margin-top: 5px;
            }
          }
        }
  
        &.tel>div {
          width: 100%;
  
          // &:nth-of-type(1) {
          //   padding-bottom: 15px;
          //   border-bottom: 1px $lightgray solid;
          //   margin: 20px 0 15px;
          // }
  
          >label {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            margin-bottom: 15px;
  
            &:last-child {
              margin: 0;
            }
  
            p {
              width: 100%;
              margin-bottom: 5px;
            }
  
            span {
              margin: 0 10px;
            }
  
            >input {
              margin-top: 5px;
              background-color: #dbffff;
  
              &[type="tel"] {
                background: none;
              }
            }
  
            // input[type="tel"] {
            //   width: 70px;
            //   background-color: #dbffff;
            //   margin: 0;
            // }
  
            label {
              margin-left: 15px;
  
              @include mq(sp) {
                width: 100%;
                margin: 10px 0 0;
              }
            }
          }
        }
  
        &.graduation input {
          width: 250px;
          margin: 0 10px;
  
          @include mq(sp) {
            width: 210px;
          }
        }
  
        .copy_btn {
          width: auto;
          padding: 0;
          background: none;
          font-size: 16px;
          font-weight: bold;
          color: $blue;
          display: flex;
          align-items: center;
          align-content: center;
          margin: 5px 0;
          @include mq(sp) {
            font-size: 14px;
          }
          span {
            width: 20px;
            height: 20px;
            border: 2px $blue solid;
            border-radius: 5px;
            margin-right: 10px;
            @include mq(sp) {
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }
          }
        }
      }
  
      .btn_box {
        display: flex;
        justify-content: center;
  
        @include mq(sp) {
          display: block;
        }
  
        .btn {
          border-radius: 30px;
          position: relative;
  
          @include mq(pc) {
            width: 220px;
            padding: 11px 0;
            font-size: 18px;
            margin: 0 10px;
          }
  
          @include mq(sp) {
            margin-bottom: 15px;
  
            &:last-child {
              margin-bottom: 0;
            }
          }
  
          img {
            width: 8px;
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }
  .error {
    color: #8a0421;
    border-color: #dd0f3b;
    background-color: #ffd9d9;
  }
  @media (min-width: 324px) and (max-width: 329px) {
    #parachannel #mypage .profile table tbody .nest_mobile_handicap {
      padding: 5px 55px 5px 5px;
    }
  }

  @media (min-width: 329px) and (max-width: 339px) {
    #parachannel #mypage .profile table tbody .nest_mobile_handicap {
      padding: 5px 65px 5px 5px;
    }
  }

  @media (min-width: 339px) and (max-width: 349px) {
    #parachannel #mypage .profile table tbody .nest_mobile_handicap {
      padding: 5px 75px 5px 5px;
    }
  }

  @media (min-width: 349px) and (max-width: 359px) {
    #parachannel #mypage .profile table tbody .nest_mobile_handicap {
      padding: 5px 85px 5px 5px;
    }
  }

  @media (min-width: 320px) and (max-width: 327px) {
    #parachannel #mypage .profile table tbody .nest_mobile_other {
      padding: 5px 5px 5px 5px;
    }
  }
  @media (min-width: 327px) and (max-width: 330px) {
    #parachannel #mypage .profile table tbody .nest_mobile_other {
      padding: 5px 10px 5px 5px;
    }
  }
  @media (min-width: 329px) and (max-width: 340px) {
    #parachannel #mypage .profile table tbody .nest_mobile_other {
      padding: 5px 20px 5px 5px;
    }
  }
  @media (min-width: 339px) and (max-width: 354px) {
    #parachannel #mypage .profile table tbody .nest_mobile_other {
      padding: 5px 30px 5px 5px;
    }
  }
  @media (min-width: 354px) and (max-width: 359px) {
    #parachannel #mypage .profile table tbody .nest_mobile_other {
      padding: 5px 35px 5px 5px;
    }
  }
  @media (min-width: 359px) and (max-width: 769px) {
    #parachannel #mypage .profile table tbody .nest_mobile_other {
      padding: 5px 5px 5px 5px;
    }
  }

  