@charset "UTF-8";
@font-face {
  // font-family: "Barlow";
  // src: url("../font/Barlow-Bold.ttf");
font-family: 'Barlow', sans-serif;
}

/* reset */
:root {
  --body-bg-color: #ffffff;
  --body-text-color: #121212;
  --color-white: #ffffff;
  --color1: #00699f;
  --color2: #ffb400;
  --color3: #f2eee6;
  --color4: #fff2d2;
  --color5: #d8d8d8;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -moz-tab-size: 4;
  tab-size: 4;
}

html:focus-within {
  scroll-behavior: auto;
}

body {
  margin: 0;
  min-height: 100vh;
  // text-rendering: optimizeSpeed;
  text-align: left;
  overflow-x: hidden;
  /* set */
  background-color: var(--body-bg-color);
  color: var(--body-text-color);
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  // line-height: 1.6875;
  font-size: 16px;
}

@media screen and (min-width: 950px) {
  body {
    // font-size: 16px !important;
    // line-height: 1.75 !important;
  }
}

main {
  display: block;
}

.main-lp-page {
  margin-bottom: 0px !important;
}

.main-lp-page p,
.main-lp-page table,
.main-lp-page blockquote,
.main-lp-page address,
.main-lp-page pre,
.main-lp-page iframe,
.main-lp-page form,
.main-lp-page figure,
.main-lp-page dl {
  margin: 0;
}

p:not([class]) {
  margin-bottom: 1rem;
}

.main-lp-page h1, .h1,
.main-lp-page h2, .h2,
.main-lp-page h3, .h3,
.main-lp-page h4, .h4,
.main-lp-page h5, .h5,
.main-lp-page h6, .h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

.main-lp-page ol:not([class]), ul:not([class]), dl:not([class]) {
  margin-top: 0;
  margin-bottom: 1rem;
}

.main-lp-page ol:not([class]) ol, ul:not([class]) ul, ol:not([class]) ul, ul:not([class]) ol {
  margin-bottom: 0;
}

.main-lp-page ul[role="list"],
.main-lp-page ol[role="list"] {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-lp-page dt {
  font-weight: bold;
}

.main-lp-page dd {
  margin-left: 0;
}

.main-lp-page dd:not([class]) {
  margin-bottom: .5rem;
}

.main-lp-page hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
}

.main-lp-page pre {
  font-family: monospace, monospace;
  font-size: inherit;
}

.ul_lp{
  list-style-type: disc;
  padding-left: 40px;
}

.main-lp-page address {
  font-style: inherit;
}

.main-lp-page a {
  color: var(--body-text-color);
  background-color: transparent;
  text-decoration: underline;
}

.main-lp-page a[class] {
  background-color: transparent;
  color: inherit;
}

.main-lp-page a[class] {
  text-decoration: none;
}

.main-lp-page a:not([class]) {
  text-decoration-skip-ink: auto;
}

.main-lp-page a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

.main-lp-page a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

.main-lp-page a:not([href]):not([tabindex]):focus {
  outline: 0;
}

.main-lp-page abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

.main-lp-page b,
.main-lp-page strong {
  font-weight: bolder;
}

.main-lp-page code,
.main-lp-page kbd,
.main-lp-page samp {
  font-family: monospace, monospace;
  font-size: inherit;
}

.main-lp-page small {
  font-size: 80%;
}

.main-lp-page sub,
.main-lp-page sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.main-lp-page sub {
  bottom: -0.25em;
}

.main-lp-page sup {
  top: -0.5em;
}

.main-lp-page img {
  vertical-align: middle;
  border-style: none;
  height: auto;
}

.main-lp-page img,
.main-lp-page embed,
.main-lp-page object,
.main-lp-page iframe {
  vertical-align: bottom;
}

.main-lp-page img,
.main-lp-page picture {
  max-width: 100%;
  display: block;
}

.main-lp-page button,
.main-lp-page input,
.main-lp-page optgroup,
.main-lp-page select,
.main-lp-page textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit;
}

[type="checkbox"] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

[type="radio"] {
  -webkit-appearance: radio;
  appearance: radio;
}

.main-lp-page button,
.main-lp-page input {
  overflow: visible;
}

.main-lp-page button,
.main-lp-page select {
  text-transform: none;
}

.main-lp-page button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.main-lp-page button[disabled],
[type="button"][disabled],
[type="reset"][disabled],
[type="submit"][disabled] {
  cursor: default;
}

.main-lp-page button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.main-lp-page button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.main-lp-page select::-ms-expand {
  display: none;
}

.main-lp-page option {
  padding: 0;
}

.main-lp-page fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}

.main-lp-page legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

.main-lp-page progress {
  vertical-align: baseline;
}

.main-lp-page textarea {
  overflow: auto;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  /* 1 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.main-lp-page label[for] {
  cursor: pointer;
}

.main-lp-page details {
  display: block;
}

.main-lp-page summary {
  display: list-item;
}

[contenteditable] {
  outline: none;
}

.main-lp-page table {
  border-collapse: collapse;
  border-spacing: 0;
}

.main-lp-page caption {
  text-align: left;
}

.main-lp-page td,
.main-lp-page th {
  vertical-align: top;
  padding: 0;
}

.main-lp-page th {
  text-align: left;
  font-weight: bold;
}

.main-lp-page template {
  display: none;
}

[hidden] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*

コモン

*/
.all_wrap {
  overflow: hidden;
}

.pc_only {
  display: none;
}

@media screen and (min-width: 950px) {
  .pc_only {
    display: inherit;
  }
}

@media screen and (min-width: 950px) {
  .sp_only {
    display: none;
  }
}

.bold {
  font-weight: bold;
}

.main-lp-page a:hover {
  opacity: 0.7;
}

.contents-container {
  margin: -82px 0 0;
  padding: 82px 20px 0;
}

@media screen and (min-width: 950px) {
  .contents-container {
    max-width: 1140px;
    margin: -80px auto 0;
    padding: 80px 20px 0;
  }
}

.bg_blue {
  background-color: var(--color1);
}

.bg_beige {
  background-color: var(--color3);
}

.bg_yellow {
  background-color: var(--color4);
}

.bg_lattice {
  background-image: url(../../../public/assets/img/bg_lattice.png);
}

.bg_stripe_yellow {
  background-image: url(../../../public/assets/img/bg_stripe_yellow.png);
}

.bg_stripe_blue {
  background-image: url(../../../public/assets/img/bg_stripe_blue.png);
}

.bg_stripe_lite_blue {
  background-image: url(../../../public/assets/img/bg_stripe_lite_blue.png);
}

.main-lp-page h2 {
  margin-bottom: 25px;
  font-size: 30px;
  line-height: 1.375;
  font-weight: bold;
  text-align: center;
}

@media screen and (min-width: 950px) {
  .main-lp-page h2 {
    margin-bottom: 50px;
    font-size: 52px;
  }
}

.button-container {
  margin: 0 20px;
}

.button-container .button {
  margin: 0 auto;
  max-width: 312px;
  height: 68px;
  font-size: 20px;
  font-weight: bold;
  border: 4px solid;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (min-width: 750px) {
  .button-container .button {
    max-width: initial;
    width: 312px;
  }
}

.button-container .button::after {
  content: "";
  display: block;
  width: 11px;
  height: 11px;
  border-top: 3px solid;
  border-right: 3px solid;
  transform: rotate(45deg);
  position: absolute;
  right: 18px;
}

.button-container .button.white_blue {
  color: var(--color1) !important;
  background-color: var(--color-white);
  border-color: var(--color1);
}

.button-container .button.white_blue::after {
  border-color: var(--color1);
}

.button-container .button.yellow {
  color: var(--color-white) !important;
  background-color: var(--color2);
  border-color: var(--color2);
}

.button-container .button.yellow::after {
  border-color: var(--color-white);
}

.button-container .button.white_yellow {
  color: var(--color2) !important;
  background-color: var(--color-white);
  border-color: var(--color2);
}

.button-container .button.white_yellow::after {
  border-color: var(--color2);
}

.cta_box {
  padding: 30px 0;
  color: var(--color-white);
  text-align: center;
  font-weight: bold;
}

.cta_box .h_text {
  margin-bottom: 16px;
  font-size: 19px;
}

@media screen and (min-width: 750px) {
  .cta_box .h_text {
    font-size: 24px;
  }
}

@media screen and (min-width: 750px) {
  .cta_box .flex {
    display: flex;
    justify-content: center;
  }
}

.cta_box .sup_text {
  margin-bottom: 10px;
  line-height: 1;
  position: relative;
  display: inline-block;
  font-size: 16px;
}

@media screen and (min-width: 750px) {
  .cta_box .sup_text {
    font-size: 18px;
  }
}

.cta_box .sup_text::before, .cta_box .sup_text::after {
  content: "";
  display: block;
  width: 2px;
  height: 16px;
  background-color: var(--color-white);
  position: absolute;
  top: 2px;
}

@media screen and (min-width: 750px) {
  .cta_box .sup_text::before, .cta_box .sup_text::after {
    top: 3px;
  }
}

.cta_box .sup_text::before {
  left: -10px;
  transform: rotate(-20deg);
}

.cta_box .sup_text::after {
  right: -10px;
  transform: rotate(20deg);
}

.cta_box .button-container:first-child {
  margin-bottom: 20px;
}

@media screen and (min-width: 750px) {
  .cta_box .button-container:first-child {
    margin-bottom: 0;
  }
}

.rec_header {
  padding: 0px 0 !important;
  width: 100%;
  display: block !important;
  background-color: var(--color-white);
  position: fixed;
  z-index: 99;
}

.rec_header .headeer_wrap {
  margin: 0 auto;
  padding: 6px 0;
  max-width: 1180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1160px) {
  .rec_header .headeer_wrap {
    height: 100px;
  }
}

.rec_header .logo-lp img {
  margin-left: 20px;
  width: 62px !important;
}

@media screen and (min-width: 1160px) {
  .rec_header .logo-lp img {
    width: 79px !important;
  }
}

.lp-menunav {
  color: var(--body-text-color) !important;
}

.lp-menunav:hover {
  color: var(--color1) !important;
  border-bottom: 1px solid var(--color1);
}

.main-lp-page nav ul {
  margin: 0;
  padding: 0;
}

.main-lp-page nav li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-lp-page nav a {
  font-weight: bold;
  text-decoration: none;
}

.PC_nav .lp-menunav:hover {
  color: var(--color1);
  border-bottom: 1px solid var(--color1);
}

.main-lp-page nav a.button1, nav a.button2 {
  margin: 0 auto;
  width: 156px;
  height: 40px;
  border: 2px solid;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-lp-page nav a.button1::after, nav a.button2::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  position: absolute;
  right: 10px;
}

.main-lp-page nav a.button1 {
  margin-right: 10px;
  color: var(--color-white) !important;
  background-color: var(--color2);
  border-color: var(--color2);
}

.main-lp-page nav a.button2 {
  color: var(--color2) !important;
  background-color: var(--color-white);
  border-color: var(--color2);
}

.main-lp-page nav a.txt_link {
  margin: 10px 0;
  margin-right: 0;
  line-height: 1.1;
  color: var(--color2) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.main-lp-page nav a.txt_link span {
  padding-right: 2px;
}

.main-lp-page nav a.txt_link:hover {
  color: var(--color2);
  border-bottom: 1px solid var(--color2);
}

.main-lp-page nav a.txt_link::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
}

.PC_nav {
  display: none;
}

.PC_nav ul li {
  margin-right: 0px !important;
}

@media screen and (min-width: 1160px) {
  .PC_nav {
    display: block;
  }
  .PC_nav ul {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }
  .PC_nav a {
    margin-right: 30px;
    font-size: 14px;
  }
}

/****SPメニュー****/
#sp_menu {
  position: fixed;
  top: 12px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  /****メニュー開閉ボタン****/
  /****メニュー一覧****/
  /****メニューオープン時****/
}

#sp_menu a:hover {
  text-decoration: none;
}

#sp_menu div {
  width: 58px;
  height: 35px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 20px;
}

#sp_menu div .menuopen {
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

#sp_menu div .menuopen span {
  display: block;
  position: absolute;
  width: 25px;
  height: 2px;
  right: 7px;
  background: var(--color1);
}

#sp_menu div .menuopen span:nth-child(1) {
  top: 0;
}

#sp_menu div .menuopen span:nth-child(2) {
  top: 7px;
}

#sp_menu div .menuopen span:nth-child(3) {
  top: 14px;
}

#sp_menu div .menuclose {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
}

#sp_menu div .menuclose span {
  display: block;
  position: absolute;
  width: 25px;
  height: 2px;
  right: 9px;
  background: var(--color1);
}

#sp_menu div .menuclose span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#sp_menu div .menuclose span:nth-child(2) {
  top: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

#sp_menu div .menuclose span:nth-child(3) {
  display: none;
}

#sp_menu div .menuopen span:nth-child(4), #sp_menu div .menuclose span:nth-child(4) {
  width: 100%;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  text-align: right;
  top: 22px;
  right: 0;
  color: var(--color1);
  background-color: transparent;
}

#sp_menu #sp_manu_box {
  width: 100%;
  height: 0;
  position: absolute;
  top: 50px;
  right: 0;
}

#sp_menu #sp_manu_box ul {
  margin: 0 auto;
  max-width: 420px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#sp_menu #sp_manu_box ul li {
  height: 0;
  overflow: hidden;
  pointer-events: auto;
  width: 100%;
  padding: 0 20px;
  line-height: 65px;
}

#sp_menu:target .menuopen {
  display: none;
}

#sp_menu:target .menuclose {
  display: block;
}

#sp_menu:target #sp_manu_box {
  display: block;
  height: 100%;
}

#sp_menu:target #sp_manu_box ul {
  border-top: 1px solid #d4d4d4;
}

#sp_menu:target #sp_manu_box li {
  height: auto;
  border-bottom: 1px solid #d4d4d4;
}

#sp_menu:target #sp_manu_box li:nth-child(n+6) {
  width: auto;
  padding: 25px 5px 0;
  border-bottom: none;
}

#sp_menu:target #sp_manu_box li:nth-child(8) {
  padding: 10px 25px;
  width: 100%;
}

#sp_menu:target #sp_manu_box li a:not([class]) {
  width: 100%;
  display: block;
  position: relative;
}

#sp_menu:target #sp_manu_box li a:not([class])::after {
  content: "";
  display: block;
  width: 8.5px;
  height: 8.5px;
  border-top: 1.5px solid;
  border-right: 1.5px solid;
  transform: rotate(45deg);
  position: absolute;
  right: 0px;
  top: 28px;
}

#sp_menu:target #sp_manu_box a.button1, #sp_menu:target #sp_manu_box a.button2 {
  margin: 0;
  width: 165px;
  height: 55px;
  border-width: 1px;
}

#sp_menu:target #sp_manu_box a.button1::after, #sp_menu:target #sp_manu_box a.button2::after {
  width: 9px;
  height: 9px;
  border-top: 1px solid;
  border-right: 1px solid;
  right: 5px;
}

#sp_menu:target #sp_manu_box a.txt_link {
  justify-content: flex-start;
}

#sp_menu:target #sp_manu_box a.txt_link::after {
  width: 9px;
  height: 9px;
  border-top: 1px solid;
  border-right: 1px solid;
}

@media screen and (min-width: 1160px) {
  #sp_menu {
    display: none;
  }
}

/*** メインエフェクト ***/
#sp_manu_box {
  background-color: var(--color-white);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.section_mv {
  margin-top: 62px;
  background: linear-gradient(0deg, #a9d4ef, #dfeff9);
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 1160px) {
  .section_mv {
    margin-top: 120px;
    padding-top: 0;
  }
}

.section_mv .mv_box img {
  width: auto !important;
}

.section_mv .mv_box {
  margin: auto;
  padding: 0 20px;
  max-width: 650px;
  height: 170.6vw;
}

.section_mv .mv_box::before {
  width: 100%;
  height: 113.3vw;
  content: "";
  display: block;
  background: url(../../../public/assets/img/mv_photo.png) no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: calc(50% - 50vw);
  z-index: -1;
}

@media screen and (min-width: 750px) {
  .section_mv .mv_box {
    height: 1109px;
  }
  .section_mv .mv_box::before {
    width: 630px;
    height: 736px;
    left: calc(50% - 325px);
  }
}

@media screen and (min-width: 950px) {
  .section_mv .mv_box {
    max-width: 1140px;
    height: 570px;
    margin: 0 auto;
  }
  .section_mv .mv_box::before {
    width: 539px;
    height: 516px;
    top: 54px;
    left: calc(50% + 88px);
  }
  .section_mv .mv_box img {
    margin-left: auto;
  }
}

.section_feature {
  padding: 60px 0 15px;
}

@media screen and (min-width: 750px) {
  .section_feature {
    padding: 85px 0 60px;
  }
}

.section_feature .flax_area {
  margin: 0 0 45px;
}
.section_feature .flax_area .link a{
  display: inline-block !important;
}

.text_w_h {
  line-height: 1.6875 !important;
  font-size: 16px !important;
}

@media screen and (min-width: 750px) {
  .section_feature .flax_area {
    display: flex;
  }
  .section_feature .flax_area.reverse {
    flex-direction: row-reverse;
  }
  .section_feature .flax_area.reverse img {
    margin-left: 40px;
    margin-right: 0;
  }
}

.section_feature .flax_area h3 {
  margin: 0 0 10px;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.35;
  color: var(--color1);
}

@media screen and (min-width: 950px) {
  .section_feature .flax_area h3 {
    font-size: 28px;
  }
}

.section_feature .flax_area img {
  margin-right: 40px;
  margin-bottom: 14px;
  width: 100%;
}

@media screen and (min-width: 750px) {
  .section_feature .flax_area img {
    margin-bottom: 0;
    width: 50% !important;
  }
}

@media screen and (min-width: 950px) {
  .section_feature .flax_area img {
    width: auto !important;
  }
}

.section_feature .link::before {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  content: "";
  display: inline-block;
  background: url(../../../public/assets/img/icon_note.png) no-repeat;
  background-size: contain;
  vertical-align: bottom;
}

.section_feature .link a {
  font-weight: bold;
  color: var(--color2) !important;
  text-decoration: none;
}

.section_feature .link a span {
  border-bottom: 1px solid var(--color2);
}

.section_feature .link a span::after {
  content: "";
  display: inline-block;
  border: 5px solid;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.section_function {
  padding: 60px 0 20px;
}

@media screen and (min-width: 750px) {
  .section_function {
    padding: 85px 0 60px;
  }
}

.section_function .function_list {
  margin: 0 -20px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (min-width: 1160px) {
  .section_function .function_list {
    margin: 0;
  }
}

.section_function .function_item {
  margin: 0 20px 40px;
  padding: 30px;
  max-width: 340px;
  text-align: center;
  list-style: none;
  border: 3px solid var(--color1);
  background-color: var(--color-white);
}

@media screen and (min-width: 1160px) {
  .section_function .function_item {
    margin-left: 0;
    margin-right: 40px;
  }
  .section_function .function_item:nth-child(3n) {
    margin-right: 0;
  }
}

.section_function .function_item img {
  margin: 0 0 15px;
  display: inline-block !important;
  width: auto !important;
}

.section_function .function_item h3 {
  margin: 0 0 15px;
  font-size: 24px;
  line-height: 1.3;
  font-weight: bold;
  letter-spacing: 0;
}

.section_function .function_item p {
  margin: 0;
  text-align: left;
}

.section_comparison {
  padding: 60px 0 60px;
}

@media screen and (min-width: 750px) {
  .section_comparison {
    padding: 85px 0 60px;
  }
}

.section_comparison .img_box_comparison {
  margin: 0 20px;
  overflow-x: scroll !important;
}

.section_comparison .img_box_comparison img {
  margin: 0 auto;
  max-width: 800px !important;
  width: auto !important;
}

@media screen and (min-width: 1160px) {
  .section_comparison .img_box_comparison {
    margin: 0;
    overflow-x: visible;
  }
  .section_comparison .img_box_comparison img {
    max-width: 1100px !important;
    width: auto !important;
  }
}

.section_enterprise {
  padding: 60px 0;
  text-align: center;
  background: linear-gradient(0deg, #c8e4f5, #f9fdff);
}

@media screen and (min-width: 750px) {
  .section_enterprise {
    padding: 100px 0;
  }
}

.section_enterprise p {
  margin: 0 0 40px;
  padding: 10px 5px 10px 10px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  display: inline-block;
  color: var(--color-white);
  background-color: var(--color1);
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 950px) {
  .section_enterprise p {
    margin: 0 0 60px;
    padding-left: 20px;
    font-size: 52px;
    line-height: 80px;
  }
}

.section_enterprise p::after {
  content: "";
  display: inline-block;
  width: 33px;
  height: 33px;
  transform: rotate(45deg);
  background-color: var(--color1);
  position: absolute;
  bottom: -5px;
  left: calc(50% - 16px);
  z-index: -1;
}

@media screen and (min-width: 950px) {
  .section_enterprise p::after {
    bottom: -9px;
  }
}

.section_enterprise img {
  margin: 0 auto;
}

.section_voice {
  margin: 0 -10px;
  padding: 60px 0 30px;
}

@media screen and (min-width: 750px) {
  .section_voice {
    padding: 85px 0 60px;
  }
}

.section_voice .voice_list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.section_voice .voice_item {
  margin: 0 20px 40px;
  width: 340px;
  list-style: none;
  background-color: var(--color-white);
  box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
}

@media screen and (min-width: 1160px) {
  .section_voice .voice_item {
    margin-left: 0;
    margin-right: 40px;
    margin: 0 40px 40px 0;
  }
  .section_voice .voice_item:nth-child(3n) {
    margin-right: 0;
  }
}

.section_voice .voice_item img {
  margin: 0 0;
}

.section_voice .voice_item h3 {
  margin: 0 0 10px;
  padding: 0 20px 15px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.38;
}

@media screen and (min-width: 950px) {
  .section_voice .voice_item h3 {
    padding: 0 20px 25px;
    font-size: 24px;
    line-height: 1.58;
  }
}

.section_voice .voice_tag {
  margin: 30px 10px 0 20px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.section_voice .voice_tag li {
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 0 0.5em;
  font-weight: bold;
  list-style: none;
  color: var(--color1);
  border: 1px solid var(--color1);
}

@media screen and (min-width: 750px) {
  .section_voice .voice_tag li {
    border-width: 2px;
  }
}

.section_flow {
  padding: 60px 0;
}

@media screen and (min-width: 750px) {
  .section_flow {
    padding: 100px 0;
  }
}

.section_flow .lead_text {
  width: 90%;
  margin: 0 auto 10px;
  padding-bottom: 9px;
  line-height: 1.2;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  color: var(--color1);
  background: url(../../../public/assets/img/flow_p_bg.png) no-repeat center bottom;
}

@media screen and (min-width: 750px) {
  .section_flow .lead_text {
    width: 25.5em;
    font-size: 24px;
    line-height: 1;
    background-image: url(../../../public/assets/img/flow_p_bg.png);
  }
}

.section_flow .lead_text span {
  width: 100%;
  padding: 11px 0 11px;
  border: 1.5px solid var(--color1);
  border-bottom: none;
  display: inline-block;
}

@media screen and (min-width: 750px) {
  .section_flow .lead_text span {
    border-width: 3px;
  }
}

.section_flow picture img {
  margin: 0 auto;
  max-width: 243px !important;
}

@media screen and (min-width: 950px) {
  .section_flow picture img {
    max-width: 100% !important;
  }
}

.section_thought {
  padding: 60px 0 60px;
}

@media screen and (min-width: 750px) {
  .section_thought {
    padding: 85px 0 100px;
  }
}

.section_thought .thought_movie {
  margin: 0 auto 50px;
  text-align: center;
}

.section_thought .thought_movie iframe {
  width: 90vw;
  height: 47.647vw;
}

@media screen and (min-width: 950px) {
  .section_thought .thought_movie iframe {
    width: 850px;
    height: 450px;
  }
}

@media screen and (min-width: 750px) {
  .section_thought .flex {
    display: flex;
  }
}

.section_thought .flex .img_box_thought {
  margin: 0 auto;
  padding-bottom: 70px;
}

@media screen and (min-width: 750px) {
  .section_thought .flex .img_box_thought {
    margin-right: 40px;
    padding: 0;
    width: 380px;
  }
}

@media screen and (min-width: 750px) {
  .section_thought .flex .text_box {
    width: 680px;
  }
}

.section_thought .thought_name {
  position: relative;
}

.section_thought .thought_name p {
  width: 90%;
  padding: 10px;
  background-color: var(--color1);
  color: var(--color-white);
  opacity: 0.9;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
  display: inline-block;
  position: absolute;
  top: -25px;
  left: 5%;
}

.section_thought .thought_name p span {
  font-size: 20px;
}

@media screen and (min-width: 950px) {
  .section_thought .thought_name p {
    width: 300px;
    left: calc(50% - 150px);
  }
  .section_thought .thought_name p span {
    font-size: 24px;
  }
}

.section_thought .flat_box {
  padding: 10px;
  background-image: url(../../../public/assets/img/bg_stripe_orange.png);
}

.section_thought .flat_box .flat_box_inner {
  padding: 20px 30px;
  background-color: var(--color4);
}

@media screen and (min-width: 750px) {
  .section_thought .flat_box .flat_box_inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.section_thought .flat_box img {
  margin: 0 auto;
  width: 240px !important;
}

@media screen and (min-width: 750px) {
  .section_thought .flat_box img {
    padding-right: 30px;
  }
}

.section_thought .flat_box .text_box {
  padding-top: 20px;
}

@media screen and (min-width: 750px) {
  .section_thought .flat_box .text_box {
    width: 784px;
    padding-top: 0;
    padding-left: 20px;
    border-left: 1px solid var(--color2);
  }
}

.section_thought .flat_box .text_box p {
  margin-bottom: 0;
  font-weight: bold;
}

.section_thought .flat_box .text_box p.link {
  margin-bottom: 0.8em;
  text-align: right;
}

.section_thought .flat_box .text_box p.link a {
  text-decoration: none !important;
  color: var(--color2) !important;
  border-bottom: 1px solid var(--color2);
  display: inline-block !important;
}

.section_thought .flat_box .text_box p.link a::after {
  margin-left: 2px;
  display: inline-block;
  content: "";
  border-left: 8px solid var(--color2);
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.section_thought .text_box {
  font-size: 16px;
  line-height: 1.75;
}

.section_faq {
  padding: 60px 0 20px;
}

@media screen and (min-width: 750px) {
  .section_faq {
    padding: 85px 0 60px;
  }
}

.section_faq .faq_box {
  margin-bottom: 40px;
  padding: 0 0 1px;
  background-color: var(--color-white);
  box-shadow: 0px 5px 14px 1px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 750px) {
  .section_faq .faq_box {
    padding: 0 0 10px;
  }
}

.section_faq .faq_box h3 {
  margin-bottom: 20px;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: bold;
  background-color: var(--color1);
  color: var(--color-white);
}

@media screen and (min-width: 750px) {
  .section_faq .faq_box h3 {
    margin-bottom: 30px;
    padding: 10px 50px;
    font-size: 28px;
  }
}

.section_faq .faq_item {
  margin: 0 20px 20px;
  border: 2px solid var(--color5);
}

@media screen and (min-width: 750px) {
  .section_faq .faq_item {
    margin: 0 40px 20px;
  }
}

.section_faq .question {
  padding: 10px 20px;
  font-size: 18px;
  position: relative;
}

@media screen and (min-width: 750px) {
  .section_faq .question {
    padding: 20px 30px;
    font-size: 24px;
  }
}

.section_faq .question span {
  margin-right: 10px;
  color: var(--color1);
  font-family: Barlow, sans-serif;
  font-weight: 700;
}

.section_faq .question::after {
  content: "";
  display: inline-block;
  width: 11px;
  height: 11px;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(45deg);
  position: absolute;
  top: 19px;
  right: 15px;
}

@media screen and (min-width: 750px) {
  .section_faq .question::after {
    top: 35px;
    right: 30px;
  }
}

.section_faq .question.open::after {
  transform: rotate(-135deg);
  top: 25px;
}

@media screen and (min-width: 750px) {
  .section_faq .question.open::after {
    top: 41px;
  }
}

.section_faq .answer {
  padding: 15px 25px 5px;
  border-top: 1px solid var(--color5);
}

@media screen and (min-width: 750px) {
  .section_faq .answer {
    padding: 15px 55px 5px;
    font-size: 16px !important;
    line-height: 1.75 !important;
  }
  .section_faq .answer a {
    text-decoration: underline !important;
  }
}

.section_inqu {
  padding: 30px 0;
  text-align: center;
}

@media screen and (min-width: 750px) {
  .section_inqu {
    padding: 50px 0;
  }
}

.section_inqu h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--color-white);
}

@media screen and (min-width: 950px) {
  .section_inqu h3 {
    font-size: 32px;
  }
}

.section_inqu p {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-white);
}

@media screen and (min-width: 950px) {
  .section_inqu p {
    font-size: 18px;
  }
}

footer {
  background-color: var(--color-white);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 3em;
}

.scr-target {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
}

.scr-target.is-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 1s;
}

html {
  scroll-behavior: smooth;
}
