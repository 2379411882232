//	編集終了済み表示

#parachannel {

	// テキストエリア
	.textarea_cont {
		width: 100%;
		margin-top: 10px;

		h4 {
			color: #888;

			@include mq(sp) {
				font-size: 110%;
			}
		}

		label {
			display: block;
			margin-bottom: 5px;
		}
	}

	// アップロード
	.upload_cont {
		width: 100%;
		padding: 0;

		label {
			display: block;
			width: 100%;
			height: 100%;
			//padding: 20px;
			cursor: pointer;
		}
		input {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%!important;
			margin: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			font-size: 0;
			text-indent: -9999px;
			background: none;
		}

		.support_txt {
			display: block;
			margin-top: 10px;
			font-weight: normal;
			font-size: 75%;
			color: #fff;

			@include mq(sp) {
				padding: 0 5%;
				text-align: justify;
			}
		}

		.file_upload {
			position: relative;
			width: 60%;
			padding: 0;
			background-color: $blue;
			border-radius: 6px;
			box-shadow: 2px 2px 3px rgba(0,0,0,0.1);
			margin: 0;
			overflow: hidden;
			cursor: pointer;
			@include mq(sp) {
				width: 100%;
			}

			p {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			input[type="file"] {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%!important;
				font-size: 0;
				text-indent: -9999px;
			}

			.text {
				width: 100%;
				font-size: 14px;
				line-height: 18px;
				text-align: center;
				display: block;
				color: #fff;
			}
			.icon {
				padding: 18px 12px;
				background-color: #fff;
				img {
					width: 14px;
					height: 14px;
				}
			}
		}
		> span.support_txt {
			color: $black;
		}

		.file_name {
			margin-top: 5px;
			color: #333;
			font-size: 90%;
		}
	}

	.pr_movie {
		flex-direction: column;

		.upload_cont.btn.type3 {
			position: relative;
			width: 100%;

			& + div {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 10px;

				a {
					display: inline-block;
					margin-left: 10px;
					padding: 3px 20px;
					background-color: $navyblue;
					color: #fff;
					border-radius: 5px;
				}
			}
		}

		.note {
			margin-top: 5px;
		}
	}

	textarea {
		width: 100%;
		border-radius: 6px;
		font-size: 100%;
		font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', sans-serif;
		margin-bottom: 10px;

		+.text_length_alert {
			margin-top: -5px;
			color: $pink;
			text-align: right;
		}
	}

	.nest {
		margin: 0;
		//padding: 5px 60px 5px 10px;
		line-height: 1.8;

		&.on {
			//padding: 0 !important;
		}

		@include mq(sp) {
			//padding: 0 !important;
		}
	}

	.nest_child {
		width: 100%;
		border-bottom: 1px solid $p_gray;

		&:last-of-type,
		&:last-child {
			border-bottom: 0;
		}

		.nest_child {
			margin-left: 0;
		}

		.checkbox_cont {
			.checkbox_list {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				li {
					width: 49%;
					margin: 10px 0;
					label {
						display: flex;
						cursor: pointer;

						input {
							overflow: hidden;
							border-radius: 2px;
							width: 20px;
							height: 18px;
						}
					}
				}
			}

		}

		// 小見出し
		h4 {
			position: relative;
			//margin-bottom: 10px;
			margin-bottom: 5px;
			color: #888;

			@include mq(sp) {
				font-size: 110%;
			}

			&.acc_h4 {
				cursor: pointer;
				padding: 10px 0;
				//padding-right: 15px;

				&:last-child {
					margin-bottom: 0;
				}

				&::before {
					content: '';
					position: absolute;
					right: 5px;
					top: 18px;
					display: inline-block;
					margin-left: 1em;
					width: 6px;
					height: 6px;
					border-bottom: 2px solid $blue;
					border-right: 2px solid $blue;
					transform: rotate(45deg);
					transition: transform .15s linear;
					-webkit-transition: transform .15s linear;
				}

				&.open {
					&::before {
						transform: translate(0, -2px) rotate(225deg);
					}
				}

				&.fixed {
					&::before {
					content: '';
					position: absolute;
					right: 0;
					top: 10px;
					background-image: url(../img/mypage/check_icon.svg);
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					width: 21px;
					height: 21px;
					}
				}

				.any {
					margin-left: 1em;
					padding: .2em .5em;
					font-size: 9px;
					color: #fff;
					background-color: $p_gray;
				}
			}
		}

		// +でモーダル、-でアコーディオン部分
		.acc_list_cont {
			width: 100%;
			margin: 5px 0;

			.skill_list {
				li {
				opacity: .3;
				background-color: rgba(220, 220, 220, .75);

				&.choice {
					opacity: 1;
					background-color: rgba(219, 255, 255, .5);
				}
				}
			}
		}

		&.acc_list_cont {
			border: none;
		}

		&.select_cont {
			width: 100%;
			line-height: 1.8;

			&:last-child {
				margin-top: 20px;

				.edit_box {
					margin-bottom: 0!important;
				}
			}

			.select {
				position: relative;
				margin-bottom: 5px;
				cursor: pointer;
				//padding: 2px 20px 2px 10px;
				color: #adadad;
				border: 1px solid $p_gray;
				border-radius: 6px;
				background-color: #dbffff;
				font-weight: normal;

        		a {
					padding: 2px 20px 2px 10px!important;
				}

				&::after {
					content: '';
					position: absolute;
					top: 10px;
					right: 10px;
					width: 6px;
					height: 6px;
					border-bottom: 2px solid $blue;
					border-right: 2px solid $blue;
					transform: rotate(45deg);
				}

				&.comp {
				  background-color: #fff;
					color: #000;
          font-weight: normal;
				}

        &.disable {
          background-color: #fff;
          font-weight: normal;
          cursor: default!important;
          & > span {
            display: block;
            padding: 2px 20px 2px 10px;
          }
        }

				@include mq(sp) {
					width: 100%;
				}
			}
		}

		&.long {
			textarea {
				height: 240px;
			}
		}
	}

	.text_length_alert {
		font-size: 90%;
		color: #f00;
	}
}
