.media_uploader {
  margin-bottom: 40px;
  text-align: center;
  line-height: 2;

  p {
    font-size: 18px;

    span {
      font-size: 14px;
    }
  }

  #drop-zone {
    background-color: #F1F1F1;
    border: 4px dashed $lightgray !important;
    padding: 30px;
  }
}

#parachannel .modal_box .media_uploader form {
  padding: 20px 20px 0;
}
