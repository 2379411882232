.matomeBody main {
  display: block;
}

.matomeBody p,
.matomeBody table,
.matomeBody blockquote,
.matomeBody address,
.matomeBody pre,
.matomeBody iframe,
.matomeBody form,
.matomeBody figure,
.matomeBody dl {
  margin: 0;
}

.matomeBody p:not([class]) {
  margin-bottom: 1rem;
}

.matomeBody h1,.matomeBody  .h1,
.matomeBody h2,.matomeBody  .h2,
.matomeBody h3,.matomeBody  .h3,
.matomeBody h4,.matomeBody  .h4,
.matomeBody h5,.matomeBody  .h5,
.matomeBody h6,.matomeBody  .h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

.matomeBody ol:not([class]), ul:not([class]), dl:not([class]) {
  margin-top: 0;
  margin-bottom: 1rem;
}

.matomeBody ol:not([class]) ol, ul:not([class]) ul, ol:not([class]) ul, ul:not([class]) ol {
  margin-bottom: 0;
}

.matomeBody ul[role="list"],
.matomeBody ol[role="list"] {
  margin: 0;
  padding: 0;
  list-style: none;
}

.matomeBody dt {
  font-weight: bold;
}

.matomeBody dd {
  margin-left: 0;
}

.matomeBody dd:not([class]) {
  margin-bottom: .5rem;
}

.matomeBody hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
  margin: 0;
  clear: both;
  color: inherit;
}

.matomeBody pre {
  font-family: monospace, monospace;
  font-size: inherit;
}

.matomeBody address {
  font-style: inherit;
}

.matomeBody a {
  color: var(--body-text-color);
  background-color: transparent;
  text-decoration: none;
}

/* //a:hover {
//  opacity: 0.8;
//}

//a[class] {
//  background-color: transparent;
//  color: inherit;
//}
//
//a[class] {
//  text-decoration: none;
//} */

.matomeBody a:not([class]) {
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.5em;
  color: var(--body-text-color);
  word-break: break-all;
  text-decoration: underline;
}

.matomeBody a:not([class]):not([href]) ,
.matomeBody a:not([class]):not([href]):hover ,
.matomeBody a:not([class]):not([href]):focus {
    color: inherit;
    text-decoration: none;
    outline: 0;
}

.matomeBody abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

.matomeBody b,
.matomeBody strong {
  font-weight: bolder;
}

.matomeBody code,
.matomeBody kbd,
.matomeBody samp {
  font-family: monospace, monospace;
  font-size: inherit;
}

.matomeBody small {
  font-size: 80%;
}

.matomeBody sub,
.matomeBody sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.matomeBody sub {
  bottom: -0.25em;
}

.matomeBody sup {
  top: -0.5em;
}

.matomeBody img {
  vertical-align: middle;
  border-style: none;
  -webkit-backface-visibility: hidden;
  height: auto;
}

.matomeBody img,
.matomeBody embed,
.matomeBody object,
.matomeBody iframe {
  vertical-align: bottom;
}

.matomeBody img,
.matomeBody picture {
  max-width: 100%;
  display: block;
}

.matomeBody button,
.matomeBody input,
.matomeBody optgroup,
.matomeBody select,
.matomeBody textarea {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  color: inherit;
  font: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
  border-radius: 0;
  text-align: inherit;
}

[type="checkbox"] {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}

[type="radio"] {
  -webkit-appearance: radio;
  appearance: radio;
}

.matomeBody button,
.matomeBody input {
  overflow: visible;
}

.matomeBody button,
.matomeBody select {
  text-transform: none;
}

.matomeBody button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}

.matomeBody button[disabled],
[type="button"][disabled],
[type="reset"][disabled],
[type="submit"][disabled] {
  cursor: default;
}

.matomeBody button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.matomeBody button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.matomeBody select::-ms-expand {
  display: none;
}

.matomeBody option {
  padding: 0;
}

.matomeBody fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}

.matomeBody legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

.matomeBody progress {
  vertical-align: baseline;
}

.matomeBody textarea {
  overflow: auto;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px; /* 1 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.matomeBody label[for] {
  cursor: pointer;
}

.matomeBody details {
  display: block;
}

.matomeBody summary {
  display: list-item;
}

.matomeBody summary[class]::-webkit-details-marker {
  display: none;
}

[contenteditable] {
  outline: none;
}

.matomeBody table {
  border-collapse: collapse;
  border-spacing: 0;
}

.matomeBody caption {
  text-align: left;
}

.matomeBody td,
.matomeBody th {
  vertical-align: top;
  padding: 0;
}

.matomeBody th {
  text-align: left;
  font-weight: bold;
}

.matomeBody template {
  display: none;
}

[hidden] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
