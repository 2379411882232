$pc: 	769px;
$tab: 	769px;
$sp: 	768px;

@mixin mq($b) {
	
	//PCの場合
	@if $b == pc {
		@media print, screen and (min-width: ($pc)) { @content; }
	
	//tabletの場合
	} @else if $b == tab {
		@media print, screen and (min-width: ($tab)) { @content; }
	
	//SPの場合
	} @else if $b == sp {
		@media print, screen and (max-width: ($sp)) { @content; }
	
	/*横向きの際に適用*/
	} @else if $b == land {
		@media only screen and (orientation : landscape) { @content; }
	}
	
}

@mixin mypage_shadow {
	box-shadow: 2px 2px 3px rgba(0, 0, 0, .1);
}