#parachannel .btn.type3.disabled {
  cursor: not-allowed;
  background-color: gray
}
#parachannel #company .message .contents {
  white-space: pre-wrap;
}

.dispNone {
  display: none !important;
}

.messages-schedule {
  display: inline-block !important;
  color: #231815 !important;
  border-bottom: 1px solid;
  margin-top: 10px !important;
}

.tab-pointer {
  cursor: pointer;
}

#parachannel #company .message_box .note {
  color:red !important;
}

.modal-supp-time {
  width: 35% !important; 
  margin: 0 5px !important;
}

.main-supp-time {
  display: flex; 
  align-items: center;
}

.modal-time {
  width: 18% !important;
}

#parachannel p.ttl {
  margin-bottom: 10px;
  width: 15%;
  font-size: 115%;
  font-weight: bold;
  @media print, screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
}
.modal_inner .error {
  color: #8a0421;
  border-color: #dd0f3b;
  background-color: #ffd9d9;
}
.modal_inner .error_msg {
  color: #8a0421;
}

.change_box {
  padding: 5%;
  li {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-top: solid 1px #efefef;
    @media print, screen and (max-width: 768px) {
      display: block;
    }
    p {
      width: 15%;
      font-size: 115%;
      font-weight: bold;
      @media print, screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    .date_input_cont {
      display: flex;
      align-items: center;
      width: 85%;
      input[type="date"] {
        position: relative;
        width: 30%;
        height: 40px;
        &::-webkit-calendar-picker-indicator {
          position: absolute;
          right: 0;
        }
        @media print, screen and (max-width: 768px) {
          height: 35px;
        }
      }
      input[type="time"] {
        width: 20%;
      }
      @media print, screen and (max-width: 768px) {
        width: 100%;
      }
      input {
        margin: 0 5px;
      }
      span {
        line-height: 40px;
        margin: 0 3px;
      }
      select {
        position: relative;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAANCAYAAABGkiVgAAAACXBIWXMAABCbAAAQmwF0iZxLAAABEUlEQVQ4jZ2T8VXCQAyHP7qAbuAI4ASygQxwecgGjuAIOIFAOkCZwI4gG+gGMEF96Qt9eddKkfzTS5P7Lr9cbrLbaQU8A1uR9MKNplpugCWwL4DaMUvV8u0WpGq5dqBZNWmaJp5ithJJm38ATd2Hu63aFupBq/jJg48i6esK4Bz4dHcvkha2KEKO/Tj4ulYtZyNAi1fu2r7uPrpKPfEe+AbuPHEuko4DQMszJQ/ADzCLebFSPGCSTsA0XGIOrB1oeYv84CLf5L08S5n6JUZb+4E4sNf7HtTB1quVu92oZaNjU9JTQt7TAalx1LZh/S6SXv/adxE6AG7hYy9vUH5mVtF51A7XPOVRaJgIk2/fywb8Ajl9dI573d3wAAAAAElFTkSuQmCC) no-repeat;
        background-size: 15px;
        background-position: right 10px center;
        cursor: pointer;
      }
    }
    .edit_cont {
      display: flex;
      align-items: center;
      width: 20%;
      padding-left: 2%;
      @media print, screen and (max-width: 768px) {
        width: 20%;
      }
      span {
        width: 30px;
        margin: 0 5px;
        @media print, screen and (max-width: 768px) {
          width: 25px;
        }
      }
    }
  }
}