#parachannel .message .name_box {
  select {
    width: 135px;
    height: 30px;
    background: url(../../../../public/assets/img/company/icon04.png) no-repeat;
    background-size: 10px;
    background-position: right 10px center;
    border-radius: 5px;
    cursor: pointer;
    @media screen and (max-width: 769px) {
      width: 160px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
  }
}

.pagination {
    .arrow {
      &.disabled {
        display: none;
      }
    }
  }