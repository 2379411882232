//  mypage - 設定変更

#parachannel #mypage {

  .settings_cont {
    .setting {
      margin-bottom: 72px;

      &:last-child {
        margin-bottom: 30px;
      }

      @include mq(sp) {
        margin-bottom: 30px;

      }
    }

    h2:not(.ttl) {
      color: $blue;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 30px;

      @include mq(sp) {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }

    .info {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 18px;
      font-size: 16px;
      color: $p_gray;
      border-bottom: 1px solid $p_gray;

      @include mq(sp) {
        padding-bottom: 12px;
        font-size: 14px;
      }

      &.status {
        flex-direction: column;
        align-items: flex-start;

        .set_input {
          margin-bottom: 30px;
        }

        .switch {
          background-color: #fff;
          @include mypage_shadow;

          @include mq(sp) {
            width: 100%;
            display: flex;
          }

          a {
            display: inline-block;
            padding: 18px 30px;
            min-width: 180px;
            text-align: center;

            @include mq(sp) {
              min-width: auto;
              padding: 12px 0;
              font-size: 14px;
              width: 50%;
            }

            &.active {
              color: #fff;
              background-color: $green;
            }
          }
        }
      }

      .set_input {
        line-height: 1.6;

        ul {
          li {
            position: relative;
            padding-left: 2em;

            &::before {
              content: '';
              position: absolute;
              top: 6px;
              left: 3px;
              width: 14px;
              height: 14px;
              border: 1px solid $p_gray;

              @include mq(sp) {
                top: 4px;
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }

    }

    .set_btn {
      padding: 18px 60px;
      min-width: 180px;
      margin-left: 20px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      background-color: $blue;
      cursor: pointer;

      @include mq(sp) {
        min-width: 72px;
        padding: 12px 16px;
        font-size: 10px;
      }
    }

    .withdrawal {
      text-align: right;

      a {
        font-size: 16px;
        color: $p_gray;
        text-decoration: underline;

        @include mq(sp) {
          font-size: 12px;
        }
      }
    }

  }
}