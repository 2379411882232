#parachannel {
  #sidebar {
    width: 330px;
    padding-bottom: 50px;
    border-right: 1px $lightgray solid;
    font-weight: bold;
    @include mq(sp) {
      width: 100%;
      padding: 0;
      border-right: none;
      border-bottom: 1px $lightgray solid;
    }
    > div {
      padding: 45px 30px;
      @include mq(pc) {
        height: 100vh;
        overflow: scroll;
        position: sticky;
        top: 0;
      }
      @include mq(sp) {
        padding: 10px 3%;
        display: flex;
        align-items: center;
        align-content: center;
        //justify-content: space-between;
      }
      h1 {
        width: 150px;
        margin: 0 auto 10px;
        @include mq(sp) {
          width: auto;
          margin: 0;
          img {
            width: auto;
            max-width: none;
            height: 60px;
          }
        }
      }

      .page_ttl_area {
        @include mq(sp) {
          padding-left: 20px;
        }
      }
      .page_ttl {
        height: 40px;
        line-height: 40px;
        margin: 10px auto 50px;
        text-align: center;
        font-size: 120%;
        background-color: $blue;
        border-radius: 20px;

        @include mq(sp) {
          display: inline-block;
          height: auto;
          line-height: 1.5;
          margin: 0 0 5px;
          padding: 2px 15px;
          font-size: 100%;
        }

        a {
          display: block;
          width: 100%;
          height: 100%;
          color: #fff;
        }
      }
      .name_box {
        font-size: 16px;
        margin-bottom: 15px;
        @include mq(sp) {
          font-size: 12px;
          margin: 0;
        }
        h2 {
          margin-bottom: 15px;
          @include mq(pc) {
            font-size: 16px;
          }
          @include mq(sp) {
            margin-bottom: 0;
          }
          span {
            display: inline-block;
            font-size: 22px;
            margin: 5px 0;
            @include mq(sp) {
              font-size: 14px;
            }
          }
        }
      }
      nav {
        @include mq(pc) {
          height: auto !important;
          padding: 0 !important;
          display: block !important;
        }
        @include mq(sp) {
          width: 100vw;
          height: calc(100vh - 115px);
          padding: 30px;
          background-color: #fff;
          position: absolute;
          top: 115px;
          left: 0;
          z-index: 9999;
          overflow: scroll;
        }
        ul {
          margin-bottom: 30px;
        }
        > ul > li {
          width: 100%;
          margin-bottom: 30px;
          &:last-child {
            margin: 0;
          }
          a {
            width: 100%;
            font-size: 18px;
            color: #606e84;
            display: flex;
            align-items: center;
            align-content: center;
            img {
              width: 45px;
              margin-right: 10px;
              @include mq(sp) {
                width: 30px;
              }
            }
            span:not(.icon_box) {
              width: 60px;
              padding: 4px 0;
              border: 1px $blue solid;
              font-size: 16px;
              text-align: center;
              color: $blue;
              margin-left: 5px;
              @include mq(sp) {
                width: 50px;
                padding: 3px 0;
                font-size: 12px;
                margin-left: 10px;
              }
            }
          }
          ul {
            margin: 30px 0 70px;
            @include mq(sp) {
              margin: 25px 0 50px;
            }
            li {
              padding-left: 55px;
              margin-bottom: 15px;
              position: relative;
              @include mq(sp) {
                padding-left: 40px;
                a {
                  font-size: 16px;
                }
              }
              &:after {
                content: "";
                width: 5px;
                height: 5px;
                background-color: #606e84;
                border-radius: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 15px;
                margin: auto;
              }
              &:last-child {
                margin: 0;
              }
            }
          }
        }
        .active > a {
          color: $blue;
        }
        .passive > a {
          color: $lightgray;
        }
        .new_icon .icon_box {
          display: inline-block;
          position: relative;
          &:after {
            content: "";
            width: 15px;
            height: 15px;
            background-color: #f00;
            border-radius: 50%;
            position: absolute;
            top: -15px;
            right: -15px;
          }
        }
        .banner {
          margin-top: 30px;
          @include mq(sp) {
            width: 150px;
            margin-top: 25px;
          }
        }
      }
      .menu_btn {
        width: 25px;
        height: 20px;
        position: absolute;
        right: 3%;
        z-index: 999;
        @include mq(pc) {
          display: none;
        }
        span {
          width: 100%;
          height: 2px;
          background-color: $blue;
          position: absolute;
          left: 0;
          transition: all .4s;
          &:nth-of-type(1) {
            top: 0;
          }
          &:nth-of-type(2) {
            top: 9px;
          }
          &:nth-of-type(3) {
            bottom: 0;
          }
        }
        &.active span {
          &:nth-of-type(1) {
            -webkit-transform: translateY(9px) rotate(-45deg);
            transform: translateY(9px) rotate(-45deg);
          }
          &:nth-of-type(2) {
            opacity: 0;
          }
          &:nth-of-type(3) {
            -webkit-transform: translateY(-9px) rotate(45deg);
            transform: translateY(-9px) rotate(45deg);
          }
        }
      }
    }
  }
  &.overflow {
    overflow: hidden;
  }
}
