.toggle-slide-enter-active,
.toggle-slide-leave-active {
  transition: all 0.3s;
  overflow: hidden;
}
.toggle-slide-enter,
.toggle-slide-leave-to {
  height: 0;
  transition: all 0.3s;
  padding: 0 30px;
}

ul.disabled li a {
  cursor: not-allowed;
  color: gray
}