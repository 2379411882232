#recruiment {
    a {
      display: flex !important;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        align-items: flex-start;
      }
      .img_box {
        width: 48%;
        padding-bottom: calc(48%/4*3);
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 768px) {
          width: 45%;
          height: 115px;
          margin-bottom: 10px;
          margin-right: 10px;
        }
        .heart {
          width: 25px;
          position: absolute;
          top: 15px;
          right: 15px;
          @media screen and (max-width: 768px) {
            width: 20px;
            top: 5px;
            right: 5px;
          }
        }
      }
      .text_box {
        width: 47%;
        @media screen and (max-width: 768px) {
          width: 53%;
        }
        h3,h4 {
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }
        h3 {
          color: #00a0e9;
          margin-bottom: 10px;
        }
        p {
          line-height: 18px;
          &.update {
            width: 180px;
            padding: 1px 0;
            background-color: #8cc63f;
            font-weight: bold;
            text-align: center;
            color: #fff;
            margin-bottom: 10px;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
          &.category,
          &.type {
            padding: 0 10px;
            border-radius: 20px;
            display: inline-block;
            margin: 5px 5px 5px 0;
          }
          &.category {
            border: 1px #00a0e9 solid;
            color: #00a0e9;
          }
          &.type {
            border: 1px #ea5514 solid;
            color: #ea5514;
          }
          &.new_graduate,
          &.publish {
            border-radius: 5px;
            color: #fff;
            display: inline-block;
          }
          &.new_graduate {
            padding: 1px 10px;
            background-color: #00a0e9;
            margin: 5px 5px 5px 0;
          }
          &.publish {
            padding: 3px 10px;
            background-color: #f00;
            font-weight: bold;
            margin-bottom: 10px;
          }
          &.address {
            margin-top: 5px;
          }
          strong {
            display: block;
            margin-top: 10px;
            font-weight: bold;
          }
          &.done {
            padding: 6px 0;
            background-color: #e6e6e6;
            border-radius: 5px;
            font-weight: bold;
            text-align: center;
            color: #231815;
            margin-top: 10px;
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
          li {
            width: auto;
            padding: 0 5px!important;
            border-top: 1px #b3b3b3 solid!important;
            border: 1px #b3b3b3 solid;
            border-radius: 5px;
            margin: 0 5px 5px 0!important;
          }
        }
      }
    }
    #jobs>section .cont_box .tab_box>li .jobs_list li.not_text {
      width: 100%;
    }
  }
  #parachannel #jobs > section .sidebar > div p.note {
    margin-top: 7px;
    font-weight: normal;
    font-size: unset;
    color: unset;
  }
  .box {
    width: 100%;
}