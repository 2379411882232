#parachannel #contact {
  .list_box {
    height: 80px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    @include mq(sp) {
      height: 65px;
      padding: 0 3%;
      flex-wrap: wrap;
    }
    p {
      width: 130px;
      margin-right: 30px;
      @include mq(sp) {
        width: 120px;
        margin: 0 auto 10px;
      }
    }
    > div {
      @include mq(sp) {
        overflow: scroll;
      }
      ul {
        display: flex;
        @include mq(sp) {
          width: 620px;
        }
        li {
          margin-right: 10px;
          &:last-child {
            margin: 0;
          }
          a {
            width: 60px;
            padding: 5px 0;
            border-radius: 5px;
            font-weight: bold;
            text-align: center;
            @include mq(pc) {
              font-size: 16px;
            }
            @include mq(sp) {
              padding: 4px 0;
            }
            &:hover {
              background-color: $blue;
              color: #fff;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  h2 {
    margin: 50px auto 30px;
    font-size: 180%;
    font-weight: bold;
    text-align: center;
  }

  h3 {
    margin: 50px auto 10px;
    font-size: 150%;
    font-weight: bold;
    text-align: center;
  }

  .read {
    font-size: 120%;
    text-align: center;
    line-height: 1.5;

    @include mq(sp) {
      font-size: 100%;
    }
  }
  
  .guide_bnr {
    width: 600px;
    margin: 50px auto;

    @include mq(sp) {
      width: 90%;
      margin: 20px auto 30px;
    }
  }

  .mail {
    margin: 50px auto 0;
    padding-top: 50px;
    border-top: solid 1px #ccc;

    @include mq(sp) {
      margin-top: 30px;
      padding-top: 30px;

      .read {
        text-align: justify;
      }
    }
  }

  .mail_area {
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }

    @include mq(pc) {
      width: 640px;
      height: 1250px;
      margin: 0 auto;
    }

    @include mq(sp) {
      width: 100%;
      height: 500px;
    }
  }

  .tel {
    margin: 30px auto 0;
    padding-top: 0;
    border-top: solid 1px #ccc;

    ._flex {
      @include mq(pc) {
        display: flex;
        justify-content: center;
        margin-top: 50px;
      }

      @include mq(sp) {
        margin-top: 30px;
      }

      div {
        width: 40%;
        margin: 0 20px;

        @include mq(sp) {
          width: 100%;
          margin: 20px auto;
        }
      }

      ._left {
        padding: 2%;
        border: solid 1px #000;

        @include mq(sp) {
          padding: 5%;
        }

        h4 {
          text-align: center;
          font-size: 120%;
          font-weight: bold;
          line-height: 1.5;
        }

        p {
          margin: 30px 0;
          font-size: 180%;
          font-weight: bold;
          text-align: center;
          color: #c30d23;

          @include mq(sp) {
            margin: 20px 0;
          }
        }
        small {
          display: block;
          text-align: center;
        }
      }

      ._right {
        h4 {
          font-size: 120%;
          font-weight: bold;
          line-height: 1.5;

          @include mq(sp) {
            margin-top: 30px;
            text-align: center;
          }
        }

        p {
          margin: 10px 0 30px;
          font-size: 120%;
          font-weight: bold;

          @include mq(sp) {
            text-align: center;
          }
        }
        small {
          display: block;
          text-align: center;
        }

        ul {
          li {
            position: relative;
            margin: 5px 0;
            padding-left: 20px;
            line-height: 1.4;

            span {
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }
  }
}