.adminBody{
    /* max-width: 1440px; */
    width: 100%;
    margin: auto;
    background-color: #FDD956;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inBody{
    max-width: 480px;
    max-height: 480px;
    width: 100%;
    border-radius: 8px;
    background: #FFF;
    padding: 60px;
}

.loginForm{
    width: 100%;
}

#adminH1{
    color: #000 !important;
    text-align: center !important;

    /* パラちゃんH1 */
    font-family: Hiragino Kaku Gothic ProN !important;
    font-size: 2rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 2rem !important; /* 100% */
    letter-spacing: -0.03rem !important;
}

#adminH5{
    color: #000 !important;
    text-align: center;
    /* パラちゃん小見出し */
    font-family: Hiragino Kaku Gothic ProN !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 12px !important;
    letter-spacing: -0.18px !important;
    text-align: start;
    margin-top: 5%;
    margin-bottom: 2px;
}


#adminBtn{
    margin-top: 25%;
    width: 360px;
    height: 48px;
    flex-shrink: 0;
    background: #00A0E9;
    color: #FFF;
    text-align: center;
    font-family: Hiragino Kaku Gothic ProN;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 60% */
    letter-spacing: -0.3px;
    
}


.adminInputText{
    width: 360px !important;
    height: 48px !important;
    flex-shrink: 0 !important;
    border: 1px solid #D9D9D9;
}

/* @media print, screen and (min-width: 768px) {
    #adminHome{
        display: flex !important;
    }
} */


@media print, screen and (max-width: 768px) {
    #adminH1{
        color: #000 !important;
        text-align: center !important;
    
        /* パラちゃんH1 */
        font-family: Hiragino Kaku Gothic ProN !important;
        font-size: 1.4rem !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 1rem !important; /* 100% */
        letter-spacing: -0.03rem !important;
        padding-bottom: 10px;
    }
    .adminBody{
        padding: 10px;
    }
    .inBody{
        width: 100%;
        border-radius: 8px;
        background: #FFF;
        padding: 60px;
    }

    .adminInputText{
        width: 100% !important;
        height: 36px !important;
        flex-shrink: 0 !important;
        border: 1px solid #D9D9D9;
    }

    #adminBtn{
        margin-top: 25%;
        width: 100%;
        height: 36px;
        flex-shrink: 0;
        background: #00A0E9;
        color: #FFF;
        text-align: center;
        font-family: Hiragino Kaku Gothic ProN;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px; /* 60% */
        letter-spacing: -0.3px;
        
    }
  }