#parachannel .input_box .main {
    width: 635px;
    margin: 0 auto;
    /*横向きの際に適用*/
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main {
      width: 100%;
    }
  }
  #parachannel .input_box .main dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    margin-bottom: 80px;
    /*横向きの際に適用*/
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main dl {
      margin-bottom: 30px;
    }
  }
  #parachannel .input_box .main dl dt, #parachannel .input_box .main dl dd {
    margin-bottom: 30px;
    /*横向きの際に適用*/
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main dl dt, #parachannel .input_box .main dl dd {
      margin-bottom: 15px;
    }
  }
  #parachannel .input_box .main dl dt:last-of-type, #parachannel .input_box .main dl dd:last-of-type {
    margin: 0;
  }
  #parachannel .input_box .main dl dt {
    width: 140px;
    /*横向きの際に適用*/
  }
  @media print, screen and (min-width: 769px) {
    #parachannel .input_box .main dl dt {
      font-size: 16px;
    }
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main dl dt {
      width: 110px;
    }
  }
  #parachannel .input_box .main dl dt span {
    display: block;
    margin-top: 8px;
    /*横向きの際に適用*/
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main dl dt span {
      margin-top: 5px;
    }
  }
  #parachannel .input_box .main dl dd {
    width: calc(100% - 140px);
    /*横向きの際に適用*/
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main dl dd {
      width: calc(100% - 110px);
    }
  }
  #parachannel .input_box .main dl dd span {
    font-size: 12px;
    color: #f00;
    display: block;
    margin-top: 5px;
    /*横向きの際に適用*/
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main dl dd span {
      font-size: 10px;
    }
  }
  #parachannel .input_box .main .error_text {
    font-size: 12px;
    color: #f00;
    text-align: center;
    margin: -30px 0 30px;
    /*横向きの際に適用*/
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main .error_text {
      font-size: 10px;
      margin: 20px 0;
    }
  }
  #parachannel .input_box .main > a {
    text-align: center;
    color: #b3b3b3;
    margin-top: 15px;
    /*横向きの際に適用*/
  }
  @media print, screen and (min-width: 769px) {
    #parachannel .input_box .main > a {
      font-size: 14px;
    }
  }
  @media print, screen and (max-width: 768px) {
    #parachannel .input_box .main > a {
      margin-top: 10px;
    }
  }