.dnone {
    display: none !important;
}

.dshow {
    display: block !important;
}

.none-scout {
    display: block !important;
    background-color: #f2f2f2 !important;
    pointer-events: none !important;
}