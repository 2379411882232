#parachannel #company .top > div .btn_box {
    .disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
  
    .slide{
      position:relative;
      width: 40%;
      margin:0 auto;
    }
    .right-btn{
      color: #00a0e9 !important;
      position: absolute;
      right: 20px;
      top: 50%;
      font-size: 50px;
      font-weight: bold;
      color:black;
      cursor: pointer;
      user-select: none;
    }
    .left-btn{
      color: #00a0e9 !important;
      position: absolute;
      left: 20px;
      top: 50%;
      font-size: 25px;
      font-size: 50px;
      font-weight: bold;
      color: black;
      cursor: pointer;
      user-select: none;
    }
    
    .item_box {
      padding: 15px;
    }
  
    .item_box > .slider_main_content > a {
      width: 150px !important;
      height: 150px !important;
      /* margin: 15px auto; */
      border-radius: 50%;
  
      img{
        border-radius: 50%;
      }
    }
    .item_box > .slider_main_content > ul{
      width: calc(100% - 220px);
    }
    .item_box > .slider_main_content > ul > li{
      width: 90% !important;
      padding: 10px;
      border-bottom: 1px #b3b3b3 solid;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      position: relative;
      margin: 0 auto;
  
      span{
        color: #b3b3b3;
      }
    }
    /* .item_box > p {
      color: #e44e66;
      text-align: center;
      font-size: 20px;
      margin-bottom: 10px;
    } */
    .item_box > div > .btn_box > a{
      background-color: #73dfbd;
      width: 225px;
      border-radius: 5px !important;
      font-size: 17px !important;
      color: #fff !important;
      text-align: center;
      padding: 13px 0;
      margin: 0 10px 10px 10px;
    }
    .modal_inner > h4{
      background-color: transparent ;
      color: #6c6c6c ;
      // margin-top: 10px !important;
    }
    .recruitment_inner{
      margin: 10px 30px 30px 30px !important;
    }
    .slider_main_content{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      /* margin: 0 auto; */
      justify-content: center;
      align-items: center;
      margin: 70px 0;
    }
    .none{
      background-color: #f2f2f2 !important;
      pointer-events: none !important;
    }
    @media screen and (max-width: 769px) {
      .right-btn{
        font-size: 39px;
      }
      .left-btn{
        font-size: 39px;
      }
      .modal_inner > h4{
        padding: 0 35px !important;
        font-size: 13px !important;
        line-height: 39px !important;
        display: block !important;
        height: auto !important;
        margin-top: 10px !important;
      }
      .slider_main_content{
        display: block !important;
        margin: 15px 0 !important;
      }
      .slider_main_content > ul{
        width: 100% !important;
      }
      .slider_main_content > a{
        margin: 0 auto !important;
        margin-bottom: 10px;
      }
    }

.recruitment_inner .slider .hide {
  display: none;
}