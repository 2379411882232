/* detail */

#parachannel #jobs {

  .detail_other {
    margin-top: 50px;
    padding: 3%;
    background-color: #f2f2f2;
    border: solid 1px #f2f2f2;
    border-radius: 10px;
    justify-content: space-between;
    flex-direction: initial;

    .add_item_box {
      width: 100%;
      margin-bottom: 30px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;

      @include mq(sp) {
        margin-bottom: 15px;
      }

      &.half {
        width: 48%;

        @include mq(sp) {
          width: 100%;
        }
      }

      &.mb0_pc {
        margin-bottom: 0;
      }

      h2 {
        padding: 20px;
        font-size: 120%;
        font-weight: bold;
        background-color: #fff;
        border-radius: 5px 5px 0 0;
        border-bottom: solid 2px $blue;
      }

      ul {
        padding: 15px;
      }

      li {
        margin: 0 auto 10px;

        span {
          display: block;
          margin: 15px 0 5px;
        }

        input {
          border-color: #efefef;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        text-align: center;
        margin: 5px auto;
        padding: 0 15px 15px;
        font-weight: bold;

        button {
          display: inline-block;
          margin-left: 10px;
          padding: 10px 30px;
          background-color: #fff;
          border: solid 1px #00a0e9;
          color: #00a0e9;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }
      }

      dl {
        width: 100%;
        margin: 0 auto;
        padding: 2%;
        border-top: solid 1px #efefef;

        @include mq(pc) {
          display: flex;
          justify-content: space-between;
        }

        @include mq(sp) {
          padding: 2% 2% 5%;
        }
      }

      dt {
        width: 25%;
        line-height: 40px;
        color: #333;
        font-weight: bold;
        font-size: 110%;
        //font-size: 13px;

        @include mq(sp) {
          width: 100%;
        }
      }

      dd {
        width: 75%;

        @include mq(sp) {
          width: 100%;
        }

        label {
          position: relative;
          margin: 1% 0;
          padding-left: 25px;
          line-height: 1.5;
          font-size: 110%;

          input[type="checkbox"] {
            position: absolute;
            left: 0;
            top: 1px;
            display: inline-block;
            vertical-align: middle;
          }
        }

        ._flex {
          display: flex;
          flex-wrap: wrap;

          label {
            margin: 1% 0;

            @include mq(pc) {
              width: calc(95%/5);
            }

            @include mq(sp) {
              width: calc(100%/3);
            }
          }

          &.no_size {
            label {
              @include mq(pc) {
                width: auto;
                margin-right: 30px;
              }
            }
          }

          &.double {
            justify-content: space-between;
            label {
              @include mq(pc) {
                width: 47%;
              }
              @include mq(sp) {
                width: 100%;
              }
            }
          }

          &.three {
            @include mq(sp) {
              justify-content: space-between;
            }
            label {
              @include mq(pc) {
                width: calc(100%/3);
                padding-right: 10px;
              }
              @include mq(sp) {
                width: 48%;
              }
            }
          }
        }

        &.edit_area {
          position: relative;

          p.edit {
            margin: 0;
            padding: 13px 0;
            font-size: 110%;
            font-weight: normal;
            text-align: left;
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            padding: 15px 50px 15px 0;

            &.no_flex {
              display: block;
            }
          }

          li {
            margin: 0 0 5px;
            font-size: 110%;

            &::after {
              content: '、';
              display: inline-block;
              font-size: 100%;
            }

            &:last-child {
              &::after {
                display: none;
              }
            }
          }

          .no_flex {
            li {
              position: relative;
              padding-left: 20px;
              line-height: 1.5;
              &::after {
                content: '・';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }

          .edit_icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            margin: 0;
          }

          .error {
            color: #f00;
          }
        }

        .checkbox_list.area.area_child  {
          margin-top: 30px;
          position: relative;
          z-index: 1;
          padding: 3%;
          background-color: #f8f8f8;

          label {
            @include mq(pc) {
              width: calc(95%/6);

              &:nth-child(6n + 1) {
                margin-left: 0;
              }
            }

            @include mq(sp) {
              width: calc(100%/3);
            }
          }
        }

        button {
          display: block;
          margin: 10px 0 0;
          padding: 2px 30px;
          border: none;
          background-color: #00a0e9;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;

          @include mq(sp) {
            margin: 10px auto;
            padding: 5px 40px;
            font-size: 120%;
          }
        }
      }

      dl.no_flex {
        display: block;

        dd {
          width: 100%;
        }
      }

      //一覧表示用
      .preview_display {
        @include mq(pc) {
          display: flex;
          align-items: center;
        }

        ul {
          width: 75%;

          @include mq(sp) {
            width: 100%;
          }
        }

        li {
          &:last-child {
            margin-bottom: 0;
          }
        }

        p {
          width: 25%;

          @include mq(sp) {
            width: 100%;
          }

          button {
            margin: 10px auto 0;

            @include mq(sp) {
              margin: 0 0 0 20px;
            }
          }
        }
        > .btn {
          width: 150px;
          padding: 13px 0 !important;
          background-color: $blue !important;
          border-radius: 5px !important;
          font-size: 14px !important;
          position: absolute;
          top: 10px;
          right: 20px;
          margin: 0 !important;
          @include mq(sp) {
            width: 100px !important;
            padding: 9px 0 !important;
            font-size: 12px !important;
            top: 14px;
            right: 10px;
          }
        }
      }

    }

    .required {
      position: relative;
      top: -1px;
      font-size: 70%;
    }

    .note {
      font-size: 12px;
      color: #f00;
      margin-left: 10px;
      @include mq(sp) {
        display: block;
        margin: 10px 0 0;
      }
    }
  }

}
