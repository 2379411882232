#parachannel {
  /* 共通 */
  .lp_header {
    @include mq(sp) {
      padding-bottom: 0;
    }
  }
  .lp_footer {
    margin-bottom: 165px;
    @include mq(sp) {
      margin-bottom: 115px;
    }
    .top_btn {
      width: 90px;
      position: fixed;
      right: 15px;
      bottom: 180px;
      @include mq(sp) {
        width: 60px;
        right: 5px;
        bottom: 120px;
      }
    }
    .cont2 .wrapper {
      padding: 30px 0;
      justify-content: center;
    }
  }
  #lp {
    .wrapper {
      @include mq(pc) {
        max-width: 960px;
      }
    }
    .ttl_lp {
      font-size: 28px;
      font-weight: bold;
      line-height: 36px;
      text-align: center;
      display: flex;
      align-items: center;
      align-content: flex-end;
      justify-content: center;
      margin-bottom: 50px;
      @include mq(sp) {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 25px;
        &:before {
          margin-left: 0 !important;
        }
        &:after {
          margin-right: 0 !important;
        }
      }
      img {
        width: 120px;
        height: 80px;
        margin: 0 10px 5px 0;
        @include mq(sp) {
          width: 80px;
          margin-bottom: 5px;
        }
      }
      span {
        font-size: 40px;
        margin: 0 5px 3px;
        @include mq(sp) {
          font-size: 30px;
        }
      }
      &:before,
      &:after {
        content: "";
        width: 20px;
        height: 27px;
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0 40px;
        @include mq(sp) {
          width: 15px;
          height: 20px;
          margin: 0 10px;
        }
      }
      &.type1 {
        color: #fff;
        &:before {
          background-image: url(../img/lp/ttl_icon01.png);
          }
        &:after {
          background-image: url(../img/lp/ttl_icon02.png);
        }
      }
      &.type2 {
        color: $blue;
        &:before {
          background-image: url(../img/lp/ttl_icon03.png);
        }
        &:after {
          background-image: url(../img/lp/ttl_icon04.png);
        }
      }
      &.type3 {
        color: $navyblue;
        &:before {
          background-image: url(../img/lp/ttl_icon05.png);
        }
        &:after {
          background-image: url(../img/lp/ttl_icon06.png);
        }
      }
    }
    .btn {
      width: 300px;
      padding: 21px 0;
      background: #e51b5e url(../img/lp/fixed_icon.png) no-repeat;
      background-size: 10px;
      background-position: right 30px center;
      border-radius: 10px;
      font-size: 18px;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      @include mq(sp) {
        width: 150px;
        padding: 8px 0;
        background-size: 6px;
        background-position: right 10px center;
        border-radius: 5px;
        font-size: 14px;
      }
    }
    /* mv */
    #mv {
      font-weight: bold;
      text-align: center;
      position: relative;
      overflow: hidden;
      > img {
        width: 106%;
        max-width: none;
        margin: 0 -3%;
        @include mq(sp) {
          width: 160%;
          margin: 0 -30%;
        }
      }
      .link_box {
        width: 100%;
        padding: 0;
        position: absolute;
        bottom: 2.5%;
        left: 0;
        margin: 0;
        overflow: initial;
        .btn {
          width: 250px;
          padding: 23px 0;
          background-size: 8px;
          background-position: right 25px center;
          box-shadow: 0 0 5px 1px #fff inset, 3px 3px 5px rgba(90,90,90,0.6);
          font-size: 14px;
          @include mq(sp) {
            width: 160px;
            padding: 7px 0;
            background-size: 5px;
            background-position: right 6px center;
            font-size: 12px;
          }
          &:nth-of-type(2) {
            padding: 24px 0;
            background-image: url(../img/guide/arrow.png);
            background-color: #d6f4ff;
            font-size: 12px;
            color: $blue;
            margin-top: 10px;
            @include mq(sp) {
              padding: 8px 0;
              font-size: 10px;
              margin-top: 5px;
            }
          }
        }
      }
      .fixed_box {
        width: 100%;
        padding: 19px 0;
        background-color: #013f8a;
        font-size: 18px;
        color: #fff;
        position: fixed;
        bottom: -200px;
        left: 0;
        z-index: 999;
        transition: all .5s;
        @include mq(sp) {
          padding: 13px 0;
          font-size: 14px;
          bottom: -115px;
        }
        a {
          color: #fff;
          &.btn {
            width: 400px;
            padding: 18px 0;
            box-shadow: 0 5px 0 #c90144;
            border-radius: 15px;
            font-size: 24px;
            margin-top: 15px;
            @include mq(pc) {
              &:hover {
                transform: translateY(5px);
                box-shadow: none;
                opacity: 1;
              }
            }
            @include mq(sp) {
              width: 250px;
              padding: 11px 0;
              background-size: 10px;
              background-position: right 20px center;
              box-shadow: 0 3px 0 #c90144;
              border-radius: 10px;
              font-size: 18px;
              margin-top: 10px;
            }
          }
          &:nth-of-type(2) {
            font-size: 14px;
            text-decoration: underline;
            margin-top: 20px;
            @include mq(sp) {
              font-size: 12px;
              margin-top: 13px;
            }
          }
        }
        &.active {
          bottom: 0;
        }
      }
    }
    /* worry */
    #worry {
      padding: 75px 0 60px;
      background-color: $blue;
      @include mq(sp) {
        padding: 30px 0;
      }
      .wrapper {
        padding: 65px 80px 70px;
        background-color: #fff;
        border-radius: 50px;
        @include mq(sp) {
          padding: 20px;
          border-radius: 20px;
        }
        .cont1 {
          display: flex;
          margin-bottom: 45px;
          @include mq(sp) {
            display: block;
            margin-bottom: 30px;
          }
          > div {
            width: 50%;
            display: flex;
            align-items: center;
            align-content: center;
            @include mq(sp) {
              width: 100%;
              margin-bottom: 15px;
              &:last-child {
                margin: 0;
              }
            }
            ul {
              width: 68%;
              li {
                height: 100px;
                padding-right: 15px;
                background: url(../img/lp/worry_bg01.png) no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                line-height: 30px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                margin-bottom: 20px;
                @include mq(sp) {
                  height: 80px;
                  padding-right: 10px;
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 10px;
                }
                &:last-child {
                  margin: 0;
                }
              }
            }
            > img {
              width: 32%;
            }
            &:nth-child(2) {
              flex-direction: row-reverse;
              ul li {
                padding: 0 0 0 15px;
                background-image: url(../img/lp/worry_bg02.png);
                @include mq(sp) {
                  padding-left: 10px;
                }
              }
            }
          }
        }
        .cont2 {
          h3 {
            padding-bottom: 40px;
            background: url(../img/lp/worry_bg03.png) no-repeat;
            background-size: 100%;
            background-position: bottom;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: $blue;
            margin-bottom: 15px;
            @include mq(sp) {
              padding-bottom: 25px;
              background-size: auto 15px;
              font-size: 16px;
              line-height: 24px;
            }
          }
          ul {
            display: flex;
            justify-content: space-between;
            @include mq(sp) {
              display: block;
            }
            li {
              width: 30.5%;
              padding: 40px 0 20px;
              background-color: rgba(252,238,33,0.7);
              border-radius: 5px;
              font-weight: bold;
              text-align: center;
              color: $blue;
              position: relative;
              @include mq(sp) {
                width: 100%;
                padding: 20px 0;
                margin-bottom: 20px;
                &:last-child {
                  margin: 0;
                }
              }
              &:after {
                content: "";
                width: 40px;
                height: 100%;
                background: url(../img/lp/worry_icon03.png) no-repeat;
                background-size: 100%;
                background-position: center;
                position: absolute;
                right: -37px;
                bottom: 0;
                z-index: 9;
                pointer-events: none;
                @include mq(sp) {
                  width: 100%;
                  height: 30px;
                  background-size: auto 100%;
                  right: 0;
                  bottom: -25px;
                }
              }
              &:last-child:after {
                display: none;
              }
              h4 {
                width: 130px;
                padding: 8px 0;
                background-color: #fff;
                border-radius: 30px;
                font-size: 24px;
                margin: 0 auto 35px;
                @include mq(sp) {
                  width: 100px;
                  padding: 7px 0;
                  font-size: 16px;
                  margin-bottom: 15px;
                }
              }
              p {
                height: 60px;
                font-size: 16px;
                line-height: 20px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                @include mq(sp) {
                  height: auto;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .cont3 h3 {
          padding: 28px 0;
          background: url(../img/lp/worry_bg04.png) no-repeat;
          background-size: 100% 100%;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          color: #fff;
          margin-bottom: 20px;
          @include mq(sp) {
            padding: 12px 0;
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
      }
    }
    /* feature */
    #feature {
      padding-bottom: 40px;
      background: $blue url(../img/lp/feature_bg.png) no-repeat;
      background-size: 100%;
      background-position: bottom;
      @include mq(sp) {
        padding-bottom: 30px;
      }
      ul {
        max-width: 1100px;
        display: flex;
        justify-content: space-between;
        @include mq(sp) {
          display: block;
        }
        li {
          width: 32.5%;
          padding: 40px 0;
          background-color: #fff;
          line-height: 34px;
          text-align: center;
          font-feature-settings: "palt";
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          align-content: center;
          position: relative;
          @include mq(sp) {
            width: 100%;
            padding: 30px 0;
            margin-bottom: 20px;
            &:last-child {
              margin: 0;
            }
          }
          .number {
            width: 55px;
            position: absolute;
            top: -20px;
            left: 10px;
            @include mq(sp) {
              width: 40px;
              top: -10px;
            }
          }
          h3 {
            width: 100%;
            font-size: 22px;
            font-weight: bold;
            color: $blue;
            @include mq(sp) {
              font-size: 16px;
              line-height: 30px;
            }
            > span {
              background: linear-gradient(transparent 60%, #ff0 60%);
              line-height: 100%;
              display: inline-block;
              > span {
                font-size: 26px;
                @include mq(sp) {
                  font-size: 22px;
                }
              }
            }
          }
          p:not(.number) {
            width: 100%;
            padding-top: 20px;
            border-top: 2px #e5e5e5 dotted;
            margin: 25px 25px 0;
            @include mq(pc) {
              font-size: 16px;
            }
            @include mq(sp) {
              padding-top: 10px;
              line-height: 24px;
              margin: 15px 15px 0;
            }
          }
        }
      }
      > div {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
        margin-top: 30px;
        @include mq(sp) {
          display: block;
          margin-top: 10px;
        }
        h3 {
          width: 270px;
          margin-top: 40px;
          @include mq(sp) {
            width: 42%;
            margin: 0 0 -20% -3%;
            position: relative;
          }
        }
        > img {
          width: 675px;
          @include mq(sp) {
            width: 95%;
            margin-left: auto;
          }
        }
      }
      .btn {
        width: 350px;
        padding: 30px 0;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
        font-size: 20px;
        margin-top: 20px;
        @include mq(sp) {
          width: 200px;
          padding: 18px 0;
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
    /* flow */
    #flow {
      padding: 80px 0 60px;
      background-color: #d7f5ff;
      @include mq(sp) {
        padding: 30px 0;
      }
      ul {
        display: flex;
        justify-content: center;
        margin-bottom: 65px;
        @include mq(sp) {
          display: block;
          margin-bottom: 25px;
        }
        li {
          width: 25%;
          padding: 35px 20px 20px;
          background-color: #fff;
          margin: 0 8px;
          position: relative;
          @include mq(sp) {
            width: 100%;
            padding: 30px;
            margin: 0 0 20px;
            &:last-child {
              margin: 0;
            }
          }
          &:after {
            content: "";
            width: 40px;
            height: 100%;
            background: url(../img/lp/flow_icon01.png) no-repeat;
            background-size: 100%;
            background-position: center;
            position: absolute;
            right: -25px;
            bottom: 0;
            z-index: 9;
            pointer-events: none;
            @include mq(sp) {
              width: 100%;
              height: 40px;
              background-size: auto 100%;
              right: 0;
              bottom: -30px;
              transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
            }
          }
          &:last-child:after {
            display: none;
          }
          p {
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            color: $blue;
            margin-bottom: 35px;
            @include mq(sp) {
              font-size: 16px;
              margin-bottom: 25px;
            }
          }
        }
      }
      .wrapper > div {
        width: 100vw;
        padding: 30px 0 15px;
        background: url(../img/lp/flow_bg01.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        margin: -15px 0 35px;
        @include mq(pc) {
          min-width: 1000px;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
        }
        @include mq(sp) {
          padding: 20px 3%;
          margin: 0 0 25px;
        }
        > img {
          width: 48.5%;
          max-width: 620px;
          margin-right: 3.5%;
          @include mq(sp) {
            width: 100%;
            margin: 0;
          }
        }
        .text_box {
          width: 430px;
          padding: 40px 0 30px;
          background-color: #fff;
          text-align: center;
          margin-top: 35px;
          position: relative;
          @include mq(sp) {
            width: 100%;
          }
          h3 {
            font-size: 28px;
            font-weight: bold;
            line-height: 48px;
            color: $navyblue;
            margin-bottom: 20px;
            position: relative;
            @include mq(sp) {
              font-size: 20px;
              line-height: 40px;
              margin-bottom: 15px;
            }
            &:after {
              content: "";
              width: 30px;
              height: 10px;
              background-color: $yellow;
              position: absolute;
              top: 20px;
              left: 0;
              @include mq(sp) {
                width: 20px;
                height: 5px;
              }
            }
            span {
              background:linear-gradient(transparent 90%, $yellow 90%);
              display: inline-block;
            }
          }
          p {
            font-size: 18px;
            line-height: 33px;
            @include mq(sp) {
              font-size: 14px;
              line-height: 24px;
            }
            &:nth-of-type(1) {
              width: 100%;
              padding: 15px 0 30px;
              background: url(../img/lp/flow_bg02.png) no-repeat;
              background-size: auto 100%;
              background-position: center;
              font-weight: bold;
              line-height: 25px;
              color: #fff;
              position: absolute;
              top: -50px;
              left: 0;
              @include mq(sp) {
                padding: 10px 0 22px;
                line-height: 20px;
                top: -25px;
              }
            }
          }
        }
      }
      .btn {
        width: 640px;
        padding: 28px 0;
        background-image: none;
        background-color: #f05a24;
        box-shadow: 0 0 15px 5px #fff inset, 3px 3px 5px rgba(90,90,90,0.8);
        border-radius: 100px;
        font-size: 25px;
        @include mq(sp) {
          width: 100%;
          padding: 20px 0;
          font-size: 20px;
        }
        span {
          font-size: 20px;
          display: block;
          margin-bottom: 15px;
          @include mq(sp) {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
        img {
          width: 30px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 35px;
          @include mq(sp) {
            width: 25px;
            margin-left: 10px;
          }
        }
      }
    }
    /* project */
    #project {
      background-color: #d7f5ff;
      .wrapper {
        max-width: 1000px;
        padding: 35px 45px;
        background-color: #fff;
        @include mq(sp) {
          padding: 30px 15px;
        }
        .ttl_lp {
          @include mq(pc) {
            font-size: 26px;
            line-height: 44px;
            margin-bottom: 25px;
          }
        }
        p {
          line-height: 36px;
          text-align: center;
          @include mq(pc) {
            font-size: 18px;
          }
          @include mq(sp) {
            line-height: 24px;
          }
          span {
            font-weight: bold;
            color: $blue;
          }
        }
        > div {
          &:nth-of-type(1) {
            margin: 65px 0 40px;
            @include mq(sp) {
              margin: 30px 0;
            }
            ul {
              display: flex;
              justify-content: space-between;
              margin-bottom: 100px;
              @include mq(sp) {
                display: block;
                margin-bottom: 30px;
                position: relative;
              }
              li {
                width: 48.5%;
                padding: 30px;
                border: 10px solid;
                position: relative;
                @include mq(sp) {
                  width: 100%;
                  padding: 20px;
                  border-width: 5px;
                  margin-bottom: 15px;
                  position: static;
                  &:last-child {
                    margin: 0;
                  }
                }
                p {
                  margin-top: 25px;
                  @include mq(sp) {
                    margin-top: 15px;
                  }
                }
                > img {
                  width: 75px;
                  position: absolute;
                  bottom: -80px;
                  @include mq(sp) {
                    width: 40px;
                    bottom: -35px;
                  }
                }
                &:nth-child(1) {
                  border-color: #d7f5ff;
                  > img {
                    right: 90px;
                    @include mq(sp) {
                      right: none;
                      left: 20px;
                    }
                  }
                }
                &:nth-child(2) {
                  border-color: rgba(5,106,135,0.1);
                  > img {
                    left: 90px;
                    @include mq(sp) {
                      right: 20px;
                      left: auto;
                    }
                  }
                }
              }
            }
            > h3 {
              width: 440px;
              margin: 0 auto 45px;
              @include mq(sp) {
                width: 100%;
                margin-bottom: 25px;
              }
            }
          }
          &:nth-of-type(2) {
            h3 {
              padding: 30px 0 55px;
              background: url(../img/lp/project_bg.png) no-repeat;
              background-size: 100% 100%;
              font-size: 20px;
              font-weight: bold;
              text-align: center;
              color: $blue;
              margin-bottom: 20px;
              @include mq(sp) {
                padding: 18px 0 30px;
                font-size: 16px;
                margin-bottom: 10px;
              }
            }
            ul {
              display: flex;
              justify-content: space-between;
              @include mq(sp) {
                display: block;
              }
              li {
                width: 48.5%;
                @include mq(sp) {
                  width: 100%;
                  margin-bottom: 20px;
                  &:last-child {
                    margin: 0;
                  }
                }
                > div {
                  height: 0;
                  padding-bottom: 56.25%;
                  position: relative;
                  overflow: hidden;
                  iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
                p {
                  line-height: 24px;
                  font-weight: bold;
                  color: $navyblue;
                  margin-top: 20px;
                  @include mq(pc) {
                    font-size: 16px;
                  }
                  @include mq(sp) {
                    line-height: 20px;
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    /* about */
    #about {
      padding: 30px 0;
      background: url(../img/lp/about_bg.jpg) no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      @include mq(sp) {
        padding: 20px 0;
      }
      .btn {
        width: 350px;
        padding: 30px 0;
        box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
        font-size: 20px;
        margin: 0;
        @include mq(sp) {
          width: 200px;
          padding: 18px 0;
          font-size: 14px;
        }
      }
      > img {
        width: 105px;
        margin: 0 40px;
        @include mq(sp) {
          width: 50px;
          margin: 0 10px;
        }
      }
    }
    /* voice */
    #voice {
      background-color: #d7f5ff;
      .wrapper {
        max-width: 1000px;
        padding: 65px 50px 0;
        background-color: #fff;
        @include mq(sp) {
          padding: 30px 15px 0;
        }
        ul {
          padding: 45px 50px;
          background-color: #d7f5ff;
          @include mq(sp) {
            padding: 20px;
          }
          li {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            margin-bottom: 20px;
            @include mq(sp) {
              margin-bottom: 15px;
            }
            &:nth-child(2n) {
              flex-direction: row-reverse;
              > div {
                > p {
                  text-align: right;
                }
                > div {
                  padding: 0 20px 0 0;
                  background-image: url(../img/lp/voice_bg02_pc.png);
                  @include mq(sp) {
                    padding: 5px 15px 5px 5px;
                    background-image: url(../img/lp/voice_bg02_sp.png);
                  }
                }
              }
            }
            &:last-child {
              margin: 0;
            }
            img {
              width: 120px;
              border-radius: 50%;
              @include mq(sp) {
                width: 60px;
              }
            }
            > div {
              width: calc(100% - 135px);
              @include mq(sp) {
                width: calc(100% - 70px);
              }
              > p {
                padding: 0 25px;
                font-weight: bold;
                color: $navyblue;
                margin-bottom: 10px;
                @include mq(pc) {
                  font-size: 16px;
                }
                @include mq(sp) {
                  padding: 0 10px;
                  margin-bottom: 5px;
                }
              }
              > div {
                width: 100%;
                height: 90px;
                padding-left: 20px;
                background: url(../img/lp/voice_bg01_pc.png) no-repeat;
                background-size: 100% 100%;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                @include mq(sp) {
                  height: auto;
                  padding: 5px 5px 5px 15px;
                  background-image: url(../img/lp/voice_bg01_sp.png);
                }
                p {
                  font-weight: bold;
                  line-height: 28px;
                  text-align: center;
                  @include mq(pc) {
                    font-size: 16px;
                  }
                  @include mq(sp) {
                    line-height: 18px;
                    text-align: left;
                    br {
                      display: none;
                    }
                  }
                  span {
                    color: $blue;
                  }
                }
              }
            }
          }
        }
      }
    }
    /* comparison */
    #comparison {
      background-color: #d7f5ff;
      .wrapper {
        max-width: 1000px;
        padding: 80px 50px 70px;
        background-color: #fff;
        @include mq(sp) {
          padding: 30px 15px;
        }
        table {
          width: 100%;
          tr {
            background-color: #fff;
            border-bottom: 1px #fff solid;
            &:last-child {
              border: none;
            }
            th,td {
              border-right: 1px #fff solid;
              text-align: center;
              vertical-align: middle;
              &:last-child {
                border: none;
              }
            }
            th {
              background-color: #4472c4;
              font-weight: bold;
              color: #fff;
              @include mq(pc) {
                font-size: 16px;
              }
            }
            td {
              height: 125px;
              background-color: rgba(240,240,240,0.2);
              @include mq(sp) {
                height: 110px;
              }
              img {
                width: 55px;
                margin: 0 auto;
                @include mq(sp) {
                  width: 40px;
                }
              }
              p {
                line-height: 18px;
                margin-top: 10px;
                @include mq(sp) {
                  font-size: 10px;
                  line-height: 12px;
                  margin-top: 5px;
                }
              }
              &:nth-child(2) p {
                font-size: 16px;
                font-weight: bold;
                color: #e51b5e;
                @include mq(sp) {
                  font-size: 12px;
                  line-height: 14px;
                }
              }
            }
            &:nth-child(1) th {
              width: 28%;
              height: 65px;
              @include mq(sp) {
                width: 25%;
                height: 50px;
              }
              &:nth-child(1) {
                width: 12%;
                background: none;
                background-color: #fff;
                @include mq(sp) {
                  width: 20%;
                }
              }
              &:nth-child(2) {
                width: 32%;
                @include mq(sp) {
                  width: 30%;
                }
              }
            }
            th:nth-child(1),
            &:nth-child(1) th:nth-child(2) {
              background: url(../img/lp/about_bg.jpg) no-repeat;
              color: $navyblue;
            }
            &:nth-child(2n+1) td {
              background-color: rgba(207,213,234,0.3);
            }
          }
        }
        > p {
          text-align: right;
          margin-top: 15px;
          @include mq(sp) {
            font-size: 10px;
            margin-top: 10px;
          }
          a {
            text-decoration: underline;
            display: inline-block;
          }
        }
      }
    }
    /* faq */
    #faq {
      padding-top: 70px;
      @include mq(sp) {
        padding-top: 30px;
      }
      ul {
        padding: 60px 100px 110px;
        border: 8px #d7f5ff solid;
        @include mq(sp) {
          padding: 20px;
          border-width: 4px;
        }
        li {
          margin-bottom: 65px;
          @include mq(sp) {
            margin-bottom: 30px;
          }
          &:last-child {
            margin: 0;
          }
          h3,p {
            padding: 0 60px 0 145px;
            position: relative;
            @include mq(sp) {
              padding: 0 5px 0 30px;
            }
            &:after {
              position: absolute;
              top: 0;
              left: 100px;
              @include mq(sp) {
                left: 5px;
              }
            }
          }
          h3 {
            padding-bottom: 20px;
            background: url(../img/lp/faq_icon01.png) no-repeat;
            background-size: 10px;
            background-position: top 10px right 15px;
            border-bottom: 1px $navyblue solid;
            font-size: 20px;
            font-weight: bold;
            color: $blue;
            cursor: pointer;
            @include mq(sp) {
              padding-bottom: 10px;
              background-position: top 5px right 5px;
              font-size: 14px;
            }
            &:after {
              content: "Q.";
            }
            &.active {
              background-image: url(../img/lp/faq_icon02.png);
            }
          }
          p {
            display: none;
            line-height: 28px;
            margin-top: 25px;
            @include mq(pc) {
              font-size: 16px;
            }
            @include mq(sp) {
              line-height: 24px;
              margin-top: 10px;
            }
            &:after {
              content: "A.";
              font-size: 20px;
              font-weight: bold;
              @include mq(sp) {
                font-size: 14px;
              }
            }
            a {
              text-decoration: underline;
              word-wrap: break-word;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}